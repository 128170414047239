.gw-feature-card {
    @include bp-medium {
        width: unset;
    }

    max-width: 30rem;

    &__divider,
    &__title {
        @include bp-medium {
            margin-block-end: $gutter-l;
        }

        margin-block-end: $gutter-s;
    }

    &__desc {
        color: $color-black;
        font-family: $font-family-bold;
        margin-block-end: 0;
    }

    &__divider {
        @include bp-medium {
            height: 5px;
            width: 100px;
        }

        background-color: $color-brand;
        height: 3px;
        width: 60px;

        & + p {
            @include bp-medium {
                margin-block-end: $gutter-xl;
            }

            margin-block-end: $gutter;
        }
    }

    &__feature {
        align-items: center;
        display: flex;
        margin-block-end: $gutter;

        &:last-child {
            margin-block-end: 0;
        }
    }

    &__icon {
        align-items: center;
        background-color: $color-grey-1;
        display: flex;
        height: 3rem;
        justify-content: center;
        margin-inline-end: $gutter;
        width: 3rem;

        .gw-icon {
            fill: $color-brand;
            -moz-transform: scale(1.5);
            -o-transform: scale(1.5);
            -webkit-transform: scale(1.5);
            transform: scale(1.5);
        }
    }

    &__title {
        @include bp-medium {
            font-size: $font-size-xl;
        }

        font-size: $font-size-m;
    }

    &.gw-card {
        padding: 0;

        &:hover {
            box-shadow: none;
            transform: none;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-feature-card) {
    @include gw-feature-card;
}
