@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');

.gw-clean-car-rating,
.gw-clean-car-rating * {
    box-sizing: border-box;
}

.gw-clean-car-rating {
    background: $color-grey-1;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 2rem auto;
    max-width: 50rem; // 800px
    padding: $gutter-xs;
    width: 100%;

    &__container {
        background-color: $color-white;
        padding: $gutter;

        > .gw-clean-car-rating {
            // TODO: This can be removed, alongside the max-width and margin set above once this is made a global change.
            margin: 0;
            max-width: unset;
        }
    }

    &__readout {
        background: $color-positive-alt;
        color: $color-text;
        flex: 0 0 100%;
        margin-block-end: $gutter-xs;
        padding: $gutter-xs;
        padding-inline-start: $gutter-s;
        position: relative;

        &:before {
            background: $color-positive;
            content: '';
            height: 100%;
            inset-block-start: 0;
            inset-inline-start: 0;
            position: absolute;
            width: $gutter-xs;
        }
    }

    &__column {
        @media screen and (min-width: 850px) {
            flex: 0 0 calc(50% - 5px);
            padding: $gutter-s;
        }

        background: $color-white;
        flex: 0 0 100%;
        padding: $gutter-s $gutter-s 0;

        &:nth-child(3) {
            padding: $gutter-xs $gutter-s $gutter-s;
        }

        &-item {
            display: flex;
            flex-flow: column;
            position: relative;

            &:not(:last-child) {
                margin-block-end: $gutter-xs;
            }

            &[data-tooltip] {
                /* stylelint-disable max-nesting-depth */
                &:before {
                    background: $color-grey-1;
                    content: attr(data-tooltip);
                    display: none;
                    inset-block-end: 100%;
                    inset-inline-start: 100%;
                    min-width: 9.5rem;
                    padding: $gutter-xs;
                    position: absolute;
                    text-align: center;
                    transform: translateX(calc(-50% - $gutter-xs));
                    z-index: z('default');
                }

                &:after {
                    background: $color-brand;
                    border-radius: 6rem;
                    color: $color-white;
                    content: 'i';
                    display: flex;
                    font-family: 'Times New Roman', serif;
                    font-size: 1rem;
                    font-weight: 900;
                    height: $gutter-s;
                    inset-inline-end: 0;
                    justify-content: space-around;
                    line-height: 1.2;
                    padding-inline-start: 0;
                    position: absolute;
                    top: 2px;
                    width: $gutter-s;
                }

                &:hover {
                    &:before {
                        display: block;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }

            &--left img {
                display: none;
            }

            &--right {
                margin-block-start: math.div($gutter-s, 4);
                min-height: 23px;
                /* stylelint-disable max-nesting-depth */
                &:after {
                    color: $color-brand-alt;
                    display: inline-block;
                    font-family: FontAwesome, sans-serif;
                    font-size: 1rem;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }

    &__eligibility {
        background: $color-white;
        display: flex;
        flex-flow: row wrap;
        margin-block-start: $gutter-xs;
        padding: $gutter-s;
        width: 100%;

        &--check {
            flex: 0 0 100%;
            margin-block-start: $gutter-xs;
        }

        :first-child {
            margin-block-start: 0;
        }
    }

    .icon-true,
    .icon-false {
        position: relative;

        &:after {
            color: $color-brand-alt;
            font-family: FontAwesome, sans-serif;
            font-size: 1rem;
            inset-block-start: 0;
            inset-inline-start: 0;
            position: absolute;
        }
    }

    .icon-true {
        padding-inline-start: $gutter-s;

        &:after {
            color: $color-positive-alt;
            content: '\f14a';
        }
    }

    .icon-false {
        padding-inline-start: $gutter-s;

        &:after {
            color: $color-negative-alt;
            content: '\f2d3';
            opacity: 0.3;
        }
    }

    .srnone:after {
        content: 'Vehicle has no rating';
        font-family: sans-serif;
    }

    .sr000:after {
        content: '\f006\f006\f006\f006\f006\f006';
    }

    .sr05:after,
    .sr100:after {
        content: '\f123\f006\f006\f006\f006\f006';
    }

    .sr10:after,
    .sr200:after {
        content: '\f005\f006\f006\f006\f006\f006';
    }

    .sr15:after,
    .sr300:after {
        content: '\f005\f123\f006\f006\f006\f006';
    }

    .sr20:after,
    .sr400:after {
        content: '\f005\f005\f006\f006\f006\f006';
    }

    .sr25:after,
    .sr500:after {
        content: '\f005\f005\f123\f006\f006\f006';
    }

    .sr30:after,
    .sr600:after {
        content: '\f005\f005\f005\f006\f006\f006';
    }

    .sr35:after,
    .sr700:after {
        content: '\f005\f005\f005\f123\f006\f006';
    }

    .sr40:after,
    .sr800:after {
        content: '\f005\f005\f005\f005\f006\f006';
    }

    .sr45:after,
    .sr900:after {
        content: '\f005\f005\f005\f005\f123\f006';
    }

    .sr50:after,
    .sr1000:after {
        content: '\f005\f005\f005\f005\f005\f006';
    }

    .sr55:after,
    .sr1100:after {
        content: '\f005\f005\f005\f005\f005\f123';
    }

    .sr60:after,
    .sr1200:after {
        content: '\f005\f005\f005\f005\f005\f005';
    }

    .sr0:after,
    .sr00:after {
        content: '\f006\f006\f006\f006\f006';
    }

    .sr01:after {
        content: '\f123\f006\f006\f006\f006';
    }

    .sr02:after {
        content: '\f005\f006\f006\f006\f006';
    }

    .sr03:after {
        content: '\f005\f123\f006\f006\f006';
    }

    .sr04:after {
        content: '\f005\f005\f006\f006\f006';
    }

    .sr05:after {
        content: '\f005\f005\f123\f006\f006';
    }

    .sr06:after {
        content: '\f005\f005\f005\f006\f006';
    }

    .sr07:after {
        content: '\f005\f005\f005\f123\f006';
    }

    .sr08:after {
        content: '\f005\f005\f005\f005\f006';
    }

    .sr09:after {
        content: '\f005\f005\f005\f005\f123';
    }

    .sr010:after {
        content: '\f005\f005\f005\f005\f005';
    }
    /* stylelint-disable no-duplicate-selectors */
    .sr0:after,
    .sr01:after,
    .sr02:after,
    .sr03:after,
    .sr04:after,
    .sr05:after,
    .sr06:after,
    .sr07:after,
    .sr08:after,
    .sr09:after,
    .sr010:after {
        display: inline-block;
        font-family: FontAwesome, sans-serif;
        font-size: 1rem;
    }
    /* stylelint-enable no-duplicate-selectors */
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-clean-car-rating) {
    @include gw-clean-car-rating;
}
