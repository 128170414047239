@mixin gw-breadcrumb {
    .gw-breadcrumb {
        background-color: #{rgba(var(--themeWhite), 0.6)};

        &__item {
            margin: 0;
            padding-block-end: math.div($gutter-s, 2);
            padding-block-start: math.div($gutter-s, 2);
            padding-inline-end: $gutter-s;

            &:not(:last-child):after {
                content: '/';
                font-family: $font-family;
                color: $color-link;
                inset-block-start: calc(50% - 0.5rem);
                inset-inline-start: calc(100% - #{$gutter-s} + 0.375rem);
                width: 0.375rem;
            }
        }

        &__link {
            padding: math.div($gutter-s, 2) 0;

            &:not([aria-current]) {
                color: $color-link;
            }
        }
    }
}
