// News details.
.page-904,
.page-default-newsdetail {
    .gw-cms {
        @include layout-centered-content;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(page-904-news-details) {
    @include page-904-news-details;
}
