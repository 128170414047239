/*
----------------------------------------------------------------------------
CSS variables for dynamic theming.

- Color values are specified as a comma-separated list of RGB values. This allows us to leverage the Sass 'rgba()' function.
- Theme overrides can be applied via the CMS, rendered in HTML <style> blocks.
- Legacy browsers can only leverage CSS variables if they are defined inside ':root' element.
----------------------------------------------------------------------------
*/

:root {
    --themeColorBrand: 0, 9, 91;
    --themeColorBrandAlt: 6, 111, 239;
    --themeColorBrandAlt2: 23, 0, 244;
    --themeColorBackground: 255, 255, 255; // Only use on <body>.
    --themeColorBackgroundAlt: 51, 51, 51;
    --themeColorText: 31, 48, 71;
    --themeColorTextAlt: 255, 255, 255;
    --themeColorTextSecondary: 107, 119, 134;
    --themeColorTextSecondaryAlt: 245, 246, 247;
    --themeColorLink: 6, 111, 239;
    --themeColorLinkHover: 4, 78, 167;
    --themeColorLinkAlt: 131, 183, 247;
    --themeColorLinkAltHover: 36, 128, 241;

    --themeBlack: 0, 0, 0;
    --themeWhite: 255, 255, 255;
    --themeGrey1: 245, 246, 247; // Use on components instead of themeColorBackground.
    --themeGrey2: 209, 213, 217;
    --themeGrey3: 156, 163, 173;
    --themeGrey4: 56, 72, 92;
    --themeGrey5: 0, 20, 46;

    --themeAccentPositive: 0, 130, 0;
    --themeAccentPositiveAlt: 51, 155, 51;
    --themeAccentNegative: 191, 21, 44;
    --themeAccentNegativeAlt: 232, 72, 83;
    --themeAccentNeutral: 177, 98, 1;
    --themeAccentNeutralAlt: 221, 122, 1;

    // multi-franchise specific colours, not applicable currently!
    --themeColorHeader: null;
    --themeColorHeaderButton1: null;
    --themeColorHeaderButton2: null;
    --themeColorPreheader: null;
    --themeColorPreheaderFont: null;

    --themeColorFooter: 245, 246, 247;
    --themeColorFooterFont: 107, 119, 134;
    --themeColorFooterSocial: 245, 246, 247;
    --themeColorSocialFont: 107, 119, 134;
    --themeColorSupplementary: 245, 246, 247;
    --themeColorSupplementaryFont: 107, 119, 134;
    --themeColorFooterSupplementaryLink: null;
    --themeColorFooterSupplementaryLinkHover: null;

    --themeFontFamily: FordF1Regular;
    --themeFontFamilyBold: FordF1Bold;
    --themeFontFamilyTitle: FordF1Regular;
    --themeFontFamilySemiBold: FordF1Semibold;
    --themeFontFamilyLight: FordF1Light;
    --themeFontFamilyMedium: FordF1Medium;

    --themeFontFamilyArabic: Arial;
    --themeFontFamilyArabicTitle: Arial;
    --themeFontFamilyVietnamese: Arial;
    --themeFontFamilyVietnameseTitle: Arial;
    --themeFontSizeSmall: 75%;
    --themeFontSize: 100%;
    --themeFontSize2K: 110%;
    --themeFontSize4K: 200%;

    --themeMaxLineLength: 85ch;
    --themeMaxContentWidth: 65rem;

    --themeMediaAspectRatio: 16/9;
}
