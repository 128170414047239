@mixin gw-form {
    .gw-form {
        &__title {
            text-align: center;
        }

        &__description {
            @include fluid-type-default;
            font-family: $font-family-title;
            text-align: center;
        }

        &__label {
            color: $color-text-secondary;
            font-size: $font-size;
        }

        &__select {
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" style="fill: rgb(0, 9, 91)"><path d="M20,40l2.8-2.8L50,64.3l27.2-27.1L80,40L50,70L20,40z"/></svg>');

            &--checkboxes {
                background-color: $color-white;
            }

            &.selectize-control {
                .selectize-input {
                    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" style="fill: rgb(0, 9, 91)"><path d="M20,40l2.8-2.8L50,64.3l27.2-27.1L80,40L50,70L20,40z"/></svg>');
                    border-radius: $border-radius-medium-small;
                }
            }
        }

        &__input,
        &__select,
        &__textarea {
            background-color: $color-white;
            border-color: $color-grey-3;
            border-radius: $border-radius-medium-small;
            color: $color-black;

            ::placeholder {
                color: $color-text-secondary;
            }

            &:focus,
            &:required:focus {
                @at-root {
                    /* stylelint-disable-next-line max-nesting-depth */
                    [data-whatintent='mouse'] & {
                        box-shadow: none;
                    }
                }

                @include focus-default(
                    $opacity: false,
                    $transform: false,
                    $keyboard-outline: true
                );
                border-color: $color-grey-5;
            }

            &[type='checkbox'],
            &[type='radio'] {
                @include focus-default(
                    $opacity: false,
                    $transform: false,
                    $keyboard-outline: true
                );

                &:invalid:required:focus {
                    box-shadow: none;
                    outline-color: $color-negative;
                }
            }

            &[type='range'] {
                @at-root {
                    /* stylelint-disable max-nesting-depth */
                    [data-whatintent='mouse'] &:focus,
                    [data-whatintent='keyboard'] &:focus {
                        background-color: transparent;
                        box-shadow: none;
                    }
                    /* stylelint-enable max-nesting-depth */
                }

                &::-webkit-slider-runnable-track,
                &::-moz-range-track {
                    background-color: $color-grey-2;
                    height: 0.625rem;
                }

                &::-webkit-slider-thumb,
                &::-moz-range-thumb {
                    background-color: $color-grey-3;
                }
            }
        }

        &__range {
            .gw-form__label {
                background-color: transparent;
            }

            &__bubble {
                background-color: $color-white;
                border: $border-width $border-style $color-grey-2;
                border-radius: $border-radius-medium-small;
                color: $color-black;
                padding: $gutter-xs;
            }
        }

        &__field--checkbox-custom,
        &__fieldset--checkbox-custom .gw-form__field {
            background-color: $color-white;
            border-radius: $border-radius-medium-small;

            .gw-form__label {
                color: $color-brand;
            }

            [type='checkbox'] {
                @at-root {
                    /* stylelint-disable max-nesting-depth, no-descending-specificity */
                    [data-whatintent='mouse'] &:focus,
                    [data-whatintent='keyboard'] &:focus {
                        outline: none;
                    }
                    /* stylelint-enable max-nesting-depth, no-descending-specificity */
                }
            }
        }

        &[id='site-search'] {
            .gw-form__input {
                border-radius: 0;
            }

            .gw-form__label {
                font-size: $font-size;
            }

            .gw-button {
                @include button-primary;
                height: auto;
                margin-inline-end: $gutter-xs;
            }
        }

        .gw-form__fieldset--checkbox-custom,
        .gw-form__field--checkbox-custom {
            [type='checkbox'] {
                visibility: hidden;
            }

            [class*='gw-form__field'],
            &[class*='gw-form__field'] {
                /* stylelint-disable max-nesting-depth */
                &.is-checked {
                    background-color: $color-brand;

                    .gw-form__label {
                        color: $color-text-alt;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        &__field--has-error {
            &:not(:valid):focus,
            &:not(:invalid):focus {
                box-shadow: 0 0.5rem 0.75rem #{rgba(var(--themeBlack), 0.2)};
            }
        }

        :disabled {
            background-color: $color-grey-1;
            color: $color-text-secondary;
            opacity: 1;

            &.gw-form__select {
                background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" style="fill: rgb(107, 119, 134)"><path d="M20,40l2.8-2.8L50,64.3l27.2-27.1L80,40L50,70L20,40z"/></svg>');
            }
        }

        .is-disabled {
            &.gw-form__fieldset--checkbox,
            &.gw-form__fieldset--radio {
                span {
                    background-color: $color-grey-1;
                    color: $color-text-secondary;
                    margin-inline-start: $gutter;
                    padding: 0 math.div($gutter-xs, 2);
                    position: relative;

                    /* stylelint-disable max-nesting-depth */
                    &:after {
                        background-color: $color-grey-1;
                        content: '';
                        height: 1rem;
                        inset-block-start: 0.125rem;
                        inset-inline-start: -$gutter;
                        position: absolute;
                        width: 1rem;
                    }
                    /* stylelint-enable max-nesting-depth */
                }

                input {
                    @include visually-hide;
                }
            }

            &.gw-form__fieldset--radio {
                span {
                    /* stylelint-disable max-nesting-depth */
                    &:after {
                        border-radius: $border-radius-round;
                    }
                    /* stylelint-enable max-nesting-depth */
                }
            }
        }
    }
}
