.gw-tabs {
    margin-block-end: $gutter-xl;

    &__button {
        @include focus-default($opacity: false, $transform: false);
        background-color: #{rgba(var(--themeGrey2), 0.3)};
        color: $color-text;
        inset-block-end: -$border-width;
        padding: $gutter-xs;
        position: relative;
        z-index: z('default');

        &[aria-selected='true'] {
            @if $allow-dark-mode {
                /* stylelint-disable max-nesting-depth */
                @media (prefers-color-scheme: dark) {
                    color: inherit;
                }
                /* stylelint-enable max-nesting-depth */
            }
            background-color: $color-white;
        }
    }

    &__tablist {
        display: flex;

        > * {
            margin-block-end: 0;
            margin-inline-end: $gutter-xs;
        }
    }

    &.is-js-enabled {
        .gw-tabs__content {
            @include focus-default($opacity: false, $transform: false);
            background-color: $color-white;
            padding: $gutter;
            word-break: break-word;
        }
    }

    // Vertical tabs.
    &--vertical {
        &.is-js-enabled {
            @include bp-medium {
                display: grid;
                gap: 0;
                grid-template-areas: 'tabs content';
                grid-template-columns: auto 1fr;
            }

            .gw-tabs__content {
                grid-area: content;
            }
        }

        .gw-tabs__tablist {
            @include bp-medium {
                grid-area: tabs;
            }
            flex-direction: column;

            > * {
                margin-block-end: $gutter-xs;
                margin-inline-end: 0;
            }
        }

        .gw-tabs__button {
            border-color: transparent;
            inset-block-end: 0;
        }

        [aria-selected='true'] {
            border-color: $color-brand-alt;
            border-inline-end-color: $color-background;
            inset-inline-end: -$border-width;
        }
    }

    &--contact {
        .gw-contact-card {
            border: none;
            box-shadow: none;

            &:hover:not(:disabled) {
                border: none;
                box-shadow: none;
            }
        }

        .gw-cms__content-text {
            h1 {
                font-size: $font-size-l;
            }

            .gw-button-group {
                margin-block-end: $gutter;

                /* stylelint-disable-next-line max-nesting-depth */
                .gw-button {
                    text-transform: lowercase;
                }
            }
            /* stylelint-disable no-descending-specificity */
            .gw-contact-card {
                border: none;
                box-shadow: none;
                margin-block-end: $gutter;
                width: fit-content;

                /* stylelint-disable max-nesting-depth */
                &-grid.gw-grid {
                    justify-content: flex-start;
                }

                &:not(.gw-contact-card--person) {
                    address > span {
                        display: none;
                    }

                    .gw-button-group {
                        margin-block-end: 0;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }
            /* stylelint-enable no-descending-specificity */
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-tabs) {
    @include gw-tabs;
}
