.gw-stock-label {
    font-size: $font-size-s;
    inset-block-end: 0;
    padding: math.div($gutter-xs, 2) $gutter-xs;
    position: absolute;
    width: 100%;

    > * {
        margin-bottom: 0;
    }

    &--primary {
        background-color: rgba(var(--themeColorBrand), 0.6);
        color: $color-text-alt;
    }

    &--secondary {
        background-color: rgba(var(--themeColorBrandAlt), 0.6);
        color: $color-black;
    }

    &--positive {
        background-color: rgba(var(--themeAccentPositive), 0.6);
        color: $color-text-alt;
    }

    &--negative {
        background-color: rgba(var(--themeAccentNegative), 0.6);
        color: $color-text-alt;
    }

    &--neutral {
        background-color: rgba(var(--themeAccentNeutral), 0.6);
        color: $color-black;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-stock-label) {
    @include gw-stock-label;
}
