// Stock detail. The 2 page-specific class names refer to new stock & pre-owned stock.
.page-16,
.page-8,
.page-default-inventorydetail {
    .gw-layout__fullbleed {
        .gw-responsive-media {
            margin-inline-end: auto;
            margin-inline-start: auto;
            max-width: $layout-max-width;
        }
    }

    .gw-certifications {
        padding-block: $gutter-xl;
    }

    .gw-cms[aria-labelledby='about-us'] {
        + div {
            background-color: $color-white;
            padding: $gutter;
        }
    }

    .gw-cms,
    .gw-cms__content-text {
        @include layout-centered-content;
    }

    .gw-product-summary {
        &__name.is-hidden-on-large {
            margin-block-end: -$gutter-s;
        }

        &__price--extra {
            margin-block-start: $gutter-xs;
        }
    }

    .gw-toolbar--stock {
        margin-inline-end: -$gutter-s;
    }

    .gw-product-specs {
        margin-block-end: $gutter-l;
        margin-block-start: $gutter-l;

        &__item {
            background-color: transparent;
            color: inherit;
            flex-grow: 0;
            margin-inline-end: $gutter-s;
            padding: 0;

            span:first-of-type {
                @include visually-hide;
            }
        }
    }

    .gw-accordion {
        margin-inline-end: auto;
        margin-inline-start: auto;
        max-width: $layout-max-width;
    }

    .gw-card-slider__wrap[aria-labelledby='similar-vehicles'] {
        background-color: $color-grey-1;
    }

    .gw-gallery {
        > .gw-icon--hover {
            height: 30%;
            inset-block-start: 50%;
            inset-inline-start: 50%;
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
            width: 30%;
            z-index: 1;
        }
    }

    .gw-grid--asymmetric {
        @include responsive-grid-asymmetric($col1: 65%, $col2: 35%);

        margin-block-start: $layout-content-gutter-top;
        margin-inline-end: auto;
        margin-inline-start: auto;
        max-width: 106rem;

        .gw-cms,
        .gw-cms__content-text {
            text-align: start;
        }

        .gw-grid--asymmetric__right-track {
            position: relative;

            /* stylelint-disable max-nesting-depth */
            .gw-product-summary {
                position: sticky;
            }
            /* stylelint-enable max-nesting-depth */
        }

        .gw-grid--asymmetric__left-track {
            /* stylelint-disable max-nesting-depth */
            .gw-cms {
                margin-block-start: $gutter-xl;
            }
            /* stylelint-enable max-nesting-depth */
        }

        .gw-card-slider__wrap[aria-labelledby='similar-vehicles'] {
            margin-inline-end: 0;
            margin-inline-start: 0;
        }

        h2 {
            text-transform: unset;
        }

        .gw-product-specs {
            &__list {
                /* stylelint-disable max-nesting-depth */
                @include bp-medium {
                    justify-content: space-around;
                }
                /* stylelint-enable max-nesting-depth */
                justify-content: flex-start;
            }

            &__item {
                /* stylelint-disable max-nesting-depth */
                @include bp-medium {
                    font-size: $font-size-l;
                    width: 10rem; // 160px
                }

                @include bp-xlarge {
                    width: unset;
                }
                /* stylelint-enable max-nesting-depth */

                font-size: $font-size;
                margin-block-end: $gutter-xs;
                text-align: center;
                width: 6.25rem; // 100px
                /* stylelint-disable max-nesting-depth */
                .gw-icon {
                    @include bp-medium {
                        height: 2.5em;
                        width: 2.5em;
                    }

                    height: 1.5em;
                    margin-inline-start: 0;
                    overflow: visible;
                    width: 1.5em;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        .gw-cms.gw-payment-options {
            // So when the jump link is pressed, item isn't hidden behind the sticky nav.
            margin-block-start: -$gutter-xl;
            padding-block-start: 5rem;

            .gw-button-group--toggle__payment-options {
                /* stylelint-disable max-nesting-depth */
                .gw-button--toggle {
                    &:hover:not(:disabled) {
                        transform: scale(1);
                    }

                    &.is-selected {
                        background-color: $color-link-alt;
                        border-color: $color-brand-alt;
                        color: $color-brand-alt;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        .gw-payment-options__finance {
            .gw-grid,
            .gw-table {
                margin-block-end: 0;
            }
        }
    }

    .gw-layout__main {
        overflow: initial;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(page-16-stock-detail) {
    @include page-16-stock-detail;
}
