.gw-info-grid {
    @include bp-medium {
        gap: calc(2.5 * $gutter-xl);
        grid-template-columns: fit-content(30rem) fit-content(30rem);
    }

    gap: $gutter-xl;
    grid-template-columns: 1fr;
}

@if mixin-exists(gw-info-grid) {
    @include gw-info-grid;
}
