.gw-toolbar {
    align-items: center;
    display: flex;
    font-size: $font-size; // Allows any text & icons to inherit this size.
    gap: $gutter-xs;
    margin-block-end: $gutter-xs;

    .gw-button {
        &--text-icon,
        &--icon {
            background-color: transparent;
            border: 0;
            font-size: 1em; // Scale in proportion to toolbar font-size.
            padding-inline-end: $gutter-s;
            padding-inline-start: $gutter-s;
        }
    }

    .gw-button-group {
        align-items: center;
        flex-wrap: unset;
        justify-content: space-between;
        margin-block-end: 0;

        > * {
            margin-block-end: 0;
            margin-inline-end: 0;
        }
    }

    .gw-icon {
        height: 1.5em; // Scale in proportion to toolbar font-size.
        width: 1.5em;
    }

    // Stock.
    &--stock {
        width: 100%;

        .gw-button-group {
            width: 100%;

            > a {
                line-height: $line-height-xl;
            }

            > :first-child {
                margin-inline-end: auto;
            }
        }

        &__location {
            align-items: center;
            display: flex;
            font-family: $font-family;
            font-size: $font-size-s;
            line-height: 1;
            padding: $gutter-xs $gutter-s;

            .gw-icon {
                margin-inline-end: $gutter-xs;
            }

            & > span {
                font-size: $font-size-s;
            }
        }

        &__compare {
            .gw-icon {
                fill: transparent;
                stroke: $color-text;
                stroke-width: $icon-stroke-width;
            }

            &.is-selected {
                /* stylelint-disable max-nesting-depth */
                .gw-icon {
                    fill: inherit;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        .gw-share__fallback {
            right: 0;
        }

        .gw-button--icon {
            padding-inline: $gutter-xs;
        }

        .gw-button[class*='--text'] {
            font-family: $font-family;
            font-size: $font-size-s;

            /* stylelint-disable-next-line max-nesting-depth */
            span > span {
                font-size: $font-size-s;
            }
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-toolbar) {
    @include gw-toolbar;
}
