@mixin gw-stock-label {
    .gw-stock-label {
        &--secondary {
            color: $color-text-alt;
        }

        &--neutral {
            color: $color-text-alt;
        }
    }
}
