@mixin gw-accordion {
    .gw-accordion {
        &__button {
            @at-root {
                // Reset styles for specific accordions.
                .gw-product-filter & {
                    background-color: inherit;
                    color: inherit;
                    padding-inline: 0;
                }

                [data-whatintent='mouse'] &:hover,
                [data-whatintent='keyboard'] &:focus {
                    color: #{rgba(var(--themeColorBrandAlt), 0.8)};
                }
            }

            @include focus-default($keyboard-outline: true);

            @include bp-medium {
                font-size: $font-size-m;
            }

            @include bp-xxlarge {
                font-size: $font-size;
            }

            border-block-start: $border-style $border-width $color-grey-3;
            color: $color-text;
            font-size: $font-size;
            margin-block-end: 0;

            .gw-icon {
                color: $color-brand-alt;
            }

            &--toggle {
                color: $color-text-alt;
            }

            &[aria-expanded='true'] {
                color: $color-brand-alt;
            }
        }

        &__content {
            @at-root {
                // Reset styles for specific accordions.
                .gw-product-filter & {
                    background-color: inherit;
                    padding: 0;
                }
            }

            @include focus-default($keyboard-outline: true);

            background-color: $color-white;
            color: $color-text-secondary;
            padding: $gutter-s;
        }

        &.is-js-enabled {
            .gw-accordion__content {
                @include focus-default($keyboard-outline: true);

                [data-whatintent='mouse'] &:active,
                [data-whatintent='mouse'] &:hover {
                    opacity: 1;
                    transform: none;
                }
            }
        }
    }
}
