@mixin gw-product-search {
    .gw-product-search {
        @include bp-medium {
            padding: $gutter-l 0;
        }

        background-color: $color-grey-5;
        padding: $gutter $gutter-s;

        .gw-grid {
            @include bp-medium {
                margin-block-end: $gutter-s;
                margin-block-start: $gutter-s;
            }

            align-items: flex-end;
            margin-block-end: 0;
        }

        .gw-form__legend {
            @include bp-medium {
                @include fluid-type-xl;
                text-align: center;
            }

            // LM: Even though we avoid using mobile only styling,
            // having to do this due to the nature of how we've set the text align
            // (using relative flow attributes) it's automatically overriding the breakpoint
            // meaning it's winning in specificity, so best to keep this within a breakpoint.
            @include bp-small {
                text-align: start;
            }

            @include fluid-type-l;
            color: $color-white;
            font-family: $font-family-title;
            margin-block-end: 0;
        }

        .gw-form__label {
            > span:not([class]):first-child {
                color: $color-white;
                font-size: $font-size-s;
                height: auto;
                margin: 0;
                overflow: hidden;
                padding: 0;
                position: relative;
                width: auto;
            }
        }

        .gw-form__field {
            @include bp-medium {
                margin-inline-start: inherit;
                width: inherit;
            }

            margin-inline-start: 0;
            width: 100%;
        }

        .gw-stock-filter-ajax {
            @include bp-medium {
                width: inherit;
            }

            width: 100%;
        }

        .gw-form__buttons {
            @include bp-medium {
                margin-block: $gutter-xs 0;
                margin-inline-start: $gutter-xs;
            }

            margin-block: $gutter 0;
            margin-inline-start: 0;
        }

        .gw-form__select:disabled,
        .gw-form__select.is-disabled {
            background-color: $color-grey-3;
            color: $color-grey-4;
        }
    }
}
