.gw-header.gw-header--geronimo {
    @at-root {
        .is-js-sticky & {
            /* stylelint-disable max-nesting-depth */
            @include bp-medium {
                background-color: $color-header-background;
                grid-template-areas: 'home nav . contact demo switch';
                grid-template-columns: auto auto 1fr auto auto auto;
                grid-template-rows: $layout-header-grid-row-height;
                padding: 0;
            }
            /* stylelint-enable max-nesting-depth */
        }
    }

    @include bp-medium {
        grid-template-areas: 'home nav . contact demo switch';
        grid-template-columns: auto auto 1fr auto auto auto;
        grid-template-rows: minmax($layout-header-grid-row-height, auto);
    }

    align-items: center;
    background-color: $color-header-background;
    display: grid;
    grid-template-areas: 'home . . nav';
    grid-template-columns: auto 1fr 1fr auto;
    grid-template-rows: $layout-header-grid-row-height;

    .gw-header__home {
        background-color: $color-brand;
        height: 100%;
        margin-inline: 0;
        padding-inline: $gutter-xs;

        .gw-image {
            max-width: 9.375rem; // 150px
            object-fit: contain;
        }
    }

    .gw-header__nav {
        @include bp-medium {
            grid-column: 2;
        }

        align-items: center;
        display: flex;
        grid-column: 4;
        height: 100%;
    }

    .gw-header__contact {
        @include bp-medium {
            display: block;
            grid-area: contact;
            margin-inline-end: $gutter-xs;
            padding: $gutter-xs;
        }

        display: none;
    }

    .gw-header__demo {
        @include bp-medium {
            display: block;
            grid-area: demo;
            margin-inline-end: $gutter-xs;
            padding: $gutter-xs;
        }

        display: none;
    }

    .gw-header__language {
        @include bp-medium {
            display: block;
            grid-area: switch;
        }

        display: none;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-header--geronimo-site) {
    @include gw-header--geronimo-site;
}
