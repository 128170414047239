.gw-product-variants {
    // TODO:
    // 1. Add real component to Storybook once designs are confirmed.
    // 2. Sync dealer website HTML.
}

[aria-labelledby='product-variants'] {
    margin-block-end: $gutter;
    padding: $gutter-s;

    .gw-product-card__media {
        margin-block-end: $gutter-s;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-product-variants) {
    @include gw-product-variants;
}
