@mixin mf-slider-thumb {
    background-color: $color-brand;
    border: 0;
    border-radius: $border-radius-round;
    height: $icon-size-m;
    margin-block-start: -10px;
    width: $icon-size-m;
}

@mixin mf-slider-track {
    background-color: $color-brand-alt;
    cursor: pointer;
    height: 0.25rem;
    width: 100%;
}

.gw-form.gw-search--multi-franchise {
    &[id='site-search'] {
        @if $rounded-inputs == true {
            border-radius: $border-radius;
        } @else {
            border-radius: 0;
        }
        align-self: center;
        background-color: $color-grey-1;
        border: $border-width $border-style $color-grey-2;
        max-height: 2.5rem;

        .gw-icon {
            margin-inline-end: $gutter-xs;
            margin-inline-start: 0;
        }

        .gw-button {
            background-color: transparent;
        }
    }
}

.gw-mf-form {
    @include bp-large {
        margin: auto 0;
    }
    background-color: $color-white;
    border-radius: $border-radius-medium;
    margin: 2rem;
    width: 22rem; // 348px
    padding: $gutter-s;

    &__title {
        color: $color-brand;
        font-size: 1.5rem;
    }

    &__fieldset {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin: $gutter-xs 0 0;

        &:not(:last-child) {
            margin: $gutter-xs 0;
        }

        &--range {
            width: 45%;

            .gw-form__range__label {
                width: calc(100% * 2);
            }
        }

        &--wrapped {
            flex-flow: row wrap;

            select {
                color: $color-brand;
                margin-block-end: $gutter-s;
                max-width: 280px;
                min-width: 220px;
                width: auto;
                /* stylelint-disable max-nesting-depth */
                &:last-child {
                    margin-inline-end: auto;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        &--toggle {
            background: $color-brand;
            border-radius: $border-radius-rounded;
            padding: $gutter-xs;

            label {
                background: transparent;
                border-radius: $border-radius-rounded;
                color: $color-white;
                font-size: $font-size;
                font-weight: bold;
                min-width: 8.25rem; // 132px
                padding: ($gutter-s / 4) ($gutter-s / 2);
                text-align: center;
                /* stylelint-disable max-nesting-depth */
                &:hover {
                    cursor: pointer;
                }
                /* stylelint-enable max-nesting-depth */
            }

            input {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                overflow: hidden;
                position: absolute;
                width: 1px;
                /* stylelint-disable max-nesting-depth */
                &:checked + label {
                    background-color: $color-grey-1;
                    box-shadow: none;
                    color: $color-brand;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        /* stylelint-disable no-descending-specificity */
        .gw-form__range--multiple {
            display: flex;
            flex-flow: row nowrap;
            margin: 0;
            width: 100%;

            > * {
                min-width: auto;
            }

            .gw-form__range__label {
                color: $color-brand;
            }

            fieldset {
                height: 0;
                margin: 0;
                padding: 0;
                width: 0;
            }

            label {
                background-color: transparent;
                max-width: 50%;
                padding: 0;
                /* stylelint-disable max-nesting-depth */
                > span {
                    margin-block-end: 1rem;
                }
                /* stylelint-enable max-nesting-depth */
            }

            input {
                background-color: transparent;
                border: 0;
                height: auto;
                margin-block-end: 0.5rem;
                max-width: 100%;
                padding: 0;
                width: 100%;
                /* stylelint-disable max-nesting-depth */
                &::-webkit-slider-thumb {
                    @include mf-slider-thumb;
                    margin-block-start: -($icon-size-m/2);
                }

                &::-webkit-slider-runnable-track {
                    @include mf-slider-track;
                }

                &::-moz-range-thumb {
                    @include mf-slider-thumb;
                }

                &::-moz-range-track {
                    @include mf-slider-track;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
        /* stylelint-enable no-descending-specificity */
    }

    &__input {
        background: $color-grey-1 0% 0% no-repeat padding-box;
        border: $border-width $border-style $color-grey-3;
        border-radius: 0.25rem;
        margin: $gutter-s 0;
        padding: ($gutter-s / 4) $gutter-s;
        width: 100%;

        &:placeholder {
            color: $color-brand;
        }

        &--search {
            border-radius: $border-radius-rounded;
            color: $color-brand;

            + svg {
                inset-block-start: 50%;
                inset-inline-end: 8px;
                position: absolute;
                transform: translateY(-50%);
            }
        }
    }

    &__slider {
        margin-inline-end: $gutter-s;
        max-width: calc(440px + $gutter-s);
        width: 100%;
    }

    h2,
    h3 {
        letter-spacing: 0px;
        line-height: 1;
        text-transform: uppercase;
    }

    .gw-button--primary {
        width: 100%;
    }

    .gw-tabs__title {
        display: none;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-form--multi-franchise) {
    @include gw-form--multi-franchise;
}
