.gw-share {
    display: none;
    position: relative;

    &.is-js-enabled {
        display: block;
    }

    &__fallback {
        background-color: $color-white;
        border: $border-width $border-style $color-grey-2;
        margin: 0 $gutter-xs;
        padding: $gutter-xs;
        position: absolute;
        z-index: z('default');

        .gw-form__label,
        .gw-form__input,
        .gw-button {
            font-size: $font-size-s;
        }

        .gw-form__input {
            height: auto;
            padding: $gutter-xs $gutter-s;
        }

        .gw-button {
            padding: $gutter-xs;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-share) {
    @include gw-share;
}
