.gw-product-card {
    @if $allow-dark-mode {
        @media (prefers-color-scheme: dark) {
            background-color: $color-grey-3;
        }
    }
    align-items: center;
    background-color: $color-grey-1;
    border: 0;
    padding: 0;

    .gw-card__content {
        flex-grow: inherit;
        margin-block-start: 0;
        padding: 0 $gutter-s;
        width: 100%;
    }

    .gw-card__title {
        > a {
            // Line-clamp needs '-webkit-' prefixes. See https://css-tricks.com/line-clampin/.
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            overflow: hidden;
            padding-block-end: 1px; // To show underline on last clamped line.
        }

        .gw-product-card__block-link & {
            margin-block-end: $gutter-xs;
        }

        span {
            display: block;
        }
    }

    &__specs {
        display: flex;
        flex-wrap: wrap;
        margin-block-end: $gutter-xs;

        /* stylelint-disable no-descending-specificity */
        span {
            margin-inline-end: $gutter-s;
        }
        /* stylelint-enable no-descending-specificity */
        :last-child {
            margin-inline-end: 0;
        }
    }

    &__block-link {
        text-align: center;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        > * {
            margin-block-end: $gutter-xs;
        }
    }

    [class*='__price'] {
        font-size: $font-size-l;
        margin-block-end: 0;
    }

    [class*='__price--text'] {
        font-size: $font-size;
        margin-block-end: $gutter-xs;
    }

    [class$='__price--discount'] {
        color: #{rgba(var(--themeColorBrand), 0.8)};
    }

    &__legal {
        font-size: $font-size-s;
        margin-block-end: $gutter-xs;

        &--extra {
            span:not(:empty) {
                position: relative;

                /* stylelint-disable max-nesting-depth */
                &:after {
                    content: '.';
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }
    /* stylelint-disable max-nesting-depth */
    .gw-card__actions {
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 $gutter-s;
        width: 100%;

        .gw-button {
            &--text {
                color: $color-text-alt;
                margin-inline-end: 0;
                text-align: center;
                width: 100%;

                &.gw-button--secondary {
                    color: $color-brand;
                }
            }
        }
    }
    /* stylelint-enable max-nesting-depth */

    // Spec table.
    &__table {
        font-size: $font-size-xs;
        width: 100%;

        // Need '.gw-table' to boost specificity!
        &.gw-table {
            th,
            td {
                border-inline-end: 0;
                border-inline-start: 0;
                padding: math.div($gutter-xs, 2);
            }
        }
    }

    // Container for errors (e.g. saved products).
    &__error {
        color: $color-negative;
        flex-basis: 100%;
        font-size: $font-size-s;
    }

    // Product card overview variant.
    &--overview {
        background: transparent;
    }

    // Product card filter variant.
    /* stylelint-disable no-descending-specificity */
    &--filter {
        background: none;
        margin-block-end: 0;
        max-width: 100%;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        .gw-card__media {
            margin-block-end: $gutter-xs;
        }

        .gw-card__title {
            font-size: $font-size;
            margin: 0;
        }

        .gw-product-card__price {
            background: none;
            color: $color-text;
            font-size: $font-size-s;
            margin: 0 0 $gutter-xs;
            padding: 0;
        }
    }
    // Button group (e.g. for quick view component).
    > .gw-button-group {
        margin: 0;
        padding: 0 $gutter-s;
        width: 100%;
    }

    // Block link (as used in slider).
    &--block-link {
        justify-content: center;
        padding: 0 $gutter-s;
    }

    // In a grid.
    &-grid.gw-grid {
        @include responsive-grid-columns($max-width: 21.875rem);
    }

    // Stock page-specific card updates.
    &-grid--stock {
        ~ * > .gw-cms {
            @include bp-large {
                max-width: calc($max-line-length / 1.5);
            }

            @include bp-xlarge {
                max-width: $max-line-length;
            }

            margin: auto;
            max-width: calc($max-line-length / 2);
            text-align: center;
        }
    }

    &-grid--stock > li > .gw-product-card {
        .gw-button[data-quick-view] {
            border: 0;
            padding: 0;

            &:hover {
                background-color: transparent;
            }

            .gw-icon {
                fill: $color-brand-alt;
                margin: 0;
            }
        }

        .gw-product-card__price > .gw-button-group {
            display: inline;
        }

        .gw-product-card__pricing {
            &--finance {
                /* stylelint-disable max-nesting-depth */
                .gw-product-card__price--discount,
                .gw-product-card__price--was {
                    font-size: $font-size;
                }

                .gw-product-card__price--was {
                    margin-block-end: $gutter-xs;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }
    /* stylelint-enable no-descending-specificity */
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-product-card) {
    @include gw-product-card;
}
