$grid-gap-gallery: 0.25rem;

.gw-gallery {
    overflow-x: hidden;

    + .gw-gallery {
        margin-block-start: -$gutter-xl + $grid-gap-gallery;
    }
    // stylelint-disable max-nesting-depth
    &--portfolio {
        &.gw-slider {
            padding-block-end: 0;

            [data-button-type] {
                background-color: rgba(var(--themeColorBackgroundAlt), 0.6);
                inset-block-end: $gutter-xs;
                width: 5rem;

                > svg {
                    opacity: 0.5;
                }

                &:hover:not(:disabled) {
                    opacity: inherit;

                    > svg {
                        opacity: 1;
                    }
                }
            }
        }

        &[data-display-slide-count] {
            > .gw-button-group {
                > span {
                    color: $color-white;
                    inset-block-end: $gutter-xs;
                    inset-inline-start: 50%;
                    margin: 0;
                    position: absolute;
                    transform: translate(-50%, -25%);
                    z-index: 2;
                }
            }

            [data-button-type='previous'] {
                border-radius: $border-radius-rounded 0 0 $border-radius-rounded;
                inset-inline-start: 50%;
                justify-content: flex-start;
            }

            [data-button-type='next'] {
                border-radius: 0 $border-radius-rounded $border-radius-rounded 0;
                inset-inline-end: 50%;
                justify-content: flex-end;
            }
        }

        &-thumbs.gw-slider {
            /* stylelint-disable no-descending-specificity */
            [data-button-type] {
                position: inherit;
                transform: none;
            }
            /* stylelint-enable no-descending-specificity */
            .gw-button-group {
                align-items: center;
                display: flex;
                gap: 0 $gutter-xs;
                justify-content: center;
                margin-block-start: $gutter-s;
            }

            .gw-slider__thumbnail-guide {
                @include bp-medium {
                    width: 3rem;
                }

                background-color: $color-grey-2;
                height: 0.25rem;
                width: 1.5rem;

                &--container {
                    display: flex;
                    gap: 0 0.5rem;
                    margin: 0;
                }

                &.is-selected {
                    background-color: $color-brand;
                }
            }

            .gw-slider__slide {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        > .gw-icon--container {
            align-items: center;
            background-color: rgba(var(--themeGrey2), 0.6);
            display: flex;
            height: 100%;
            justify-content: center;
            margin-inline: calc($gutter-xs / 2);
            pointer-events: none;
            position: absolute;
            width: calc(100% - $gutter-xs);
            z-index: 1;

            > .gw-icon {
                fill: $color-white;
                height: 30%;
                width: 30%;
            }
        }
    }
    // stylelint-enable max-nesting-depth
    &__link {
        @at-root {
            [data-whatintent='keyboard'] &:focus {
                box-shadow: 0 0 0 0.25rem $color-neutral;
            }
        }
        display: block;
        width: 100%;
    }

    &__figure {
        height: 100%; // So it fills CSS grid cell.
        position: relative;
    }

    .gw-image {
        height: 100%; // So it fills CSS grid cell.
        width: 100%;
    }

    &__caption {
        align-items: center;
        background-color: #{rgba(var(--themeColorBackgroundAlt), 0.5)};
        bottom: 0;
        color: $color-text-alt;
        display: flex;
        font-weight: bold;
        height: 100%;
        justify-content: center;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: opacity 0.5s ease-in-out;
        width: 100%;

        .gw-gallery__link:hover &,
        .gw-gallery__link:focus & {
            opacity: 1;
        }
    }

    // Gallery (thumbnail images) in a grid. Default behaviour.
    &.gw-grid {
        @include responsive-grid-columns(
            $auto-layout: auto-fill,
            $min-width: 15rem
        );
        @include responsive-grid($gap: $grid-gap-gallery);
    }

    // Alternate gallery grid layouts, with specific numbers of columns.
    &[class*='--layout-thumbs'] {
        margin-inline-end: auto;
        margin-inline-start: auto;
        width: min(#{$layout-max-width}, 100%);
    }

    /* stylelint-disable order/order, max-nesting-depth */
    @supports (grid-area: auto) {
        &[class*='--layout-thumbs'] {
            @include bp-xsmall {
                // Easiest way to override grid columns on small screen is to change display property.
                display: block;

                > * + * {
                    margin-block-start: $grid-gap-gallery;
                }
            }
        }

        &[class*='--layout-thumbs-2'] {
            @include responsive-grid-columns(
                $auto-layout: 2,
                $min-width: calc((100% / 2) - (#{$grid-gap-gallery} / 2)),
                $max-width: calc((100% / 2) - (#{$grid-gap-gallery} / 2))
            );
        }

        &.gw-gallery--layout-thumbs-3 {
            grid-template-areas: 'large large .' 'large large .';

            > :first-child {
                grid-area: large;
            }
        }

        &.gw-gallery--layout-thumbs-3-alt {
            grid-template-areas: '. large large' '. large large';

            > :last-child {
                grid-area: large;
            }
        }

        &[class*='--layout-thumbs-3'] {
            @include responsive-grid-columns(
                $auto-layout: 3,
                $min-width: calc((100% / 3) - (2 * #{$grid-gap-gallery} / 3)),
                $max-width: calc((100% / 3) - (2 * #{$grid-gap-gallery} / 3))
            );
            @include bp-small {
                // Force 2-col layout, with large image spanning both cols.
                @include responsive-grid-columns(
                    $auto-layout: 2,
                    $min-width: calc((100% / 2) - (#{$grid-gap-gallery} / 2)),
                    $max-width: calc((100% / 2) - (#{$grid-gap-gallery} / 2))
                );
                grid-template-areas: 'large large' '. .';
            }
        }
    }
    /* stylelint-enable order/order, max-nesting-depth */

    // Gallery (thumbnail images) in tabs.
    &__tabs.gw-tabs {
        &.is-js-enabled {
            .gw-tabs__content {
                background-color: transparent;
                border: 0;
                padding: 0;
            }
        }

        .gw-tabs__tablist {
            justify-content: center;
            margin-block-end: $gutter;
        }

        .gw-tabs__button {
            border: $border-width $border-style $color-brand;
            inset-block-end: 0;
            margin: 0;
        }

        [aria-selected='true'] {
            background-color: $color-brand;
            border-block-end-color: $color-brand;
            bottom: 0;
            color: $color-text-alt;
        }

        [aria-selected='false'] {
            background-color: transparent;
        }
    }

    // Gallery (full-size images) in a slider.
    &.gw-slider {
        &:not(.gw-gallery--portfolio):not(.gw-gallery--gallery2) {
            padding-block-end: $gutter-xl;
        }
        // stylelint-disable-next-line no-descending-specificity
        .gw-slider__slide {
            display: flex;
            justify-content: center;
        }

        .gw-gallery__caption {
            height: auto;
            opacity: 1;
            padding: $gutter-xs;
        }

        // Tab controls.
        [role='tablist'] {
            bottom: $gutter-xl;
        }
    }

    // Gallery (full-size images) in a modal lightbox.
    &__modal.gw-modal__overlay {
        .gw-modal__inner {
            background-color: $color-white;
            max-height: 98%;
            max-width: 64rem; // This is the width of a full size image
            padding: 0;
            padding-block-start: $gutter-xl;
            width: 95%;
        }

        [data-modal-close] {
            background-color: unset;
            color: inherit;
            z-index: z('default');
        }

        [data-modal-title] {
            @include visually-hide;

            // Make sure it's still visually hidden on :focus.
            &:focus {
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                width: 1px;
            }
        }

        /* stylelint-disable no-descending-specificity */
        .gw-slider {
            padding-block-end: 5rem;
            // Buttons
            [data-button-type] {
                bottom: 0.75rem;
                top: unset;
            }

            [data-button-type='previous'] {
                left: 48%;
                right: auto;
                transform: translateX(-100%);
            }

            [data-button-type='next'] {
                left: auto;
                right: 48%;
                transform: translateX(100%);
            }
        }
        /* stylelint-enable no-descending-specificity */
        .gw-gallery {
            margin: 0;
        }

        // Enforce 4:3 aspect ratio in modal gallery.
        // stylelint-disable-next-line no-descending-specificity
        .gw-slider__slide {
            @include responsive-media($aspect-ratio: unset, $padding: 75%);

            // Bully CSS specificityby chaining class to itself!
            &.gw-slider__slide {
                width: 100%;
            }
        }

        .gw-image {
            // Prevent vertical scrollbar on screens less than 960px tall.
            @media (max-height: 60em) {
                max-height: 70vh;
            }
            max-height: 98vh;
            object-fit: contain; // So we don't get any top/bottom cropping of images.
        }

        .gw-modal__ajax {
            margin: 0;
        }
    }

    // Gallery 2 (composite of 2 gallery sliders).
    &__wrap {
        max-width: 64rem; // Same size as large image = 1024px.
    }

    /* stylelint-disable no-descending-specificity */
    &--gallery2,
    &--gallery2-thumbs {
        .gw-slider__slide {
            padding-inline-end: math.div($gutter-xs, 2);
            padding-inline-start: math.div($gutter-xs, 2);
        }

        .gw-gallery__link {
            @include responsive-media(
                $aspect-ratio: math.div(4, 3),
                $padding: 75%
            );

            // Bully CSS specificityby chaining class to itself!
            &.gw-gallery__link {
                width: 100%;
            }
        }
    }

    &--gallery2 {
        + .gw-gallery--gallery2-thumbs {
            margin-block-start: -$gutter-xl + $gutter-xs;
        }
    }

    &--gallery2-thumbs {
        .gw-slider__slide {
            @include bp-xsmall {
                flex-basis: 33.333%;
            }
            @include bp-medium {
                flex-basis: 20%;
            }
            @include bp-large {
                flex-basis: 16.667%;
            }
            flex-basis: 25%;
        }
    }
    /* stylelint-enable no-descending-specificity */
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-gallery) {
    @include gw-gallery;
}
