// Use '.gw-button' class for <button> and <a> elements for consistent visual appearance.
.gw-button {
    @include button-default;

    // A global style here, so that all next/previous buttons will have their icons flipped when it RTL mode
    @at-root {
        [data-button-type] {
            /* stylelint-disable max-nesting-depth */
            [dir='rtl'] & {
                .gw-icon {
                    transform: scale(-1);
                }
            }
            /* stylelint-enable max-nesting-depth */
        }
    }

    color: inherit;
    font-size: $font-size;
    line-height: 1;

    &--text,
    &--text-icon {
        @if $prefers-rounded-corners {
            border-radius: $border-radius;
        }
        background-color: $color-background;
        border: $border-width $border-style;
        font-weight: bold;
    }

    &--text {
        padding: $gutter-xs;
    }

    &--text-icon {
        padding: $gutter-xs;
    }

    // Apply colours to button variants.
    &--primary {
        background-color: $color-brand;
        border-color: $color-brand;
        color: $color-text-alt;
    }

    &--secondary {
        border-color: $color-brand;
        color: $color-brand;
    }

    &--positive {
        background-color: $color-positive;
        border-color: $color-positive;
        color: $color-text-alt;
    }

    &--negative {
        background-color: $color-negative;
        border-color: $color-negative;
        color: $color-text-alt;
    }

    // Even though <button> elements cannot be flex containers, adding 'display: inline-flex' here allows the icon to fit precisely, with no extra whitespace.
    &--icon {
        align-items: center;
        display: inline-flex;
        justify-content: center;
        padding: $gutter-xs;
    }

    // Buttons cannot be flex containers, so we need extra <span>.
    &__inner {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .gw-icon {
            margin-inline-end: $gutter-xs;
        }

        span {
            text-align: start;
        }

        &--reverse {
            flex-direction: row-reverse;

            .gw-icon {
                margin-inline-end: 0;
                margin-inline-start: $gutter-xs;
            }
        }
    }

    &--vertical {
        .gw-button__inner {
            flex-direction: column;

            .gw-icon {
                margin-block-end: $gutter-xs;
                margin-inline-end: 0;
            }

            span {
                text-align: center;
            }

            &--reverse {
                flex-direction: column-reverse;

                /* stylelint-disable max-nesting-depth */
                .gw-icon {
                    margin-block-end: 0;
                    margin-block-start: $gutter-xs;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }

    // Text buttons only, as icon button sizes are determined by the context of their parent container font-size (or their icon size overrides).
    &--small {
        font-size: $font-size-s;
        padding: math.div($gutter-s, 2);
    }

    &--whatsapp:not(.gw-button--secondary) {
        border: none;
        color: $color-brand;

        .gw-button__inner {
            justify-content: flex-start;
        }

        &:hover:not(:disabled) {
            text-decoration: underline;
        }
    }

    // Toggle buttons. They need JavaScript to do something useful (e.g. open|close) but we can handle the toggling of the icons here.
    &--toggle {
        // Apply colour to text-based toggles.
        &[class*='--text'] {
            background-color: transparent;
            border: $border-width $border-style;
            border-color: $color-brand;
            border-radius: 2em;
            color: $color-brand;
            font-family: $font-family-title;
            font-weight: normal;
            line-height: 1;
            padding: $gutter-xs $gutter;

            &:hover:not(:disabled) {
                background-color: $color-link-alt;
                border-color: $color-brand-alt;
                color: $color-brand-alt;
                text-decoration: none;
                transform: scale(1.025);
            }

            &.is-selected {
                /* stylelint-disable max-nesting-depth */
                @at-root {
                    [data-whatintent='keyboard'] &:focus {
                        box-shadow: inset 0 0 0 0.25rem $color-neutral;
                    }
                }
                background-color: $color-brand;
                border-color: $color-brand;
                box-shadow: 0 0.4rem 0.4rem #{rgba(var(--themeBlack), 0.2)};
                color: $color-text-alt;

                &:hover:not(:disabled) {
                    background-color: $color-brand-alt;
                    border-color: $color-brand-alt;
                    color: $color-text-alt;
                    opacity: 1;
                    text-decoration: none;
                    transform: scale(1.025);
                }
                /* stylelint-enable max-nesting-depth */
            }

            .gw-button__inner {
                flex-flow: row-reverse nowrap;

                /* stylelint-disable max-nesting-depth */
                .gw-icon {
                    margin-inline-end: 0;
                    margin-inline-start: $gutter-xs;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        &[aria-expanded='true'],
        &.is-selected {
            .gw-icon:first-of-type {
                display: none;
            }
        }

        &[aria-expanded='false'],
        &.is-not-selected {
            .gw-icon:last-of-type {
                display: none;
            }
        }
    }

    &--multi-toggle {
        border: $border-width $border-style $color-brand;
        border-radius: $border-radius-rounded;
        display: flex;
        width: min-content;

        .gw-form__field {
            border-radius: $border-radius-rounded;
            color: $color-brand;
            margin-block-end: 0;
            text-align: center;
            min-width: 5rem;

            &.is-selected {
                background-color: $color-brand;
                color: $color-white;
            }

            > .gw-form__label > span {
                align-items: center;
                display: flex;
                height: 3rem;
                justify-content: center;
                margin-block-end: 0;
                padding: $gutter-s;
            }
        }

        input {
            display: none;
        }
    }

    &--floating {
        &.gw-button[class*='--text'] {
            background-color: $color-positive;
            border-radius: 4rem;
            color: $color-text-alt;
            display: block;
            font-family: $font-family-title;
            inset-block-start: 25%;
            margin-block-end: $gutter-s;
            padding: $gutter;
            position: fixed;
            z-index: z('popup');

            &:hover {
                text-decoration: none;

                /* stylelint-disable max-nesting-depth */
                .gw-button__inner {
                    .gw-icon {
                        margin-left: $gutter-xs;
                    }

                    span {
                        height: auto;
                        opacity: 1;
                        width: auto;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        &-small {
            &.gw-button[class*='--text'] {
                padding: $gutter-xs;
            }

            /* stylelint-disable no-descending-specificity */
            .gw-button__inner {
                /* stylelint-disable max-nesting-depth */
                .gw-icon {
                    height: $icon-size-m;
                    width: $icon-size-m;
                }

                span {
                    font-size: $font-size-m;
                }
                /* stylelint-enable max-nesting-depth */
            }
            /* stylelint-enable no-descending-specificity */
        }

        &-medium {
            &.gw-button[class*='--text'] {
                padding: $gutter-s;
            }

            /* stylelint-disable no-descending-specificity */
            .gw-button__inner {
                /* stylelint-disable max-nesting-depth */
                .gw-icon {
                    height: $icon-size-l;
                    width: $icon-size-l;
                }

                span {
                    font-size: $font-size-l;
                }
                /* stylelint-enable max-nesting-depth */
            }
            /* stylelint-enable no-descending-specificity */
        }

        &-large {
            &.gw-button[class*='--text'] {
                padding: $gutter;
            }

            /* stylelint-disable no-descending-specificity */
            .gw-button__inner {
                /* stylelint-disable max-nesting-depth */
                .gw-icon {
                    height: $icon-size-xl;
                    width: $icon-size-xl;
                }

                span {
                    font-size: $font-size-xl;
                }
                /* stylelint-enable max-nesting-depth */
            }
            /* stylelint-enable no-descending-specificity */
        }

        /* stylelint-disable no-descending-specificity */
        .gw-button__inner {
            .gw-icon {
                margin-left: 0;
            }

            span {
                height: 0;
                opacity: 0;
                text-transform: uppercase;
                width: 0;
            }
        }
        /* stylelint-enable no-descending-specificity */
        &--media-inline {
            inset-inline-end: 0;
            margin-inline-end: $gutter-xs;
        }

        &--media-inline-alt {
            inset-inline-start: 0;
            margin-inline-start: $gutter-xs;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-button) {
    @include gw-button;
}
