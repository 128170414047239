.gw-ajax {
    @keyframes svg-animation {
        0% {
            transform: rotateZ(0deg);
        }

        100% {
            transform: rotateZ(360deg);
        }
    }

    @keyframes circle-animation {
        0%,
        25% {
            stroke-dashoffset: 280;
            transform: rotate(0);
        }

        50%,
        75% {
            stroke-dashoffset: 75;
            transform: rotate(45deg);
        }

        100% {
            stroke-dashoffset: 280;
            transform: rotate(360deg);
        }
    }

    &__loader {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
    }

    &__icon {
        animation: 2s linear infinite svg-animation;

        &.gw-icon {
            fill: transparent;
            height: $icon-size-loader;
            width: $icon-size-loader;
        }

        circle {
            animation: 1.4s ease-in-out infinite both circle-animation;
            display: block;
            fill: transparent;
            stroke: currentColor;
            stroke-dasharray: 283;
            stroke-dashoffset: 280;
            stroke-linecap: round;
            stroke-width: 6px;
            transform-origin: 50% 50%;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-ajax) {
    @include gw-ajax;
}
