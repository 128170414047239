/*
----------------------------------------------------------------------------
@font-face rules.

- 'font-display: swap;' ensures that all text will be rendered with a fallback system font until custom font is ready.
----------------------------------------------------------------------------
*/

@font-face {
    font-display: swap;
    font-family: 'FordF1Regular';
    font-style: normal;
    font-weight: normal;
    src: url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Regular.woff2')
            format('woff2'),
        url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Regular.woff') format('woff'),
        url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Regular.ttf')
            format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'FordF1Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Bold.woff2') format('woff2'),
        url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Bold.woff') format('woff'),
        url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Bold.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'FordF1Light';
    font-style: normal;
    font-weight: normal;
    src: url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Light.woff2')
            format('woff2'),
        url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Light.woff') format('woff'),
        url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Light.ttf')
            format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'FordF1Medium';
    font-style: normal;
    font-weight: normal;
    src: url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Medium.woff2')
            format('woff2'),
        url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Medium.woff') format('woff'),
        url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Medium.ttf')
            format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'FordF1Semibold';
    font-style: normal;
    font-weight: normal;
    src: url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Semibold.woff2')
            format('woff2'),
        url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Semibold.woff')
            format('woff'),
        url('./_THEMES/FMLDS/fonts/fordf-1/FordF-1-Semibold.ttf')
            format('truetype');
}
