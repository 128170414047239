.gw-slider.gw-slider--banner--multi-franchise {
    @include bp-large {
        margin-block-end: $gutter-xl;
    }

    margin-block-end: 0;
    padding-block-end: 0;

    [role='tablist'] {
        display: none;
    }

    [data-button-type] {
        border-color: $color-white;
        inset-block-end: 2%;

        &:hover {
            opacity: 1;
        }

        .gw-icon {
            stroke: $color-white;
        }
    }

    .gw-slider__slides {
        max-height: unset;

        .gw-banner__overlay {
            @include bp-medium {
                inset-inline-end: $gutter-xl;
                inset-inline-start: auto;
                text-align: end;
            }

            background-color: transparent;
            inset-inline: auto;

            &.is-hidden-on-small {
                /* stylelint-disable max-nesting-depth */
                @include bp-medium {
                    max-width: 75%;
                }

                @include bp-large {
                    max-width: 33%;
                }
                /* stylelint-enable max-nesting-depth */

                inset-block-start: 50%;
                transform: translate(0%, -50%);
            }

            /* stylelint-disable max-nesting-depth */
            .gw-banner__text {
                &--large {
                    @include bp-medium {
                        font-size: $font-size-xxxl;
                    }

                    font-family: $font-family-title;
                    font-size: $font-size-l;
                    text-transform: uppercase;
                }
            }

            .gw-button[class*='--text'].gw-button--primary {
                @include bp-medium {
                    margin-inline-start: auto;
                }

                background-color: $color-white;
                border-color: $color-white;
                color: $color-black;

                &:hover {
                    background-color: #{rgba(var(--themeWhite), 0.8)};
                    border-color: #{rgba(var(--themeWhite), 0.8)};
                }
            }

            &.is-hidden-on-medium {
                align-items: center;
                margin-block-end: $gutter-l;
                margin-block-start: $gutter-xs;
                padding-block-end: $gutter-xl;
                padding-block-start: $gutter-xs;
                text-align: center;
            }
            /* stylelint-enable max-nesting-depth */
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-slider--multi-franchise) {
    @include gw-slider--multi-franchise;
}
