.gw-banner__overlay--mf-search {
    height: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    padding: 0;
    z-index: z('modal');

    .gw-form.gw-search--multi-franchise[id='site-search'] {
        .gw-form__input {
            margin-inline-start: calc($gutter-xs / 2);
        }
    }

    .gw-form__select {
        width: calc(50% - 0.325rem);
    }

    .gw-button--multi-toggle {
        background-color: $color-brand;
        border: $border-width-l $border-style $color-brand;
        font-weight: 700;
        margin-block: $gutter-s;
        width: 100%;

        .gw-form__field {
            width: 50%;
            color: $color-text-alt;

            &.is-selected {
                background-color: $color-white;
                color: $color-brand;
            }
        }
    }

    &.is-displayed-on-large {
        @include bp-large {
            background-color: transparent;
            display: flex;
            inset-inline-start: $gutter-xl;
            max-width: 50%;
            position: absolute;

            .gw-mf-banner__form {
                margin-block: auto;
                margin-inline: $gutter;
            }

            .gw-mf-form__input--search {
                margin-block: 0;
            }

            .gw-form__range__bubble {
                font-size: $font-size-xs;
                padding: 0.2rem;
            }

            .gw-mf-form__fieldset {
                justify-content: space-between;
            }
        }

        display: none;
    }

    &.is-hidden-on-large {
        background-color: $color-white;
        margin-block-end: $gutter-xl;
        margin-inline: -$layout-main-bleed;

        .gw-mf-banner__form.gw-mf-form {
            border-radius: 0;
            margin: 0 auto;
        }

        .gw-mf-form__input--search {
            margin-block: 0;
        }

        .gw-button-group {
            margin-block-end: 0;
        }

        .gw-mf-form__title {
            text-align: center;
        }
    }

    .gw-mf-form__buttons {
        .gw-button.gw-button--text {
            background-color: transparent;
            border: 0;
            font-size: $font-size-xs;

            &:first-child {
                color: $color-brand;
            }

            &:hover:not(:disabled) {
                text-decoration: underline;
            }
        }
    }

    &.is-hidden {
        display: none;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-banner--multi-franchise) {
    @include gw-banner--multi-franchise;
}
