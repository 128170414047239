@mixin gw-product-summary {
    .gw-product-summary {
        &__name {
            span {
                color: $color-grey-3;
            }
        }

        &__price,
        &__finance {
            align-items: center;
            background-color: transparent;
            border: $border-width $border-style $color-grey-3;
            border-radius: $border-radius-medium-small;
            color: $color-text-secondary;
            margin-block-end: $gutter-xs;

            span:nth-child(2) {
                @include fluid-type-default;
            }

            &--discount {
                align-items: center;
                color: $color-negative;
                font-family: $font-family-semibold;
            }

            &--extra {
                align-items: center;
                color: $color-text-secondary;
            }

            &--original {
                align-items: center;
            }

            &.is-selected {
                align-items: center;
                background-color: transparent;
                border: $border-width-m $border-style $color-brand-alt;
                color: $color-text;
            }
        }

        &__smallprint {
            color: $color-text-secondary;
        }

        & > .gw-button-group {
            .gw-button--whatsapp,
            .gw-button--text:not(.gw-button--primary) {
                border: none;
                color: $color-text-secondary;
                padding: 0;

                /* stylelint-disable max-nesting-depth */
                .gw-button__inner {
                    justify-content: start;

                    .gw-icon {
                        fill: $color-text-secondary;
                        height: $icon-size-s;
                        width: $icon-size-s;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        .gw-button-group__payment-options {
            font-family: $font-family-semibold;
            padding-block-end: 0;
        }
    }
}
