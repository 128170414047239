.gw-cookie-consent {
    background-color: $color-background;
    border: $border-width $border-style $color-grey-2;
    color: $color-text;
    display: none;
    inset-block-end: $gutter-xl;
    inset-inline-end: 0;
    inset-inline-start: 0;
    margin: 0 auto;
    max-width: 40rem;
    padding: $gutter;
    position: fixed;
    width: 95vw;

    > span:not([class]) {
        display: block;
        margin-block-end: $gutter-xs;
        text-align: center;

        > .gw-button {
            margin-block-end: $gutter-xs;
        }
    }

    .gw-button-group {
        align-items: center;
        justify-content: center;
        margin-block-end: $gutter-xs;
    }

    &--overlay {
        margin: 0;
        max-width: inherit;
        width: inherit;

        .gw-modal__inner {
            padding: $gutter;
            text-align: center;
            width: auto;

            .gw-button-group {
                align-items: center;
                justify-content: center;
                margin: $gutter 0;
            }
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-cookie-consent) {
    @include gw-cookie-consent;
}
