.gw-stock-filter {
    &-ajax {
        .gw-filter-sidebar__accordion-content & {
            > .gw-form__label {
                padding-block-start: $gutter;
                /* stylelint-disable max-nesting-depth */
                > h5 {
                    font-size: $font-size;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }
}

@if mixin-exists(gw-stock-filter) {
    @include gw-stock-filter;
}
