.gw-layout__sticky--footer--geronimo {
    padding: $gutter-s;

    .gw-sticky-footer__item {
        flex-grow: 0;
        margin-inline-end: $gutter-s;
        min-height: 0;

        .gw-button__inner .gw-icon {
            height: 1.5em;
            margin: 0;
            width: 1.5em;

            & + span {
                @include visually-hide;
            }
        }

        &:last-child {
            margin-inline-start: auto;
        }

        /* stylelint-disable selector-max-id */
        #back-to-top {
            align-self: end;
            padding: $gutter-xs;
        }
        /* stylelint-enable selector-max-id */
    }
}

@if mixin-exists(gw-sticky-footer--geronimo) {
    @include gw-sticky-footer--geronimo;
}
