@mixin gw-banner {
    .gw-banner {
        &__overlay {
            @include bp-medium {
                max-width: 60%;
            }

            background-color: transparent;

            .gw-banner__text--large {
                @include fluid-type-xxxl;
            }

            &--skin2,
            &--skin4 {
                @include bp-medium {
                    /* stylelint-disable max-nesting-depth */
                    .gw-banner__overlay {
                        color: $color-text;
                    }

                    /* stylelint-disable-next-line no-descending-specificity */
                    .gw-button.gw-button--primary[class*='--text'] {
                        border-color: $color-text;
                        color: $color-text;

                        &:hover:not(:disabled) {
                            border-color: $color-text;
                            color: $color-text;
                        }
                    }
                    /* stylelint-enable max-nesting-depth */
                }
            }

            .gw-banner__inner + & {
                background-color: #{rgba(var(--themeColorBrand), 0.8)};
                display: flex;
                flex-direction: column;
            }
        }

        &--product {
            .gw-product-specs__price--large {
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    $color-black 100%
                );

                width: 100%;

                span:first-of-type {
                    text-transform: none;
                }

                [id='vehiclePrice'] {
                    font-family: $font-family-title;
                }
            }

            .gw-product-specs__price--mobile {
                background-color: $color-grey-5;
            }

            .gw-banner__text--large {
                @include fluid-type-xxl;
            }

            .gw-banner__title {
                background-color: $color-grey-5;
                padding-block-end: $gutter-s;
                padding-block-start: $gutter-l;
            }
        }
    }
}
