.gw-product-gallery {
    .gw-tabs__content {
        background-color: transparent;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-product-gallery) {
    @include gw-product-gallery;
}
