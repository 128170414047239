.gw-product-filter.gw-product-filter--multi-franchise {
    // Override default 'gw-tabs' behaviour.
    &.gw-tabs {
        .gw-tabs__tablist {
            background-color: $color-grey-1;
            margin-block-end: $gutter;
        }

        .gw-tabs__button {
            background-color: $color-white;
            border: 0;
            border-radius: $border-radius-small;
            color: $color-text;
            font-family: $font-family-title;
            height: 3.75rem;
            margin: $gutter-xs;
            padding: 0;
            width: 10rem;

            .gw-image {
                height: auto;
                margin-inline: auto;
                max-height: 2.5rem;
                width: auto;
            }

            .gw-image + span {
                @include visually-hide;
            }

            &[aria-selected='true'] {
                background-color: $color-white;
                box-shadow: 0 0 1rem #{rgb(var(--themeGrey2))};
            }
        }

        .gw-tabs__content {
            background: transparent;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-product-filter--multi-franchise) {
    @include gw-product-filter--multi-franchise;
}
