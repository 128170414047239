// Stock list (New and Used).
.page-14,
.page-17,
.page-default-inventorylist {
    .gw-cms {
        > div {
            @include layout-centered-content;
        }
    }

    .gw-layout__inner--stock {
        @include bp-medium {
            display: flex;
            flex-flow: row wrap;
            margin-inline-end: -$layout-main-bleed;
            margin-inline-start: -$layout-main-bleed;
        }
        display: flex;
        flex-flow: column wrap;
        margin-inline-end: -$layout-main-bleed-mobile;
        margin-inline-start: -$layout-main-bleed-mobile;

        > section {
            flex: 1 1 auto;
        }
    }

    .gw-product-card {
        &:hover {
            .gw-card__title a {
                color: $color-brand;
            }
        }

        .gw-card__title {
            span {
                margin-block-start: $gutter-s;
            }
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(page-17-stock-list) {
    @include page-17-stock-list;
}
