.gw-filter-sidebar {
    @include bp-medium {
        height: auto;
        max-width: 20.625rem;
        width: 0;
    }
    background-color: $color-white;
    height: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &__accordion {
        align-items: center;
        background: $color-grey-1;
        border-radius: $border-radius-small;
        color: $color-text;
        cursor: pointer;
        display: flex;
        font-size: $font-size-m;
        margin-block-end: calc($gutter-xs / 3);
        padding: 1rem;

        &.is-disabled {
            background-color: $color-grey-2;
            color: $color-text-alt;
            cursor: not-allowed;
            opacity: 0.5;
        }

        &-content {
            display: none;
            padding: $gutter-s;

            &.show {
                display: flex;
            }

            &.gw-filter-sidebar__accordion-content--select {
                padding-block-start: $gutter-xs;
                /* stylelint-disable-next-line max-nesting-depth */
                &.show {
                    display: block;
                }
            }

            .gw-form__fieldset {
                width: 100%;
                /* stylelint-disable-next-line max-nesting-depth */
                &--minmax {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }
            }

            .gw-filter-sidebar__column {
                /* stylelint-disable max-nesting-depth */
                div.gw-form__field {
                    background-color: transparent;
                    border: none;
                    border-radius: 0;
                    margin-block-end: 0;
                    padding: 0;
                    width: 100%;
                }

                .gw-form__label {
                    padding: $gutter-xs;
                    text-align: left;

                    > span {
                        color: $color-grey-3;
                        font-size: $font-size;
                        margin-inline-end: auto;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }

            .gw-form__input,
            .gw-form__select {
                background-color: transparent;
                color: $color-text;
                /* stylelint-disable max-nesting-depth */
                &[type='number'] {
                    -moz-appearance: textfield;
                    background: linear-gradient(
                        90deg,
                        $color-grey-1 2rem,
                        transparent 2rem
                    );
                    text-indent: $gutter;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }

            /* stylelint-disable max-nesting-depth */
            .gw-form__fieldset--checkbox.gw-form__fieldset--checkbox-custom {
                .gw-form__input[type='checkbox'] {
                    &:focus {
                        outline: $border-width $border-style $color-brand;
                    }
                }
            }
            /* stylelint-enable max-nesting-depth */
            .gw-form__range,
            .gw-form__range--multiple {
                width: 100%;
                /* stylelint-disable max-nesting-depth */
                .gw-form__label {
                    background-color: transparent;
                    padding-block-start: 0;
                }

                &.gw-form__range--unit-conversions {
                    .gw-form__fieldset {
                        position: inherit;

                        > .gw-form__field > .gw-form__label {
                            padding-block-start: $gutter-s;
                            padding-inline-start: 0;
                        }
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }

            .gw-button--multi-toggle {
                margin-block-end: $gutter-s;
            }
        }

        &-pricing {
            flex-wrap: wrap;
        }

        &__notification {
            align-items: center;
            background-color: $color-brand;
            border-radius: $border-radius-round;
            color: $color-white;
            display: flex;
            font-size: $font-size-s;
            height: $gutter-s;
            justify-content: center;
            margin-inline-start: $gutter-s;
            padding: $gutter-xs;
            width: $gutter-s;
        }

        > .gw-icon {
            margin-inline-start: auto;
        }

        &.active {
            margin: 0;

            > .gw-icon {
                transform: rotate(180deg);
            }
        }
    }

    &__button {
        &--reset,
        &--submit {
            margin-block: $gutter;
        }

        &--text {
            color: $color-brand-alt;
            cursor: pointer;
            font-family: $font-family-title;
            margin-inline-start: auto;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__content {
        > .gw-filter-sidebar__button--submit,
        > .gw-filter-sidebar__button--reset {
            @include bp-medium {
                display: none;
            }
        }
    }

    &__features {
        > .gw-accordion:nth-last-child(2) {
            margin-block-end: 0;
        }
    }

    &__finance-options {
        &__info {
            width: 100%;
        }
    }

    &__finance-type-button {
        margin-block-end: $gutter-s;
    }

    &__header {
        align-items: baseline;
        display: flex;
        margin: $gutter 0;

        &--main {
            margin-block-start: $gutter-xl;
        }

        &:first-of-type {
            margin-block-start: 0;
        }
    }

    &__heading {
        color: $color-brand;
        font-family: $font-family-title;
        font-size: $font-size-m;
    }

    .gw-form__fieldset--minmax > .gw-form__field {
        &--container {
            display: flex;
        }
    }

    &__payment-filter {
        width: 100%;

        > .gw-filter-sidebar__accordion-content {
            padding: $gutter-s;
        }

        .gw-form__field > .gw-form__label > .gw-filter-sidebar__currency-label {
            font-size: $font-size;
            inset-block-end: 0.7rem;
            inset-inline-start: 0.7rem;
            position: absolute;
        }
    }

    &__pricing-buttons.gw-button-group.gw-button-group--toggle {
        justify-content: inherit;
        margin: 0;
        margin-block-end: $gutter;
        padding: 0;

        .gw-button--toggle {
            &.is-selected {
                background-color: rgba(var(--themeColorBrandAlt), 0.05);
                border-color: $color-brand-alt;
                box-shadow: none;
                color: $color-brand-alt;
                /* stylelint-disable max-nesting-depth */
                &:hover:not(:disabled) {
                    background-color: rgba(var(--themeColorBrandAlt), 0.05);
                    border-color: $color-brand-alt;
                    color: $color-brand-alt;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }

    &__buttons {
        align-items: center;
        background-color: $color-white;
        display: flex;

        .gw-filter-sidebar__button {
            &--submit,
            &--reset {
                /* stylelint-disable-next-line max-nesting-depth */
                @include bp-medium {
                    display: block; // We still need to override the display: none below as this affects the height of the block when in desktop view.
                    height: fit-content;
                    visibility: hidden;
                }

                display: none;
            }
        }
    }

    .gw-filter-sidebar__toggle {
        align-items: center;
        color: $color-brand;
        display: flex;

        &--close {
            font-family: $font-family-title;
        }

        > .gw-icon {
            margin-inline-end: $gutter-xs;
        }
    }

    &__toggle[data-id='products-filter-button'] {
        @include bp-medium {
            transition: width 0.25s linear;
        }
        align-items: center;
        color: $color-brand;
        cursor: pointer;
        display: flex;
        flex-grow: 1;
        font-family: $font-family-title;
        font-size: $font-size;
        height: $gutter-l;
        inset: $gutter-s 0 0 $gutter-s;
        justify-content: flex-start;
        width: auto;

        &.open {
            @include bp-medium {
                margin-inline-start: -$layout-main-bleed;
                padding-inline-start: $layout-main-bleed;
                transition: width 0.25s linear;
            }

            background: white;
            height: 5.5rem;
            margin-block-end: 0;
            margin-block-start: -$gutter-s;
            margin-inline-end: 0;
            margin-inline-start: 0;
            padding: $gutter-s;
            width: 20.625rem;

            .gw-button__inner > span {
                display: none;
            }

            &:active {
                transform: scale(1);
            }

            + .gw-filter-sidebar__button--submit {
                /* stylelint-disable max-nesting-depth */
                @include bp-medium {
                    visibility: visible;
                }

                + .gw-filter-sidebar__button--reset {
                    @include bp-medium {
                        visibility: visible;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        /* stylelint-disable no-descending-specificity */
        svg.gw-icon {
            color: $color-brand;
            height: $icon-size-m;
            stroke: $color-brand;
            width: $icon-size-m;

            &--close {
                display: none;
            }

            &--filter {
                display: block;
            }
        }
        /* stylelint-enable no-descending-specificity */
        .gw-filter-sidebar__toggle--close {
            display: none;
        }

        .gw-filter-sidebar__toggle--open {
            display: block;
        }

        .gw-filter-sidebar__toggle--close,
        .gw-filter-sidebar__toggle--open {
            margin-inline-start: $gutter-xs;
        }
    }

    + .gw-modal__overlay[id='finance-options-modal'] {
        .gw-modal {
            &__subtitle,
            &__title {
                text-transform: none;
            }

            &__subtitle {
                color: $color-grey-3;
                font-size: $font-size-m;
                margin-block-end: $gutter;
            }

            &__title {
                margin-block-end: $gutter-l;
            }

            .gw-form {
                /* stylelint-disable max-nesting-depth */
                &__field {
                    border: $border-width $border-style $border-color;
                    border-radius: $border-radius-small;
                    padding: $gutter;

                    .gw-button {
                        display: block;
                        margin-block-start: $gutter;
                    }
                }
                /* stylelint-disable no-descending-specificity */
                &__label {
                    margin-block-end: $gutter;

                    &-text {
                        color: $color-grey-3;
                        font-size: $font-size-m;
                        margin: 0;
                        text-transform: none;
                    }
                }
                /* stylelint-enable max-nesting-depth, no-descending-specificity */
            }
        }
    }

    > .gw-product-search-content > *,
    hr {
        @include bp-medium {
            transition: all 0.2s linear;
        }
        transform: scaleX(0);
        transform-origin: left center;
    }

    &.show {
        @include bp-medium {
            height: calc(100vh - 4rem);
            inset-block-start: 0;
            position: sticky;
            transition: width 0.25s linear;
        }
        height: auto;
        margin-block-start: -$gutter-s;
        overflow: auto;
        padding: $gutter-s;
        width: 100%;

        .gw-icon--close {
            display: block;
        }

        .gw-icon--filter {
            display: none;
        }

        .gw-filter-sidebar__toggle--close {
            display: block;
        }

        .gw-filter-sidebar__toggle--open {
            display: none;
        }

        > .gw-product-search-content {
            display: block;
        }

        > .gw-product-search-content > *,
        hr {
            @include bp-medium {
                transition: all 0.2s linear;
            }
            transform: scaleX(1);
            transform-origin: left center;
        }
    }
}

@if mixin-exists(gw-filter-sidebar) {
    @include gw-filter-sidebar;
}
