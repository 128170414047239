/*
----------------------------------------------------------------------------
Simplified stylesheet for pages that need to be printed (e.g. stock details).

TODO:
- If this gets too big, we could split the code into smaller partials.
- Some of these style rules could be moved into "_print.scss".
----------------------------------------------------------------------------
*/

@media screen {
    .page-printstock {
        .gw-layout__has-max-width {
            margin-block-start: $gutter-xl;
        }

        .gw-grid--asymmetric {
            @include responsive-grid-asymmetric($col1: 55%, $col2: 45%);
        }

        // Due to specificity with the new stock pages - we are having to reference the page number to change the product specs size.
        &.page-16,
        .page-default-inventorydetail {
            .gw-grid--asymmetric {
                .gw-product-specs {
                    /* stylelint-disable-next-line max-nesting-depth */
                    &__item {
                        font-size: $font-size;
                    }
                }
            }
        }

        .gw-header__logo {
            display: block;
            margin: 0 0 $gutter-xs;
        }

        .gw-product-summary {
            background-color: transparent;

            &__name {
                & > span {
                    margin-block-start: 0;
                }
            }

            &__price,
            &__finance {
                padding: 0;
            }
        }

        h2:not(:first-of-type) {
            margin-block-start: $gutter;
        }

        .gw-grid:not(.gw-contact-card-grid),
        .gw-table {
            margin-block-end: 0;
        }

        [aria-labelledby='branch-contact-details'] {
            margin-block-start: $gutter;

            .gw-button-group {
                margin-block-end: $gutter-xs;
            }
        }

        [class*='card-grid'].gw-grid {
            justify-content: start;
        }
    }
}

@media print {
    .page-printstock {
        .gw-product-summary__price,
        .gw-product-summary__finance {
            font-size: 15pt;
            font-weight: bold;
            line-height: 1.3;
        }

        .gw-product-summary__price {
            margin-block-end: 0;
        }

        .gw-product-summary__price--extra,
        .gw-product-summary__smallprint {
            font-size: 8pt;
        }

        .gw-grid--asymmetric {
            > :first-child {
                margin-inline-start: -5pt;
                max-width: 200pt;
            }

            > :last-child {
                max-width: 350pt;
            }

            img {
                display: block;
            }

            .gw-icon {
                display: block;
                height: 1.5em;
                width: 1.5em;
            }
        }

        .gw-product-specs__list {
            display: flex;
            flex-wrap: wrap;
            font-size: 8pt;
            margin-block-start: 5pt;

            > * {
                align-items: center;
                display: flex;
                flex-direction: column;
                margin-block-end: 5pt;
                margin-inline-end: 5pt;

                span:first-of-type {
                    display: none;
                }
            }
        }

        .gw-grid {
            .gw-table {
                margin-block-end: 10pt;
                width: calc(50% - 10pt);
            }
        }
    }
}
