@mixin cta {
    @include bp-medium {
        font-size: $font-size-m;
    }
    font-size: $font-size;
    padding: $gutter-xs;
}

.gw-banner {
    margin-block-end: $gutter-xl;
    position: relative;

    // Ensure banner image takes up all available space on widescreen / hi-res.
    .gw-image {
        width: 100%;
    }

    &__custom-page {
        .gw-image {
            @include bp-large {
                max-height: 38.5rem;
            }

            @include bp-xlarge {
                max-height: 44rem;
            }

            @include bp-xxlarge {
                max-height: 35rem;
            }

            @include bp-2k {
                max-height: 24rem;
            }

            margin-block-end: $gutter-xl;
            min-width: 100%;
        }
    }

    &__overlay {
        @include bp-medium {
            inset-block-start: $gutter-xl;
            inset-inline-start: $gutter-xl;
        }
        align-items: flex-start;
        background-color: #{rgba(var(--themeColorBrand), 0.6)};
        color: $color-text-alt;
        display: flex;
        flex-direction: column;
        height: auto;
        inset-block-start: $gutter-xs;
        inset-inline-start: $gutter-xs;
        max-width: 33%;
        padding: $gutter;
        position: absolute;
        width: auto;

        // Add some separation between all sibling elements inside overlay.
        > * + * {
            @include bp-medium {
                margin-block-start: $gutter-s;
            }
            margin-block-start: $gutter-xs;
        }

        // Overlay position is based on '3x3' grid - e.g. 'top-middle' = top row, middle slot. Default = 'top-left'.
        &--top-middle {
            inset-inline-start: 50%;
            transform: translateX(-50%);
        }

        &--top-right {
            @include bp-medium {
                inset-inline-end: $gutter-xl;
            }
            inset-inline-end: $gutter-xs;
            inset-inline-start: auto;
        }

        &--middle-left {
            inset-block-start: 50%;
            transform: translateY(-50%);
        }

        &--middle-middle {
            inset-block-start: 50%;
            inset-inline-start: 50%;
            transform: translate(-50%, -50%);
        }

        &--middle-right {
            @include bp-medium {
                inset-inline-end: $gutter-xl;
            }
            inset-block-start: 50%;
            inset-inline-end: $gutter-xs;
            inset-inline-start: auto;
            transform: translateY(-50%);
        }

        &--bottom-left {
            @include bp-medium {
                inset-block-end: $gutter-xl;
                inset-inline-start: $gutter-xl;
            }
            inset-block-end: $gutter-xs;
            inset-block-start: auto;
            inset-inline-start: $gutter-xs;
        }

        &--bottom-middle {
            @include bp-medium {
                inset-block-end: $gutter-xl;
            }
            inset-block-end: $gutter-xs;
            inset-block-start: auto;
            inset-inline-start: 50%;
            transform: translateX(-50%);
        }

        &--bottom-right {
            @include bp-medium {
                inset-block-end: $gutter-xl;
                inset-inline-end: $gutter-xl;
            }
            inset-block-end: $gutter-xs;
            inset-block-start: auto;
            inset-inline-end: $gutter-xs;
            inset-inline-start: auto;
        }

        // Text alignment.
        &--top-middle,
        &--middle-middle,
        &--bottom-middle {
            align-items: center;
            text-align: center;
        }

        // CTA in overlay.
        .gw-button {
            @include cta;
        }

        // Overlay is a sibling of '.gw-banner__inner', to be shown on mobile (e.g. in hero banner).
        .gw-banner__inner + & {
            @include bp-medium {
                margin: $gutter-s $gutter-xl;
            }
            background-color: #{rgba(var(--themeColorBrand), 0.6)};
            inset-block-start: 0;
            inset-inline-start: 0;
            margin: $gutter-s auto;
            max-width: 100%;
            position: relative;
            width: 100%;
        }

        // Whole overlay is clickable. Position NOT based on '3x3' grid.
        &.gw-banner__overlay--block-link {
            height: 100%;
            inset-block-start: 0;
            inset-inline-end: 0;
            inset-inline-start: 0;
            max-width: 50%;
            text-decoration: none;
            transform: none;

            &:hover {
                background-color: $color-brand;
                opacity: 1;
            }

            &:active {
                transform: none;
            }
        }

        &--skin1 {
            &:before {
                background: linear-gradient(
                    to bottom,
                    #{rgba(var(--themeBlack), 0.4)},
                    #{rgba(var(--themeBlack), 0)}
                );
            }
        }

        &--skin2 {
            &:before {
                background: linear-gradient(
                    to bottom,
                    #{rgba(var(--themeWhite), 0.4)},
                    #{rgba(var(--themeWhite), 0)}
                );
            }
        }

        &--skin3 {
            &:before {
                background: #{rgba(var(--themeBlack), 0.4)};
            }
        }

        &--skin4 {
            &:before {
                background: #{rgba(var(--themeWhite), 0.4)};
            }
        }

        &--skin5 {
            &:before {
                background: transparent;
            }
        }
    }

    &__text {
        @include bp-medium {
            @include fluid-type-default;
        }
        font-size: $font-size-s;
        width: 100%; // So text wraps in legacy browsers.

        &--large {
            @include bp-medium {
                @include fluid-type-l;
            }
            font-size: $font-size-m;
        }

        // TODO: Confirm if 'gw-banner__text' are markdown wrapper DIVs.
        > * {
            margin: 0;
        }
    }

    &__desktop {
        &--hidden {
            @include bp-medium {
                display: none;
            }
        }
    }

    small {
        width: 100%; // So text wraps in legacy browsers.
    }

    // Hero banner.
    &--hero {
        // In case we need hero overrides.
    }

    // Page title banner.
    &--page-title {
        margin-block-end: 0;

        .gw-banner__title {
            background-color: #{rgba(var(--themeBlack), 0.6)};
            color: $color-text-alt;
            padding: $gutter-xs;
        }

        h1 {
            @include bp-medium {
                @include fluid-type-l;
            }
            color: inherit;
            font-size: $font-size-m;
            margin: 0;
        }
    }

    // Specific banner variants - e.g. promotions, news, etc.
    &-wrap {
        background: $color-grey-1;
        padding: $gutter;

        &__title {
            margin-block-end: $gutter;
        }

        .gw-slider {
            margin-block-end: $gutter;
            margin-inline-end: -$gutter;
            margin-inline-start: -$gutter;
        }

        .gw-banner__overlay {
            max-width: 50%;
        }
    }

    // Video banners.
    &--video {
        video {
            width: 100%;
        }
    }

    &--product {
        .gw-product-specs__branch-dropdown {
            color: $color-text;
            font-family: $font-family-bold;
            font-size: $font-size-m;
            height: 4rem;
            margin-block-end: $gutter-xs;
            margin-block-start: $gutter-xs;
            padding-inline-end: 4.5rem;
        }

        .gw-product-specs__price--large {
            @include bp-medium {
                display: flex;
            }
            background-color: #{rgba(var(--themeColorBrand), 0.5)};
            display: none;
            inset-block-end: 0;
            padding: $gutter-l;
            position: absolute;
        }

        .gw-product-specs__price--mobile {
            @include bp-medium {
                display: none;
            }
            display: block;
            padding-block-end: $gutter-xs;
            padding-block-start: $gutter-xs;

            span,
            strong {
                display: block;
            }

            /* stylelint-disable-next-line no-descending-specificity */
            .gw-product-specs__branch-dropdown {
                height: 3.5rem;
                max-width: 100%;
                text-align: center;
            }
        }

        .gw-product-specs__price {
            span:first-of-type {
                margin-block-start: 0;
                text-transform: uppercase;
            }
        }

        .gw-banner__title {
            padding-block-end: 0;
        }

        .gw-product-specs__price--mobile,
        .gw-banner__title {
            background-color: $color-brand;
            color: $color-text-alt;
            text-align: center;
        }

        [id='vehiclePrice'] {
            font-family: $font-family-bold;
        }
    }

    &__tabs {
        position: relative;

        &--background {
            display: none;
            height: 100%;
            inset-block-start: 0;
            object-fit: cover;
            object-position: right;
            position: absolute;
            width: 100%;
            z-index: 0;

            &.active {
                display: block;
            }
        }

        &--container {
            @include bp-medium {
                background: linear-gradient(
                    90deg,
                    rgba(var(--themeColorBrandAlt), 1) 0%,
                    rgba(var(--themeColorBrandAlt), 1) 20%,
                    rgba(var(--themeColorBrandAlt), 0.5) 100%
                );
                padding: 8rem 2rem;
            }

            background: linear-gradient(
                180deg,
                rgba(var(--themeColorBrandAlt), 1) 0%,
                rgba(var(--themeColorBrandAlt), 1) 20%,
                rgba(var(--themeColorBrandAlt), 0.5) 100%
            );
            height: 100%;
            min-height: 850px;
            padding: 2rem;
            position: relative;
            width: 100%;
            z-index: 1;
        }

        &--tabs {
            @include bp-medium {
                flex-flow: row nowrap;
            }

            display: flex;
            flex-flow: column nowrap;
            justify-content: left;
            margin: 0 auto;
            max-width: 95vw;
        }

        &--tab {
            @include bp-medium {
                padding: 2rem;
            }

            border-bottom: 2px solid rgb(var(--themeWhite), 0.35);
            color: $color-grey-1;
            cursor: pointer;
            opacity: 0.7;
            padding: 1rem 0;
            position: relative;

            &:not(:first-child) {
                /* stylelint-disable max-nesting-depth */
                @include bp-medium {
                    margin-left: 0.5rem;
                }
                /* stylelint-enable max-nesting-depth */
            }

            &:after {
                background: $color-grey-3;
                content: '';
                height: 2px;
                inset-block-start: 100%;
                inset-inline-start: 0;
                opacity: 0.5;
                position: absolute;
                transition: width 0.2s linear;
                width: 0px;
            }

            &:hover {
                opacity: 1;

                /* stylelint-disable max-nesting-depth */
                &:after {
                    background: $color-white;
                    opacity: 1;
                    transition: width 0.2s linear;
                    width: calc(100% - 2rem);
                }
                /* stylelint-enable max-nesting-depth */
            }

            &.active {
                color: $color-white;
                opacity: 1;

                /* stylelint-disable max-nesting-depth */
                &:after {
                    background: $color-white;
                    opacity: 1;
                    transition: width 0.2s linear;
                    width: calc(100% - 2rem);
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        &--content {
            margin: 0 auto;
            max-width: 95vw;
        }

        &--item {
            color: $color-white;
            display: none;
            margin: 3rem 0 0 0;
            max-height: 450px;
            overflow: auto;

            &.active {
                display: flex;
                flex-flow: column nowrap;
            }

            h2 {
                color: $color-white;
                margin: 3rem 0;
            }

            a {
                margin-block-start: 3rem;
                max-width: 200px;
                padding: 1.75rem;
                text-align: center;
                width: auto;

                /* stylelint-disable max-nesting-depth */
                &.gw-button {
                    &.gw-button--text {
                        &.gw-button--positive {
                            background-color: $color-grey-1;
                            border-color: $color-grey-1;
                            color: $color-brand;

                            &:hover,
                            &:focus,
                            &:active {
                                background: $color-brand-alt;
                                border-color: $color-brand-alt;
                                color: $color-text-alt;
                            }
                        }
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }

    // Banners in a slider.
    /* stylelint-disable order/order */
    @at-root {
        .gw-slider & {
            margin-block-end: 0;
        }
    }
    /* stylelint-enable order/order */
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-banner) {
    @include gw-banner;
}
