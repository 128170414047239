.gw-tabbed-slider {
    @include bp-medium {
        margin-inline: $layout-main-bleed;
    }

    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: $grid-gap;
    justify-content: center;
    margin-inline: $layout-main-bleed-mobile;

    &__wrap {
        @include bp-medium {
            margin-inline: -$layout-main-bleed;
        }

        background-color: lightgrey;
        margin-inline: -$layout-main-bleed-mobile;
        padding-block: $gutter-xl;
    }

    &__tablist {
        @include bp-medium {
            max-width: 40%;
        }
    }

    &__title {
        font-family: $font-family-title;
        margin-block-end: $gutter-l;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: $font-size-xxl;
        }
    }

    &__button {
        border: $border-width $border-style $color-brand;
        border-radius: $border-radius-medium;
        display: flex;
        flex-direction: flex-start;
        font-family: $font-family-title;
        margin-block-end: $gutter-xs;
        padding: $gutter;

        .gw-icon {
            height: $icon-size-l;
            margin-inline-end: $gutter-xs;
            vertical-align: middle;
            width: $icon-size-l;
        }

        span {
            display: none;
        }

        &:hover {
            cursor: pointer;
        }

        &.is-selected,
        &:hover {
            background-color: rgba(var(--themeWhite), 0.2);

            span {
                display: block;
                font-family: $font-family;
                margin-block-start: math.div($gutter-xs, 2);
            }
        }

        .gw-tabbed-slider__input {
            display: none;
        }
    }

    &__label {
        align-self: center;
    }

    &__images {
        @include bp-medium {
            max-width: 55%;
        }
    }

    &__image {
        display: none;

        &.is-selected {
            display: block;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-tabbed-slider) {
    @include gw-tabbed-slider;
}
