@mixin mobile-grid-cols {
    // Home link width constrained by minmax so icon can flex-shrink.
    @include bp-small {
        grid-template-columns: auto auto minmax(50vw, 1fr);
    }

    @include bp-xsmall {
        grid-template-columns: minmax(auto, 20vw) auto minmax(50vw, 1fr);
    }
}

.gw-header {
    /* stylelint-disable max-nesting-depth */
    @at-root {
        // Place sticky header CSS Grid items in 1 row.
        .is-js-sticky & {
            // Only show sticky header on medium screens.
            @include bp-medium {
                align-items: center;
                display: grid;
                grid-template-areas: 'primary secondary';
                grid-template-columns: auto 1fr;
                grid-template-rows: minmax(
                    $layout-header-grid-row-height,
                    auto
                );
                position: sticky;

                .gw-header__contact {
                    display: block;
                }

                .gw-header--primary {
                    grid-template-areas: 'home nav';
                    grid-template-columns: 1fr auto;
                    padding: 0;

                    .gw-header__nav {
                        align-items: center;
                    }
                }

                .gw-header--secondary {
                    background-color: $color-background;
                    grid-template-columns: 3fr auto 2.5fr;
                    grid-template-areas: 'search offers contact';
                }
            }

            @include bp-medium {
                padding-block-start: 0;
            }
        }

        .is-js-sticky &__home {
            margin-inline-end: 0;

            .gw-image {
                height: $icon-size-home-mobile;

                &.is-hidden-on-large {
                    /* stylelint-disable-next-line declaration-no-important */
                    display: block !important;
                }

                & + .gw-image {
                    display: none;
                }
            }
        }

        .is-js-sticky &__offers {
            height: 100%;
        }

        .is-js-sticky &__logo {
            display: none;
        }
    }
    /* stylelint-enable max-nesting-depth */
    &--primary {
        @include mobile-grid-cols;
        @include bp-medium {
            grid-template-columns: auto 1fr auto;
        }

        @include bp-large {
            grid-template-rows: minmax($layout-header-grid-row-height, auto);
        }

        background-color: $color-background;
        display: grid;
        grid-area: primary;
        grid-template-areas: 'home nav logo';
        grid-template-columns: auto 4fr 2fr;
        grid-template-rows: minmax($layout-header-grid-row-height-mobile, auto);
        padding: $gutter-xs 0;
    }

    &--secondary {
        @include bp-medium {
            grid-template-columns: 0.5fr 3fr;
        }

        @include bp-large {
            grid-template-areas: 'search offers contact';
            grid-template-columns: 4fr auto 2.5fr;
            grid-template-rows: minmax($layout-header-grid-row-height, auto);
        }

        background-color: $color-grey-1;
        display: grid;
        grid-area: secondary;
        grid-template-areas:
            'offers contact'
            'search search';
        grid-template-columns: 0.4fr 3fr;
        grid-template-rows: minmax($layout-header-grid-row-height-mobile, auto) auto;
        row-gap: 0;
    }

    &__home {
        @include bp-medium {
            margin-inline-end: $gutter-s;
            margin-inline-start: $gutter-s;
        }
        align-items: center;
        grid-area: home;
        justify-content: flex-start;
        margin: 0 $gutter-xs;
        padding: 0;

        .gw-image {
            @include bp-xsmall {
                height: auto;
            }
            @include bp-medium {
                height: $icon-size-home;
            }
            flex-shrink: 1;
            height: $icon-size-home-mobile;
            width: auto;

            & + .gw-image {
                /* stylelint-disable-next-line max-nesting-depth */
                @include bp-large {
                    display: block;
                }
                display: none;
            }
        }
    }

    &__nav {
        grid-area: nav;
    }

    &__logo {
        @include bp-medium {
            margin-inline-end: $gutter-s;
            margin-inline-start: $gutter;
        }
        align-items: center;
        color: $color-brand-alt;
        display: flex;
        grid-area: logo;
        justify-content: flex-end;
        margin: 0 $gutter-s;

        /* stylelint-disable-next-line no-descending-specificity */
        .gw-image {
            max-height: 3.125rem; // 50px.
            max-width: 15.625rem; // 250px.
        }

        * {
            // Ensure we clamp font-size to be the same as <body>.
            @include fluid-type-default;
            margin: 0;
        }
    }

    &__search {
        grid-area: search;
    }

    &__offers {
        @include bp-medium {
            padding: inherit;
        }

        align-items: center;
        display: flex;
        grid-area: offers;
        justify-content: center;
        max-height: $layout-header-grid-row-height;
        max-width: $layout-header-grid-col-width-small;
        padding: 0;
    }

    &__contact {
        grid-area: contact;
    }

    &__login {
        grid-area: login;
    }

    // Vertical header
    &--vertical {
        @include bp-medium {
            align-items: center;
            flex-direction: column;
            height: 100%;
            justify-content: flex-start;
        }

        display: flex;
        justify-content: space-between;

        .gw-header__home {
            margin: 0;
            padding: $gutter-xs;
        }
    }

    &--gc {
        /* stylelint-disable max-nesting-depth */
        @at-root {
            // Place sticky header CSS Grid items in 1 row.
            .is-js-sticky & {
                // Only show sticky header on medium screens.
                @include bp-medium {
                    background-color: $color-white;
                    grid-template-areas: 'home . login nav';
                    grid-template-columns: auto 1fr auto auto;
                }
            }

            .is-js-sticky & .gw-header__login.gw-button[class*='--text'] {
                background-color: rgba(var(--themeColorBrand), 0.1);
                color: $color-brand;

                &:hover {
                    background-color: $color-brand;
                    color: $color-text-alt;
                }
            }
        }

        @include bp-medium {
            padding-inline: $layout-main-bleed;
        }

        background-color: transparent;
        display: grid;
        grid-template-areas: 'home . nav login';
        grid-template-columns: auto 1fr auto auto;

        .gw-header__home {
            @include bp-medium {
                margin: 0;
            }

            .gw-image {
                @include bp-small {
                    &.is-hidden-on-large {
                        /* stylelint-disable-next-line declaration-no-important */
                        display: none !important;
                    }

                    & + .gw-image {
                        display: block;
                    }
                }
                @include bp-medium {
                    height: 3rem;
                }

                height: 2rem;
            }
        }

        .gw-header__login {
            @include bp-medium {
                display: flex;
            }

            display: none;

            &.gw-button[class*='--text'] {
                align-self: center;
                background-color: #{rgba(var(--themeWhite), 0.2)};
                border: 0;
                border-radius: $border-radius-rounded;
                color: $color-text-alt;
                height: fit-content;
                margin: $gutter-xs;
            }
        }
        /* stylelint-enable max-nesting-depth */
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-header) {
    @include gw-header;
}
