.gw-show-hide {
    &__button {
        font-size: $font-size-xs;
        margin-block-end: $gutter-s;
    }

    &.is-js-enabled {
        .gw-show-hide__content {
            @include focus-default($opacity: false, $transform: false);
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-show-hide) {
    @include gw-show-hide;
}
