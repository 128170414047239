// This is not included in Storybook. It's simply a container at the top of the page for links to swap languages.
.gw-languages-menu {
    padding: $gutter-xs / 2;
    text-align: end;

    > a {
        margin-inline-end: $gutter-xs;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-languages-menu) {
    @include gw-languages-menu;
}
