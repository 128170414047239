@mixin gw-promotion-card {
    .gw-promotion-card {
        background-color: $color-white;
        border: $border-width $border-style $color-grey-2;
        border-radius: $border-radius-medium-small;
        box-shadow: none;

        &:hover {
            border: $border-width $border-style $color-grey-3;
        }

        .gw-card__media {
            .gw-image {
                border-end-end-radius: 0;
                border-end-start-radius: 0;
                border-start-end-radius: $border-radius-medium-small;
                border-start-start-radius: $border-radius-medium-small;
            }
        }

        .gw-card__title {
            color: $color-text;
            text-transform: none;
        }

        .gw-card__text {
            color: $color-text-secondary;
            margin-block-end: $gutter-xs;
        }

        .gw-card__actions {
            padding-block-end: $gutter-xs;
            padding-block-start: $gutter-xs;
        }

        .gw-button-group.gw-card__actions {
            margin-block-end: $gutter-xs;
            margin-block-start: $gutter-s;
        }

        &__tags {
            color: $color-text-secondary;
            text-transform: capitalize;
        }

        &--secondary {
            background: $color-white;

            .gw-card__title {
                font-family: $font-family-title;
                font-weight: 200;
                letter-spacing: 0.01875rem;
                text-transform: none;
            }

            .gw-card__media {
                .gw-image {
                    border-start-end-radius: 0;
                    border-start-start-radius: 0;
                }
            }
        }

        &--block-link {
            background-color: $color-grey-1;
            color: $color-link;
            font-family: $font-family-title;
            text-decoration: none;

            .gw-card__title {
                color: $color-link;
                text-align: center;
            }
        }
    }
}
