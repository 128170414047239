@mixin gw-modal {
    .gw-modal {
        &__ajax {
            margin-block-end: $gutter-l;
        }

        &__inner {
            background-color: $color-grey-1;
        }
    }
}
