.gw-accordion {
    margin-block-end: $gutter-xl;

    .gw-icon {
        margin-inline-start: $gutter-s;
    }

    &__title {
        font-family: $font-family;
        font-size: unset;
        font-weight: normal;
        margin-block-end: 0;
    }

    &__button {
        @at-root {
            [data-whatintent='mouse'] &:hover,
            [data-whatintent='keyboard'] &:focus {
                background-color: #{rgba(var(--themeWhite), 0.5)};
                color: #{rgba(var(--themeColorBrand), 0.8)};
            }
        }

        @include focus-default($opacity: false, $transform: false);
        background-color: $color-white;
        color: $color-brand;
        font-family: $font-family-title;
        font-size: $font-size-l;
        line-height: $line-height-l;
        margin-block-end: $gutter-xs;
        padding: $gutter-s;
        width: 100%;

        &[aria-expanded='true'] {
            background-color: $color-white;
            color: $color-brand;
            margin-block-end: 0;
        }

        &__inner {
            display: flex;
            justify-content: space-between;
        }
    }

    &__content {
        @at-root {
            // Reset styles for specific accordions.
            .gw-product-filter & {
                background-color: inherit;
                padding: 0;
            }
        }
        background-color: $color-white;
        margin-block-end: $gutter-xs;
        padding: $gutter;
        word-break: break-word;
    }

    &--contact {
        /* stylelint-disable max-nesting-depth */
        .gw-accordion {
            @include bp-medium {
                &__content {
                    display: grid;
                    gap: 0 10%;
                    grid-template-columns: 1fr 1fr;
                }

                &__row {
                    gap: 0;

                    &:not(:last-child) {
                        margin-block-end: $gutter-s;
                    }
                }
            }

            &__button,
            &__content {
                border-block-start: 0;
                border-inline-end: 0;
                border-inline-start: 0;
                border-radius: 0;
                margin: 0;

                &[aria-expanded='true'] {
                    border-radius: 0;
                }
            }

            &__button {
                padding: $gutter-l 0;
            }

            &__content {
                padding: 0;
                padding-block-end: $gutter-l;
            }

            &__link {
                color: $color-text;
                text-decoration: underline;
            }

            &__row {
                display: grid;
                gap: $gutter;
                grid-template-columns: 1fr 1fr;
                margin-block-end: $gutter-s;
            }

            &__title {
                font-size: $font-size-l;
            }
        }
        /* stylelint-enable max-nesting-depth */
    }

    &.is-js-enabled {
        .gw-accordion__content {
            @include focus-default($opacity: false, $transform: false);
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-accordion) {
    @include gw-accordion;
}
