.gw-table {
    margin-block-end: $gutter;
    width: 100%;

    th,
    td {
        padding: $gutter-xs;
    }

    tr {
        th {
            background-color: transparent;
            border: none;
            color: $color-text;
            font-weight: normal;
        }

        td {
            border: none;
            font-weight: bold;
        }

        &:nth-child(even) {
            background-color: $color-grey-1;
        }
    }

    caption {
        margin-block-end: $gutter-xs;
    }
    /* stylelint-disable no-descending-specificity */
    &--striped {
        th {
            background-color: transparent;
        }

        tr:nth-child(odd) {
            @if $allow-dark-mode {
                /* stylelint-disable max-nesting-depth */
                @media (prefers-color-scheme: dark) {
                    color: $color-text-alt;
                }
                /* stylelint-enable max-nesting-depth */
            }
            background-color: $color-grey-1;
        }

        tr:nth-child(even) {
            background-color: $color-background;
        }
    }
    /* stylelint-enable no-descending-specificity */

    // Responsive wrapper.
    &-responsive-wrap[role='region'][aria-labelledby][tabindex] {
        @at-root {
            [data-whatintent='keyboard'] &:focus {
                @include focus-default($opacity: false, $transform: false);
            }
        }
        overflow: auto;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-table) {
    @include gw-table;
}
