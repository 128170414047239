.gw-product-leader-image {
    display: grid;
    gap: 0;
    grid-template-areas: 'a b';
    grid-template-columns: repeat(2, 50%);
    margin-block-end: $gutter-xl;

    &--single-image {
        display: block;
    }

    .gw-image {
        width: 100%;
    }

    :first-child {
        grid-area: a;
    }

    :last-child {
        grid-area: b;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-product-leader-image) {
    @include gw-product-leader-image;
}
