@mixin gw-typography {
    body {
        @include fluid-type-default(
            $min: $font-size-s,
            $ideal: calc(0.85rem + #{$fluid-type-target}),
            $max: $font-size
        );
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: normal;
    }

    h6 {
        font-size: inherit;
    }

    h4,
    h5 {
        font-family: $font-family-medium;
    }

    /* stylelint-disable no-descending-specificity */
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &:not([class]) {
            color: $color-link;

            &:hover {
                color: $color-link-hover;
            }
        }
    }
    /* stylelint-enable no-descending-specificity */
    [aria-current] {
        font-weight: normal;
    }

    b,
    strong {
        font-family: $font-family-semibold;
    }
}
