.gw-quick-view {
    &-grid {
        // Ensure grid can adopt a basic "masonry" layout.
        grid-auto-flow: dense;

        // Highlight selected (product) card.
        .gw-product-card {
            &.is-selected {
                border: $border-width $border-style $color-brand;
            }
        }

        .gw-button--toggle {
            .gw-icon {
                /* stylelint-disable max-nesting-depth */
                &:first-of-type {
                    display: block;
                }

                // Production code uses a toggle button helper which renders 2 icons.
                & + .gw-icon {
                    display: none;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }

    &__item {
        @at-root {
            [data-whatintent='keyboard'] &:focus {
                box-shadow: 0 0 0 0.25rem $color-neutral;
                outline: 0;
            }
        }
        @include bp-large {
            padding-block-start: 0;
            padding-inline-end: $gutter-l;
        }
        border: $border-width $border-style $color-brand;
        grid-column: 1 / -1; // Make quick view item span all grid columns.
        padding-block-start: $gutter-l;
        position: relative;
    }

    &__close {
        color: $color-brand;
        font-size: $font-size-m;
        inset-block-start: 0;
        inset-inline-end: 0;
        position: absolute;
    }

    &__media {
        @include responsive-media(
            $aspect-ratio: math.div(3, 2),
            $padding: 66.667%
        );
    }

    &__content {
        @include bp-large {
            padding-inline-end: 0;
            padding-inline-start: 0;
        }
        padding: 0 $gutter-s $gutter-s;
    }

    &--finance {
        padding: $gutter-l;

        &__description {
            margin-block-end: $gutter-l;
        }

        &__title {
            margin: 0;
        }

        &__subtitle {
            color: $color-grey-4;
            font-size: $font-size-m;
            margin-block-end: $gutter;
            text-transform: none;
        }

        .gw-button {
            margin-inline-end: $gutter;
        }

        .gw-product-summary__price,
        .gw-product-summary__finance {
            font-family: $font-family;
        }
    }

    .gw-grid--asymmetric {
        @include responsive-grid-asymmetric($col1: 45%, $col2: 55%);

        // Bully specificity.
        &.gw-grid--asymmetric {
            margin-block-end: 0;
        }
    }

    .gw-product-summary__name {
        color: $color-brand;
        font-family: $font-family-title;
        font-size: $font-size-m;
    }

    .gw-toolbar,
    .gw-product-specs {
        @include bp-large {
            margin-inline-end: 0;
        }
        margin-inline-end: -$gutter-s;
        margin-inline-start: -$gutter-s;
        width: 100%;
    }

    .gw-product-specs {
        background-color: $color-grey-1;
        font-size: $font-size-s;

        &__item {
            background: none;
            color: $color-text;
            flex-grow: 0;
            padding: $gutter-xs;

            span:first-of-type {
                @include visually-hide;
            }
        }
    }

    .gw-button-group {
        margin-block-end: 0;

        .gw-button {
            margin-block-end: 0;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-quick-view) {
    @include gw-quick-view;
}
