@mixin gw-filter-sidebar {
    .gw-filter-sidebar {
        &__accordion {
            background-color: transparent;
            border-block-start: $border-width $border-style $color-grey-3;
            border-radius: 0;
            color: $color-brand;
            font-family: $font-family-title;
            font-size: $font-size-m;
            margin-block-end: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;

            .gw-icon {
                color: $color-brand-alt;
            }

            &.is-disabled {
                background-color: transparent;
                border-block-start: $border-width $border-style $color-grey-3;
                color: $color-grey-3;
                opacity: 1;

                .gw-icon {
                    color: $color-grey-3;
                }
            }
        }

        &__accordion-content {
            padding: 0;
        }

        &__heading {
            font-size: $font-size-l;
        }

        &__currency-label + .gw-form__select {
            padding-inline-start: $gutter;
        }

        .gw-form__label {
            padding-inline-end: 0;
            padding-inline-start: 0;
        }

        hr {
            //TODO: remove from HTML?
            display: none;
        }
    }
}
