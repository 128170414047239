@mixin page-16-stock-detail {
    .page-16,
    .page-8,
    .page-default-inventorydetail {
        .gw-grid--asymmetric {
            .gw-tabs,
            .gw-tabs.is-js-enabled {
                .gw-tabs__content {
                    padding-inline-end: 0;
                    padding-inline-start: 0;
                }
            }

            .gw-product-specs {
                /* stylelint-disable max-nesting-depth */
                &__list {
                    @include bp-medium {
                        border: $border-width $border-style $color-grey-3;
                        border-radius: $border-radius-medium-small;
                        padding: $gutter-l $gutter;
                    }
                }

                &__item {
                    @include bp-medium {
                        flex: 1;
                        flex-direction: column;
                        font-size: $font-size-m;
                        gap: 0;
                        text-align: center;
                    }

                    color: $color-text;
                    flex: 0 0 100%;
                    flex-direction: row;
                    font-family: $font-family-title;
                    gap: $gutter-s;
                    text-align: initial;

                    .gw-icon {
                        fill: $color-text-secondary;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }

            .gw-contact-card {
                border: $border-width $border-style $color-grey-3;

                &:hover:not(:disabled) {
                    border: $border-width $border-style $color-grey-3;
                }
            }

            .gw-cms[aria-labelledby='about-us'] + div {
                padding: 0;
            }

            .gw-cms.gw-payment-options
                .gw-button-group--toggle__payment-options {
                margin-block: $gutter-l;

                .gw-button--toggle.is-selected {
                    background-color: transparent;
                    border-color: $color-brand-alt;
                    box-shadow: none;
                    color: $color-text;
                }
            }
        }
    }
}
