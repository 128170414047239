.gw-branch-rotator {
    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__branch-details {
        @include bp-medium {
            display: flex;
        }
    }

    &__dropdown {
        @if $allow-dark-mode {
            @media (prefers-color-scheme: dark) {
                background-color: $color-background-alt;
            }
        }
        background-color: $color-background;
        color: $color-text;
        color: initial;
        height: auto;
        max-height: calc(90vh - #{$layout-header-grid-row-height * 2});
        overflow-y: auto;
        padding-block-start: $gutter-xs;
        padding-inline-end: $gutter-s;
        padding-inline-start: $gutter-s;
        position: fixed;
        width: 100%;
        z-index: z('modal');

        &__list {
            .gw-accordion {
                /* stylelint-disable-next-line max-nesting-depth */
                &__button {
                    text-align: left;
                }
            }
        }

        &__item {
            align-items: center;
            display: flex;
            margin-block-end: $gutter-xs;
        }
    }

    &__inner {
        align-items: center;
        display: flex;
        height: 100%; // Just in case buttons don't render, we still get full height.
        padding-inline-start: $gutter-s;

        > .gw-icon,
        > a > .gw-icon {
            height: $icon-size-m;
            margin-inline-end: $gutter-xs;
            width: $icon-size-m;
        }

        > a:first-of-type > .gw-icon {
            @include bp-medium {
                display: inherit;
            }

            display: none;
        }

        // Get directions link.
        > a.gw-button--icon {
            .gw-icon {
                /* stylelint-disable max-nesting-depth */
                @include bp-medium {
                    height: $icon-size-l;
                    width: $icon-size-l;
                }
                /* stylelint-enable max-nesting-depth */
                height: $icon-size-m;
                width: $icon-size-m;
            }
        }

        > .gw-button.event-getdirections {
            @include bp-medium {
                padding: inherit;
            }

            padding: 0.5rem;

            > .gw-icon {
                /* stylelint-disable-next-line max-nesting-depth */
                @include bp-medium {
                    margin-inline-end: inherit;
                }
                margin-inline-end: 0;
            }
        }

        .gw-button {
            @include bp-medium {
                height: $layout-header-grid-row-height;
            }
            height: $layout-header-grid-row-height-mobile;
            margin-inline-start: auto;

            & + .gw-button {
                margin-inline-end: math.div($gutter-xs, 2);
                margin-inline-start: math.div($gutter-xs, 2);
            }
        }
    }

    &__link {
        display: flex;
        flex-wrap: wrap;
        margin-inline-end: math.div($gutter-xs, 2);
        overflow: hidden;

        > span {
            @include bp-medium {
                font-size: inherit;
            }

            flex-shrink: 0;
            font-size: 0.8125rem;
        }
    }

    &__name {
        font-weight: bold;
        margin-inline-end: $gutter-xs;
    }

    &__text {
        &--open-close {
            @include bp-medium {
                font-size: inherit;
            }

            font-size: 0.8125rem;
        }
    }

    [data-id='toggle-branch-rotator-dropdown'] {
        display: none;
    }

    &.gw-slider {
        margin-block-end: 0;

        [data-button-type] {
            @include bp-medium {
                width: $layout-header-grid-row-height;
            }
            height: 100%;
            transform: none;
            transition: none;
            width: $layout-header-grid-row-height-mobile;
        }

        [data-button-type='next'] {
            @at-root {
                /* stylelint-disable max-nesting-depth */
                [data-whatintent='touch'] &:focus {
                    background-color: $color-positive;
                }
                /* stylelint-enable max-nesting-depth */
            }
            background-color: $color-positive;
            inset-block-start: 0;
            inset-inline-end: 0;
            inset-inline-start: auto;

            &:hover {
                opacity: 1;
            }
        }

        [data-button-type='previous'] {
            display: none;
        }
    }

    .gw-slider__slides {
        align-items: center;
        height: 100%;
        min-height: auto;
    }

    .gw-slider__slide {
        @at-root {
            /* stylelint-disable max-nesting-depth */
            [data-whatintent='touch'] &:focus {
                background-color: #{rgba(var(--themeAccentPositive), 0.9)};
            }
            /* stylelint-enable max-nesting-depth */
        }

        @if $allow-dark-mode {
            @media (prefers-color-scheme: dark) {
                background-color: #{rgba(var(--themeAccentPositive), 0.5)};
                color: currentColor;
            }
        }
        @include bp-medium {
            flex-basis: calc(100% - #{$layout-header-grid-row-height});
        }
        background-color: #{rgba(var(--themeAccentPositive), 0.9)};
        color: $color-text-alt;
        flex-basis: calc(100% - #{$layout-header-grid-row-height-mobile});
        height: $layout-header-grid-row-height; // Height needs to be manually set as some browsers (ie. Safari) are not rendering 100%

        &:only-child {
            flex-basis: 100%;
        }
    }

    // Apply hotline theme override.
    &[data-hotline-theme='intense'] {
        .gw-branch-rotator__hotline-slide {
            @at-root {
                /* stylelint-disable max-nesting-depth */
                [data-whatintent='touch'] &:focus {
                    background-color: #{rgba(var(--themeAccentNegative), 0.9)};
                }
                /* stylelint-enable max-nesting-depth */
            }
            @if $allow-dark-mode {
                /* stylelint-disable max-nesting-depth */
                @media (prefers-color-scheme: dark) {
                    background-color: #{rgba(var(--themeAccentNegative), 0.6)};
                }
                /* stylelint-enable max-nesting-depth */
            }
            background-color: #{rgba(var(--themeAccentNegative), 0.9)};
        }

        .gw-branch-rotator__hotline-button {
            @at-root {
                /* stylelint-disable max-nesting-depth */
                [data-whatintent='touch'] &:focus {
                    background-color: $color-negative;
                }
                /* stylelint-enable max-nesting-depth */
            }
            background-color: $color-negative;
        }
    }

    .gw-accordion {
        margin-block-end: 0;

        /* stylelint-disable no-descending-specificity */
        .gw-icon {
            margin-inline-end: $gutter-xs;
            margin-inline-start: 0;
        }
    }

    .gw-button.gw-button--toggle[data-id='toggle-branch-rotator-dropdown'] {
        @include bp-medium {
            margin: inherit;
            padding: $gutter-s;
        }

        margin: 0;
        padding: 0.5rem;
    }

    &.is-js-enabled {
        [data-id='toggle-branch-rotator-dropdown'] {
            display: inline-flex;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-branch-rotator) {
    @include gw-branch-rotator;
}
