.gw-icon-card {
    @at-root {
        .gw-icon-card-grid {
            grid-gap: $gutter-xl;
        }

        [class$='card-grid--media-inline'].gw-grid {
            justify-content: start;
        }

        [class$='card-grid--media-inline-alt'].gw-grid {
            justify-content: end;
        }
    }

    &.gw-card:hover {
        box-shadow: none;
        transform: none;
    }

    .gw-card__icon {
        margin-block-end: $gutter;

        .gw-icon {
            fill: $color-brand;
            height: 7.5rem; // 120px
            width: 7.5rem; // 120px
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-icon-card) {
    @include gw-icon-card;
}
