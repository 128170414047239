// Promo details.
.page-20,
.page-default-offerdetail {
    .gw-layout__fullbleed {
        background-color: $color-white;

        & + .gw-layout__has-max-width {
            margin-block-start: $gutter-xl;
        }
    }

    .gw-toolbar--promotions {
        @include bp-medium {
            padding: 0 $layout-main-bleed;
        }
        @include bp-large {
            @include layout-constraint;
            padding: 0;
        }
        padding: 0 $layout-main-bleed-mobile;

        .gw-share__button {
            margin-left: -$gutter-s;
        }
    }

    .gw-banner {
        .gw-cms__content-image {
            margin-block-end: 0;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(page-20-promo-details) {
    @include page-20-promo-details;
}
