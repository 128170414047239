.gw-product-summary {
    background-color: $color-white;

    > * {
        @include bp-large {
            margin: 0 $gutter;
        }
        margin: 0 $gutter-xs;
    }

    &.hide-at-tablet {
        @include bp-large {
            display: none;
        }
        display: block;
    }

    &.display-at-tablet {
        @include bp-large {
            display: block;
        }
        display: none;
        top: 5rem;
    }

    &__name {
        @include fluid-type-xxl;
        margin-block-end: $gutter-s;

        span {
            @include bp-large {
                margin-block-start: $gutter-s;
            }

            display: block;
            font-family: $font-family;
            font-size: $font-size;
            line-height: $line-height;
            text-transform: capitalize;
            word-wrap: break-word;
        }
    }

    &__name:not(h1) {
        @include fluid-type-xxl;
        font-family: $font-family;
        font-size: $font-size-m;
    }

    &__price {
        font-family: $font-family;
        font-size: $font-size-l;
        margin-block-end: 0;
        padding: $gutter-xs;

        &--extra {
            font-size: $font-size-s;

            span:not(:empty) {
                position: relative;

                /* stylelint-disable max-nesting-depth */
                &:after {
                    content: '.';
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        &--original {
            display: flex;
            justify-content: space-between;
            margin-block-end: 0;

            /* stylelint-disable max-nesting-depth */
            span:first-child {
                font-size: $font-size;
                font-weight: normal;
            }

            span:nth-child(2) {
                @include fluid-type-l;
                font-weight: bold;
            }
            /* stylelint-enable max-nesting-depth */
        }

        &--discount {
            color: $color-neutral-alt;
            display: flex;
            justify-content: space-between;
            margin-block-end: 0;

            /* stylelint-disable max-nesting-depth */
            span:first-child {
                font-size: $font-size;
                font-weight: normal;
            }

            span:nth-child(2) {
                @include fluid-type-l;
                font-weight: bold;
            }
            /* stylelint-enable max-nesting-depth */
        }

        &--was {
            @include fluid-type-l;
            display: flex;
            font-weight: bold;
            justify-content: flex-end;
            margin-block-end: 0;
        }

        &.is-selected {
            background-color: $color-grey-1;
        }
    }

    &__finance {
        align-items: center;
        display: flex;
        font-family: $font-family;
        font-size: $font-size-m;
        justify-content: space-between;
        padding: $gutter-xs;

        span:first-child {
            font-size: $font-size;
            font-weight: normal;
        }

        span:nth-child(2) {
            @include fluid-type-l;
            font-weight: bold;
        }

        &.is-selected {
            background-color: $color-grey-1;
        }
    }

    &__smallprint {
        .gw-table {
            font-size: $font-size;
        }
    }

    &__price--extra,
    &__smallprint {
        font-size: $font-size-xs;
    }

    .gw-toolbar {
        margin: 0 0 $gutter-s;
        padding-block-start: $gutter-s;

        &.gw-toolbar--stock {
            margin: 0 0 $gutter-s;

            .gw-button-group {
                flex-flow: row wrap;
                margin-block-end: 0;
            }
        }
    }

    &__reserve {
        @include bp-large {
            padding: $gutter;
        }
        align-items: center;
        background-color: $color-brand;
        color: $color-text-alt;
        display: flex;
        flex-flow: column nowrap;
        font-family: $font-family;
        gap: $gutter-xs;
        margin: 0;
        padding: $gutter $gutter-xs;
        text-align: center;

        p {
            font-size: $font-size-m;
            margin-block-end: 0;
        }

        .gw-button--primary {
            background-color: $color-brand-alt;
            border-radius: $border-radius-rounded;
            min-width: 15rem;
        }
    }

    /* stylelint-disable no-descending-specificity */
    > .gw-button-group {
        @include bp-large {
            margin-block: $gutter-s;
            padding-block-end: $gutter-s;
        }
        align-items: flex-start;
        flex-flow: column nowrap;
        justify-content: start;
        margin-block: $gutter;
        padding-block-end: $gutter;

        &:last-child {
            margin-block-start: $gutter-l;
            padding-block-end: $gutter-l;
        }

        &__payment-options > a {
            color: $color-brand-alt;
        }

        > a {
            margin-block-end: 0;

            /* stylelint-disable max-nesting-depth */
            + a {
                margin-block-start: $gutter-s;
            }
            /* stylelint-enable max-nesting-depth */
        }

        .gw-button--text:not(.gw-button--primary),
        .gw-button--whatsapp {
            border: none;
            color: $color-brand;
            font-family: $font-family;
            font-size: $font-size-s;
            padding: 0;

            /* stylelint-disable max-nesting-depth */
            .gw-button__inner {
                justify-content: start;

                .gw-icon {
                    fill: $color-neutral;
                    height: 1.6em;
                    width: 1.5em;
                }
            }
            /* stylelint-enable max-nesting-depth */
        }
    }
    /* stylelint-enable no-descending-specificity */
    .gw-button--primary {
        display: block;
        text-align: center;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-product-summary) {
    @include gw-product-summary;
}
