@mixin gw-card {
    .gw-card {
        @at-root {
            [data-whatintent='mouse'] &:hover,
            [data-whatintent='keyboard'] &:focus-within,
            [data-whatintent='touch'] &:focus-within {
                box-shadow: none;
                transform: none;
            }
        }
        background-color: $color-white;
        border: $border-width $border-style transparent;
        border-radius: $border-radius-medium-small;

        &--quiet {
            @at-root {
                [data-whatintent='mouse'] &:hover,
                [data-whatintent='keyboard'] &:focus-within,
                [data-whatintent='touch'] &:focus-within {
                    box-shadow: none;
                }
            }
        }

        &--fullwidth {
            @at-root {
                // When inside a fullbleed container.
                .gw-layout__fullbleed & {
                    /* stylelint-disable max-nesting-depth */
                    @include bp-medium {
                        padding-inline-end: $layout-main-bleed;
                        padding-inline-start: $layout-main-bleed;
                    }
                    /* stylelint-enable max-nesting-depth */
                }

                [data-whatintent='mouse'] &:hover,
                [data-whatintent='keyboard'] &:focus-within,
                [data-whatintent='touch'] &:focus-within {
                    box-shadow: none;
                }
            }
        }

        &--media-inline,
        &--media-inline-alt {
            @include bp-medium {
                padding-block-end: $gutter-xl;
                padding-block-start: $gutter-xl;
            }
            background-color: $color-white;
            padding-block-end: $gutter;
            padding-block-start: $gutter;

            .gw-card__content {
                @include bp-medium {
                    justify-content: center;
                }
            }
        }

        &__title,
        &__title > a:not([class]) {
            color: $color-text;
        }

        &__media {
            img {
                border-radius: $border-radius-medium-small;
            }
        }
    }
}
