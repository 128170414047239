.gw-header--multi-franchise--wrapper {
    .gw-header.gw-header--multi-franchise {
        @at-root {
            .gw-header__brand {
                display: none;
            }

            // Only display the brand image when multifranchise page is activated through class injected in body
            [class*='multif'] {
                /* stylelint-disable max-nesting-depth */
                .gw-header__brand {
                    @include bp-medium {
                        margin-inline-end: $gutter-s;
                    }

                    align-items: center;
                    display: inline-flex;
                    grid-area: brand;
                    justify-content: flex-start;
                    margin: 0;
                    padding: 0;

                    .gw-image {
                        @include bp-xsmall {
                            height: auto;
                        }
                        @include bp-medium {
                            height: $icon-size-home;
                        }

                        flex-shrink: 1;
                        max-height: 3rem;
                    }
                }

                .gw-header__home {
                    .gw-image {
                        max-height: 3rem;
                    }

                    .gw-image + .gw-image {
                        display: none;
                    }

                    .gw-image.is-hidden-on-large {
                        // Have to use !important to override the usual functionality that will exist on indie sites
                        /* stylelint-disable-next-line declaration-no-important */
                        display: block !important;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }

            .is-js-sticky & {
                /* stylelint-disable max-nesting-depth */
                @include bp-medium {
                    grid-template-areas: 'home brand nav . offers search';
                    grid-template-columns: auto auto auto 4fr auto auto;
                    grid-template-rows: minmax(2rem, auto);
                    padding: 0;
                }

                .gw-header__nav {
                    padding: 0;
                }

                .gw-header__home {
                    margin-inline-end: $gutter-s;
                }

                .gw-header__offers {
                    margin-block: $gutter-xs;
                }

                .gw-header__brand {
                    /* stylelint-disable-next-line no-descending-specificity */
                    .gw-image {
                        height: $icon-size-home-mobile;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }

            .is-js-sticky &__brand {
                /* stylelint-disable no-descending-specificity */
                /* stylelint-disable max-nesting-depth */
                .gw-image {
                    height: $icon-size-home-mobile;

                    &.is-hidden-on-large {
                        /* stylelint-disable-next-line declaration-no-important */
                        display: block !important;
                    }

                    & + .gw-image {
                        display: none;
                    }
                }
                /* stylelint-enable max-nesting-depth */
                /* stylelint-enable no-descending-specificity */
            }
        }

        @include bp-medium {
            grid-template-areas: 'home brand nav offers' 'search search search search';
            grid-template-columns: auto auto 4fr auto;
            grid-template-rows: repeat(
                2,
                minmax($layout-header-grid-row-height, auto)
            );
            padding-block-end: 0;
        }

        @include bp-large {
            grid-template-areas: 'home brand nav offers search';
            grid-template-columns: auto auto 4fr auto auto;
            grid-template-rows: minmax($layout-header-grid-row-height, auto);
            padding-block-end: $gutter-xs;
        }
        background-color: $color-header-background;
        display: grid;
        grid-template-areas: 'home brand . nav' 'offers search search search';
        grid-template-columns: auto auto 1fr auto;
        grid-template-rows: repeat(2, $layout-header-grid-row-height-mobile);
        padding-block: $gutter-xs;

        .gw-header__offers {
            @include bp-small {
                background-color: $color-header-button-secondary;
                color: $color-header-button-primary;
                padding: $gutter-xs;
            }

            align-self: center;
            background-color: $color-header-button-primary;
            border-radius: $border-radius;
            color: $color-header-button-secondary;
            margin: auto $gutter-xs;
            max-height: 2.5rem; // 40px
            padding: $gutter-xs $gutter-s;

            &:hover {
                /* stylelint-disable-next-line max-nesting-depth */
                @include bp-small {
                    background-color: $color-header-button-primary;
                    color: $color-header-button-secondary;
                    padding: $gutter-xs;
                }

                background-color: $color-header-button-secondary;
                color: $color-header-button-primary;
                opacity: 1;
            }
        }

        .gw-header__search {
            margin-block: auto;
            margin-inline-end: $gutter-xs;
            max-height: 2.5rem;

            .gw-form.gw-search--multi-franchise[id='site-search'] {
                /* stylelint-disable max-nesting-depth */
                @include bp-medium {
                    margin-inline-start: $gutter-xs;
                }

                @include bp-large {
                    margin-inline-start: 0;
                    max-width: 25rem; // 400px
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        .gw-header__nav {
            @include bp-medium {
                padding: $gutter-s 0;

                /* stylelint-disable-next-line max-nesting-depth */
                .gw-navigation__item {
                    margin-block-end: 0;
                }
            }

            align-items: center;
            display: flex;
        }

        .gw-header__home {
            .gw-image {
                /* stylelint-disable max-nesting-depth */
                @include bp-medium {
                    max-width: 15rem; // 240px
                }

                @include bp-xsmall {
                    height: $icon-size-home-mobile;
                }
                /* stylelint-enable max-nesting-depth */

                max-width: 9.375rem; // 150px
                object-fit: contain;
            }
        }

        .gw-header__brand {
            .gw-image {
                /* stylelint-disable max-nesting-depth */
                @include bp-medium {
                    height: $icon-size-home;
                }
                flex-shrink: 1;
                height: $icon-size-home-mobile;
                max-width: 9.375rem; // 150px
                object-fit: contain;
                width: auto;

                & + .gw-image {
                    @include bp-large {
                        display: block;
                    }
                    display: none;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }

    .gw-header__contact-details {
        @at-root {
            .is-js-sticky & {
                display: none;
            }
        }
        background-color: $color-preheader-background;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding-block: $gutter-xs / 3;

        .gw-header__address,
        .gw-header__telephone {
            color: $color-preheader-font;
            font-size: $font-size;
            margin-block-end: 0;
            margin-inline-end: $gutter-s;
            text-transform: uppercase;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-header--multi-franchise) {
    @include gw-header--multi-franchise;
}
