@mixin gw-slider {
    .gw-slider {
        padding-block-end: $gutter-l + $gutter-xs;

        [data-button-type] {
            background-color: $color-black;
            border-radius: $border-radius-rounded;
            inset-block-end: 0;
            inset-block-start: auto;

            &:hover {
                background-color: rgba(var(--themeBlack), 0.7);
            }
        }

        [data-button-type='next'] {
            @at-root {
                /* stylelint-disable max-nesting-depth */
                [dir='rtl'] & {
                    transform: translateX(-100%);
                }
                /* stylelint-enable max-nesting-depth */
            }
            inset-inline-end: 49.5%;
            transform: translateX(100%);
        }

        [data-button-type='previous'] {
            @at-root {
                /* stylelint-disable max-nesting-depth */
                [dir='rtl'] & {
                    transform: translateX(100%);
                }
                /* stylelint-enable max-nesting-depth */
            }
            inset-inline-end: auto;
            inset-inline-start: 49.5%;
            transform: translateX(-100%);
        }

        [role='tab'] {
            background-color: $color-grey-1;
        }

        [role='tab'][aria-selected='true'] {
            border-color: $color-white;
        }

        &--no-buttons {
            margin-block-end: $gutter-l;
            padding-block-end: 0;
        }

        [role='tablist'] {
            display: none;
        }

        &--banner {
            @include bp-medium {
                .gw-slider__slides {
                    z-index: z('reset');
                }

                .gw-banner--hero:before {
                    content: '';
                    height: 100%;
                    inset-block-start: 0;
                    inset-inline-start: 0;
                    position: absolute;
                    width: 100%;
                    z-index: z('default');
                }

                .gw-banner__overlay {
                    z-index: z('default');

                    /* stylelint-disable-next-line max-nesting-depth */
                    &:not([class*='--skin']) {
                        background-color: transparent;
                    }
                }
            }

            padding-block-end: 0;

            .gw-slider__slide {
                @at-root {
                    /* stylelint-disable max-nesting-depth */
                    [data-whatintent='touch'] &:focus {
                        background-color: #{rgba(var(--themeGrey4), 0.8)};
                    }
                    /* stylelint-enable max-nesting-depth */
                }

                @include bp-medium {
                    background-color: transparent;
                    padding-block-end: 0;
                }
                background-color: #{rgba(var(--themeGrey4), 0.8)};
                padding-block-end: $gutter-l;
            }

            /* stylelint-disable no-descending-specificity */
            [data-button-type] {
                bottom: $gutter-s;
                inset-block-end: $gutter-xs;
                inset-block-start: auto;
            }

            [data-button-type='next'] {
                @at-root {
                    /* stylelint-disable max-nesting-depth */
                    [dir='rtl'] & {
                        transform: translateX(-100%);
                    }
                    /* stylelint-enable max-nesting-depth */
                }
                transform: translateX(100%);
            }

            [data-button-type='previous'] {
                @at-root {
                    /* stylelint-disable max-nesting-depth */
                    [dir='rtl'] & {
                        transform: translateX(100%);
                    }
                    /* stylelint-enable max-nesting-depth */
                }
                inset-inline-end: auto;
                transform: translateX(-100%);
            }

            &-thumbs {
                padding-block-end: $gutter-l + $gutter-xs;

                [data-button-type] {
                    inset-block-end: 0;
                }

                [data-button-type='next'] {
                    inset-inline-end: calc(50% - #{$gutter-s});
                }

                [data-button-type='previous'] {
                    inset-inline-end: auto;
                    inset-inline-start: calc(50% - #{$gutter-s});
                }
            }
            /* stylelint-enable no-descending-specificity */
        }

        &.gw-gallery--gallery2 {
            padding-block-end: 0;

            /* stylelint-disable no-descending-specificity */
            [data-button-type] {
                bottom: $gutter-s;
                inset-block-end: $gutter-xs;
                inset-block-start: auto;
            }

            [data-button-type='next'] {
                @at-root {
                    /* stylelint-disable max-nesting-depth */
                    [dir='rtl'] & {
                        border-radius: $border-radius 0 0 $border-radius;
                        transform: translateX(-100%);
                    }
                    /* stylelint-enable max-nesting-depth */
                }
                border-radius: 0 $border-radius $border-radius 0;
                inset-inline-end: 50%;
                transform: translateX(100%);
            }

            [data-button-type='previous'] {
                @at-root {
                    /* stylelint-disable max-nesting-depth */
                    [dir='rtl'] & {
                        border-radius: 0 $border-radius $border-radius 0;
                        transform: translateX(100%);
                    }
                    /* stylelint-enable max-nesting-depth */
                }
                border-radius: $border-radius 0 0 $border-radius;
                inset-inline-end: auto;
                inset-inline-start: 50%;
                transform: translateX(-100%);
            }

            &-thumbs {
                padding-block-end: $gutter-l + $gutter-xs;

                [data-button-type] {
                    inset-block-end: 0;
                }

                [data-button-type='next'] {
                    inset-inline-end: calc(50% - #{$gutter-s});
                }

                [data-button-type='previous'] {
                    inset-inline-end: auto;
                    inset-inline-start: calc(50% - #{$gutter-s});
                }
            }
            /* stylelint-enable no-descending-specificity */
        }
    }
}
