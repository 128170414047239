.gw-counter {
    align-items: center;
    display: flex;
    padding: 0 $gutter;
    width: fit-content;

    &--container {
        align-items: end;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    &__text,
    &__count {
        display: inline;
    }

    &__count {
        @include fluid-type-xxl(
            $min: $font-size-xxl,
            $ideal: calc(0.85rem + (2 * #{$fluid-type-target-xxxl})),
            $max: 6rem
        );

        color: $color-brand;
        font-family: $font-family-title;
        margin-inline-start: $gutter-xs;
    }

    &__text {
        @include fluid-type-default(
            $min: calc(0.3 * $font-size-xs),
            $ideal: calc(0.65rem + #{$fluid-type-target}),
            $max: $font-size
        );

        text-transform: uppercase;
        -webkit-transform: scale(-1);
        -moz-transform: scale(-1);
        -o-transform: scale(-1);
        -ms-transform: scale(-1);
        transform: scale(-1);
        writing-mode: vertical-lr;
    }
}

@if mixin-exists(gw-counter) {
    @include gw-counter;
}
