@mixin gw-product-filter {
    .gw-product-filter {
        &.gw-tabs:not(.gw-tabs--vertical) {
            .gw-tabs__tablist {
                background-color: transparent;
                flex-wrap: wrap;
                padding-block-start: $gutter-xs;
            }

            .gw-tabs__button {
                @include button-secondary;

                @include focus-default(
                    $opacity: false,
                    $transform: false,
                    $mouse-outline: false,
                    $keyboard-outline: true
                );

                @include bp-medium {
                    font-size: $font-size;
                }

                background-color: transparent;
                border-color: $color-grey-3;
                border-radius: $border-radius-medium-small;
                color: $color-text-secondary;
                margin-block-end: $gutter-xs;

                &:hover:not(:disabled) {
                    background-color: transparent;
                    border-color: $color-grey-4;
                    color: $color-text-secondary;
                    opacity: 1;
                    transform: scale(1);
                }

                &.is-selected {
                    border-color: $color-brand-alt;
                    box-shadow: none;
                    color: $color-text;
                }

                &.is-selected:hover {
                    border-color: $color-brand-alt;
                    color: $color-text;
                }

                &[aria-selected='true'] {
                    @include button-secondary;
                    border-width: $border-width-m;
                    border-radius: $border-radius-medium-small;
                    box-shadow: none;
                    color: $color-text;
                }

                &[aria-selected='true']:hover:not(:disabled) {
                    border-color: $color-brand-alt;
                    color: $color-text;
                }
            }
        }

        .gw-tabs__content {
            background-color: transparent;
        }
    }
}
