.gw-testimonials {
    @include bp-medium {
        display: flex;
        flex-flow: row nowrap;
        padding: 4rem;
    }
    padding: 0 4rem;
    position: relative;

    &__doublequote {
        @include bp-medium {
            inset-block-start: 0;
            inset-inline-start: 4rem;
            margin: 4rem;
            position: absolute;
            transform: translateY(0);
        }
        margin: 0 auto;
        transform: translateY(25%);
    }

    &__testimonial {
        @include bp-medium {
            padding: 6rem 2rem 6rem 0;
        }
    }

    &__quote {
        font-weight: bold;
        margin: 3rem 0;
    }

    &__info {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        margin: 2rem 0;
    }

    &__logo {
        height: auto;
        margin-inline-end: 1rem;
        max-width: 150px;
        object-fit: contain;
        object-position: left center;
    }

    &__text {
        display: flex;
        flex-flow: column nowrap;
    }

    &__name {
        font-weight: bold;
    }

    &__position {
        color: $color-brand-alt-2;
    }

    &__picture {
        @include bp-large {
            max-width: 450px;
        }
        max-width: 350px;
        object-fit: contain;
    }
}
