@mixin gw-clean-car-rating {
    .gw-clean-car-rating {
        &__readout {
            background: $color-positive;
            color: $color-text-alt;

            &:before {
                background: $color-positive-alt;
            }
        }
    }
}
