.gw-price-grid {
    display: block;

    &__caption {
        padding: $gutter-l 0;
    }

    &--container {
        border-block-end: $border-width $border-style $color-grey-3;
        display: flex;
        flex-wrap: wrap;
        gap: $gutter;
    }

    &__toggle {
        @include bp-large {
            align-items: center;
            display: flex;
        }

        margin-block-end: $gutter-l;
    }

    .gw-button-group--toggle {
        @at-root {
            .gw-layout__inner & {
                margin: 0;
            }
        }

        flex: 0 0 30rem;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
    }
}

@if mixin-exists(gw-price-grid) {
    @include gw-price-grid;
}
