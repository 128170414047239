@mixin gw-swatch {
    .gw-swatch {
        @at-root {
            [data-whatintent='touch'] &:focus {
                background-color: $color-white;
            }
        }

        &__title {
            @include visually-hide;
        }
    }
}
