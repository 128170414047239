// Product details.
.page-900,
.page-default-productdetail {
    .gw-product-specs {
        margin-block-end: $gutter-xl;

        &__item {
            @include bp-medium {
                color: $color-text-alt;
            }
            align-items: center;
            background-color: transparent;
            color: $color-brand;
            flex: initial;
            flex-basis: auto;

            .gw-icon {
                margin-block-end: 0;
            }

            span:first-of-type {
                margin-block-start: 0.625rem; // 10px
            }
        }

        &__list {
            @include bp-medium {
                background-color: $color-brand;
            }

            background-color: $color-white;
            justify-content: center;

            .gw-product-specs__item:not(.gw-product-specs__action) {
                min-width: 12.5rem;
            }
        }

        &__actions {
            width: 100%;

            &__list {
                background-color: transparent;
                justify-content: center;
            }
        }

        &__action {
            margin: $gutter-xs;

            .gw-button[class*='--text'].gw-button--primary {
                /* stylelint-disable-next-line max-nesting-depth */
                @include bp-medium {
                    background-color: $color-white;
                    border-color: $color-brand;
                    color: $color-brand;
                }
                background-color: $color-brand;
                border-color: $color-brand;
                color: $color-text-alt;
            }

            span:first-of-type {
                margin-block-start: 0;
            }
        }

        &__price {
            align-items: flex-start;

            &--mobile {
                background-color: $color-brand;
                color: $color-text-alt;
            }

            &--large {
                background-color: #{rgba(var(--themeColorBrand), 0.5)};
            }
        }
    }

    [aria-labelledby='product-variants'] {
        @include bp-medium {
            padding-inline-end: $layout-main-bleed;
            padding-inline-start: $layout-main-bleed;
        }
        padding-inline-end: $layout-main-bleed-mobile;
        padding-inline-start: $layout-main-bleed-mobile;
    }

    [id='product-variants'],
    [id='product-gallery-title'],
    .gw-product-features > h2 {
        text-align: center;
    }

    .gw-cms {
        @include layout-centered-content;
    }

    // TODO: Update DW to use correct 'gw-cms' and/or 'gw-layout' classes for this content.
    [id='dealerHtml'] {
        @include layout-centered-content;
    }

    &--productvideo {
        @include bp-small {
            @at-root {
                /* stylelint-disable-next-line max-nesting-depth */
                .gw-layout__main > .gw-layout__inner {
                    overflow-x: hidden;
                }
            }
        }
    }

    .gw-banner__video {
        margin-block-end: -0.5rem;
        object-fit: cover;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(page-900-product-details) {
    @include page-900-product-details;
}
