@mixin gw-social {
    .gw-social {
        background-color: $color-white;
        text-align: center;

        &__icons {
            justify-content: center;
        }
    }
}
