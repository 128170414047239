// News list.
.page-19,
.page-default-newslist {
    .gw-cms {
        @include layout-centered-content;
    }

    .gw-layout__inner {
        > .gw-layout__has-max-width:first-of-type,
        > .gw-cms:first-of-type {
            margin-block-start: $gutter-xl;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(page-19-news-list) {
    @include page-19-news-list;
}
