@mixin gw-table {
    .gw-table {
        th {
            background-color: $color-grey-2;
            color: $color-text;
        }

        &--striped {
            th {
                background-color: transparent;
                color: inherit;
            }

            tr:nth-child(odd) {
                background-color: $color-grey-2;
                color: $color-text;
            }

            tr:nth-child(even) {
                background-color: $color-grey-1;
            }
        }
    }
}
