.gw-pagination {
    justify-content: center;

    &:empty {
        display: none;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-pagination) {
    @include gw-pagination;
}
