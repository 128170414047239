@mixin gw-filters {
    .gw-mf-filters {
        &__makes {
            @at-root {
                .gw-layout__inner & {
                    /* stylelint-disable max-nesting-depth */
                    @include bp-medium {
                        margin-inline-end: -$layout-main-bleed;
                        margin-inline-start: -$layout-main-bleed;
                    }
                    /* stylelint-enable max-nesting-depth */
                    margin-inline-end: -$layout-main-bleed-mobile;
                    margin-inline-start: -$layout-main-bleed-mobile;
                }
            }

            @include bp-medium {
                margin-block-end: $gutter-xl;
            }

            padding: $gutter-l;

            &--home-page {
                background-color: $color-grey-1;
            }

            &--new-vehicles {
                background-color: $color-brand-alt;
            }
        }

        &__content {
            align-content: center;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            margin: 0 auto;
            max-width: 78.75rem; //1260px
            width: 100%;
        }

        &__item {
            background-color: white;
            border-radius: $border-radius-medium-small;
            display: flex;
            font-family: $font-family-title;
            margin: 0 calc($gutter-s / 2) $gutter-s calc($gutter-s / 2);
            min-width: calc(
                6.25rem + ($gutter-s * 2)
            ); //100px with the padding size added for each side
            padding: $gutter-s;
            text-decoration: none;

            &:hover {
                background-color: rgba(var(--themeWhite), 0.75);
                cursor: pointer;
            }

            span {
                color: $color-text;
                line-height: 2.25rem;
                text-decoration: none;
            }

            img {
                margin: auto;
                object-fit: contain;
            }

            span + img {
                font-size: 0;
                line-height: 0;
            }

            .gw-mf-filters__makes--home-page & {
                img {
                    height: auto;
                    max-height: 6.25rem; //100px
                    max-width: 6.25rem; //100px
                    width: 100%;
                }
            }

            .gw-mf-filters__makes--new-vehicles & {
                img {
                    height: 100%;
                    max-height: 2.5rem; //40px
                    max-width: 6.25rem; //100px
                    width: auto;
                }
            }
        }
    }
}
