.gw-product-search {
    .gw-form {
        margin-block-end: 0;
    }

    .gw-form__legend {
        text-align: center;
        width: 100%;
    }

    .gw-form__label {
        > span:not([class]):first-child {
            @include visually-hide;
        }
    }

    .gw-stock-filter-ajax {
        width: 15rem;
    }

    .gw-button {
        margin: 0;
    }

    .gw-grid {
        @include responsive-grid($centered: true);

        > * {
            @include bp-small {
                margin-inline-end: auto;
                margin-inline-start: auto;
                min-width: 0;
            }
        }
    }

    &-content {
        display: block;
        flex-basis: 100%;
        margin-block-end: $gutter;

        &.is-js-enabled {
            display: none;
        }

        .gw-product-filter,
        .gw-form__range .gw-form__label {
            background-color: transparent;
        }

        .gw-tabs__title + .gw-tabs__content .gw-form__range {
            display: none;
        }
    }

    &-results__modal {
        .gw-product-filter {
            background-color: $color-white;
        }

        .gw-form__range .gw-form__label {
            background-color: $color-grey-1;
        }

        .gw-product-search-content {
            display: block;
        }

        .gw-tabs__title + .gw-tabs__content .gw-form__range {
            display: flex;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-product-search) {
    @include gw-product-search;
}
