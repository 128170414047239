@mixin gw-footer {
    .gw-footer {
        border-block-start: $border-width $border-style $color-grey-3;
        background-color: $color-footer-background;
        color: $color-footer-background-font;
        grid-template-columns: repeat(
            auto-fit,
            minmax($grid-item-min-width, 14rem)
        );

        .gw-button--whatsapp {
            color: $color-footer-background-font;
        }

        &--gc {
            background-color: $color-footer-background;
        }

        > :last-child {
            width: max-content;
        }

        &--mobile {
            background-color: $color-footer-background;
            border-block-start: $border-style $border-width $color-grey-3;
            color: $color-footer-background-font;
            padding: 0;

            .gw-accordion__title {
                font-weight: inherit;

                .gw-accordion__button {
                    border-block-end-color: $color-grey-3;
                    border-block-start: 0;
                    padding: $gutter-s $gutter;
                    text-transform: none;
                }
            }

            .gw-button--whatsapp {
                color: $color-footer-background-font;
            }
        }

        &--supplementary {
            @include bp-medium {
                .gw-social__icons {
                    padding: 0;
                    width: auto;
                }
            }

            background-color: $color-footer-supplementary;
            border-block-start: $border-width solid $color-grey-3;
            color: $color-footer-background-font;

            .gw-footer {
                &__item,
                &__link {
                    font-size: $font-size-xs;
                }
            }

            .gw-social {
                background-color: $color-footer-social;

                &__title {
                    color: $color-text;
                    font-family: $font-family-bold;
                    font-size: $font-size;
                    text-transform: none;
                }

                &__icons {
                    justify-content: flex-start;
                    margin-inline-start: -($gutter-xs / 2);
                    padding: $gutter-xs 0;
                    width: 100%;
                }

                &__icon {
                    margin: $gutter-xs / 2;
                }
            }
        }

        &--supplementary-social {
            @include bp-small {
                border-block-start: 0;
                padding: $gutter;
            }
        }

        &__group-title {
            border: none;
            color: $color-text;
            font-family: $font-family-bold;
            font-size: $font-size;
        }

        &__manufacturer {
            @include bp-medium {
                padding: 0;
                width: auto;
            }
            padding: $gutter-xs 0;
            width: 100%;

            .gw-image {
                @include bp-medium {
                    height: 3.75rem;
                }

                height: 2rem;
            }
        }
    }
}
