.gw-info-card {
    @include bp-medium {
        column-gap: $gutter;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    margin-block-end: 0;
    width: 100%;

    &.gw-card:hover {
        box-shadow: none;
        transform: none;
    }

    &__column {
        @include bp-medium {
            &:nth-child(2):last-child {
                grid-column: 2 / 4;
            }
        }

        .gw-card {
            margin-block-end: $gutter-l;
            padding: 0;
            width: 100%;

            &:hover {
                box-shadow: none;
                transform: none;
            }

            &__title h4 {
                margin-block-end: $gutter-s;
            }
        }
    }

    &__title h2 {
        margin-block-end: $gutter;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-info-card) {
    @include gw-info-card;
}
