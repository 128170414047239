.gw-contact-card {
    border: $border-width $border-style $color-grey-2;
    padding: $gutter;

    .gw-card__title {
        margin-block-end: 0;
    }

    a:not([class]) {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .gw-button--whatsapp {
        font-weight: normal;
        padding: 0;

        > .gw-button__inner .gw-icon {
            margin-inline-end: math.div($gutter-xs, 2);
        }
    }

    address {
        font-size: $font-size;
    }

    .gw-ratings {
        margin-block-end: -$gutter;
        margin-inline-end: -$gutter;
        margin-inline-start: -$gutter;
        padding-inline-end: $gutter;
        padding-inline-start: $gutter;
    }

    // Person details card with an image.
    &--person {
        @include card-media-top;

        .gw-card__content {
            order: 2;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-contact-card) {
    @include gw-contact-card;
}
