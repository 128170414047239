@mixin gw-header {
    .gw-header {
        @at-root {
            .is-js-sticky & {
                @include bp-medium {
                    /* stylelint-disable max-nesting-depth */
                    .gw-header--primary {
                        height: 100%;
                    }
                    /* stylelint-enable max-nesting-depth */
                }

                .gw-header__search,
                .gw-header__contact,
                .gw-header__offers .gw-button.gw-button--text {
                    box-shadow: none;
                }

                .gw-header--secondary {
                    background-color: $color-white;
                }
            }
        }

        border-block-end: $border-width $border-style #{rgba(
                var(--themeBlack),
                0.2
            )};

        &--primary {
            background-color: $color-white;
        }

        &__search,
        &__contact {
            @include bp-medium {
                box-shadow: 0 -#{$border-width} 0 0 #{rgba(
                        var(--themeBlack),
                        0.2
                    )};
            }
        }

        &__offers {
            border-radius: 0;

            // Bully CSS specificity.
            &.gw-button--primary {
                &[class*='--text'],
                &[class*='--icon'] {
                    background-color: $color-grey-5;
                    border-color: $color-grey-5;
                    font-family: $font-family-semibold;
                    padding-inline: $gutter-s;
                    text-transform: capitalize;

                    /* stylelint-disable max-nesting-depth */
                    &:hover {
                        background-color: $color-link-hover;
                        border-color: $color-link-hover;
                        transform: none;
                    }
                    /* stylelint-enable max-nesting-depth */
                }
            }
        }

        &__logo {
            color: $color-brand;
        }
    }
}
