@mixin gw-product-search-results {
    .gw-product-search-results {
        [data-id='stock-compare'] {
            color: $color-brand-alt;
            font-family: $font-family-semibold;

            .gw-icon {
                stroke: $color-brand-alt;
                stroke-width: 0.15rem;
            }

            &.is-selected {
                .gw-icon {
                    fill: $color-brand-alt;
                }
            }
        }

        [data-id='products-filter-button'] {
            color: $color-brand;
            font-family: $font-family;

            &.is-selected {
                background-color: transparent;
                color: inherit;
            }
        }

        .gw-form__select {
            background-color: $color-white;
        }

        .gw-button--toggle {
            &[class*='--text'].is-selected {
                background-color: $color-white;
                color: $color-text;

                /* stylelint-disable-next-line max-nesting-depth */
                &:hover:not(:disabled) {
                    background-color: $color-white;
                    border-color: $color-brand-alt;
                    color: $color-text;
                }
            }
        }
    }
}
