.gw-product-specs {
    margin-block-end: $gutter-xl;

    &__list {
        display: flex;
        flex-wrap: wrap;

        .gw-tabs__content & {
            padding-block-start: $gutter-xs;
        }
    }

    &__item {
        align-items: center;
        background-color: $color-grey-3;
        color: $color-text-alt;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: $gutter-s;

        .gw-icon {
            height: 1.5em;
            margin-block-end: $gutter-xs;
            width: 1.5em;
        }
    }

    &__price {
        @include bp-medium {
            // Take up minimum of 25% of available space.
            flex-basis: 25%;
        }
        align-items: flex-start;
        flex-basis: 100%;
        flex-grow: 3;
        justify-content: center;

        strong {
            @include fluid-type-xl;
        }
    }

    &__actions {
        padding: 0;

        &__list {
            background-color: $color-brand;
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            width: 100%;
        }
    }

    &__action {
        background-color: transparent;

        .gw-button {
            height: 100%;
            padding: $gutter-s;
            width: 100%;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-product-specs) {
    @include gw-product-specs;
}
