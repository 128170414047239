.gw-slider {
    // Turn off opacity & transform as they create a new stacking context, which breaks header navigation slider.
    @include focus-default($opacity: false, $transform: false);
    @at-root {
        // Use outline instead of box-shadow for focus.
        [data-whatintent='keyboard'] &:focus {
            outline: 0.25rem solid $color-neutral;
        }

        [data-whatintent='touch'] &:focus {
            background-color: transparent;
        }
    }

    margin-block-end: $gutter-xl;
    overflow-x: auto;
    position: relative;

    &__slides {
        display: flex;
        inset-inline-end: 0;
        inset-inline-start: 0;
        min-height: $slider-button-size + ($gutter-xs * 2);
        position: relative;
        transition: left 0.5s ease-in-out, right 0.5s ease-in-out;
    }

    &[data-slide-no-animations='true'] {
        .gw-slider__slides {
            transition: none;
        }
    }

    &__slide {
        @include focus-default($opacity: false, $transform: false);
        @at-root {
            // Use outline instead of box-shadow for focus.
            [data-whatintent='keyboard'] &:focus {
                outline: 0.25rem solid $color-neutral;
                outline-offset: -0.25rem;
            }

            [data-whatintent='touch'] &:focus {
                background-color: transparent;
            }
        }
        flex-basis: 100%;
        flex-shrink: 0;
    }

    .gw-button-group {
        margin: 0;
    }

    [data-button-type] {
        @if $allow-dark-mode {
            @media (prefers-color-scheme: dark) {
                color: $color-text;
            }
        }

        background-color: #{rgba(var(--themeColorBrand), 0.6)};
        color: $color-text-alt;
        height: calc(1em + (#{$gutter-xs} * 2));
        inset-inline-end: 0;
        inset-inline-start: auto;
        margin: 0;
        position: absolute;
        z-index: z('default');
    }

    [data-button-type='next'] {
        @at-root {
            /* stylelint-disable max-nesting-depth */
            [dir='rtl'] & {
                transform: translateX(-100%);
            }
            /* stylelint-enable max-nesting-depth */
        }
        inset-block: auto $gutter-xs;
        inset-inline-end: 49.5%;
        transform: translateX(100%);
    }

    [data-button-type='previous'] {
        @if $allow-dark-mode {
            @media (prefers-color-scheme: dark) {
                background-color: #{rgba(var(--themeColorBackground), 0.6)};
            }
        }
        background-color: #{rgba(var(--themeColorBackgroundAlt), 0.6)};
        inset-block: auto $gutter-xs;
        inset-inline-end: auto;
        inset-inline-start: 49.5%;
        transform: translateX(-100%);
    }

    [role='tablist'] {
        display: none;
    }

    [role='tab'] {
        background-color: $color-background;
        border: $border-width-m $border-style $color-brand;
        border-radius: $border-radius-round;
        height: 1em;
        margin: $gutter-xs math.div($gutter-xs, 2);
        padding: $gutter-xs;
        pointer-events: all;

        &[aria-selected='true'] {
            background-color: $color-brand;
        }
    }

    &[data-slide-custom-tabs='true'] {
        [role='tablist'] {
            display: flex;
        }
    }

    // Banner slider.
    &--banner {
        @include bp-small {
            // In case lots of banners are added, we don't want loads of tab buttons on mobile.
            [role='tablist'] {
                display: none;
            }
        }

        @include bp-medium {
            .gw-slider__slides {
                max-height: calc(100vw / var(--themeMediaAspectRatio));
            }
        }
        /* stylelint-disable-next-line no-descending-specificity */
        .gw-slider__slides {
            margin-block-end: -$gutter;
        }

        .gw-slider__slide {
            background-color: #{rgba(var(--themeColorBrand), 0.6)};
        }

        .gw-banner__inner + .gw-banner__overlay {
            background-color: transparent;
        }
    }

    // Card slider (e.g. news/promotions).
    &--card {
        .gw-slider__slide {
            // Slide widths/padding match card grid layouts, with sufficient vertical padding to support :hover effects.
            flex-basis: $grid-item-max-width;
            padding: math.div($gutter, 2);
        }
    }

    // No prev/next buttons are shown.
    &--no-buttons {
        // No styles needed in whitelabel.
    }

    &.is-js-enabled {
        overflow: hidden;
    }
}

/* stylelint-disable no-descending-specificity */
.gw-brand-list {
    &--slider {
        .gw-button[data-button-type='previous']:active {
            transform: translateX(-100%);
        }

        .gw-button-group {
            display: none;
        }

        .gw-slider {
            &__slide {
                display: flex;
                justify-content: center;

                /* stylelint-disable max-nesting-depth */
                .gw-slider__slide--brand {
                    filter: grayscale(1);
                    padding: 0.5rem;
                    transition: filter 0.3s ease;
                }

                &:first-child {
                    padding-inline-start: 0;

                    .gw-slider__slide--brand {
                        padding-inline-start: 0;
                    }
                }

                &:hover {
                    .gw-slider__slide--brand {
                        filter: grayscale(0);
                        transition: filter 0.3s ease;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }
}
/* stylelint-enable no-descending-specificity */

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-slider) {
    @include gw-slider;
}
