@mixin gw-gallery {
    .gw-gallery {
        &--portfolio {
            /* stylelint-disable max-nesting-depth */
            &.gw-slider {
                [data-button-type] {
                    background-color: $color-black;

                    &:hover:not(:disabled) {
                        background-color: rgba(var(--themeBlack), 0.7);
                    }
                }
            }

            &-thumbs.gw-slider {
                .gw-slider__thumbnail-guide {
                    border-radius: $border-radius;

                    &.is-selected {
                        background-color: $color-brand-alt;
                    }
                }
            }
            /* stylelint-enable max-nesting-depth */
        }
    }
}
