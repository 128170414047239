.gw-guide-card {
    max-width: 30rem;
    width: auto;

    &--grid {
        @include bp-medium {
            display: grid;
            grid-template-columns: repeat(3, fit-content(30rem));
        }

        display: block;
    }

    &.gw-card:hover {
        box-shadow: none;
    }

    [class$='__content'] {
        order: 2;
    }

    .gw-card {
        &__title {
            font-size: $font-size-l;
            margin: $gutter 0;

            a {
                color: $color-black;
            }
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-guide-card) {
    @include gw-guide-card;
}
