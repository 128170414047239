// Product details.
@mixin page-900-product-details {
    .page-900,
    .page-default-productdetail {
        .gw-product-specs {
            &__list {
                background-color: $color-grey-5;
            }

            &__item {
                color: $color-white;

                .gw-icon {
                    height: $icon-size-l;
                    width: $icon-size-l;
                }
            }

            &__actions__list {
                padding-block-end: $gutter-xs;
                padding-block-start: $gutter-xs;
            }

            &__action {
                .gw-button {
                    background-color: transparent;
                    border-color: $color-white;
                    box-shadow: none;
                    color: $color-white;
                    padding: $gutter-xs $gutter;
                }

                .gw-button.gw-button--primary,
                .gw-button[class*='--text'].gw-button--primary {
                    background-color: $color-brand-alt;
                    border-color: $color-brand-alt;
                    color: $color-white;
                }

                .gw-icon {
                    height: $icon-size-s;
                    width: $icon-size-s;
                }
            }

            &__price {
                &--mobile {
                    background-color: $color-grey-5;
                }

                &--large {
                    background-color: transparent;
                }
            }

            .gw-banner__title,
            .gw-product-specs__item:not(.gw-product-specs__action):first-child,
            [aria-labelledby='product-variants'] {
                padding-inline-end: $gutter-s;
                padding-inline-start: $gutter-s;
            }
        }

        .gw-news-card .gw-toolbar,
        .gw-promotion-card .gw-toolbar {
            flex-direction: row;
            margin-block-end: 0;
        }
    }
}
