@mixin gw-contact-card {
    .gw-contact-card {
        background-color: $color-white;
        box-shadow: none;
        border: none;

        .gw-card__actions {
            margin-block-end: $gutter-s;
        }

        &--person {
            background-color: $color-grey-1;
            border: 0;
            padding: 0;

            .gw-card__content {
                padding-inline-end: $gutter;
                padding-inline-start: $gutter;
                text-align: center;

                a:not([class]) {
                    font-family: $font-family-title;
                }
            }

            .gw-card__media {
                .gw-image {
                    width: 100%;
                    border-end-end-radius: 0;
                    border-end-start-radius: 0;
                    border-start-end-radius: $border-radius-medium-small;
                    border-start-start-radius: $border-radius-medium-small;
                }
            }
        }
    }
}
