@mixin gw-toolbar {
    .gw-toolbar {
        margin: $gutter-xs 0;

        .gw-icon {
            color: $color-brand-alt;
            stroke: $color-brand-alt;
        }

        &--stock {
            .gw-button.gw-button--text-icon {
                font-family: $font-family-semibold;
                letter-spacing: normal;
            }

            &__location {
                color: $color-brand-alt;
                font-family: $font-family-semibold;
            }

            &__compare {
                &.is-selected {
                    /* stylelint-disable max-nesting-depth */
                    .gw-icon {
                        stroke-width: 0.15rem;
                        fill: $color-brand-alt;
                    }
                    /* stylelint-enable max-nesting-depth */
                }
            }
        }

        &__item {
            color: $color-text-secondary;
            font-family: $font-family-title;
        }
    }
}
