.gw-ratings {
    @if $allow-dark-mode {
        @media (prefers-color-scheme: dark) {
            background-color: $color-grey-3;
        }
    }
    background-color: $color-grey-1;
    padding: $gutter-xs;

    &__text {
        font-size: $font-size;
        margin-block-end: 0;

        span {
            @supports (background-clip: text) {
                @include visually-hide;
            }
        }
    }

    &__stars {
        display: none;

        /* stylelint-disable order/order */
        @supports (background-clip: text) {
            display: inline-block;
            font-family: $font-family;
            font-size: $icon-size-m;
            line-height: 1;

            &:before {
                background: linear-gradient(
                    to right,
                    $color-neutral var(--rating),
                    $color-text var(--rating)
                );
                background-clip: text;
                content: '★★★★★';
                letter-spacing: -3px;
                -webkit-text-fill-color: transparent;
            }
        }
        /* stylelint-enable order/order */
    }

    // Banner.
    &-banner {
        align-items: center;
        border: $border-width $border-style $color-grey-2;
        display: flex;
        flex-wrap: wrap;
        gap: $gutter-s;
        margin-block-end: $gutter-xl;
        padding: $gutter-s;

        &__branch {
            margin-block-end: 0;
        }

        .gw-ratings {
            align-items: center;
            background: none;
            display: flex;
            flex-wrap: wrap;
            gap: $gutter-xs;
            margin-inline-end: auto;
            padding: 0;
        }

        &__inner {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: $gutter-s;
        }
    }

    // Signpost.
    &-signpost {
        @include bp-medium {
            align-items: center;
            background-color: #{rgba(var(--themeColorBrandAlt), 0.9)};
            color: $color-text-alt;
            display: inline-flex;
            font-size: $font-size;
            padding: $gutter-xs;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            .gw-icon {
                fill: $color-neutral;
                height: $icon-size-s;
                margin-inline-start: math.div($gutter-xs, 2);
                width: $icon-size-s;
            }
        }

        &__wrap {
            @at-root {
                /* stylelint-disable max-nesting-depth */
                .has-open-modal & {
                    display: none; // Hide it if we have a modal open.
                }
                /* stylelint-enable max-nesting-depth */
            }
            @include bp-medium {
                display: block;
                inset-block-start: 50%;
                position: fixed;
                transform: rotate(-90deg);
                transform-origin: 0 0;
                z-index: z('popup');
            }
            display: none;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-ratings) {
    @include gw-ratings;
}
