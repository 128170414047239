// Product list.
.page-10,
.page-default-productlist {
    .gw-cms {
        @include layout-centered-content;

        > h2 {
            margin-block-start: 2rem;
        }
    }

    .gw-layout__inner {
        > .gw-cms:first-of-type {
            margin-block-start: $gutter-xl;
        }
    }

    .gw-product-card-grid {
        margin-block-end: $gutter-xl;
    }

    .gw-product-card__block-link {
        p:first-of-type {
            color: $color-text;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(page-10-product-list) {
    @include page-10-product-list;
}
