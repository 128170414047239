.gw-vacancies {
    padding: 3rem 1rem;

    &__button {
        @include bp-medium {
            background: rgba(var(--themeColorBrand), 0.05);
            color: $color-brand;
            display: block;
            font-weight: bold;
            padding: 1rem 2rem;

            &:hover {
                background: $color-brand;
                color: $color-white;
            }
        }
        display: none;
    }

    &__vacancy {
        align-items: center;
        color: $color-text;
        display: flex;
        flex-flow: row nowrap;
        font-weight: normal;
        padding: 2rem 0;
        text-decoration: none;

        * {
            color: $color-text;
            font-weight: normal;
            text-decoration: none;
        }

        &:hover {
            text-decoration: none;
            transform: scale(1);

            * {
                text-decoration: none;
                transform: scale(1);
            }

            .gw-vacancies__button {
                background: $color-brand;
                color: $color-white;
            }
        }

        + .gw-vacancies__vacancy {
            border-top: $border-width $border-style $color-grey-4;
        }
    }

    &__state {
        color: $color-white;
        font-weight: bold;
        margin-inline-end: 1rem;
        padding: 0.5rem;

        &.open {
            background: $color-positive;
        }

        &.filled {
            background: $color-grey-4;
        }
    }

    &__title {
        margin: 0 auto 0 0;
    }

    &__info {
        @include bp-medium {
            align-items: center;
            display: flex;
            flex-flow: row nowrap;
            margin-inline-end: 3rem;
        }
        display: none;
    }

    &__icon {
        height: 24px;
        margin-right: 0.5rem;
        object-fit: contain;
        stroke: $color-brand;
        width: 24px;
    }
}
