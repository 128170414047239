.gw-sticky-footer {
    background-color: $color-brand;
    color: $color-text-alt;
    min-height: $layout-sticky-footer-height;
    transition: min-height 0.5s ease-in-out;

    &__list {
        display: flex;
        position: relative;
    }

    &__item {
        @include bp-medium {
            min-height: $layout-sticky-footer-height;
        }
        flex-grow: 1;
        min-height: $layout-sticky-footer-height-mobile;

        > * {
            width: 100%;
        }
    }

    &__button {
        @at-root {
            [data-whatintent='keyboard'] &:focus,
            [data-whatintent='touch'] &:focus {
                position: relative;
            }
        }
        @if $allow-dark-mode {
            @media (prefers-color-scheme: dark) {
                color: $color-text;
            }
        }
        @include bp-small {
            padding: 0.5rem;
        }
        background-color: $color-background;
        color: $color-brand;
        height: 100%;
        text-transform: unset;

        .gw-button__inner {
            @include bp-small {
                height: 100%;
                justify-content: center;

                /* stylelint-disable max-nesting-depth */
                span {
                    @include visually-hide;
                }

                .gw-icon {
                    margin: 0;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-sticky-footer) {
    @include gw-sticky-footer;
}
