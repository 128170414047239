.gw-video-card {
    align-items: center;
    text-align: center;

    .gw-card__media {
        margin-block-end: $gutter-s;
    }

    &.gw-card--media-inline {
        text-align: start;

        &-alt {
            text-align: end;
        }
    }

    &.gw-card--media-inline,
    &.gw-card--media-inline-alt {
        .gw-card__media {
            margin-block-end: 0;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-video-card) {
    @include gw-video-card;
}
