/*
----------------------------------------------------------------------------
Classes generated by CMS templates.

TODO:
- We might need to add a Storybook page?
----------------------------------------------------------------------------
*/

.gw-cms {
    overflow-x: hidden; // Just in case CMS content is stupidly wide.

    // Add some separation between CMS (markdown content) and any elements immediately after.
    + * {
        margin-block-start: $gutter-xl;
    }

    // Class to override typography.
    &__font-override {
        // Not used in whitelabel theme.
    }

    &__smallprint {
        font-size: $font-size-xs;

        p:not([class]),
        li:not([class]) {
            max-width: none;
        }
    }

    // Custom page content.
    &__content {
        &-text {
            [id] {
                @include has-scroll-margin;
            }
        }

        &-image {
            margin-block-end: $gutter;

            .gw-image {
                // Forces main image to take up all available space.
                min-width: 100%;
            }
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-cms) {
    @include gw-cms;
}
