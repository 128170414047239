@mixin gw-product-card {
    .gw-product-card {
        background-color: $color-white;
        border: $border-width $border-style $color-grey-2;
        border-radius: $border-radius-medium-small;
        box-shadow: none;

        &:hover {
            border: $border-width $border-style $color-grey-3;
        }

        .gw-card__actions .gw-button--text.gw-button--secondary {
            color: $color-brand-alt;
        }

        .gw-card__media {
            .gw-image {
                border-end-end-radius: 0;
                border-end-start-radius: 0;
                border-start-end-radius: $border-radius-medium-small;
                border-start-start-radius: $border-radius-medium-small;
            }
        }

        &--filter {
            @at-root {
                [data-whatintent='mouse'] &:hover {
                    opacity: 1;
                }
            }

            border-color: transparent;
            border-radius: $border-radius-medium-small;
            box-shadow: none;
            padding: $gutter-xs;

            .gw-card__content {
                text-align: center;
            }

            .gw-card__media {
                .gw-image {
                    border-start-end-radius: 0;
                    border-start-start-radius: 0;
                }
            }

            &:hover {
                text-decoration: none;
            }

            .gw-product-card__price {
                color: $color-text-secondary;
            }
        }

        .gw-card__title {
            color: $color-text;

            > a:hover {
                text-decoration: none;
            }

            span {
                color: $color-text-secondary;
                font-family: $font-family;
                font-size: $font-size;
                margin-block-start: $gutter-xs;
                text-transform: none;
            }
        }

        .gw-card__content {
            padding-block-end: $gutter-xs;
        }

        &__block-link {
            margin-block-start: $gutter-xs;

            &:hover {
                color: $color-brand-alt;
                text-decoration: none;
            }

            & > p:not([class]) {
                color: $color-text-secondary;
            }
        }

        &__legal {
            color: $color-text-secondary;
        }

        [class$='__specs'] {
            font-size: $font-size-s;
            /* stylelint-disable no-descending-specificity */
            span {
                color: $color-text-secondary;
                margin-inline-end: 0.2rem;
            }

            span:not(:last-child):after {
                content: '•';
            }
            /* stylelint-enable no-descending-specificity */
        }

        &:not(.gw-product-card--filter) {
            [class*='__price'] {
                align-items: center;
                font-family: $font-family-semibold;
                font-size: $font-size-l;
                font-weight: normal;
                margin-block-end: $gutter-xs;
            }

            [class$='__price--was'] {
                color: $color-text-secondary;
                font-family: $font-family;
                font-size: $font-size-m;
                font-weight: normal;
            }

            [class$='__price--discount'] {
                color: $color-negative;
                font-family: $font-family-semibold;
                font-size: $font-size-l;
                font-weight: normal;
                margin-block-end: 0;
            }

            [class$='__price--text'] {
                font-size: $font-size;
                font-size: $font-size-m;
                font-weight: normal;
                line-height: 1.3;
            }
        }

        > .gw-button-group {
            align-content: flex-start;
            border-radius: 0 0 $border-radius-medium-small
                $border-radius-medium-small;
            margin-block-start: auto;
            padding-block-start: $gutter-xs;
        }

        &--overview {
            border-color: transparent;

            > .gw-button-group {
                height: 100%;
                margin-block-start: unset;
            }

            .gw-card__media {
                .gw-image {
                    border-start-end-radius: 0;
                    border-start-start-radius: 0;
                }
            }
        }
    }
}
