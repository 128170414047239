@mixin footer-styles {
    @include bp-medium {
        padding: $gutter-l $layout-main-bleed;
    }
    background-color: $color-grey-3;
    color: $color-text-alt;
    padding: $gutter $layout-main-bleed-mobile;
}

.gw-footer {
    @if $allow-dark-mode {
        @media (prefers-color-scheme: dark) {
            color: $color-text;
        }
    }

    @include bp-medium {
        @include footer-styles;
        @include responsive-grid-columns;
        @include responsive-grid;
    }

    display: none;

    &--mobile {
        @include bp-medium {
            display: none;
        }

        @include footer-styles;
        margin-block-end: 0;

        .gw-accordion__title {
            font-family: $font-family-title;
            font-size: $font-size-m;
        }

        .gw-accordion__button {
            border-block-end: 1px solid $color-text-alt;
            margin-block-end: 0;
        }

        .gw-accordion__button,
        .gw-accordion__button:hover,
        .gw-accordion__button:focus,
        .gw-accordion__content {
            background-color: transparent;
        }

        .gw-button--whatsapp {
            color: $color-text-alt;

            .gw-button__inner > .gw-icon {
                margin-inline-start: 0;
            }
        }
    }

    &__group-title {
        border-block-end: $border-width $border-style;
        font-size: $font-size-m;
        padding-block-end: $gutter-xs;
    }

    &__list {
        & + * {
            margin-block-start: $gutter-xs;
        }
    }

    &__item {
        font-size: $font-size;
        margin-block-end: $gutter-xs;

        &--logo {
            .gw-image {
                flex-shrink: 1;
                height: $icon-size-home-mobile;
                width: auto;
            }
        }
    }

    &__link {
        color: currentColor;
        font-size: $font-size;
        padding-block-end: math.div($gutter-xs, 2);
        padding-block-start: math.div($gutter-xs, 2);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &.gw-button--whatsapp {
            color: $color-text-alt;
        }
    }

    &__group-content {
        font-size: $font-size;
        margin-block-end: $gutter-xs;

        // In case there's a link without any CSS classes.
        a {
            color: currentColor;
        }

        > span {
            display: block;
        }
    }

    &__smallprint {
        font-size: $font-size-xs;
    }

    // Supplementary nav (e.g. copyright).
    &--supplementary {
        @if $allow-dark-mode {
            @media (prefers-color-scheme: dark) {
                background-color: $color-background;
                color: $color-text;
            }
        }
        @include bp-medium {
            padding: $gutter-s $layout-main-bleed;
        }
        background-color: $color-background-alt;
        color: $color-text-alt;
        padding: $gutter-s $layout-main-bleed-mobile;

        &-social {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            > a {
                margin-top: 0;
            }
        }

        .gw-social {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            padding: 0;

            &__title {
                font-family: $font-family;
                font-size: $font-size;
                margin-block-end: 0;
                margin-inline-end: $gutter-s;
            }

            &__icons {
                margin-inline-start: -$gutter-xs;
            }
        }

        .gw-footer__item {
            @include bp-xsmall {
                flex-basis: 100%;
            }
        }

        .gw-footer__list {
            align-items: center;
            display: flex;
            flex-wrap: wrap;

            > *:not(:last-child) {
                margin-inline-end: $gutter-s;
            }

            &--social {
                flex-grow: 1;
                /* stylelint-disable max-nesting-depth */
                > *:not(:last-child) {
                    margin-inline-end: $gutter-xs;
                }

                .gw-footer__item {
                    @include bp-xsmall {
                        flex-basis: auto;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }

    &__manufacturer {
        .gw-image {
            height: 3.75rem;
        }
    }

    &--gc {
        background-color: $color-footer-background;

        .gw-layout__inner {
            @include bp-medium {
                padding-inline: $layout-main-bleed;
            }
        }

        .gw-footer__border {
            &--desktop {
                /* stylelint-disable-next-line max-nesting-depth */
                @include bp-medium {
                    background: linear-gradient(
                        120deg,
                        $color-brand 0%,
                        $color-brand 60%,
                        $color-secondary-1 60%,
                        $color-secondary-1 70%,
                        $color-secondary-2 70%,
                        $color-secondary-2 80%,
                        $color-secondary-3 80%,
                        $color-secondary-3 90%,
                        $color-secondary-4 90%,
                        $color-secondary-4 100%
                    );
                    display: block;
                    height: 0.5rem;
                    width: 100%;
                }

                display: none;
            }

            &--mobile {
                /* stylelint-disable-next-line max-nesting-depth */
                @include bp-medium {
                    display: none;
                }

                background: linear-gradient(
                    120deg,
                    $color-brand 0%,
                    $color-brand 40%,
                    $color-secondary-1 40%,
                    $color-secondary-1 55%,
                    $color-secondary-2 55%,
                    $color-secondary-2 70%,
                    $color-secondary-3 70%,
                    $color-secondary-3 85%,
                    $color-secondary-4 85%,
                    $color-secondary-4 100%
                );
                display: block;
                height: 0.5rem;
                width: 100%;
            }
        }

        .gw-footer--pre-footer {
            /* stylelint-disable max-nesting-depth */
            @include bp-medium {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                margin-inline: $layout-main-bleed;
                padding-block: $gutter-l;
            }

            border-block-end: $border-width $border-style
                rgba(var(--themeWhite), 0.5);
            color: $color-footer-background-font;
            margin-inline: $layout-main-bleed-mobile;
            padding-block: $gutter;

            .gw-footer__logo {
                display: block;
                max-width: 15rem; // 325px. Variable not in place here as it's only one element on a component only used by one theme.
            }

            .gw-footer__address {
                @include bp-medium {
                    flex-grow: 1;
                    margin-block-start: 0;
                    margin-inline-start: $gutter-xs;
                }

                margin-block-start: $gutter-xs;

                p {
                    margin-block-end: 0;
                }
            }
            /* stylelint-enable max-nesting-depth */
        }

        .gw-footer {
            &__group-title {
                border-block-end: 0;
            }
        }

        .gw-footer--mobile {
            .gw-accordion {
                /* stylelint-disable max-nesting-depth */
                &__button {
                    @at-root {
                        [data-whatintent='mouse'] &:hover,
                        [data-whatintent='keyboard'] &:focus {
                            color: $color-grey-5;
                        }
                    }

                    border-block-end: $border-width $border-style
                        rgba(var(--themeWhite), 0.5);
                }
                /* stylelint-enable max-nesting-depth */
            }
        }

        .gw-form {
            margin-block-end: 0;
            padding-block-start: 0;

            &__input {
                /* stylelint-disable-next-line max-nesting-depth */
                @at-root [data-whatintent='mouse'] &:focus {
                    border: $border-width $border-style $color-grey-1;
                }

                background-color: rgba(var(--themeGrey5), 0.3);
                border: $border-width $border-style rgba(var(--themeWhite), 0.5);
                border-radius: $border-radius-rounded;
                padding-inline-start: $gutter-xs;
            }

            .gw-button[class*='--text'] {
                border-radius: $border-radius-rounded;
                width: 100%;
            }
        }

        .gw-footer--supplementary {
            .gw-footer__list {
                /* stylelint-disable max-nesting-depth */
                .gw-footer__item--copyright {
                    @include bp-medium {
                        width: auto;
                    }

                    flex-grow: 1;
                    width: 100%;
                }
                /* stylelint-enable max-nesting-depth */
            }

            &-social {
                /* stylelint-disable max-nesting-depth */
                @include bp-medium {
                    display: flex;
                    justify-content: flex-end;
                    margin-inline: $layout-main-bleed;
                    padding-block: $gutter-s;
                }

                border-block-end: $border-width $border-style
                    rgba(var(--themeWhite), 0.5);
                margin-inline: $layout-main-bleed-mobile;
                padding: $gutter-s 0;

                .gw-social {
                    .gw-button--facebook {
                        margin-inline-end: -$gutter-xs;

                        .gw-icon {
                            border: $border-width $border-style $color-text-alt;
                            border-radius: $border-radius-rounded;
                            height: 2.5em;
                            width: 2.5em;
                        }
                    }

                    .gw-button:not(.gw-button--facebook) {
                        border: $border-width $border-style $color-text-alt;
                        border-radius: $border-radius-rounded;
                        margin-inline-start: $gutter-xs;
                        padding: 0.5rem;

                        .gw-icon {
                            height: 1.5em;
                            width: 1.5em;
                        }
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-footer) {
    @include gw-footer;
}
