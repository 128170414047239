/*
----------------------------------------------------------------------------
Print stylesheet
----------------------------------------------------------------------------
*/

/*
----------------------------------------------------------------------------
Basic reset.
----------------------------------------------------------------------------
*/

/* stylelint-disable declaration-no-important */
@page {
    margin: 10pt;
}

body {
    font-size: 10pt;
}

body:not(.page-printstock) {
    color: #000; // Black prints faster.
    font-family: sans-serif;
}

figure {
    margin: 0;
}

h1 {
    font-size: 20pt;
}

h2 {
    font-size: 16pt;
}

h3 {
    font-size: 12pt;
}

h4,
h5,
h6 {
    font-size: 10pt;
    margin: 0;
}

h1,
h2,
h3,
p {
    margin-block-end: 5pt;
}

th,
td {
    border: 1pt solid grey;
    font-size: 8pt;
    padding: 3pt;
    text-align: initial;
}

a {
    text-decoration: underline;

    + a {
        margin: 0 10pt;
    }
}

[href]:after {
    content: '(' attr(href) ')';
}

[href^='mailto']:after,
[href^='tel']:after,
[href^='fax']:after,
[href='/']:after,
[href='#']:after {
    content: '';
}

table,
figure,
pre {
    margin-block-end: 10pt;
    page-break-inside: avoid;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    page-break-after: avoid;
}

p {
    orphans: 3;
    widows: 3;
}

thead {
    display: table-header-group;
}

/*
----------------------------------------------------------------------------
Specific component styles.
----------------------------------------------------------------------------
*/

[class*='gw-grid'] {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;

    > * {
        margin: 5pt;
    }
}

.gw-form {
    &__field,
    &__fieldset {
        margin-block-end: 10pt;
    }

    &__label {
        span {
            display: block;
        }
    }
}

.gw-button-group {
    margin-block-end: 10pt;

    .gw-button {
        display: block;
        margin: 0;
    }
}

.gw-header__logo {
    font-size: 16pt;

    .gw-image {
        display: block;
        max-height: 30pt;
    }
}

.gw-banner__inner {
    margin-block-end: 10pt;

    &.gw-responsive-media {
        padding: 0 !important;
    }
}

/*
----------------------------------------------------------------------------
Hide stuff.

Notes:
- We might want to still show specific images?
----------------------------------------------------------------------------
*/

img,
button,
.gw-icon,
.gw-skiplinks,
.gw-navigation,
.gw-header__search,
.gw-header__offers,
.gw-branch-rotator,
.gw-product-search,
.gw-footer__group-title,
.gw-footer__group-content,
.gw-footer__item:not(.gw-footer__item--copyright),
.gw-layout__sticky--footer {
    display: none;
}
/* stylelint-enable declaration-no-important */
