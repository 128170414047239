.gw-price-card {
    max-width: 350px;
    padding: 0;

    /* stylelint-disable max-nesting-depth */
    &__check-icon {
        @include bp-medium {
            display: none;
        }

        fill: $color-brand;
        margin-inline-end: $gutter-xs;
    }

    &__content {
        background-color: $color-grey-1;
        padding: $gutter;
    }

    &__desc {
        min-height: 6rem;
    }

    &__feature {
        align-items: center;
        display: flex;
        margin-block-end: $gutter-xs;
    }

    &__feature-desc--container {
        display: none;
    }

    &__feature-desc {
        background-color: $color-grey-1;
        border: $border-width $border-style $color-grey-3;
        font-size: $font-size-s;
        padding: $gutter-s;
        position: absolute;
        transform: translateY(-50%);
        width: 200px;
    }

    &__feature-group {
        padding: $gutter;

        h3 {
            font-size: $font-size-m;
            margin-block-end: $gutter-s;
        }
    }

    &__price {
        margin: 0;

        span {
            color: $color-brand;
            font-family: $font-family-title;
            font-size: $font-size-xxl;
        }

        &--additional {
            margin-block-end: 0;
            min-height: $gutter-xl;

            span {
                @include bp-medium {
                    font-size: $font-size-l;
                }

                color: $color-brand;
                font-family: $font-family-title;
            }
        }

        &.hide {
            display: none;
        }
    }

    &__question-icon {
        @include bp-medium {
            display: block;
        }

        display: none;
        fill: $color-brand;
        margin-inline-start: $gutter-xs;

        &:hover {
            cursor: pointer;
        }

        &:hover + .gw-price-card__feature-desc--container {
            display: block;
        }
    }

    &__title {
        @include bp-medium {
            border-block-end: $border-width $border-style $color-grey-3;
            padding-block-end: $gutter;
        }

        font-size: $font-size-l;
        padding-block-end: $gutter-s;
    }

    .gw-accordion {
        margin: 0;

        &__button:focus,
        &.is-js-enabled .gw-accordion__content:focus {
            background-color: transparent;
        }

        &__button__inner {
            font-size: $font-size-m;
        }
    }

    .gw-button {
        margin: 0;
        text-align: center;
        width: 100%;
    }

    &.gw-card:hover {
        box-shadow: none;
        transform: none;
    }

    /* stylelint-enable max-nesting-depth */
}

@if mixin-exists(gw-price-card) {
    @include gw-price-card;
}
