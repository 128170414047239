// Homepage.
.page-1,
.page-default-home {
    .gw-cms {
        @include layout-centered-content;

        &:first-of-type {
            margin-block-start: $gutter-xl;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(page-1-homepage) {
    @include page-1-homepage;
}
