@mixin gw-tabs {
    [role='tab'] {
        border-radius: 0;
    }

    .gw-tabs {
        &.is-js-enabled {
            .gw-tabs__content {
                @include focus-default(
                    $opacity: false,
                    $transform: false,
                    $mouse-outline: false,
                    $keyboard-outline: true
                );

                border-radius: $border-radius-medium-small;
            }
        }

        &__button {
            @include bp-medium {
                font-size: $font-size-m;
            }

            @include bp-xxlarge {
                font-size: $font-size-l;
            }

            @include focus-default(
                $opacity: false,
                $transform: false,
                $mouse-outline: false,
                $keyboard-outline: true
            );

            background-color: transparent;
            border-block-end: $border-width-m $border-style
                $color-text-secondary;
            color: $color-text-secondary;

            &:focus {
                border-radius: $border-radius-small;
            }

            &:hover {
                color: $color-link-hover;
            }

            &[aria-selected='true'] {
                background-color: transparent;
                border-block-end: $border-width-l $border-style $color-brand-alt;
                color: $color-brand-alt;
            }
        }
    }
}
