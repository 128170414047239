.gw-nav-in-page {
    &__list {
        display: flex;
        flex-direction: column;
    }

    &__item {
        margin-block-end: $gutter-xs;
    }

    &__link {
        text-decoration: none;

        &:hover {
            color: $color-brand-alt;
            text-decoration: underline;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-nav-in-page) {
    @include gw-nav-in-page;
}
