@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css";
@charset "UTF-8";
/*
----------------------------------------------------------------------------
NOTE:

DO NOT EDIT 'index.scss' directly.

Instead, make changes to '_index.scss.template', which will then be copied to 'index.scss' by Parcel bundler.
----------------------------------------------------------------------------
Default CSS variables.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
CSS variables for dynamic theming.

- Color values are specified as a comma-separated list of RGB values. This allows us to leverage the Sass 'rgba()' function.
- Theme overrides can be applied via the CMS, rendered in HTML <style> blocks.
- Legacy browsers can only leverage CSS variables if they are defined inside ':root' element.
----------------------------------------------------------------------------
*/
:root {
  --themeColorBrand: 0, 9, 91;
  --themeColorBrandAlt: 6, 111, 239;
  --themeColorBrandAlt2: 23, 0, 244;
  --themeColorBackground: 255, 255, 255;
  --themeColorBackgroundAlt: 51, 51, 51;
  --themeColorText: 31, 48, 71;
  --themeColorTextAlt: 255, 255, 255;
  --themeColorTextSecondary: 107, 119, 134;
  --themeColorTextSecondaryAlt: 245, 246, 247;
  --themeColorLink: 6, 111, 239;
  --themeColorLinkHover: 4, 78, 167;
  --themeColorLinkAlt: 131, 183, 247;
  --themeColorLinkAltHover: 36, 128, 241;
  --themeBlack: 0, 0, 0;
  --themeWhite: 255, 255, 255;
  --themeGrey1: 245, 246, 247;
  --themeGrey2: 209, 213, 217;
  --themeGrey3: 156, 163, 173;
  --themeGrey4: 56, 72, 92;
  --themeGrey5: 0, 20, 46;
  --themeAccentPositive: 0, 130, 0;
  --themeAccentPositiveAlt: 51, 155, 51;
  --themeAccentNegative: 191, 21, 44;
  --themeAccentNegativeAlt: 232, 72, 83;
  --themeAccentNeutral: 177, 98, 1;
  --themeAccentNeutralAlt: 221, 122, 1;
  --themeColorHeader: null;
  --themeColorHeaderButton1: null;
  --themeColorHeaderButton2: null;
  --themeColorPreheader: null;
  --themeColorPreheaderFont: null;
  --themeColorFooter: 245, 246, 247;
  --themeColorFooterFont: 107, 119, 134;
  --themeColorFooterSocial: 245, 246, 247;
  --themeColorSocialFont: 107, 119, 134;
  --themeColorSupplementary: 245, 246, 247;
  --themeColorSupplementaryFont: 107, 119, 134;
  --themeColorFooterSupplementaryLink: null;
  --themeColorFooterSupplementaryLinkHover: null;
  --themeFontFamily: FordF1Regular;
  --themeFontFamilyBold: FordF1Bold;
  --themeFontFamilyTitle: FordF1Regular;
  --themeFontFamilySemiBold: FordF1Semibold;
  --themeFontFamilyLight: FordF1Light;
  --themeFontFamilyMedium: FordF1Medium;
  --themeFontFamilyArabic: Arial;
  --themeFontFamilyArabicTitle: Arial;
  --themeFontFamilyVietnamese: Arial;
  --themeFontFamilyVietnameseTitle: Arial;
  --themeFontSizeSmall: 75%;
  --themeFontSize: 100%;
  --themeFontSize2K: 110%;
  --themeFontSize4K: 200%;
  --themeMaxLineLength: 85ch;
  --themeMaxContentWidth: 65rem;
  --themeMediaAspectRatio: 16/9;
}
/*
----------------------------------------------------------------------------
THEME import goes before Sass variable declarations.
See https://robots.thoughtbot.com/sass-default
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
THEME overrides...

NOTES:
1. If you need to reference a base variable from "/base/" folder, WITHOUT CHANGING ITS VALUE, you will need to DECLARE IT AGAIN in this file, otherwise it will be undefined.

2. Theme background image paths are relative to "./src/styles/index.scss".
	e.g. url(themes/theme-name/images/name-of-file.jpg)

3. Theme font import path is relative to "./src/styles/index.scss".
	e.g. @import 'themes/theme-name/fonts/fonts';
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
1. Override base styles from "./src/styles/base/" folder directly below.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
2. Override global styles from "./src/styles/globals/" folder, by importing the appropriate Sass partial(s).
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
3. Override component styles from "./src/styles/components/" folder, by importing the appropriate Sass partial(s).
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
4. Override page styles from "./src/styles/pages/" folder, by importing the appropriate Sass partial(s).
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
5. Override default fonts.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
@font-face rules.

- 'font-display: swap;' ensures that all text will be rendered with a fallback system font until custom font is ready.
----------------------------------------------------------------------------
*/
@font-face {
  font-display: swap;
  font-family: "FordF1Regular";
  font-style: normal;
  font-weight: normal;
  src: url("FordF-1-Regular.57e86fc0.woff2") format("woff2"), url("FordF-1-Regular.056efcac.woff") format("woff"), url("FordF-1-Regular.5b20c055.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "FordF1Bold";
  font-style: normal;
  font-weight: normal;
  src: url("FordF-1-Bold.89e90e5f.woff2") format("woff2"), url("FordF-1-Bold.36dd5c8d.woff") format("woff"), url("FordF-1-Bold.e9c9b14b.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "FordF1Light";
  font-style: normal;
  font-weight: normal;
  src: url("FordF-1-Light.33493361.woff2") format("woff2"), url("FordF-1-Light.8c1c85f1.woff") format("woff"), url("FordF-1-Light.7fed2698.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "FordF1Medium";
  font-style: normal;
  font-weight: normal;
  src: url("FordF-1-Medium.d43f7c96.woff2") format("woff2"), url("FordF-1-Medium.8f3ad8b0.woff") format("woff"), url("FordF-1-Medium.233a967f.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "FordF1Semibold";
  font-style: normal;
  font-weight: normal;
  src: url("FordF-1-Semibold.b8e2a0be.woff2") format("woff2"), url("FordF-1-Semibold.d447fd46.woff") format("woff"), url("FordF-1-Semibold.c320e225.ttf") format("truetype");
}
/*
----------------------------------------------------------------------------
Base scaffold, containing all !default Sass variables.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Base scaffold, containing all !default Sass variables.
Be aware of the order of partials below.
----------------------------------------------------------------------------
*/
/* The clever stuff... put it first*/
/* stylelint-disable order/order */
/*
----------------------------------------------------------------------------
Mixins.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Accessibility - for screen readers.

Usage:
1.
.classname {
    @include visually-hide;
}

2. Or just add 'sr-only' class in the HTML to the appropriate element, as it already uses this mixin.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Add scroll margin so content is not hidden below sticky header.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Fluid typography. Uses 'clamp()' to set MIN|MAX values, with a simple non-responsive fallback for older browsers.

See https://piccalil.li/tutorial/fluid-typography-with-css-clamp.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Grid - for internal gallery or grid layouts.

- Uses CSS Grid, with automatic responsive behaviour as per https://css-tricks.com/look-ma-no-media-queries-responsive-layouts-using-css-grid/.
- Fallback to Flexbox for browsers that either don't understand '@supports' directive, or don't support 'grid-area: auto'.

Usage:
1. Default responsive grid, HTML markup:
    <ul class="grid">
        <li>[...content...]</li>
    </ul>

2. Grid variants:

    - Centered layout:
        <ul class="grid grid--centered">...</ul>

    - Remove gaps between grid items:
        <ul class="grid grid--no-gap">...</ul>

3. If you can't add the 'grid' classes to the HTML directly, include Sass mixin(s) on parent container instead:

    .gw-widget {
        @include responsive-grid-columns;
        @include responsive-grid;
    }
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Focus management.

- Explicitly setting :HOVER behaviour for mouse, and :FOCUS behaviour for keyboard interactions, using "what-input" NPM package.

TODO:
- Replace non-JS fallback with ':focus-visible'? See https://css-tricks.com/the-focus-visible-trick/.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Buttons.

- <button>
- <a href=""> links styled to look like buttons.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Cards.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Responsive media.

Browsers that do NOT support 'aspect-ratio' use padding on parent container to enable aspect ratio of media to be preserved.

See https://css-tricks.com/aspect-ratio-boxes/
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Apply max-width constraint and optionally center container with auto margins.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Center content within container.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Micro clearfix hack - see http://nicolasgallagher.com/micro-clearfix-hack/
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Detect Safari.

Usage:
.my-selector {
    @include safari {
        // Safari code...
    }
}
----------------------------------------------------------------------------
*/
/* stylelint-enable order/order */
/* Variable declarations... order is important here too*/
/*
----------------------------------------------------------------------------
Main breakpoints.

NOTES:
1. Add breakpoint Sass variables as appropriate.
1a. Breakpoint specified in EM units due to Safari bug when increasing root font-size on HTML element - see https://zellwk.com/blog/media-query-units/.
2. Create a mixin per breakpoint, whose name is the same as the breakpoints defined in (1).
3. Create a mixin to generate CSS content to identify the media query, which can be leveraged by JS.
4. Invoke the mixin from (3) on the body element.
----------------------------------------------------------------------------
*/
/*[1], [1a]*/
/*[2]*/
/*[3]*/
/*[4]*/
@media (max-width: 30em) {
  body::before {
    content: "bp-small";
    display: none;
  }
}
@media (max-width: 48em) {
  body::before {
    content: "bp-small";
    display: none;
  }
}
@media (min-width: 48.0625em) {
  body::before {
    content: "bp-medium";
    display: none;
  }
}
@media (min-width: 64em) {
  body::before {
    content: "bp-large";
    display: none;
  }
}
@media (min-width: 90em) {
  body::before {
    content: "bp-xlarge";
    display: none;
  }
}
@media (min-width: 160em) {
  body::before {
    content: "bp-2k";
    display: none;
  }
}
@media (min-width: 240em) {
  body::before {
    content: "bp-4k";
    display: none;
  }
}
/*
----------------------------------------------------------------------------
Colors.

- Please use JSDoc comments to identify all the colors.
- This is so that when the theme is built, the 'sass-export' Node package can correctly sub-divide all the colors into appropriate groups for Storybook.
----------------------------------------------------------------------------
*/
/**
 * @sass-export-section="main theme colours"
 */
/**
 * @sass-export-section="secondary colours"
 */
/**
 * @sass-export-section="neutral colours"
 */
/**
 * @sass-export-section="other accent colours"
 */
/* Set colours for header here! This can be changed through multi-franchise pages */
/**
 * @sass-export-section="ThemeBuilder variables"
 */
/*
----------------------------------------------------------------------------
Typography.

- Please use JSDoc comments to identify all the type variables.
- This is so that when the theme is built, the 'sass-export' Node package can correctly sub-divide all the variables into appropriate groups for Storybook.
----------------------------------------------------------------------------
*/
/**
 * @sass-export-section="theme typefaces"
 */
/**
 * @sass-export-section="miscellaneous - these will be hidden in Storybook"
 */
/*
----------------------------------------------------------------------------
Font sizes.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Line heights.
We're only adjusting values when fonts get large.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Max line-length (aka measure) to improve readability of editorial content.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Dark mode.

Usage:
    @if $allow-dark-mode {
        @media (prefers-color-scheme: dark) {
            // code...
        }
    }
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Layout.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
CSS Grid.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Gutters.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Borders.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Icons.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Forms.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Maps.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Navigation.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Rounded inputs - only applicable for multi-franchise currently but this can be changed.

Are rounded inputs required? If so, set to true
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Slider.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Swatch.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
z-index.

Implementation taken from http://www.sitepoint.com/better-solution-managing-z-index-sass/
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
The 'z-layers' Sass map contains all z-index values for whole site.

Usage:
1. .classname { z-index: z('popup'); }
2. There's a subtle distinction between a modal that sits above everything, and a popup (e.g. tooltip).
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Fonts & globals.
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
@font-face rules.

- 'font-display: swap;' ensures that all text will be rendered with a fallback system font until custom font is ready.
----------------------------------------------------------------------------
*/
@font-face {
  font-display: swap;
  font-family: "roboto_slabregular";
  font-style: normal;
  font-weight: normal;
  src: url("RobotoSlab-Regular-webfont.b1e6932f.woff") format("woff"), url("RobotoSlab-Regular-webfont.16b5b2f3.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "robotolight";
  font-style: normal;
  font-weight: normal;
  src: url("Roboto-Light-webfont.29fa1411.woff") format("woff"), url("Roboto-Light-webfont.fc22396b.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "robotobold";
  font-style: normal;
  font-weight: normal;
  src: url("Roboto-Bold.b30e91a1.woff") format("woff"), url("Roboto-Bold.daa8e441.ttf") format("truetype");
}
/*
----------------------------------------------------------------------------
Global resets.
Based on:
Eric Meyer = https://meyerweb.com/eric/tools/css/reset/
Andy Bell = https://hankchizljaw.com/wrote/a-modern-css-reset/
Josh Comeau = https://www.joshwcomeau.com/css/custom-css-reset/
Kilian Valkhof = https://kilianvalkhof.com/2022/css-html/your-css-reset-needs-text-size-adjust-probably/
----------------------------------------------------------------------------
*/
html {
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
* {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
html,
body {
  background-color: rgb(var(--themeColorBackground));
  color: rgb(var(--themeColorText));
  min-height: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  html,
body {
    scroll-behavior: smooth;
  }
}
ol[class],
ul[class] {
  list-style: none;
}
ul ul,
ol ol {
  -webkit-margin-after: 0;
          margin-bottom: 0;
}
ol ol {
  list-style-type: lower-alpha;
}
ol ol ol {
  list-style-type: lower-roman;
}
[dir="ltr"] ol:not([class]),[dir="ltr"] 
ul:not([class]){
          padding-left: 2.5rem;
}
[dir="rtl"] ol:not([class]),[dir="rtl"] 
ul:not([class]){
          padding-right: 2.5rem;
}
ol:not([class]),
ul:not([class]) {
  -webkit-margin-after: 1.25rem;
          margin-bottom: 1.25rem;
          padding-inline-start: 2.5rem;
  -webkit-padding-start: 2.5rem;
}
[type=submit],
label {
  cursor: pointer;
}
blockquote,
q {
  quotes: none;
}
blockquote ::after,
blockquote ::before,
q ::after,
q ::before {
  content: "";
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
[dir="ltr"] caption,[dir="ltr"] 
th,[dir="ltr"] 
td{
  text-align: left;
}
[dir="rtl"] caption,[dir="rtl"] 
th,[dir="rtl"] 
td{
  text-align: right;
}
caption,
th,
td {
  font-weight: normal;
  text-align: start;
}
img {
  display: block;
  height: auto;
  max-width: 100%;
}
input,
textarea,
select {
  color: inherit;
  vertical-align: middle;
}
button,
input,
select,
textarea {
  /* stylelint-disable-next-line prettier/prettier */
  font: inherit;
}
/* <a> needs to be included here to remove the default tab focus */
button,
a {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
sub,
sup {
  vertical-align: revert;
}
/* Remove all animations & transitions for people that prefer not to see them */
/* stylelint-disable declaration-no-important */
@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}
html {
  font-size: var(--themeFontSize, 100%);
}
@media (min-width: 48.0625em) {
  html {
    font-size: var(--themeFontSizeSmall, 75%);
  }
}
@media (min-width: 120em) {
  html {
    font-size: var(--themeFontSize, 100%);
  }
}
@media (min-width: 160em) {
  html {
    font-size: var(--themeFontSize2k, 110%);
  }
}
@media (min-width: 240em) {
  html {
    font-size: var(--themeFontSize4k, 200%);
  }
}
[dir=rtl] {
  font-size: 112.5%;
}
[lang=ar]:root {
  --themeFontFamily: var(--themeFontFamilyArabic);
  --themeFontFamilyTitle: var(--themeFontFamilyArabicTitle);
}
[lang=vi]:root {
  --themeFontFamily: var(--themeFontFamilyVietnamese);
  --themeFontFamilyTitle: var(--themeFontFamilyVietnameseTitle);
}
body {
  font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 100%;
  font-size: clamp(1rem, 1rem + 0.2vw, 1.25rem);
  line-height: 1.6;
}
[dir=rtl] body {
  font-size: clamp(1rem, calc(1rem + 0.2vw) * 1.125, 1.25rem);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
h1,
.gw-h1 {
  font-size: 3.375rem;
  font-size: clamp(2.5rem, 1rem + 3.5vw, 3.375rem);
  line-height: 1.2;
  -webkit-margin-after: 0.75rem;
          margin-bottom: 0.75rem;
}
.gw-layout__inner > h1,
.gw-layout__inner > .gw-h1 {
  -webkit-margin-before: 1.875rem;
          margin-top: 1.875rem;
}
[dir=rtl] h1,
[dir=rtl] .gw-h1 {
  font-size: clamp(2.5rem, calc(1rem + 3.5vw) * 1.125, 3.375rem);
}
h2,
.gw-h2 {
  font-size: 2.5rem;
  font-size: clamp(2rem, 1rem + 2.5vw, 2.5rem);
  line-height: 1.2;
  -webkit-margin-after: 0.75rem;
          margin-bottom: 0.75rem;
}
[dir=rtl] h2,
[dir=rtl] .gw-h2 {
  font-size: clamp(2rem, calc(1rem + 2.5vw) * 1.125, 2.5rem);
}
h3,
.gw-h3 {
  font-size: 2rem;
  font-size: clamp(1.5rem, 1rem + 2vw, 2rem);
  line-height: 1.2;
  -webkit-margin-after: 0.75rem;
          margin-bottom: 0.75rem;
}
[dir=rtl] h3,
[dir=rtl] .gw-h3 {
  font-size: clamp(1.5rem, calc(1rem + 2vw) * 1.125, 2rem);
}
h4,
.gw-h4 {
  font-size: 1.5rem;
  font-size: clamp(1.25rem, 1rem + 1.5vw, 1.5rem);
  line-height: 1.3;
  -webkit-margin-after: 0.75rem;
          margin-bottom: 0.75rem;
}
[dir=rtl] h4,
[dir=rtl] .gw-h4 {
  font-size: clamp(1.25rem, calc(1rem + 1.5vw) * 1.125, 1.5rem);
}
h5,
h6,
.gw-h5,
.gw-h6 {
  font-size: 1.25rem;
}
p {
  -webkit-margin-after: 1.25rem;
          margin-bottom: 1.25rem;
}
small {
  font-size: 0.6875rem;
}
code {
  font-family: Menlo, Monaco, Consolas, "Droid Sans Mono", "Courier New", monospace;
  font-size: 1rem;
}
p:not([class]),
li:not([class]),
dd,
small {
  max-width: var(--themeMaxLineLength);
}
address {
  font-style: normal;
}
address > span {
  display: block;
}
a {
  color: rgb(var(--themeColorLink));
  transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
}
[data-whatintent=keyboard] a:focus {
  box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
  outline: 0;
}
[data-whatintent=touch] a:focus {
  background-color: rgba(var(--themeColorBrand), 0.2);
  outline: 0;
}
[data-whatintent=mouse] a:focus {
  outline: 0;
}
a:focus {
  outline: 0.25rem solid rgb(var(--themeAccentNeutral));
}
[data-whatintent=mouse] a:hover {
  opacity: 0.8;
}
a:active {
  transform: scale(0.975);
}
a:hover {
  text-decoration: none;
}
a[href^=mailto] {
  overflow-wrap: anywhere;
}
hr {
  border: none;
  -webkit-border-before: 0.0625rem solid rgb(var(--themeColorBrand));
          border-top: 0.0625rem solid rgb(var(--themeColorBrand));
  margin: 1.875rem 0;
}
[aria-current] {
  color: rgb(var(--themeColorText));
  font-weight: bold;
}
body {
  font-size: 100%;
  font-size: clamp(0.875rem, 0.85rem + 0.2vw, 1rem);
  line-height: 1.6;
}
[dir=rtl] body {
  font-size: clamp(0.875rem, calc(0.85rem + 0.2vw) * 1.125, 1rem);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
h6 {
  font-size: inherit;
}
h4,
h5 {
  font-family: var(--themeFontFamilyMedium) -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
/* stylelint-disable no-descending-specificity */
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a:not([class]) {
  color: rgb(var(--themeColorLink));
}
a:not([class]):hover {
  color: rgb(var(--themeColorLinkHover));
}
/* stylelint-enable no-descending-specificity */
[aria-current] {
  font-weight: normal;
}
b,
strong {
  font-family: var(--themeFontFamilySemiBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
/*
----------------------------------------------------------------------------
Utilities.

- '!important' is OK for global utility classes.
----------------------------------------------------------------------------
*/
/* stylelint-disable declaration-no-important */
/*
----------------------------------------------------------------------------
Accessibility - for screen readers.
----------------------------------------------------------------------------
*/
.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sr-only:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  width: auto;
}
/*
----------------------------------------------------------------------------
Hide content (e.g. in 'show|hide' component).
----------------------------------------------------------------------------
*/
.is-hidden {
  display: none !important;
}
@media (max-width: 48em) {
  .is-hidden-on-small {
    display: none !important;
  }
}
@media (min-width: 48.0625em) {
  .is-hidden-on-medium {
    display: none !important;
  }
}
@media (min-width: 64em) {
  .is-hidden-on-large {
    display: none !important;
  }
}
/*
----------------------------------------------------------------------------
Prevent scrolling on BODY when modal is open.
See https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior
----------------------------------------------------------------------------
*/
.has-open-modal {
  overflow: hidden;
      overscroll-behavior: none;
  -ms-scroll-chaining: none;
}
.has-open-modal .gw-layout__sticky {
  z-index: 0;
}
.has-open-modal .gw-layout__sticky--footer {
  display: none;
}
.gw-layout__sticky {
  z-index: 1;
}
/*
----------------------------------------------------------------------------
Add scroll margin so content is not hidden below sticky header.
----------------------------------------------------------------------------
*/
@media (min-width: 64em) {
  .has-scroll-margin {
    scroll-margin: 8rem 0 0;
  }
}
/*
----------------------------------------------------------------------------
Applies same styling as ':disabled' elements (e.g. to a form field that has a disabled input inside it).
----------------------------------------------------------------------------
*/
.is-disabled,
.is-disabled * {
  cursor: not-allowed;
  opacity: 0.8;
}
.is-disabled,
.is-disabled * {
  opacity: 1;
}
/*
----------------------------------------------------------------------------
Classes generated by CMS templates.

TODO:
- We might need to add a Storybook page?
----------------------------------------------------------------------------
*/
.gw-cms {
  overflow-x: hidden;
}
.gw-cms + * {
  -webkit-margin-before: 3.75rem;
          margin-top: 3.75rem;
}
.gw-cms__smallprint {
  font-size: 0.6875rem;
}
.gw-cms__smallprint p:not([class]),
.gw-cms__smallprint li:not([class]) {
  max-width: none;
}
@media (min-width: 64em) {
  .gw-cms__content-text [id] {
    scroll-margin: 8rem 0 0;
  }
}
.gw-cms__content-image {
  -webkit-margin-after: 1.875rem;
          margin-bottom: 1.875rem;
}
.gw-cms__content-image .gw-image {
  min-width: 100%;
}
@media screen {
  /*
  ----------------------------------------------------------------------------
  Components & pages.
  ----------------------------------------------------------------------------
  */
  [dir="ltr"] .gw-banner__overlay--mf-search{
    left: 0;
  }
  [dir="rtl"] .gw-banner__overlay--mf-search{
    right: 0;
  }
  .gw-banner__overlay--mf-search {
    height: 100%;
    top: 0;
    inset-inline-start: 0;
    padding: 0;
    z-index: 1000;
  }
  [dir="ltr"] .gw-banner__overlay--mf-search .gw-form.gw-search--multi-franchise[id=site-search] .gw-form__input{
            margin-left: 0.375rem;
  }
  [dir="rtl"] .gw-banner__overlay--mf-search .gw-form.gw-search--multi-franchise[id=site-search] .gw-form__input{
            margin-right: 0.375rem;
  }
  .gw-banner__overlay--mf-search .gw-form.gw-search--multi-franchise[id=site-search] .gw-form__input {
            margin-inline-start: 0.375rem;
    -webkit-margin-start: 0.375rem;
  }
  .gw-banner__overlay--mf-search .gw-form__select {
    width: calc(50% - 0.325rem);
  }
  .gw-banner__overlay--mf-search .gw-button--multi-toggle {
    background-color: rgb(var(--themeColorBrand));
    border: 0.25rem solid rgb(var(--themeColorBrand));
    font-weight: 700;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    margin-block: 1.25rem;
    width: 100%;
  }
  .gw-banner__overlay--mf-search .gw-button--multi-toggle .gw-form__field {
    color: rgb(var(--themeColorTextAlt));
    width: 50%;
  }
  .gw-banner__overlay--mf-search .gw-button--multi-toggle .gw-form__field.is-selected {
    background-color: rgb(var(--themeWhite));
    color: rgb(var(--themeColorBrand));
  }
  .gw-banner__overlay--mf-search.is-displayed-on-large {
    display: none;
  }

  /*
  ----------------------------------------------------------------------------
  Grid - for internal gallery or grid layouts.
  ----------------------------------------------------------------------------
  */

  /*
  ----------------------------------------------------------------------------
  Layout - at template/page level.

  Try *NOT* to add visual styles for components inside the layout structural elements (e.g. nav, search). Instead, use separate Sass partials for doing that. This file should be for layout/structure only.
  ----------------------------------------------------------------------------
  */

  /*
  ----------------------------------------------------------------------------
  Site navigation.

  1. Using 'hoverIntent' plugin for managing header nav dropdown on :HOVER.
  2. Mobile nav behaviour is triggered via toggle button.
  3. Mobile sub-navigation is triggered by CLICK/TOUCH event on parent links.
  4. Dropdown should NOT be shown on keyboard :FOCUS as this is regarded as bad for accessibility/usability. Instead, the parent links should all go to a relevant landing page.

  By default, with JS disabled, header navigation does work, even on mobile.
  ----------------------------------------------------------------------------
  */

  /* stylelint-disable no-descending-specificity */

  /* stylelint-enable no-descending-specificity */

  /*
  ----------------------------------------------------------------------------
  ShameCSS - for hacks.
  ----------------------------------------------------------------------------
  */
  /*
  ----------------------------------------------------------------------------
  shame.css

  - Temporary hacks & one-off fixes to help us get stuff done!
  - See https://csswizardry.com/2013/04/shame-css/.
  ----------------------------------------------------------------------------
  */
  /* stylelint-disable declaration-no-important, selector-max-id */
  /*
  ----------------------------------------------------------------------------
  Cookie consent form.
  - The rendered HTML is not as per our standard <form> markup as detailed in Storybook, and the HTML is added directly via the CMS, so is not easy to update across 100s of websites.
  - There is also an inline '<style scoped>' block, so the CSS below overrides these styles to improve the form's UI consistency & accessibility.
  ----------------------------------------------------------------------------
  */

  /*
  ----------------------------------------------------------------------------
  iframes.
  - The 'seamless' class for iframe is a legacy class that is added via the CMS currently. This will be fixed properly when the time allows. This is to allow the iframe to display full width and have a manually set height with no border.
  ----------------------------------------------------------------------------
  */

  /*
  ----------------------------------------------------------------------------
  Navigation.
  - We have added a class to override the z-index issue on the navigation dropdowns when a modal is on the page. This class is added via JS when a dropdown is hovered over or when the mobile menu is interacted with by the user.
  ----------------------------------------------------------------------------
  */
}
@media screen and (min-width: 64em) {
  [dir="ltr"] .gw-banner__overlay--mf-search.is-displayed-on-large{
    left: 3.75rem;
  }
  [dir="rtl"] .gw-banner__overlay--mf-search.is-displayed-on-large{
    right: 3.75rem;
  }
  .gw-banner__overlay--mf-search.is-displayed-on-large {
    background-color: transparent;
    display: flex;
    inset-inline-start: 3.75rem;
    max-width: 50%;
    position: absolute;
  }
  .gw-banner__overlay--mf-search.is-displayed-on-large .gw-mf-banner__form {
    margin-top: auto;
    margin-bottom: auto;
    margin-block: auto;
    margin-left: 1.875rem;
    margin-right: 1.875rem;
    margin-inline: 1.875rem;
  }
  .gw-banner__overlay--mf-search.is-displayed-on-large .gw-mf-form__input--search {
    margin-top: 0;
    margin-bottom: 0;
    margin-block: 0;
  }
  .gw-banner__overlay--mf-search.is-displayed-on-large .gw-form__range__bubble {
    font-size: 0.6875rem;
    padding: 0.2rem;
  }
  .gw-banner__overlay--mf-search.is-displayed-on-large .gw-mf-form__fieldset {
    justify-content: space-between;
  }
}
@media screen {
  .gw-banner__overlay--mf-search.is-hidden-on-large {
    background-color: rgb(var(--themeWhite));
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
    margin-left: -5rem;
    margin-right: -5rem;
    margin-inline: -5rem;
  }
  .gw-banner__overlay--mf-search.is-hidden-on-large .gw-mf-banner__form.gw-mf-form {
    border-radius: 0;
    margin: 0 auto;
  }
  .gw-banner__overlay--mf-search.is-hidden-on-large .gw-mf-form__input--search {
    margin-top: 0;
    margin-bottom: 0;
    margin-block: 0;
  }
  .gw-banner__overlay--mf-search.is-hidden-on-large .gw-button-group {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-banner__overlay--mf-search.is-hidden-on-large .gw-mf-form__title {
    text-align: center;
  }
}
@media screen {
  .gw-banner__overlay--mf-search .gw-mf-form__buttons .gw-button.gw-button--text {
    background-color: transparent;
    border: 0;
    font-size: 0.6875rem;
  }
  .gw-banner__overlay--mf-search .gw-mf-form__buttons .gw-button.gw-button--text:first-child {
    color: rgb(var(--themeColorBrand));
  }
  .gw-banner__overlay--mf-search .gw-mf-form__buttons .gw-button.gw-button--text:hover:not(:disabled) {
    text-decoration: underline;
  }
}
@media screen {
  .gw-banner__overlay--mf-search.is-hidden {
    display: none;
  }
}
@media screen {
  .gw-form.gw-search--multi-franchise[id=site-search] {
    align-self: center;
    background-color: rgb(var(--themeGrey1));
    border: 0.0625rem solid rgb(var(--themeGrey2));
    border-radius: 0;
    max-height: 2.5rem;
  }
  [dir="ltr"] .gw-form.gw-search--multi-franchise[id=site-search] .gw-icon{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-form.gw-search--multi-franchise[id=site-search] .gw-icon{
            margin-left: 0.75rem;
  }
  [dir="ltr"] .gw-form.gw-search--multi-franchise[id=site-search] .gw-icon{
            margin-left: 0;
  }
  [dir="rtl"] .gw-form.gw-search--multi-franchise[id=site-search] .gw-icon{
            margin-right: 0;
  }
  .gw-form.gw-search--multi-franchise[id=site-search] .gw-icon {
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
  .gw-form.gw-search--multi-franchise[id=site-search] .gw-button {
    background-color: transparent;
  }
}
@media screen {
  .gw-mf-form {
    background-color: rgb(var(--themeWhite));
    border-radius: 1rem;
    margin: 2rem;
    padding: 1.25rem;
    width: 22rem;
  }
}
@media screen and (min-width: 64em) {
  .gw-mf-form {
    margin: auto 0;
  }
}
@media screen {
  .gw-mf-form__title {
    color: rgb(var(--themeColorBrand));
    font-size: 1.5rem;
  }
}
@media screen {
  .gw-mf-form__fieldset {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 0.75rem 0 0;
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-mf-form__fieldset:not(:last-child) {
    margin: 0.75rem 0;
  }
  .gw-mf-form__fieldset--range {
    width: 45%;
  }
  .gw-mf-form__fieldset--range .gw-form__range__label {
    width: 200%;
  }
  .gw-mf-form__fieldset--wrapped {
    flex-flow: row wrap;
  }
  .gw-mf-form__fieldset--wrapped select {
    color: rgb(var(--themeColorBrand));
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
    max-width: 280px;
    min-width: 220px;
    width: auto;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [dir="ltr"] .gw-mf-form__fieldset--wrapped select:last-child{
            margin-right: auto;
  }
  [dir="rtl"] .gw-mf-form__fieldset--wrapped select:last-child{
            margin-left: auto;
  }
  .gw-mf-form__fieldset--wrapped select:last-child {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
  }
  .gw-mf-form__fieldset--toggle {
    background: rgb(var(--themeColorBrand));
    border-radius: 20rem;
    padding: 0.75rem;
  }
  .gw-mf-form__fieldset--toggle label {
    background: transparent;
    border-radius: 20rem;
    color: rgb(var(--themeWhite));
    font-size: 1rem;
    font-weight: bold;
    min-width: 8.25rem;
    padding: 0.3125rem 0.625rem;
    text-align: center;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-mf-form__fieldset--toggle label:hover {
    cursor: pointer;
  }
  .gw-mf-form__fieldset--toggle input {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-mf-form__fieldset--toggle input:checked + label {
    background-color: rgb(var(--themeGrey1));
    box-shadow: none;
    color: rgb(var(--themeColorBrand));
  }
  .gw-mf-form__fieldset .gw-form__range--multiple {
    display: flex;
    flex-flow: row nowrap;
    margin: 0;
    width: 100%;
  }
  .gw-mf-form__fieldset .gw-form__range--multiple > * {
    min-width: auto;
  }
  .gw-mf-form__fieldset .gw-form__range--multiple .gw-form__range__label {
    color: rgb(var(--themeColorBrand));
  }
  .gw-mf-form__fieldset .gw-form__range--multiple fieldset {
    height: 0;
    margin: 0;
    padding: 0;
    width: 0;
  }
  .gw-mf-form__fieldset .gw-form__range--multiple label {
    background-color: transparent;
    max-width: 50%;
    padding: 0;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-mf-form__fieldset .gw-form__range--multiple label > span {
    -webkit-margin-after: 1rem;
            margin-bottom: 1rem;
  }
  .gw-mf-form__fieldset .gw-form__range--multiple input {
    background-color: transparent;
    border: 0;
    height: auto;
    -webkit-margin-after: 0.5rem;
            margin-bottom: 0.5rem;
    max-width: 100%;
    padding: 0;
    width: 100%;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-mf-form__fieldset .gw-form__range--multiple input::-webkit-slider-thumb {
    background-color: rgb(var(--themeColorBrand));
    border: 0;
    border-radius: 50%;
    height: 1.5rem;
    -webkit-margin-before: -10px;
    -webkit-margin-before: -0.75rem;
            margin-top: -10px;
            margin-top: -0.75rem;
    width: 1.5rem;
  }
  .gw-mf-form__fieldset .gw-form__range--multiple input::-webkit-slider-runnable-track {
    background-color: rgb(var(--themeColorBrandAlt));
    cursor: pointer;
    height: 0.25rem;
    width: 100%;
  }
  .gw-mf-form__fieldset .gw-form__range--multiple input::-moz-range-thumb {
    background-color: rgb(var(--themeColorBrand));
    border: 0;
    border-radius: 50%;
    height: 1.5rem;
    margin-top: -10px;
    width: 1.5rem;
  }
  .gw-mf-form__fieldset .gw-form__range--multiple input::-moz-range-track {
    background-color: rgb(var(--themeColorBrandAlt));
    cursor: pointer;
    height: 0.25rem;
    width: 100%;
  }
}
@media screen {
  .gw-mf-form__input {
    background: rgb(var(--themeGrey1)) 0% 0% no-repeat padding-box;
    border: 0.0625rem solid rgb(var(--themeGrey3));
    border-radius: 0.25rem;
    margin: 1.25rem 0;
    padding: 0.3125rem 1.25rem;
    width: 100%;
  }
  .gw-mf-form__input:placeholder {
    color: rgb(var(--themeColorBrand));
  }
  .gw-mf-form__input--search {
    border-radius: 20rem;
    color: rgb(var(--themeColorBrand));
  }
  [dir="ltr"] .gw-mf-form__input--search + svg{
    right: 8px;
  }
  [dir="rtl"] .gw-mf-form__input--search + svg{
    left: 8px;
  }
  .gw-mf-form__input--search + svg {
    top: 50%;
    inset-inline-end: 8px;
    position: absolute;
    transform: translateY(-50%);
  }
}
@media screen {
  [dir="ltr"] .gw-mf-form__slider{
            margin-right: 1.25rem;
  }
  [dir="rtl"] .gw-mf-form__slider{
            margin-left: 1.25rem;
  }
  .gw-mf-form__slider {
    -webkit-margin-end: 1.25rem;
            margin-inline-end: 1.25rem;
    max-width: calc(440px + 1.25rem);
    width: 100%;
  }
}
@media screen {
  .gw-mf-form h2,
.gw-mf-form h3 {
    letter-spacing: 0px;
    line-height: 1;
    text-transform: uppercase;
  }
}
@media screen {
  .gw-mf-form .gw-button--primary {
    width: 100%;
  }
}
@media screen {
  .gw-mf-form .gw-tabs__title {
    display: none;
  }
}
@media screen {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise {
    background-color: rgb(var(--themeColorHeader));
    display: grid;
    grid-template-areas: "home brand . nav" "offers search search search";
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: repeat(2, 3rem);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-block: 0.75rem;
  }
  .gw-header__brand {
    display: none;
  }

  [class*=multif] {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [class*=multif] .gw-header__brand {
    align-items: center;
    display: inline-flex;
    grid-area: brand;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] [class*=multif] .gw-header__brand{
            margin-right: 1.25rem;
  }
  [dir="rtl"] [class*=multif] .gw-header__brand{
            margin-left: 1.25rem;
  }
  [class*=multif] .gw-header__brand {
    -webkit-margin-end: 1.25rem;
            margin-inline-end: 1.25rem;
  }
}
@media screen {
  [class*=multif] .gw-header__brand .gw-image {
    flex-shrink: 1;
    max-height: 3rem;
  }
}
@media screen and (max-width: 30em) {
  [class*=multif] .gw-header__brand .gw-image {
    height: auto;
  }
}
@media screen and (min-width: 48.0625em) {
  [class*=multif] .gw-header__brand .gw-image {
    height: 2.125rem;
  }
}
@media screen {
  [class*=multif] .gw-header__home .gw-image {
    max-height: 3rem;
  }
  [class*=multif] .gw-header__home .gw-image + .gw-image {
    display: none;
  }
  [class*=multif] .gw-header__home .gw-image.is-hidden-on-large {
    /* stylelint-disable-next-line declaration-no-important */
    display: block !important;
  }
}
@media screen {
  .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise {
    grid-template-areas: "home brand nav . offers search";
    grid-template-columns: auto auto auto 4fr auto auto;
    grid-template-rows: minmax(2rem, auto);
    padding: 0;
  }
}
@media screen {
  .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__nav {
    padding: 0;
  }
}
@media screen {
  [dir="ltr"] .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__home{
            margin-right: 1.25rem;
  }
  [dir="rtl"] .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__home{
            margin-left: 1.25rem;
  }
  .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__home {
    -webkit-margin-end: 1.25rem;
            margin-inline-end: 1.25rem;
  }
}
@media screen {
  .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__offers {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    margin-block: 0.75rem;
  }
}
@media screen {
  .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__brand {
    /* stylelint-disable-next-line no-descending-specificity */
  }
  .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__brand .gw-image {
    height: 1.625rem;
  }
}
@media screen {
  .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise__brand {
    /* stylelint-disable no-descending-specificity */
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    /* stylelint-enable no-descending-specificity */
  }
  .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise__brand .gw-image {
    height: 1.625rem;
  }
  .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise__brand .gw-image.is-hidden-on-large {
    /* stylelint-disable-next-line declaration-no-important */
    display: block !important;
  }
  .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise__brand .gw-image + .gw-image {
    display: none;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise {
    grid-template-areas: "home brand nav offers" "search search search search";
    grid-template-columns: auto auto 4fr auto;
    grid-template-rows: repeat(2, minmax(4rem, auto));
    -webkit-padding-after: 0;
            padding-bottom: 0;
  }
}
@media screen and (min-width: 64em) {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise {
    grid-template-areas: "home brand nav offers search";
    grid-template-columns: auto auto 4fr auto auto;
    grid-template-rows: minmax(4rem, auto);
    -webkit-padding-after: 0.75rem;
            padding-bottom: 0.75rem;
  }
}
@media screen {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__offers {
    align-self: center;
    background-color: rgb(var(--themeColorHeaderButton1));
    border-radius: 2rem;
    color: rgb(var(--themeColorHeaderButton2));
    margin: auto 0.75rem;
    max-height: 2.5rem;
    padding: 0.75rem 1.25rem;
  }
}
@media screen and (max-width: 48em) {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__offers {
    background-color: rgb(var(--themeColorHeaderButton2));
    color: rgb(var(--themeColorHeaderButton1));
    padding: 0.75rem;
  }
}
@media screen {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__offers:hover {
    /* stylelint-disable-next-line max-nesting-depth */
    background-color: rgb(var(--themeColorHeaderButton2));
    color: rgb(var(--themeColorHeaderButton1));
    opacity: 1;
  }
}
@media screen and (max-width: 48em) {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__offers:hover {
    background-color: rgb(var(--themeColorHeaderButton1));
    color: rgb(var(--themeColorHeaderButton2));
    padding: 0.75rem;
  }
}
@media screen {
  [dir="ltr"] .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__search{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__search{
            margin-left: 0.75rem;
  }
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__search {
    margin-top: auto;
    margin-bottom: auto;
    margin-block: auto;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
    max-height: 2.5rem;
  }
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__search .gw-form.gw-search--multi-franchise[id=site-search] {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__search .gw-form.gw-search--multi-franchise[id=site-search]{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__search .gw-form.gw-search--multi-franchise[id=site-search]{
            margin-right: 0.75rem;
  }
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__search .gw-form.gw-search--multi-franchise[id=site-search] {
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
}
@media screen and (min-width: 64em) {
  [dir="ltr"] .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__search .gw-form.gw-search--multi-franchise[id=site-search]{
            margin-left: 0;
  }
  [dir="rtl"] .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__search .gw-form.gw-search--multi-franchise[id=site-search]{
            margin-right: 0;
  }
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__search .gw-form.gw-search--multi-franchise[id=site-search] {
            margin-inline-start: 0;
    -webkit-margin-start: 0;
    max-width: 25rem;
  }
}
@media screen {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__nav {
    align-items: center;
    display: flex;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__nav {
    padding: 1.25rem 0;
    /* stylelint-disable-next-line max-nesting-depth */
  }
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__nav .gw-navigation__item {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__home .gw-image {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    max-width: 9.375rem;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__home .gw-image {
    max-width: 15rem;
  }
}
@media screen and (max-width: 30em) {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__home .gw-image {
    height: 1.625rem;
  }
}
@media screen {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__brand .gw-image {
    /* stylelint-disable max-nesting-depth */
    flex-shrink: 1;
    height: 1.625rem;
    max-width: 9.375rem;
    -o-object-fit: contain;
       object-fit: contain;
    width: auto;
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__brand .gw-image {
    height: 2.125rem;
  }
}
@media screen {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__brand .gw-image + .gw-image {
    display: none;
  }
}
@media screen and (min-width: 64em) {
  .gw-header--multi-franchise--wrapper .gw-header.gw-header--multi-franchise .gw-header__brand .gw-image + .gw-image {
    display: block;
  }
}
@media screen {
  .gw-header--multi-franchise--wrapper .gw-header__contact-details {
    background-color: rgb(var(--themeColorPreheader));
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-block: 0.25rem;
  }
  .is-js-sticky .gw-header--multi-franchise--wrapper .gw-header__contact-details {
    display: none;
  }

  [dir="ltr"] .gw-header--multi-franchise--wrapper .gw-header__contact-details .gw-header__address,[dir="ltr"] 
.gw-header--multi-franchise--wrapper .gw-header__contact-details .gw-header__telephone{
            margin-right: 1.25rem;
  }

  [dir="rtl"] .gw-header--multi-franchise--wrapper .gw-header__contact-details .gw-header__address,[dir="rtl"] 
.gw-header--multi-franchise--wrapper .gw-header__contact-details .gw-header__telephone{
            margin-left: 1.25rem;
  }

  .gw-header--multi-franchise--wrapper .gw-header__contact-details .gw-header__address,
.gw-header--multi-franchise--wrapper .gw-header__contact-details .gw-header__telephone {
    color: rgb(var(--themeColorPreheaderFont));
    font-size: 1rem;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    -webkit-margin-end: 1.25rem;
            margin-inline-end: 1.25rem;
    text-transform: uppercase;
  }
}
@media screen {
  .gw-product-filter.gw-product-filter--multi-franchise.gw-tabs .gw-tabs__tablist {
    background-color: rgb(var(--themeGrey1));
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
  }
  .gw-product-filter.gw-product-filter--multi-franchise.gw-tabs .gw-tabs__button {
    background-color: rgb(var(--themeWhite));
    border: 0;
    border-radius: 0.1875rem;
    color: rgb(var(--themeColorText));
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    height: 3.75rem;
    margin: 0.75rem;
    padding: 0;
    width: 10rem;
  }
  .gw-product-filter.gw-product-filter--multi-franchise.gw-tabs .gw-tabs__button .gw-image {
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-inline: auto;
    max-height: 2.5rem;
    width: auto;
  }
  .gw-product-filter.gw-product-filter--multi-franchise.gw-tabs .gw-tabs__button .gw-image + span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-product-filter.gw-product-filter--multi-franchise.gw-tabs .gw-tabs__button .gw-image + span:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
  .gw-product-filter.gw-product-filter--multi-franchise.gw-tabs .gw-tabs__button[aria-selected=true] {
    background-color: rgb(var(--themeWhite));
    box-shadow: 0 0 1rem rgb(var(--themeGrey2));
  }
  .gw-product-filter.gw-product-filter--multi-franchise.gw-tabs .gw-tabs__content {
    background: transparent;
  }
}
@media screen {
  .gw-slider.gw-slider--banner--multi-franchise {
    -webkit-margin-after: 0;
            margin-bottom: 0;
    -webkit-padding-after: 0;
            padding-bottom: 0;
  }
}
@media screen and (min-width: 64em) {
  .gw-slider.gw-slider--banner--multi-franchise {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
}
@media screen {
  .gw-slider.gw-slider--banner--multi-franchise [role=tablist] {
    display: none;
  }
}
@media screen {
  .gw-slider.gw-slider--banner--multi-franchise [data-button-type] {
    border-color: rgb(var(--themeWhite));
    bottom: 2%;
  }
  .gw-slider.gw-slider--banner--multi-franchise [data-button-type]:hover {
    opacity: 1;
  }
  .gw-slider.gw-slider--banner--multi-franchise [data-button-type] .gw-icon {
    stroke: rgb(var(--themeWhite));
  }
}
@media screen {
  .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides {
    max-height: unset;
  }
  .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay {
    background-color: transparent;
    left: auto;
    right: auto;
    inset-inline: auto;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay{
    right: 3.75rem;
  }
  [dir="rtl"] .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay{
    left: 3.75rem;
  }
  [dir="ltr"] .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay{
    left: auto;
  }
  [dir="rtl"] .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay{
    right: auto;
  }
  [dir="ltr"] .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay{
    text-align: right;
  }
  [dir="rtl"] .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay{
    text-align: left;
  }
  .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay {
    inset-inline-end: 3.75rem;
    inset-inline-start: auto;
    text-align: end;
  }
}
@media screen {
  .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay.is-hidden-on-small {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    top: 50%;
    transform: translate(0%, -50%);
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay.is-hidden-on-small {
    max-width: 75%;
  }
}
@media screen and (min-width: 64em) {
  .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay.is-hidden-on-small {
    max-width: 33%;
  }
}
@media screen {
  .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay .gw-banner__text--large {
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay .gw-banner__text--large {
    font-size: 3.375rem;
  }
}
@media screen {
  .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay .gw-button[class*="--text"].gw-button--primary {
    background-color: rgb(var(--themeWhite));
    border-color: rgb(var(--themeWhite));
    color: rgb(var(--themeBlack));
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay .gw-button[class*="--text"].gw-button--primary{
            margin-left: auto;
  }
  [dir="rtl"] .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay .gw-button[class*="--text"].gw-button--primary{
            margin-right: auto;
  }
  .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay .gw-button[class*="--text"].gw-button--primary {
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
}
@media screen {
  .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay .gw-button[class*="--text"].gw-button--primary:hover {
    background-color: rgba(var(--themeWhite), 0.8);
    border-color: rgba(var(--themeWhite), 0.8);
  }
}
@media screen {
  .gw-slider.gw-slider--banner--multi-franchise .gw-slider__slides .gw-banner__overlay.is-hidden-on-medium {
    align-items: center;
    -webkit-margin-after: 2.5rem;
    -webkit-margin-before: 0.75rem;
            margin-bottom: 2.5rem;
            margin-top: 0.75rem;
    -webkit-padding-after: 3.75rem;
    -webkit-padding-before: 0.75rem;
            padding-bottom: 3.75rem;
            padding-top: 0.75rem;
    text-align: center;
  }
}
@media screen {
  .gw-layout__sticky--footer--multi-franchise {
    /* stylelint-disable max-nesting-depth */
    background-color: rgb(var(--themeColorStickyBackground));
    padding: 1.25rem;
    /* stylelint-enable max-nesting-depth */
  }
  [dir="ltr"] .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item{
            margin-right: 1.25rem;
  }
  [dir="rtl"] .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item{
            margin-left: 1.25rem;
  }
  .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item {
    flex-grow: 0;
    -webkit-margin-end: 1.25rem;
            margin-inline-end: 1.25rem;
    min-height: 0;
    /* stylelint-disable selector-max-id */
    /* stylelint-enable selector-max-id */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item {
    /* stylelint-disable selector-max-id */
    /* stylelint-enable selector-max-id */
  }
  [dir="ltr"] .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item .gw-button--text-icon:not(#back-to-top) .gw-button__inner .gw-icon{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item .gw-button--text-icon:not(#back-to-top) .gw-button__inner .gw-icon{
            margin-left: 0.75rem;
  }
  .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item .gw-button--text-icon:not(#back-to-top) .gw-button__inner .gw-icon {
    display: none;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
  .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item .gw-button--text-icon:not(#back-to-top) .gw-button__inner .gw-icon + span {
    height: auto;
    overflow: initial;
    position: initial;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
}
@media screen {
  .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item .gw-button__inner .gw-icon {
    margin: 0;
  }
  .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item .gw-button__inner .gw-icon + span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item .gw-button__inner .gw-icon + span:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
}
@media screen {
  [dir="ltr"] .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item:last-child{
            margin-left: auto;
  }
  [dir="rtl"] .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item:last-child{
            margin-right: auto;
  }
  .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item:last-child {
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
}
@media screen {
  .gw-layout__sticky--footer--multi-franchise .gw-sticky-footer__item #back-to-top {
    align-self: end;
    border-radius: 50%;
  }
}
@media screen {
  .gw-header.gw-header--geronimo {
    align-items: center;
    background-color: rgb(var(--themeColorHeader));
    display: grid;
    grid-template-areas: "home . . nav";
    grid-template-columns: auto 1fr 1fr auto;
    grid-template-rows: 4rem;
  }
  .is-js-sticky .gw-header.gw-header--geronimo {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .is-js-sticky .gw-header.gw-header--geronimo {
    background-color: rgb(var(--themeColorHeader));
    grid-template-areas: "home nav . contact demo switch";
    grid-template-columns: auto auto 1fr auto auto auto;
    grid-template-rows: 4rem;
    padding: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header.gw-header--geronimo {
    grid-template-areas: "home nav . contact demo switch";
    grid-template-columns: auto auto 1fr auto auto auto;
    grid-template-rows: minmax(4rem, auto);
  }
}
@media screen {
  .gw-header.gw-header--geronimo .gw-header__home {
    background-color: rgb(var(--themeColorBrand));
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-inline: 0;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-inline: 0.75rem;
  }
  .gw-header.gw-header--geronimo .gw-header__home .gw-image {
    max-width: 9.375rem;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
@media screen {
  .gw-header.gw-header--geronimo .gw-header__nav {
    align-items: center;
    display: flex;
    grid-column: 4;
    height: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header.gw-header--geronimo .gw-header__nav {
    grid-column: 2;
  }
}
@media screen {
  .gw-header.gw-header--geronimo .gw-header__contact {
    display: none;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-header.gw-header--geronimo .gw-header__contact{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-header.gw-header--geronimo .gw-header__contact{
            margin-left: 0.75rem;
  }
  .gw-header.gw-header--geronimo .gw-header__contact {
    display: block;
    grid-area: contact;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
    padding: 0.75rem;
  }
}
@media screen {
  .gw-header.gw-header--geronimo .gw-header__demo {
    display: none;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-header.gw-header--geronimo .gw-header__demo{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-header.gw-header--geronimo .gw-header__demo{
            margin-left: 0.75rem;
  }
  .gw-header.gw-header--geronimo .gw-header__demo {
    display: block;
    grid-area: demo;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
    padding: 0.75rem;
  }
}
@media screen {
  .gw-header.gw-header--geronimo .gw-header__language {
    display: none;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header.gw-header--geronimo .gw-header__language {
    display: block;
    grid-area: switch;
  }
}
@media screen {
  .gw-layout__sticky--footer--geronimo {
    padding: 1.25rem;
  }
  [dir="ltr"] .gw-layout__sticky--footer--geronimo .gw-sticky-footer__item{
            margin-right: 1.25rem;
  }
  [dir="rtl"] .gw-layout__sticky--footer--geronimo .gw-sticky-footer__item{
            margin-left: 1.25rem;
  }
  .gw-layout__sticky--footer--geronimo .gw-sticky-footer__item {
    flex-grow: 0;
    -webkit-margin-end: 1.25rem;
            margin-inline-end: 1.25rem;
    min-height: 0;
    /* stylelint-disable selector-max-id */
    /* stylelint-enable selector-max-id */
  }
  .gw-layout__sticky--footer--geronimo .gw-sticky-footer__item .gw-button__inner .gw-icon {
    height: 1.5em;
    margin: 0;
    width: 1.5em;
  }
  .gw-layout__sticky--footer--geronimo .gw-sticky-footer__item .gw-button__inner .gw-icon + span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-layout__sticky--footer--geronimo .gw-sticky-footer__item .gw-button__inner .gw-icon + span:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
  [dir="ltr"] .gw-layout__sticky--footer--geronimo .gw-sticky-footer__item:last-child{
            margin-left: auto;
  }
  [dir="rtl"] .gw-layout__sticky--footer--geronimo .gw-sticky-footer__item:last-child{
            margin-right: auto;
  }
  .gw-layout__sticky--footer--geronimo .gw-sticky-footer__item:last-child {
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
  .gw-layout__sticky--footer--geronimo .gw-sticky-footer__item #back-to-top {
    align-self: end;
    padding: 0.75rem;
  }
}
@media screen {
  .gw-accordion {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  [dir="ltr"] .gw-accordion .gw-icon{
            margin-left: 1.25rem;
  }
  [dir="rtl"] .gw-accordion .gw-icon{
            margin-right: 1.25rem;
  }
  .gw-accordion .gw-icon {
            margin-inline-start: 1.25rem;
    -webkit-margin-start: 1.25rem;
  }
  .gw-accordion__title {
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: unset;
    font-weight: normal;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-accordion__button {
    background-color: rgb(var(--themeWhite));
    color: rgb(var(--themeColorBrand));
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.5rem;
    line-height: 1.3;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    padding: 1.25rem;
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
    width: 100%;
  }
  [data-whatintent=mouse] .gw-accordion__button:hover, [data-whatintent=keyboard] .gw-accordion__button:focus {
    background-color: rgba(var(--themeWhite), 0.5);
    color: rgba(var(--themeColorBrand), 0.8);
  }

  [data-whatintent=keyboard] .gw-accordion__button:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] .gw-accordion__button:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] .gw-accordion__button:focus {
    outline: 0;
  }

  .gw-accordion__button:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
  .gw-accordion__button[aria-expanded=true] {
    background-color: rgb(var(--themeWhite));
    color: rgb(var(--themeColorBrand));
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-accordion__button__inner {
    display: flex;
    justify-content: space-between;
  }
  .gw-accordion__content {
    background-color: rgb(var(--themeWhite));
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    padding: 1.875rem;
    word-break: break-word;
  }
  .gw-product-filter .gw-accordion__content {
    background-color: inherit;
    padding: 0;
  }

  .gw-accordion--contact {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-accordion--contact .gw-accordion__content {
    display: grid;
    gap: 0 10%;
    grid-template-columns: 1fr 1fr;
  }
  .gw-accordion--contact .gw-accordion__row {
    gap: 0;
  }
  .gw-accordion--contact .gw-accordion__row:not(:last-child) {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
}
@media screen {
  [dir="ltr"] .gw-accordion--contact .gw-accordion__button,[dir="ltr"]  .gw-accordion--contact .gw-accordion__content{
            border-right: 0;
  }
  [dir="rtl"] .gw-accordion--contact .gw-accordion__button,[dir="rtl"]  .gw-accordion--contact .gw-accordion__content{
            border-left: 0;
  }
  [dir="ltr"] .gw-accordion--contact .gw-accordion__button,[dir="ltr"]  .gw-accordion--contact .gw-accordion__content{
            border-left: 0;
  }
  [dir="rtl"] .gw-accordion--contact .gw-accordion__button,[dir="rtl"]  .gw-accordion--contact .gw-accordion__content{
            border-right: 0;
  }
  .gw-accordion--contact .gw-accordion__button, .gw-accordion--contact .gw-accordion__content {
    -webkit-border-before: 0;
            border-top: 0;
    -webkit-border-end: 0;
            border-inline-end: 0;
            border-inline-start: 0;
    border-radius: 0;
    -webkit-border-start: 0;
    margin: 0;
  }
  .gw-accordion--contact .gw-accordion__button[aria-expanded=true], .gw-accordion--contact .gw-accordion__content[aria-expanded=true] {
    border-radius: 0;
  }
}
@media screen {
  .gw-accordion--contact .gw-accordion__button {
    padding: 2.5rem 0;
  }
}
@media screen {
  .gw-accordion--contact .gw-accordion__content {
    padding: 0;
    -webkit-padding-after: 2.5rem;
            padding-bottom: 2.5rem;
  }
}
@media screen {
  .gw-accordion--contact .gw-accordion__link {
    color: rgb(var(--themeColorText));
    text-decoration: underline;
  }
}
@media screen {
  .gw-accordion--contact .gw-accordion__row {
    display: grid;
    gap: 1.875rem;
    grid-template-columns: 1fr 1fr;
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
}
@media screen {
  .gw-accordion--contact .gw-accordion__title {
    font-size: 1.5rem;
  }
}
@media screen {
  .gw-accordion.is-js-enabled .gw-accordion__content {
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
  }
  [data-whatintent=keyboard] .gw-accordion.is-js-enabled .gw-accordion__content:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] .gw-accordion.is-js-enabled .gw-accordion__content:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] .gw-accordion.is-js-enabled .gw-accordion__content:focus {
    outline: 0;
  }

  .gw-accordion.is-js-enabled .gw-accordion__content:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
}
@media screen {
  .gw-accordion__button {
    -webkit-border-before: solid 0.0625rem rgb(var(--themeGrey3));
            border-top: solid 0.0625rem rgb(var(--themeGrey3));
    color: rgb(var(--themeColorText));
    font-size: 1rem;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-product-filter .gw-accordion__button {
    background-color: inherit;
    color: inherit;
    padding-left: 0;
    padding-right: 0;
    padding-inline: 0;
  }

  [data-whatintent=mouse] .gw-accordion__button:hover, [data-whatintent=keyboard] .gw-accordion__button:focus {
    color: rgba(var(--themeColorBrandAlt), 0.8);
  }

  [data-whatintent=keyboard] .gw-accordion__button:focus {
    box-shadow: none;
    outline: 0.125rem solid rgb(var(--themeBlack));
    outline-offset: 0.375rem;
    outline-style: solid;
  }

  [data-whatintent=mouse] .gw-accordion__button:hover {
    opacity: 0.8;
  }

  .gw-accordion__button:active {
    transform: scale(0.975);
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-accordion__button {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 120em) {
  .gw-accordion__button {
    font-size: 1rem;
  }
}
@media screen {
  .gw-accordion__button .gw-icon {
    color: rgb(var(--themeColorBrandAlt));
  }
}
@media screen {
  .gw-accordion__button--toggle {
    color: rgb(var(--themeColorTextAlt));
  }
}
@media screen {
  .gw-accordion__button[aria-expanded=true] {
    color: rgb(var(--themeColorBrandAlt));
  }
}
@media screen {
  .gw-accordion__content {
    background-color: rgb(var(--themeWhite));
    color: rgb(var(--themeColorTextSecondary));
    padding: 1.25rem;
  }
  .gw-product-filter .gw-accordion__content {
    background-color: inherit;
    padding: 0;
  }

  [data-whatintent=keyboard] .gw-accordion__content:focus {
    box-shadow: none;
    outline: 0.125rem solid rgb(var(--themeBlack));
    outline-offset: 0.375rem;
    outline-style: solid;
  }

  [data-whatintent=mouse] .gw-accordion__content:hover {
    opacity: 0.8;
  }

  .gw-accordion__content:active {
    transform: scale(0.975);
  }
}
@media screen {
  [data-whatintent=keyboard] .gw-accordion.is-js-enabled .gw-accordion__content:focus {
    box-shadow: none;
    outline: 0.125rem solid rgb(var(--themeBlack));
    outline-offset: 0.375rem;
    outline-style: solid;
  }

  [data-whatintent=mouse] .gw-accordion.is-js-enabled .gw-accordion__content:hover {
    opacity: 0.8;
  }

  .gw-accordion.is-js-enabled .gw-accordion__content:active {
    transform: scale(0.975);
  }
  [data-whatintent=mouse] .gw-accordion.is-js-enabled .gw-accordion__content:active, [data-whatintent=mouse] .gw-accordion.is-js-enabled .gw-accordion__content:hover {
    opacity: 1;
    transform: none;
  }
}
@media screen {
  @-webkit-keyframes svg-animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
  @keyframes svg-animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
  @-webkit-keyframes circle-animation {
    0%, 25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }
    50%, 75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }
    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }
  @keyframes circle-animation {
    0%, 25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }
    50%, 75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }
    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }
  .gw-ajax__loader {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
  .gw-ajax__icon {
    -webkit-animation: 2s linear infinite svg-animation;
            animation: 2s linear infinite svg-animation;
  }
  .gw-ajax__icon.gw-icon {
    fill: transparent;
    height: 3rem;
    width: 3rem;
  }
  .gw-ajax__icon circle {
    -webkit-animation: 1.4s ease-in-out infinite both circle-animation;
            animation: 1.4s ease-in-out infinite both circle-animation;
    display: block;
    fill: transparent;
    stroke: currentColor;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-linecap: round;
    stroke-width: 6px;
    transform-origin: 50% 50%;
  }
}
@media screen {
  .gw-banner {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
    position: relative;
    /* stylelint-disable order/order */
    /* stylelint-enable order/order */
  }
  .gw-banner .gw-image {
    width: 100%;
  }
  .gw-banner__custom-page .gw-image {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
    min-width: 100%;
  }
}
@media screen and (min-width: 64em) {
  .gw-banner__custom-page .gw-image {
    max-height: 38.5rem;
  }
}
@media screen and (min-width: 90em) {
  .gw-banner__custom-page .gw-image {
    max-height: 44rem;
  }
}
@media screen and (min-width: 120em) {
  .gw-banner__custom-page .gw-image {
    max-height: 35rem;
  }
}
@media screen and (min-width: 160em) {
  .gw-banner__custom-page .gw-image {
    max-height: 24rem;
  }
}
@media screen {
  [dir="ltr"] .gw-banner__overlay{
    left: 0.75rem;
  }
  [dir="rtl"] .gw-banner__overlay{
    right: 0.75rem;
  }
  .gw-banner__overlay {
    align-items: flex-start;
    background-color: rgba(var(--themeColorBrand), 0.6);
    color: rgb(var(--themeColorTextAlt));
    display: flex;
    flex-direction: column;
    height: auto;
    top: 0.75rem;
    inset-inline-start: 0.75rem;
    max-width: 33%;
    padding: 1.875rem;
    position: absolute;
    width: auto;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-banner__overlay{
    left: 3.75rem;
  }
  [dir="rtl"] .gw-banner__overlay{
    right: 3.75rem;
  }
  .gw-banner__overlay {
    top: 3.75rem;
    inset-inline-start: 3.75rem;
  }
}
@media screen {
  .gw-banner__overlay > * + * {
    -webkit-margin-before: 0.75rem;
            margin-top: 0.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner__overlay > * + * {
    -webkit-margin-before: 1.25rem;
            margin-top: 1.25rem;
  }
}
@media screen {
  [dir="ltr"] .gw-banner__overlay--top-middle{
    left: 50%;
  }
  [dir="rtl"] .gw-banner__overlay--top-middle{
    right: 50%;
  }
  .gw-banner__overlay--top-middle {
    inset-inline-start: 50%;
    transform: translateX(-50%);
  }
}
@media screen {
  [dir="ltr"] .gw-banner__overlay--top-right{
    right: 0.75rem;
  }
  [dir="rtl"] .gw-banner__overlay--top-right{
    left: 0.75rem;
  }
  [dir="ltr"] .gw-banner__overlay--top-right{
    left: auto;
  }
  [dir="rtl"] .gw-banner__overlay--top-right{
    right: auto;
  }
  .gw-banner__overlay--top-right {
    inset-inline-end: 0.75rem;
    inset-inline-start: auto;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-banner__overlay--top-right{
    right: 3.75rem;
  }
  [dir="rtl"] .gw-banner__overlay--top-right{
    left: 3.75rem;
  }
  .gw-banner__overlay--top-right {
    inset-inline-end: 3.75rem;
  }
}
@media screen {
  .gw-banner__overlay--middle-left {
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen {
  [dir="ltr"] .gw-banner__overlay--middle-middle{
    left: 50%;
  }
  [dir="rtl"] .gw-banner__overlay--middle-middle{
    right: 50%;
  }
  .gw-banner__overlay--middle-middle {
    top: 50%;
    inset-inline-start: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen {
  [dir="ltr"] .gw-banner__overlay--middle-right{
    right: 0.75rem;
  }
  [dir="rtl"] .gw-banner__overlay--middle-right{
    left: 0.75rem;
  }
  [dir="ltr"] .gw-banner__overlay--middle-right{
    left: auto;
  }
  [dir="rtl"] .gw-banner__overlay--middle-right{
    right: auto;
  }
  .gw-banner__overlay--middle-right {
    top: 50%;
    inset-inline-end: 0.75rem;
    inset-inline-start: auto;
    transform: translateY(-50%);
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-banner__overlay--middle-right{
    right: 3.75rem;
  }
  [dir="rtl"] .gw-banner__overlay--middle-right{
    left: 3.75rem;
  }
  .gw-banner__overlay--middle-right {
    inset-inline-end: 3.75rem;
  }
}
@media screen {
  [dir="ltr"] .gw-banner__overlay--bottom-left{
    left: 0.75rem;
  }
  [dir="rtl"] .gw-banner__overlay--bottom-left{
    right: 0.75rem;
  }
  .gw-banner__overlay--bottom-left {
    bottom: 0.75rem;
    top: auto;
    inset-inline-start: 0.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-banner__overlay--bottom-left{
    left: 3.75rem;
  }
  [dir="rtl"] .gw-banner__overlay--bottom-left{
    right: 3.75rem;
  }
  .gw-banner__overlay--bottom-left {
    bottom: 3.75rem;
    inset-inline-start: 3.75rem;
  }
}
@media screen {
  [dir="ltr"] .gw-banner__overlay--bottom-middle{
    left: 50%;
  }
  [dir="rtl"] .gw-banner__overlay--bottom-middle{
    right: 50%;
  }
  .gw-banner__overlay--bottom-middle {
    bottom: 0.75rem;
    top: auto;
    inset-inline-start: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner__overlay--bottom-middle {
    bottom: 3.75rem;
  }
}
@media screen {
  [dir="ltr"] .gw-banner__overlay--bottom-right{
    right: 0.75rem;
  }
  [dir="rtl"] .gw-banner__overlay--bottom-right{
    left: 0.75rem;
  }
  [dir="ltr"] .gw-banner__overlay--bottom-right{
    left: auto;
  }
  [dir="rtl"] .gw-banner__overlay--bottom-right{
    right: auto;
  }
  .gw-banner__overlay--bottom-right {
    bottom: 0.75rem;
    top: auto;
    inset-inline-end: 0.75rem;
    inset-inline-start: auto;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-banner__overlay--bottom-right{
    right: 3.75rem;
  }
  [dir="rtl"] .gw-banner__overlay--bottom-right{
    left: 3.75rem;
  }
  .gw-banner__overlay--bottom-right {
    bottom: 3.75rem;
    inset-inline-end: 3.75rem;
  }
}
@media screen {
  .gw-banner__overlay--top-middle, .gw-banner__overlay--middle-middle, .gw-banner__overlay--bottom-middle {
    align-items: center;
    text-align: center;
  }
}
@media screen {
  .gw-banner__overlay .gw-button {
    font-size: 1rem;
    padding: 0.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner__overlay .gw-button {
    font-size: 1.25rem;
  }
}
@media screen {
  [dir="ltr"] .gw-banner__inner + .gw-banner__overlay{
    left: 0;
  }
  [dir="rtl"] .gw-banner__inner + .gw-banner__overlay{
    right: 0;
  }
  .gw-banner__inner + .gw-banner__overlay {
    background-color: rgba(var(--themeColorBrand), 0.6);
    top: 0;
    inset-inline-start: 0;
    margin: 1.25rem auto;
    max-width: 100%;
    position: relative;
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner__inner + .gw-banner__overlay {
    margin: 1.25rem 3.75rem;
  }
}
@media screen {
  [dir="ltr"] .gw-banner__overlay.gw-banner__overlay--block-link{
    right: 0;
  }
  [dir="rtl"] .gw-banner__overlay.gw-banner__overlay--block-link{
    left: 0;
  }
  [dir="ltr"] .gw-banner__overlay.gw-banner__overlay--block-link{
    left: 0;
  }
  [dir="rtl"] .gw-banner__overlay.gw-banner__overlay--block-link{
    right: 0;
  }
  .gw-banner__overlay.gw-banner__overlay--block-link {
    height: 100%;
    top: 0;
    inset-inline-end: 0;
    inset-inline-start: 0;
    max-width: 50%;
    text-decoration: none;
    transform: none;
  }
  .gw-banner__overlay.gw-banner__overlay--block-link:hover {
    background-color: rgb(var(--themeColorBrand));
    opacity: 1;
  }
  .gw-banner__overlay.gw-banner__overlay--block-link:active {
    transform: none;
  }
}
@media screen {
  .gw-banner__overlay--skin1:before {
    background: linear-gradient(to bottom, rgba(var(--themeBlack), 0.4), rgba(var(--themeBlack), 0));
  }
}
@media screen {
  .gw-banner__overlay--skin2:before {
    background: linear-gradient(to bottom, rgba(var(--themeWhite), 0.4), rgba(var(--themeWhite), 0));
  }
}
@media screen {
  .gw-banner__overlay--skin3:before {
    background: rgba(var(--themeBlack), 0.4);
  }
}
@media screen {
  .gw-banner__overlay--skin4:before {
    background: rgba(var(--themeWhite), 0.4);
  }
}
@media screen {
  .gw-banner__overlay--skin5:before {
    background: transparent;
  }
}
@media screen {
  .gw-banner__text {
    font-size: 0.875rem;
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner__text {
    font-size: 100%;
    font-size: clamp(1rem, 1rem + 0.2vw, 1.25rem);
    line-height: 1.6;
  }
  [dir=rtl] .gw-banner__text {
    font-size: clamp(1rem, calc(1rem + 0.2vw) * 1.125, 1.25rem);
  }
}
@media screen {
  .gw-banner__text--large {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner__text--large {
    font-size: 1.5rem;
    font-size: clamp(1.25rem, 1rem + 1.5vw, 1.5rem);
    line-height: 1.3;
  }
  [dir=rtl] .gw-banner__text--large {
    font-size: clamp(1.25rem, calc(1rem + 1.5vw) * 1.125, 1.5rem);
  }
}
@media screen {
  .gw-banner__text > * {
    margin: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner__desktop--hidden {
    display: none;
  }
}
@media screen {
  .gw-banner small {
    width: 100%;
  }
}
@media screen {
  .gw-banner--page-title {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-banner--page-title .gw-banner__title {
    background-color: rgba(var(--themeBlack), 0.6);
    color: rgb(var(--themeColorTextAlt));
    padding: 0.75rem;
  }
  .gw-banner--page-title h1 {
    color: inherit;
    font-size: 1.25rem;
    margin: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner--page-title h1 {
    font-size: 1.5rem;
    font-size: clamp(1.25rem, 1rem + 1.5vw, 1.5rem);
    line-height: 1.3;
  }
  [dir=rtl] .gw-banner--page-title h1 {
    font-size: clamp(1.25rem, calc(1rem + 1.5vw) * 1.125, 1.5rem);
  }
}
@media screen {
  .gw-banner-wrap {
    background: rgb(var(--themeGrey1));
    padding: 1.875rem;
  }
  .gw-banner-wrap__title {
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
  }
  [dir="ltr"] .gw-banner-wrap .gw-slider{
            margin-right: -1.875rem;
  }
  [dir="rtl"] .gw-banner-wrap .gw-slider{
            margin-left: -1.875rem;
  }
  [dir="ltr"] .gw-banner-wrap .gw-slider{
            margin-left: -1.875rem;
  }
  [dir="rtl"] .gw-banner-wrap .gw-slider{
            margin-right: -1.875rem;
  }
  .gw-banner-wrap .gw-slider {
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
    -webkit-margin-end: -1.875rem;
            margin-inline-end: -1.875rem;
            margin-inline-start: -1.875rem;
    -webkit-margin-start: -1.875rem;
  }
  .gw-banner-wrap .gw-banner__overlay {
    max-width: 50%;
  }
}
@media screen {
  .gw-banner--video video {
    width: 100%;
  }
}
@media screen {
  [dir="ltr"] .gw-banner--product .gw-product-specs__branch-dropdown{
            padding-right: 4.5rem;
  }
  [dir="rtl"] .gw-banner--product .gw-product-specs__branch-dropdown{
            padding-left: 4.5rem;
  }
  .gw-banner--product .gw-product-specs__branch-dropdown {
    color: rgb(var(--themeColorText));
    font-family: var(--themeFontFamilyBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.25rem;
    height: 4rem;
    -webkit-margin-after: 0.75rem;
    -webkit-margin-before: 0.75rem;
            margin-bottom: 0.75rem;
            margin-top: 0.75rem;
    -webkit-padding-end: 4.5rem;
            padding-inline-end: 4.5rem;
  }
  .gw-banner--product .gw-product-specs__price--large {
    background-color: rgba(var(--themeColorBrand), 0.5);
    display: none;
    bottom: 0;
    padding: 2.5rem;
    position: absolute;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner--product .gw-product-specs__price--large {
    display: flex;
  }
}
@media screen {
  .gw-banner--product .gw-product-specs__price--mobile {
    display: block;
    -webkit-padding-after: 0.75rem;
    -webkit-padding-before: 0.75rem;
            padding-bottom: 0.75rem;
            padding-top: 0.75rem;
    /* stylelint-disable-next-line no-descending-specificity */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner--product .gw-product-specs__price--mobile {
    display: none;
  }
}
@media screen {
  .gw-banner--product .gw-product-specs__price--mobile span,
.gw-banner--product .gw-product-specs__price--mobile strong {
    display: block;
  }
}
@media screen {
  .gw-banner--product .gw-product-specs__price--mobile .gw-product-specs__branch-dropdown {
    height: 3.5rem;
    max-width: 100%;
    text-align: center;
  }
}
@media screen {
  .gw-banner--product .gw-product-specs__price span:first-of-type {
    -webkit-margin-before: 0;
            margin-top: 0;
    text-transform: uppercase;
  }
}
@media screen {
  .gw-banner--product .gw-banner__title {
    -webkit-padding-after: 0;
            padding-bottom: 0;
  }
}
@media screen {
  .gw-banner--product .gw-product-specs__price--mobile,
.gw-banner--product .gw-banner__title {
    background-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorTextAlt));
    text-align: center;
  }
}
@media screen {
  .gw-banner--product [id=vehiclePrice] {
    font-family: var(--themeFontFamilyBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
}
@media screen {
  .gw-banner__tabs {
    position: relative;
  }
  .gw-banner__tabs--background {
    display: none;
    height: 100%;
    top: 0;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: right;
       object-position: right;
    position: absolute;
    width: 100%;
    z-index: 0;
  }
  .gw-banner__tabs--background.active {
    display: block;
  }
  .gw-banner__tabs--container {
    background: linear-gradient(180deg, rgba(var(--themeColorBrandAlt), 1) 0%, rgba(var(--themeColorBrandAlt), 1) 20%, rgba(var(--themeColorBrandAlt), 0.5) 100%);
    height: 100%;
    min-height: 850px;
    padding: 2rem;
    position: relative;
    width: 100%;
    z-index: 1;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner__tabs--container {
    background: linear-gradient(90deg, rgba(var(--themeColorBrandAlt), 1) 0%, rgba(var(--themeColorBrandAlt), 1) 20%, rgba(var(--themeColorBrandAlt), 0.5) 100%);
    padding: 8rem 2rem;
  }
}
@media screen {
  .gw-banner__tabs--tabs {
    display: flex;
    flex-flow: column nowrap;
    justify-content: left;
    margin: 0 auto;
    max-width: 95vw;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner__tabs--tabs {
    flex-flow: row nowrap;
  }
}
@media screen {
  .gw-banner__tabs--tab {
    border-bottom: 2px solid rgb(var(--themeWhite), 0.35);
    color: rgb(var(--themeGrey1));
    cursor: pointer;
    opacity: 0.7;
    padding: 1rem 0;
    position: relative;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner__tabs--tab {
    padding: 2rem;
  }
}
@media screen {
  .gw-banner__tabs--tab:not(:first-child) {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner__tabs--tab:not(:first-child) {
    margin-left: 0.5rem;
  }
}
@media screen {
  [dir="ltr"] .gw-banner__tabs--tab:after{
    left: 0;
  }
  [dir="rtl"] .gw-banner__tabs--tab:after{
    right: 0;
  }
  .gw-banner__tabs--tab:after {
    background: rgb(var(--themeGrey3));
    content: "";
    height: 2px;
    top: 100%;
    inset-inline-start: 0;
    opacity: 0.5;
    position: absolute;
    transition: width 0.2s linear;
    width: 0px;
  }
}
@media screen {
  .gw-banner__tabs--tab:hover {
    opacity: 1;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-banner__tabs--tab:hover:after {
    background: rgb(var(--themeWhite));
    opacity: 1;
    transition: width 0.2s linear;
    width: calc(100% - 2rem);
  }
}
@media screen {
  .gw-banner__tabs--tab.active {
    color: rgb(var(--themeWhite));
    opacity: 1;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-banner__tabs--tab.active:after {
    background: rgb(var(--themeWhite));
    opacity: 1;
    transition: width 0.2s linear;
    width: calc(100% - 2rem);
  }
}
@media screen {
  .gw-banner__tabs--content {
    margin: 0 auto;
    max-width: 95vw;
  }
}
@media screen {
  .gw-banner__tabs--item {
    color: rgb(var(--themeWhite));
    display: none;
    margin: 3rem 0 0 0;
    max-height: 450px;
    overflow: auto;
  }
  .gw-banner__tabs--item.active {
    display: flex;
    flex-flow: column nowrap;
  }
  .gw-banner__tabs--item h2 {
    color: rgb(var(--themeWhite));
    margin: 3rem 0;
  }
  .gw-banner__tabs--item a {
    -webkit-margin-before: 3rem;
            margin-top: 3rem;
    max-width: 200px;
    padding: 1.75rem;
    text-align: center;
    width: auto;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-banner__tabs--item a.gw-button.gw-button--text.gw-button--positive {
    background-color: rgb(var(--themeGrey1));
    border-color: rgb(var(--themeGrey1));
    color: rgb(var(--themeColorBrand));
  }
  .gw-banner__tabs--item a.gw-button.gw-button--text.gw-button--positive:hover, .gw-banner__tabs--item a.gw-button.gw-button--text.gw-button--positive:focus, .gw-banner__tabs--item a.gw-button.gw-button--text.gw-button--positive:active {
    background: rgb(var(--themeColorBrandAlt));
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorTextAlt));
  }
}
@media screen {
  .gw-slider .gw-banner {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .gw-banner__overlay {
    background-color: transparent;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner__overlay {
    max-width: 60%;
  }
}
@media screen {
  .gw-banner__overlay .gw-banner__text--large {
    font-size: 3.375rem;
    font-size: clamp(2.5rem, 1rem + 3.5vw, 3.375rem);
    line-height: 1.2;
  }
  [dir=rtl] .gw-banner__overlay .gw-banner__text--large {
    font-size: clamp(2.5rem, calc(1rem + 3.5vw) * 1.125, 3.375rem);
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-banner__overlay--skin2, .gw-banner__overlay--skin4 {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-disable-next-line no-descending-specificity */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-banner__overlay--skin2 .gw-banner__overlay, .gw-banner__overlay--skin4 .gw-banner__overlay {
    color: rgb(var(--themeColorText));
  }
  .gw-banner__overlay--skin2 .gw-button.gw-button--primary[class*="--text"], .gw-banner__overlay--skin4 .gw-button.gw-button--primary[class*="--text"] {
    border-color: rgb(var(--themeColorText));
    color: rgb(var(--themeColorText));
  }
  .gw-banner__overlay--skin2 .gw-button.gw-button--primary[class*="--text"]:hover:not(:disabled), .gw-banner__overlay--skin4 .gw-button.gw-button--primary[class*="--text"]:hover:not(:disabled) {
    border-color: rgb(var(--themeColorText));
    color: rgb(var(--themeColorText));
  }
}
@media screen {
  .gw-banner__inner + .gw-banner__overlay {
    background-color: rgba(var(--themeColorBrand), 0.8);
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .gw-banner--product .gw-product-specs__price--large {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(var(--themeBlack)) 100%);
    width: 100%;
  }
  .gw-banner--product .gw-product-specs__price--large span:first-of-type {
    text-transform: none;
  }
  .gw-banner--product .gw-product-specs__price--large [id=vehiclePrice] {
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  .gw-banner--product .gw-product-specs__price--mobile {
    background-color: rgb(var(--themeGrey5));
  }
  .gw-banner--product .gw-banner__text--large {
    font-size: 2.5rem;
    font-size: clamp(2rem, 1rem + 2.5vw, 2.5rem);
    line-height: 1.2;
  }
  [dir=rtl] .gw-banner--product .gw-banner__text--large {
    font-size: clamp(2rem, calc(1rem + 2.5vw) * 1.125, 2.5rem);
  }
  .gw-banner--product .gw-banner__title {
    background-color: rgb(var(--themeGrey5));
    -webkit-padding-after: 1.25rem;
    -webkit-padding-before: 2.5rem;
            padding-bottom: 1.25rem;
            padding-top: 2.5rem;
  }
}
@media screen {
  .gw-branch-rotator a {
    color: inherit;
    text-decoration: none;
  }
  .gw-branch-rotator a:hover {
    text-decoration: underline;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-branch-rotator__branch-details {
    display: flex;
  }
}
@media screen {
  [dir="ltr"] .gw-branch-rotator__dropdown{
            padding-right: 1.25rem;
  }
  [dir="rtl"] .gw-branch-rotator__dropdown{
            padding-left: 1.25rem;
  }
  [dir="ltr"] .gw-branch-rotator__dropdown{
            padding-left: 1.25rem;
  }
  [dir="rtl"] .gw-branch-rotator__dropdown{
            padding-right: 1.25rem;
  }
  .gw-branch-rotator__dropdown {
    background-color: rgb(var(--themeColorBackground));
    color: rgb(var(--themeColorText));
    color: initial;
    height: auto;
    max-height: calc(90vh - 8rem);
    overflow-y: auto;
    -webkit-padding-before: 0.75rem;
            padding-top: 0.75rem;
    -webkit-padding-end: 1.25rem;
            padding-inline-end: 1.25rem;
            padding-inline-start: 1.25rem;
    -webkit-padding-start: 1.25rem;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
  .gw-branch-rotator__dropdown__list .gw-accordion {
    /* stylelint-disable-next-line max-nesting-depth */
  }
  .gw-branch-rotator__dropdown__list .gw-accordion__button {
    text-align: left;
  }
  .gw-branch-rotator__dropdown__item {
    align-items: center;
    display: flex;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
}
@media screen {
  [dir="ltr"] .gw-branch-rotator__inner{
            padding-left: 1.25rem;
  }
  [dir="rtl"] .gw-branch-rotator__inner{
            padding-right: 1.25rem;
  }
  .gw-branch-rotator__inner {
    align-items: center;
    display: flex;
    height: 100%;
            padding-inline-start: 1.25rem;
    -webkit-padding-start: 1.25rem;
  }
  [dir="ltr"] .gw-branch-rotator__inner > .gw-icon,[dir="ltr"] 
.gw-branch-rotator__inner > a > .gw-icon{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-branch-rotator__inner > .gw-icon,[dir="rtl"] 
.gw-branch-rotator__inner > a > .gw-icon{
            margin-left: 0.75rem;
  }
  .gw-branch-rotator__inner > .gw-icon,
.gw-branch-rotator__inner > a > .gw-icon {
    height: 1.5rem;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
    width: 1.5rem;
  }
  .gw-branch-rotator__inner > a:first-of-type > .gw-icon {
    display: none;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-branch-rotator__inner > a:first-of-type > .gw-icon {
    display: inherit;
  }
}
@media screen {
  .gw-branch-rotator__inner > a.gw-button--icon .gw-icon {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    height: 1.5rem;
    width: 1.5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-branch-rotator__inner > a.gw-button--icon .gw-icon {
    height: 2rem;
    width: 2rem;
  }
}
@media screen {
  .gw-branch-rotator__inner > .gw-button.event-getdirections {
    padding: 0.5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-branch-rotator__inner > .gw-button.event-getdirections {
    padding: inherit;
  }
}
@media screen {
  [dir="ltr"] .gw-branch-rotator__inner > .gw-button.event-getdirections > .gw-icon{
            margin-right: 0;
  }
  [dir="rtl"] .gw-branch-rotator__inner > .gw-button.event-getdirections > .gw-icon{
            margin-left: 0;
  }
  .gw-branch-rotator__inner > .gw-button.event-getdirections > .gw-icon {
    /* stylelint-disable-next-line max-nesting-depth */
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-branch-rotator__inner > .gw-button.event-getdirections > .gw-icon{
            margin-right: inherit;
  }
  [dir="rtl"] .gw-branch-rotator__inner > .gw-button.event-getdirections > .gw-icon{
            margin-left: inherit;
  }
  .gw-branch-rotator__inner > .gw-button.event-getdirections > .gw-icon {
    -webkit-margin-end: inherit;
            margin-inline-end: inherit;
  }
}
@media screen {
  [dir="ltr"] .gw-branch-rotator__inner .gw-button{
            margin-left: auto;
  }
  [dir="rtl"] .gw-branch-rotator__inner .gw-button{
            margin-right: auto;
  }
  .gw-branch-rotator__inner .gw-button {
    height: 3rem;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-branch-rotator__inner .gw-button {
    height: 4rem;
  }
}
@media screen {
  [dir="ltr"] .gw-branch-rotator__inner .gw-button + .gw-button{
            margin-right: 0.375rem;
  }
  [dir="rtl"] .gw-branch-rotator__inner .gw-button + .gw-button{
            margin-left: 0.375rem;
  }
  [dir="ltr"] .gw-branch-rotator__inner .gw-button + .gw-button{
            margin-left: 0.375rem;
  }
  [dir="rtl"] .gw-branch-rotator__inner .gw-button + .gw-button{
            margin-right: 0.375rem;
  }
  .gw-branch-rotator__inner .gw-button + .gw-button {
    -webkit-margin-end: 0.375rem;
            margin-inline-end: 0.375rem;
            margin-inline-start: 0.375rem;
    -webkit-margin-start: 0.375rem;
  }
}
@media screen {
  [dir="ltr"] .gw-branch-rotator__link{
            margin-right: 0.375rem;
  }
  [dir="rtl"] .gw-branch-rotator__link{
            margin-left: 0.375rem;
  }
  .gw-branch-rotator__link {
    display: flex;
    flex-wrap: wrap;
    -webkit-margin-end: 0.375rem;
            margin-inline-end: 0.375rem;
    overflow: hidden;
  }
  .gw-branch-rotator__link > span {
    flex-shrink: 0;
    font-size: 0.8125rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-branch-rotator__link > span {
    font-size: inherit;
  }
}
@media screen {
  [dir="ltr"] .gw-branch-rotator__name{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-branch-rotator__name{
            margin-left: 0.75rem;
  }
  .gw-branch-rotator__name {
    font-weight: bold;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
}
@media screen {
  .gw-branch-rotator__text--open-close {
    font-size: 0.8125rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-branch-rotator__text--open-close {
    font-size: inherit;
  }
}
@media screen {
  .gw-branch-rotator [data-id=toggle-branch-rotator-dropdown] {
    display: none;
  }
}
@media screen {
  .gw-branch-rotator.gw-slider {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-branch-rotator.gw-slider [data-button-type] {
    height: 100%;
    transform: none;
    transition: none;
    width: 3rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-branch-rotator.gw-slider [data-button-type] {
    width: 4rem;
  }
}
@media screen {
  [dir="ltr"] .gw-branch-rotator.gw-slider [data-button-type=next]{
    right: 0;
  }
  [dir="rtl"] .gw-branch-rotator.gw-slider [data-button-type=next]{
    left: 0;
  }
  [dir="ltr"] .gw-branch-rotator.gw-slider [data-button-type=next]{
    left: auto;
  }
  [dir="rtl"] .gw-branch-rotator.gw-slider [data-button-type=next]{
    right: auto;
  }
  .gw-branch-rotator.gw-slider [data-button-type=next] {
    background-color: rgb(var(--themeAccentPositive));
    top: 0;
    inset-inline-end: 0;
    inset-inline-start: auto;
  }
  /* stylelint-disable max-nesting-depth */
  [data-whatintent=touch] .gw-branch-rotator.gw-slider [data-button-type=next]:focus {
    background-color: rgb(var(--themeAccentPositive));
  }

  /* stylelint-enable max-nesting-depth */
  .gw-branch-rotator.gw-slider [data-button-type=next]:hover {
    opacity: 1;
  }
}
@media screen {
  .gw-branch-rotator.gw-slider [data-button-type=previous] {
    display: none;
  }
}
@media screen {
  .gw-branch-rotator .gw-slider__slides {
    align-items: center;
    height: 100%;
    min-height: auto;
  }
}
@media screen {
  .gw-branch-rotator .gw-slider__slide {
    background-color: rgba(var(--themeAccentPositive), 0.9);
    color: rgb(var(--themeColorTextAlt));
    flex-basis: calc(100% - 3rem);
    height: 4rem;
  }
  /* stylelint-disable max-nesting-depth */
  [data-whatintent=touch] .gw-branch-rotator .gw-slider__slide:focus {
    background-color: rgba(var(--themeAccentPositive), 0.9);
  }

  /* stylelint-enable max-nesting-depth */
}
@media screen and (min-width: 48.0625em) {
  .gw-branch-rotator .gw-slider__slide {
    flex-basis: calc(100% - 4rem);
  }
}
@media screen {
  .gw-branch-rotator .gw-slider__slide:only-child {
    flex-basis: 100%;
  }
}
@media screen {
  .gw-branch-rotator[data-hotline-theme=intense] .gw-branch-rotator__hotline-slide {
    background-color: rgba(var(--themeAccentNegative), 0.9);
  }
  /* stylelint-disable max-nesting-depth */
  [data-whatintent=touch] .gw-branch-rotator[data-hotline-theme=intense] .gw-branch-rotator__hotline-slide:focus {
    background-color: rgba(var(--themeAccentNegative), 0.9);
  }

  /* stylelint-enable max-nesting-depth */
  .gw-branch-rotator[data-hotline-theme=intense] .gw-branch-rotator__hotline-button {
    background-color: rgb(var(--themeAccentNegative));
  }
  /* stylelint-disable max-nesting-depth */
  [data-whatintent=touch] .gw-branch-rotator[data-hotline-theme=intense] .gw-branch-rotator__hotline-button:focus {
    background-color: rgb(var(--themeAccentNegative));
  }

  /* stylelint-enable max-nesting-depth */
}
@media screen {
  .gw-branch-rotator .gw-accordion {
    -webkit-margin-after: 0;
            margin-bottom: 0;
    /* stylelint-disable no-descending-specificity */
  }
  [dir="ltr"] .gw-branch-rotator .gw-accordion .gw-icon{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-branch-rotator .gw-accordion .gw-icon{
            margin-left: 0.75rem;
  }
  [dir="ltr"] .gw-branch-rotator .gw-accordion .gw-icon{
            margin-left: 0;
  }
  [dir="rtl"] .gw-branch-rotator .gw-accordion .gw-icon{
            margin-right: 0;
  }
  .gw-branch-rotator .gw-accordion .gw-icon {
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
}
@media screen {
  .gw-branch-rotator .gw-button.gw-button--toggle[data-id=toggle-branch-rotator-dropdown] {
    margin: 0;
    padding: 0.5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-branch-rotator .gw-button.gw-button--toggle[data-id=toggle-branch-rotator-dropdown] {
    margin: inherit;
    padding: 1.25rem;
  }
}
@media screen {
  .gw-branch-rotator.is-js-enabled [data-id=toggle-branch-rotator-dropdown] {
    display: inline-flex;
  }
}
@media screen {
  .gw-branch-rotator {
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1rem;
  }
  .gw-branch-rotator__inner > .gw-icon {
    display: none;
  }
  .gw-branch-rotator.gw-slider {
    -webkit-padding-after: 0;
            padding-bottom: 0;
  }
  .gw-branch-rotator.gw-slider [data-button-type] {
    border-radius: 0;
  }
  .gw-branch-rotator.gw-slider [data-button-type=next] {
    background-color: rgb(var(--themeGrey4));
    color: rgb(var(--themeColorTextAlt));
  }
  /* stylelint-disable max-nesting-depth */
  [dir=rtl] .gw-branch-rotator.gw-slider [data-button-type=next] {
    transform: none;
  }

  [data-whatintent=touch] .gw-branch-rotator.gw-slider [data-button-type=next]:focus {
    background-color: rgb(var(--themeGrey4));
  }

  /* stylelint-enable max-nesting-depth */
  .gw-branch-rotator.gw-slider [data-button-type=next]:hover {
    background-color: rgb(var(--themeColorBrand));
  }
  .gw-branch-rotator .gw-slider__slide {
    background-color: rgb(var(--themeGrey4));
    color: rgb(var(--themeColorTextAlt));
  }
  [data-whatintent=touch] .gw-branch-rotator .gw-slider__slide:focus {
    background-color: rgb(var(--themeGrey4));
  }

  .gw-branch-rotator[data-hotline-theme=intense] .gw-branch-rotator__hotline-slide {
    background-color: rgb(var(--themeAccentNegative));
    color: rgb(var(--themeColorTextAlt));
  }
  /* stylelint-disable max-nesting-depth */
  [data-whatintent=touch] .gw-branch-rotator[data-hotline-theme=intense] .gw-branch-rotator__hotline-slide:focus {
    background-color: rgb(var(--themeAccentNegative));
  }

  /* stylelint-enable max-nesting-depth */
  .gw-branch-rotator[data-hotline-theme=intense] .gw-branch-rotator__hotline-button {
    background-color: rgb(var(--themeAccentNegative));
    color: rgb(var(--themeColorTextAlt));
  }
  /* stylelint-disable max-nesting-depth */
  [data-whatintent=touch] .gw-branch-rotator[data-hotline-theme=intense] .gw-branch-rotator__hotline-button:focus {
    background-color: rgb(var(--themeAccentNegative));
  }

  /* stylelint-enable max-nesting-depth */
  .gw-branch-rotator .gw-button--icon .gw-icon {
    fill: rgb(var(--themeColorTextAlt));
  }
  [dir="ltr"] .gw-branch-rotator__dropdown{
            padding-right: 0;
  }
  [dir="rtl"] .gw-branch-rotator__dropdown{
            padding-left: 0;
  }
  [dir="ltr"] .gw-branch-rotator__dropdown{
            padding-left: 0;
  }
  [dir="rtl"] .gw-branch-rotator__dropdown{
            padding-right: 0;
  }
  .gw-branch-rotator__dropdown {
    -webkit-padding-before: 0;
            padding-top: 0;
    -webkit-padding-end: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
}
@media screen {
  .gw-breadcrumb {
    background-color: rgb(var(--themeWhite));
    padding: 0 1.25rem;
  }
  .gw-breadcrumb__list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
  [dir="ltr"] .gw-breadcrumb__item{
            padding-right: 1.875rem;
  }
  [dir="rtl"] .gw-breadcrumb__item{
            padding-left: 1.875rem;
  }
  .gw-breadcrumb__item {
    font-size: 0.875rem;
    line-height: 1;
    -webkit-margin-after: 0.75rem;
    -webkit-margin-before: 0.75rem;
            margin-bottom: 0.75rem;
            margin-top: 0.75rem;
    -webkit-padding-end: 1.875rem;
            padding-inline-end: 1.875rem;
    position: relative;
  }
  [dir="ltr"] .gw-breadcrumb__item:not(:last-child):after{
    right: 0.625rem;
  }
  [dir="rtl"] .gw-breadcrumb__item:not(:last-child):after{
    left: 0.625rem;
  }
  .gw-breadcrumb__item:not(:last-child):after {
    content: ">";
    top: 0;
    inset-inline-end: 0.625rem;
    position: absolute;
  }
  .gw-breadcrumb__link {
    text-decoration: none;
  }
  .gw-breadcrumb__link:hover {
    text-decoration: underline;
  }
}
@media screen {
  .gw-breadcrumb {
    background-color: rgba(var(--themeWhite), 0.6);
  }
  [dir="ltr"] .gw-breadcrumb__item{
            padding-right: 1.25rem;
  }
  [dir="rtl"] .gw-breadcrumb__item{
            padding-left: 1.25rem;
  }
  .gw-breadcrumb__item {
    margin: 0;
    -webkit-padding-after: 0.625rem;
    -webkit-padding-before: 0.625rem;
            padding-bottom: 0.625rem;
            padding-top: 0.625rem;
    -webkit-padding-end: 1.25rem;
            padding-inline-end: 1.25rem;
  }
  [dir="ltr"] .gw-breadcrumb__item:not(:last-child):after{
    left: calc(100% - 1.25rem + 0.375rem);
  }
  [dir="rtl"] .gw-breadcrumb__item:not(:last-child):after{
    right: calc(100% - 1.25rem + 0.375rem);
  }
  .gw-breadcrumb__item:not(:last-child):after {
    color: rgb(var(--themeColorLink));
    content: "/";
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    top: calc(50% - 0.5rem);
    inset-inline-start: calc(100% - 1.25rem + 0.375rem);
    width: 0.375rem;
  }
  .gw-breadcrumb__link {
    padding: 0.625rem 0;
  }
  .gw-breadcrumb__link:not([aria-current]) {
    color: rgb(var(--themeColorLink));
  }
}
@media screen {
  .gw-button {
    color: inherit;
    display: inline-block;
    font-size: 1rem;
    line-height: 1;
    text-decoration: none;
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
  }
  [data-whatintent=keyboard] .gw-button:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] .gw-button:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] .gw-button:focus {
    outline: 0;
  }

  .gw-button:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
  [data-whatintent=mouse] .gw-button:hover {
    opacity: 0.8;
  }

  .gw-button:active {
    transform: scale(0.975);
  }
  .gw-button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  .gw-button:disabled:hover {
    opacity: 0.8;
  }
  .gw-button:disabled:active {
    transform: none;
  }
  [data-button-type] {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [dir=rtl] [data-button-type] .gw-icon {
    transform: scale(-1);
  }

  .gw-button--text, .gw-button--text-icon {
    background-color: rgb(var(--themeColorBackground));
    border: 0.0625rem solid;
    border-radius: 2rem;
    font-weight: bold;
  }
  .gw-button--text {
    padding: 0.75rem;
  }
  .gw-button--text-icon {
    padding: 0.75rem;
  }
  .gw-button--primary {
    background-color: rgb(var(--themeColorBrand));
    border-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-button--secondary {
    border-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorBrand));
  }
  .gw-button--positive {
    background-color: rgb(var(--themeAccentPositive));
    border-color: rgb(var(--themeAccentPositive));
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-button--negative {
    background-color: rgb(var(--themeAccentNegative));
    border-color: rgb(var(--themeAccentNegative));
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-button--icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    padding: 0.75rem;
  }
  .gw-button__inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  [dir="ltr"] .gw-button__inner .gw-icon{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-button__inner .gw-icon{
            margin-left: 0.75rem;
  }
  .gw-button__inner .gw-icon {
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
  [dir="ltr"] .gw-button__inner span{
    text-align: left;
  }
  [dir="rtl"] .gw-button__inner span{
    text-align: right;
  }
  .gw-button__inner span {
    text-align: start;
  }
  .gw-button__inner--reverse {
    flex-direction: row-reverse;
  }
  [dir="ltr"] .gw-button__inner--reverse .gw-icon{
            margin-right: 0;
  }
  [dir="rtl"] .gw-button__inner--reverse .gw-icon{
            margin-left: 0;
  }
  [dir="ltr"] .gw-button__inner--reverse .gw-icon{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-button__inner--reverse .gw-icon{
            margin-right: 0.75rem;
  }
  .gw-button__inner--reverse .gw-icon {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
  .gw-button--vertical .gw-button__inner {
    flex-direction: column;
  }
  [dir="ltr"] .gw-button--vertical .gw-button__inner .gw-icon{
            margin-right: 0;
  }
  [dir="rtl"] .gw-button--vertical .gw-button__inner .gw-icon{
            margin-left: 0;
  }
  .gw-button--vertical .gw-button__inner .gw-icon {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
  .gw-button--vertical .gw-button__inner span {
    text-align: center;
  }
  .gw-button--vertical .gw-button__inner--reverse {
    flex-direction: column-reverse;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-button--vertical .gw-button__inner--reverse .gw-icon {
    -webkit-margin-after: 0;
    -webkit-margin-before: 0.75rem;
            margin-bottom: 0;
            margin-top: 0.75rem;
  }
  .gw-button--small {
    font-size: 0.875rem;
    padding: 0.625rem;
  }
  .gw-button--whatsapp:not(.gw-button--secondary) {
    border: none;
    color: rgb(var(--themeColorBrand));
  }
  .gw-button--whatsapp:not(.gw-button--secondary) .gw-button__inner {
    justify-content: flex-start;
  }
  .gw-button--whatsapp:not(.gw-button--secondary):hover:not(:disabled) {
    text-decoration: underline;
  }
  .gw-button--toggle[class*="--text"] {
    background-color: transparent;
    border: 0.0625rem solid;
    border-color: rgb(var(--themeColorBrand));
    border-radius: 2em;
    color: rgb(var(--themeColorBrand));
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: normal;
    line-height: 1;
    padding: 0.75rem 1.875rem;
  }
  .gw-button--toggle[class*="--text"]:hover:not(:disabled) {
    background-color: rgb(var(--themeColorLinkAlt));
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorBrandAlt));
    text-decoration: none;
    transform: scale(1.025);
  }
  .gw-button--toggle[class*="--text"].is-selected {
    /* stylelint-disable max-nesting-depth */
    background-color: rgb(var(--themeColorBrand));
    border-color: rgb(var(--themeColorBrand));
    box-shadow: 0 0.4rem 0.4rem rgba(var(--themeBlack), 0.2);
    color: rgb(var(--themeColorTextAlt));
    /* stylelint-enable max-nesting-depth */
  }
  [data-whatintent=keyboard] .gw-button--toggle[class*="--text"].is-selected:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
  }

  .gw-button--toggle[class*="--text"].is-selected:hover:not(:disabled) {
    background-color: rgb(var(--themeColorBrandAlt));
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorTextAlt));
    opacity: 1;
    text-decoration: none;
    transform: scale(1.025);
  }
  .gw-button--toggle[class*="--text"] .gw-button__inner {
    flex-flow: row-reverse nowrap;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [dir="ltr"] .gw-button--toggle[class*="--text"] .gw-button__inner .gw-icon{
            margin-right: 0;
  }
  [dir="rtl"] .gw-button--toggle[class*="--text"] .gw-button__inner .gw-icon{
            margin-left: 0;
  }
  [dir="ltr"] .gw-button--toggle[class*="--text"] .gw-button__inner .gw-icon{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-button--toggle[class*="--text"] .gw-button__inner .gw-icon{
            margin-right: 0.75rem;
  }
  .gw-button--toggle[class*="--text"] .gw-button__inner .gw-icon {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
  .gw-button--toggle[aria-expanded=true] .gw-icon:first-of-type, .gw-button--toggle.is-selected .gw-icon:first-of-type {
    display: none;
  }
  .gw-button--toggle[aria-expanded=false] .gw-icon:last-of-type, .gw-button--toggle.is-not-selected .gw-icon:last-of-type {
    display: none;
  }
  .gw-button--multi-toggle {
    border: 0.0625rem solid rgb(var(--themeColorBrand));
    border-radius: 20rem;
    display: flex;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }
  .gw-button--multi-toggle .gw-form__field {
    border-radius: 20rem;
    color: rgb(var(--themeColorBrand));
    -webkit-margin-after: 0;
            margin-bottom: 0;
    min-width: 5rem;
    text-align: center;
  }
  .gw-button--multi-toggle .gw-form__field.is-selected {
    background-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeWhite));
  }
  .gw-button--multi-toggle .gw-form__field > .gw-form__label > span {
    align-items: center;
    display: flex;
    height: 3rem;
    justify-content: center;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    padding: 1.25rem;
  }
  .gw-button--multi-toggle input {
    display: none;
  }
  .gw-button--floating {
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-button--floating.gw-button[class*="--text"] {
    background-color: rgb(var(--themeAccentPositive));
    border-radius: 4rem;
    color: rgb(var(--themeColorTextAlt));
    display: block;
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    top: 25%;
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
    padding: 1.875rem;
    position: fixed;
    z-index: 999;
  }
  .gw-button--floating.gw-button[class*="--text"]:hover {
    text-decoration: none;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-button--floating.gw-button[class*="--text"]:hover .gw-button__inner .gw-icon {
    margin-left: 0.75rem;
  }
  .gw-button--floating.gw-button[class*="--text"]:hover .gw-button__inner span {
    height: auto;
    opacity: 1;
    width: auto;
  }
  .gw-button--floating-small {
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-button--floating-small.gw-button[class*="--text"] {
    padding: 0.75rem;
  }
  .gw-button--floating-small .gw-button__inner {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-button--floating-small .gw-button__inner .gw-icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  .gw-button--floating-small .gw-button__inner span {
    font-size: 1.25rem;
  }
  .gw-button--floating-medium {
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-button--floating-medium.gw-button[class*="--text"] {
    padding: 1.25rem;
  }
  .gw-button--floating-medium .gw-button__inner {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-button--floating-medium .gw-button__inner .gw-icon {
    height: 2rem;
    width: 2rem;
  }
  .gw-button--floating-medium .gw-button__inner span {
    font-size: 1.5rem;
  }
  .gw-button--floating-large {
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-button--floating-large.gw-button[class*="--text"] {
    padding: 1.875rem;
  }
  .gw-button--floating-large .gw-button__inner {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-button--floating-large .gw-button__inner .gw-icon {
    height: 3rem;
    width: 3rem;
  }
  .gw-button--floating-large .gw-button__inner span {
    font-size: 2rem;
  }
  .gw-button--floating .gw-button__inner .gw-icon {
    margin-left: 0;
  }
  .gw-button--floating .gw-button__inner span {
    height: 0;
    opacity: 0;
    text-transform: uppercase;
    width: 0;
  }
  [dir="ltr"] .gw-button--floating--media-inline{
    right: 0;
  }
  [dir="rtl"] .gw-button--floating--media-inline{
    left: 0;
  }
  [dir="ltr"] .gw-button--floating--media-inline{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-button--floating--media-inline{
            margin-left: 0.75rem;
  }
  .gw-button--floating--media-inline {
    inset-inline-end: 0;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
  [dir="ltr"] .gw-button--floating--media-inline-alt{
    left: 0;
  }
  [dir="rtl"] .gw-button--floating--media-inline-alt{
    right: 0;
  }
  [dir="ltr"] .gw-button--floating--media-inline-alt{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-button--floating--media-inline-alt{
            margin-right: 0.75rem;
  }
  .gw-button--floating--media-inline-alt {
    inset-inline-start: 0;
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
}
@media screen {
  .gw-button {
    letter-spacing: 0.1rem;
  }
  [data-whatintent=keyboard] .gw-button:focus {
    box-shadow: none;
    outline: 0.125rem solid rgb(var(--themeBlack));
    outline-offset: 0.375rem;
    outline-style: solid;
  }

  .gw-button:disabled {
    color: rgb(var(--themeGrey2));
  }
  .gw-button:disabled.gw-button--primary[class*="--text"], .gw-button:disabled.gw-button--secondary[class*="--text"], .gw-button:disabled.gw-button--positive[class*="--text"], .gw-button:disabled.gw-button--negative[class*="--text"] {
    background-color: rgb(var(--themeGrey2));
    border-color: rgb(var(--themeGrey2));
    box-shadow: none;
    color: rgb(var(--themeColorTextAlt));
  }
  [dir="ltr"] .gw-button[class*="--text"]{
            padding-right: 1.875rem;
  }
  [dir="rtl"] .gw-button[class*="--text"]{
            padding-left: 1.875rem;
  }
  [dir="ltr"] .gw-button[class*="--text"]{
            padding-left: 1.875rem;
  }
  [dir="rtl"] .gw-button[class*="--text"]{
            padding-right: 1.875rem;
  }
  .gw-button[class*="--text"] {
    background-color: transparent;
    border-color: transparent;
    color: rgb(var(--themeColorLink));
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: normal;
    -webkit-padding-end: 1.875rem;
            padding-inline-end: 1.875rem;
            padding-inline-start: 1.875rem;
    -webkit-padding-start: 1.875rem;
    transition: none;
  }
  [data-whatintent=keyboard] .gw-button[class*="--text"]:focus {
    box-shadow: none;
    outline: 0.125rem solid rgb(var(--themeBlack));
    outline-offset: 0.375rem;
    outline-style: solid;
  }

  .gw-button[class*="--text"].gw-button--primary, .gw-button[class*="--text"].gw-button--positive {
    background-color: rgb(var(--themeColorBrandAlt));
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorTextAlt));
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  .gw-button[class*="--text"].gw-button--primary:hover:not(:disabled), .gw-button[class*="--text"].gw-button--positive:hover:not(:disabled) {
    background-color: rgb(var(--themeColorLinkHover));
    border-color: rgb(var(--themeColorLinkHover));
    color: rgb(var(--themeColorTextAlt));
    opacity: 1;
    text-decoration: none;
    transform: scale(1);
  }
  .gw-button[class*="--text"].gw-button--secondary, .gw-button[class*="--text"].gw-button--negative {
    background-color: transparent;
    border: 0.0625rem solid;
    border-color: rgb(var(--themeColorBrandAlt));
    border-radius: 2rem;
    color: rgb(var(--themeColorBrandAlt));
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    line-height: 1;
    padding: 0.75rem 1.875rem;
  }
  .gw-button[class*="--text"].gw-button--secondary:hover:not(:disabled), .gw-button[class*="--text"].gw-button--negative:hover:not(:disabled) {
    border-color: rgb(var(--themeColorLinkHover));
    color: rgb(var(--themeColorLinkHover));
    text-decoration: none;
    transform: scale(1);
  }
  .gw-button[class*="--text"].gw-button--toggle {
    background-color: transparent;
    border-color: rgb(var(--themeGrey3));
    border-radius: 0.5rem;
    color: rgb(var(--themeColorTextSecondary));
  }
  /* stylelint-disable max-nesting-depth */
  [data-whatintent=keyboard] .gw-button[class*="--text"].gw-button--toggle:focus {
    box-shadow: none;
    outline: solid rgb(var(--themeBlack));
    outline-offset: 0.375rem;
    outline-style: solid;
  }

  /* stylelint-enable max-nesting-depth */
  .gw-button[class*="--text"].gw-button--toggle:hover:not(:disabled) {
    background-color: transparent;
    border-color: rgb(var(--themeGrey4));
    color: rgb(var(--themeColorTextSecondary));
    opacity: 1;
    transform: scale(1);
  }
  .gw-button[class*="--text"].gw-button--toggle.is-selected {
    background-color: transparent;
    border-color: rgb(var(--themeColorBrandAlt));
    border-width: 0.125rem;
    box-shadow: none;
    color: rgb(var(--themeColorText));
  }
  .gw-button[class*="--text"].gw-button--toggle.is-selected:hover {
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorText));
  }
  .gw-button[class*="--text"]:hover:not(:disabled) {
    color: rgb(var(--themeColorLinkHover));
    transition: none;
  }
  .gw-button[class*="--text"]:hover:not(:disabled).gw-button--positive, .gw-button[class*="--text"]:hover:not(:disabled).gw-button--negative {
    opacity: 1;
    text-decoration: none;
    transform: scale(1);
  }
  .gw-button[class*="--text"].gw-button--floating {
    background-color: transparent;
    border-color: rgb(var(--themeColorLink));
    color: rgb(var(--themeColorLink));
  }
  .gw-button[class*="--text"].gw-button--floating:hover:not(:disabled) {
    background-color: rgb(var(--themeColorLinkHover));
    border-color: rgb(var(--themeColorLinkHover));
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-button[class*="--text"].gw-button--floating .gw-button__inner > span {
    text-transform: unset;
  }
  [dir="ltr"] .gw-button[class*="--text"].gw-button--floating-small.gw-button[class*="--text"]{
            padding-right: 0.75rem;
  }
  [dir="rtl"] .gw-button[class*="--text"].gw-button--floating-small.gw-button[class*="--text"]{
            padding-left: 0.75rem;
  }
  [dir="ltr"] .gw-button[class*="--text"].gw-button--floating-small.gw-button[class*="--text"]{
            padding-left: 0.75rem;
  }
  [dir="rtl"] .gw-button[class*="--text"].gw-button--floating-small.gw-button[class*="--text"]{
            padding-right: 0.75rem;
  }
  .gw-button[class*="--text"].gw-button--floating-small.gw-button[class*="--text"] {
    -webkit-padding-end: 0.75rem;
            padding-inline-end: 0.75rem;
            padding-inline-start: 0.75rem;
    -webkit-padding-start: 0.75rem;
  }
  [dir="ltr"] .gw-button[class*="--text"].gw-button--floating-medium.gw-button[class*="--text"]{
            padding-right: 1.25rem;
  }
  [dir="rtl"] .gw-button[class*="--text"].gw-button--floating-medium.gw-button[class*="--text"]{
            padding-left: 1.25rem;
  }
  [dir="ltr"] .gw-button[class*="--text"].gw-button--floating-medium.gw-button[class*="--text"]{
            padding-left: 1.25rem;
  }
  [dir="rtl"] .gw-button[class*="--text"].gw-button--floating-medium.gw-button[class*="--text"]{
            padding-right: 1.25rem;
  }
  .gw-button[class*="--text"].gw-button--floating-medium.gw-button[class*="--text"] {
    -webkit-padding-end: 1.25rem;
            padding-inline-end: 1.25rem;
            padding-inline-start: 1.25rem;
    -webkit-padding-start: 1.25rem;
  }
  .gw-button--multi-toggle {
    border-color: rgb(var(--themeGrey3));
  }
  .gw-button--multi-toggle .gw-form__field {
    color: rgb(var(--themeColorText));
  }
  .gw-button--multi-toggle .gw-form__field.is-selected {
    background-color: rgb(var(--themeColorBrandAlt));
  }
  .gw-button--multi-toggle .gw-form__field.is-selected .gw-form__label {
    color: rgb(var(--themeWhite));
  }
}
@media screen {
  .gw-button-group {
    display: flex;
    flex-wrap: wrap;
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  .gw-button-group:empty {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  [dir="ltr"] .gw-button-group > *{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-button-group > *{
            margin-left: 0.75rem;
  }
  .gw-button-group > * {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
  [dir="ltr"] .gw-button-group > *:last-child{
            margin-right: 0;
  }
  [dir="rtl"] .gw-button-group > *:last-child{
            margin-left: 0;
  }
  .gw-button-group > *:last-child {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
  .gw-button-group--centered {
    justify-content: center;
  }
  .gw-button-group--toggle {
    background-color: rgb(var(--themeWhite));
    justify-content: center;
    -webkit-margin-before: 3.75rem;
            margin-top: 3.75rem;
    padding: 1.5rem;
    -webkit-padding-after: 0.75rem;
            padding-bottom: 0.75rem;
  }
  [dir="ltr"] .gw-layout__inner .gw-button-group--toggle{
            margin-right: -1.875rem;
  }
  [dir="rtl"] .gw-layout__inner .gw-button-group--toggle{
            margin-left: -1.875rem;
  }
  [dir="ltr"] .gw-layout__inner .gw-button-group--toggle{
            margin-left: -1.875rem;
  }
  [dir="rtl"] .gw-layout__inner .gw-button-group--toggle{
            margin-right: -1.875rem;
  }
  .gw-layout__inner .gw-button-group--toggle {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    -webkit-margin-end: -1.875rem;
            margin-inline-end: -1.875rem;
            margin-inline-start: -1.875rem;
    -webkit-margin-start: -1.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-layout__inner .gw-button-group--toggle{
            margin-right: -5rem;
  }
  [dir="rtl"] .gw-layout__inner .gw-button-group--toggle{
            margin-left: -5rem;
  }
  [dir="ltr"] .gw-layout__inner .gw-button-group--toggle{
            margin-left: -5rem;
  }
  [dir="rtl"] .gw-layout__inner .gw-button-group--toggle{
            margin-right: -5rem;
  }
  .gw-layout__inner .gw-button-group--toggle {
    -webkit-margin-end: -5rem;
            margin-inline-end: -5rem;
            margin-inline-start: -5rem;
    -webkit-margin-start: -5rem;
  }
}
@media screen {
  .gw-button-group--toggle__payment-options {
    background-color: transparent;
  }
  /* stylelint-disable max-nesting-depth */
  [dir="ltr"] .gw-layout__inner .gw-button-group--toggle__payment-options{
            margin-right: unset;
  }
  [dir="rtl"] .gw-layout__inner .gw-button-group--toggle__payment-options{
            margin-left: unset;
  }
  [dir="ltr"] .gw-layout__inner .gw-button-group--toggle__payment-options{
            margin-left: unset;
  }
  [dir="rtl"] .gw-layout__inner .gw-button-group--toggle__payment-options{
            margin-right: unset;
  }
  .gw-layout__inner .gw-button-group--toggle__payment-options {
    justify-content: flex-start;
    -webkit-margin-end: unset;
            margin-inline-end: unset;
            margin-inline-start: unset;
    -webkit-margin-start: unset;
    padding: 0;
  }

  /* stylelint-enable max-nesting-depth */
}
@media screen {
  .gw-card {
    display: flex;
    flex-direction: column;
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
    padding: 0.75rem;
    /* stylelint-disable order/order */
    /* stylelint-enable order/order */
  }
  [data-whatintent=mouse] .gw-card:hover, [data-whatintent=keyboard] .gw-card:focus-within, [data-whatintent=touch] .gw-card:focus-within {
    box-shadow: 0 0 0.25rem 0.25rem rgba(var(--themeColorBrand), 0.2);
    transform: scale(1.0125);
    transition: box-shadow 150ms ease-in-out, transform 150ms ease-in-out;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-card {
    width: 50%;
    width: clamp(40ch, 50%, 60ch);
  }
}
@media screen {
  .gw-card__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 60ch;
  }
}
@media screen {
  .gw-card__media {
    flex-grow: 0;
  }
}
@media screen {
  .gw-card--media-top [class$=__content] {
    -webkit-margin-before: 1.25rem;
            margin-top: 1.25rem;
    order: 2;
  }
  .gw-card--media-top [class$=__media] {
    order: 1;
  }
  .gw-card--media-top > .gw-button-group {
    order: 3;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-card--media-inline[class*="--media-inline"], .gw-card--media-inline-alt[class*="--media-inline"] {
    display: grid;
    gap: 1.25rem;
    grid-template-areas: "media content" "buttons buttons";
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    width: auto;
  }
  .gw-card--media-inline[class*="--media-inline"] .gw-card__content, .gw-card--media-inline-alt[class*="--media-inline"] .gw-card__content {
    grid-area: content;
  }
  [dir="ltr"] .gw-card--media-inline[class*="--media-inline"] .gw-card__media,[dir="ltr"]  .gw-card--media-inline-alt[class*="--media-inline"] .gw-card__media{
            margin-right: auto;
  }
  [dir="rtl"] .gw-card--media-inline[class*="--media-inline"] .gw-card__media,[dir="rtl"]  .gw-card--media-inline-alt[class*="--media-inline"] .gw-card__media{
            margin-left: auto;
  }
  .gw-card--media-inline[class*="--media-inline"] .gw-card__media, .gw-card--media-inline-alt[class*="--media-inline"] .gw-card__media {
    grid-area: media;
    -webkit-margin-end: auto;
            margin-inline-end: auto;
  }
  .gw-card--media-inline[class*="--media-inline"] .gw-button-group, .gw-card--media-inline-alt[class*="--media-inline"] .gw-button-group {
    grid-area: buttons;
    -webkit-margin-before: 0;
            margin-top: 0;
  }
  .gw-card--media-inline[class*="--media-inline-alt"], .gw-card--media-inline-alt[class*="--media-inline-alt"] {
    gap: 1.25rem;
    grid-template-areas: "content media" "buttons buttons";
  }
  [dir="ltr"] .gw-card--media-inline[class*="--media-inline-alt"] .gw-card__media,[dir="ltr"]  .gw-card--media-inline-alt[class*="--media-inline-alt"] .gw-card__media{
            margin-right: 0;
  }
  [dir="rtl"] .gw-card--media-inline[class*="--media-inline-alt"] .gw-card__media,[dir="rtl"]  .gw-card--media-inline-alt[class*="--media-inline-alt"] .gw-card__media{
            margin-left: 0;
  }
  [dir="ltr"] .gw-card--media-inline[class*="--media-inline-alt"] .gw-card__media,[dir="ltr"]  .gw-card--media-inline-alt[class*="--media-inline-alt"] .gw-card__media{
            margin-left: auto;
  }
  [dir="rtl"] .gw-card--media-inline[class*="--media-inline-alt"] .gw-card__media,[dir="rtl"]  .gw-card--media-inline-alt[class*="--media-inline-alt"] .gw-card__media{
            margin-right: auto;
  }
  .gw-card--media-inline[class*="--media-inline-alt"] .gw-card__media, .gw-card--media-inline-alt[class*="--media-inline-alt"] .gw-card__media {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
}
@media screen and (max-width: 48em) {
  .gw-card--media-inline [class$=__content], .gw-card--media-inline-alt [class$=__content] {
    -webkit-margin-before: 1.25rem;
            margin-top: 1.25rem;
    order: 2;
  }
  .gw-card--media-inline [class$=__media], .gw-card--media-inline-alt [class$=__media] {
    order: 1;
  }
  .gw-card--media-inline > .gw-button-group, .gw-card--media-inline-alt > .gw-button-group {
    order: 3;
  }
}
@media screen {
  .gw-card--media-inline .gw-card__content > :last-child, .gw-card--media-inline-alt .gw-card__content > :last-child {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .gw-card__content p:not([class]) {
    font-size: 1rem;
  }
}
@media screen {
  .gw-card__title {
    font-size: 1.5rem;
    font-size: clamp(1.25rem, 1rem + 1.5vw, 1.5rem);
    line-height: 1.3;
  }
  [class*=card-grid] .gw-card__title {
    font-size: 1.25rem;
  }

  .gw-slider .gw-card__title {
    font-size: 1.25rem;
  }

  [dir=rtl] .gw-card__title {
    font-size: clamp(1.25rem, calc(1rem + 1.5vw) * 1.125, 1.5rem);
  }
  .gw-card__title > a {
    text-decoration: none;
  }
  .gw-card__title > a:hover {
    text-decoration: underline;
  }
}
@media screen {
  .gw-card__text {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
}
@media screen {
  .gw-card__actions {
    -webkit-margin-after: 0;
    -webkit-margin-before: 1.25rem;
            margin-bottom: 0;
            margin-top: 1.25rem;
  }
}
@media screen {
  [dir="ltr"] .gw-card-slider__wrap{
            margin-right: -1.875rem;
  }
  [dir="rtl"] .gw-card-slider__wrap{
            margin-left: -1.875rem;
  }
  [dir="ltr"] .gw-card-slider__wrap{
            margin-left: -1.875rem;
  }
  [dir="rtl"] .gw-card-slider__wrap{
            margin-right: -1.875rem;
  }
  .gw-card-slider__wrap {
    background-color: rgb(var(--themeWhite));
    -webkit-margin-end: -1.875rem;
            margin-inline-end: -1.875rem;
            margin-inline-start: -1.875rem;
    -webkit-margin-start: -1.875rem;
    -webkit-padding-after: 3.75rem;
    -webkit-padding-before: 3.75rem;
            padding-bottom: 3.75rem;
            padding-top: 3.75rem;
    text-align: center;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-card-slider__wrap{
            margin-right: -5rem;
  }
  [dir="rtl"] .gw-card-slider__wrap{
            margin-left: -5rem;
  }
  [dir="ltr"] .gw-card-slider__wrap{
            margin-left: -5rem;
  }
  [dir="rtl"] .gw-card-slider__wrap{
            margin-right: -5rem;
  }
  .gw-card-slider__wrap {
    -webkit-margin-end: -5rem;
            margin-inline-end: -5rem;
            margin-inline-start: -5rem;
    -webkit-margin-start: -5rem;
  }
}
@media screen {
  [dir="ltr"] .gw-card-slider__wrap .gw-slider--card{
            margin-right: 1.875rem;
  }
  [dir="rtl"] .gw-card-slider__wrap .gw-slider--card{
            margin-left: 1.875rem;
  }
  [dir="ltr"] .gw-card-slider__wrap .gw-slider--card{
            margin-left: 1.875rem;
  }
  [dir="rtl"] .gw-card-slider__wrap .gw-slider--card{
            margin-right: 1.875rem;
  }
  .gw-card-slider__wrap .gw-slider--card {
    -webkit-margin-end: 1.875rem;
            margin-inline-end: 1.875rem;
            margin-inline-start: 1.875rem;
    -webkit-margin-start: 1.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-card-slider__wrap .gw-slider--card{
            margin-right: 5rem;
  }
  [dir="rtl"] .gw-card-slider__wrap .gw-slider--card{
            margin-left: 5rem;
  }
  [dir="ltr"] .gw-card-slider__wrap .gw-slider--card{
            margin-left: 5rem;
  }
  [dir="rtl"] .gw-card-slider__wrap .gw-slider--card{
            margin-right: 5rem;
  }
  .gw-card-slider__wrap .gw-slider--card {
    -webkit-margin-end: 5rem;
            margin-inline-end: 5rem;
            margin-inline-start: 5rem;
    -webkit-margin-start: 5rem;
  }
}
@media screen {
  [dir="ltr"] .gw-card-slider__wrap + .gw-card-slider__wrap{
            margin-right: 0;
  }
  [dir="rtl"] .gw-card-slider__wrap + .gw-card-slider__wrap{
            margin-left: 0;
  }
  [dir="ltr"] .gw-card-slider__wrap + .gw-card-slider__wrap{
            margin-left: 0;
  }
  [dir="rtl"] .gw-card-slider__wrap + .gw-card-slider__wrap{
            margin-right: 0;
  }
  .gw-card-slider__wrap + .gw-card-slider__wrap {
    background-color: transparent;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-card-slider__wrap + .gw-card-slider__wrap{
            margin-right: 0;
  }
  [dir="rtl"] .gw-card-slider__wrap + .gw-card-slider__wrap{
            margin-left: 0;
  }
  [dir="ltr"] .gw-card-slider__wrap + .gw-card-slider__wrap{
            margin-left: 0;
  }
  [dir="rtl"] .gw-card-slider__wrap + .gw-card-slider__wrap{
            margin-right: 0;
  }
  .gw-card-slider__wrap + .gw-card-slider__wrap {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
}
@media screen {
  [dir="ltr"] .gw-card-slider__wrap + .gw-card-slider__wrap .gw-slider--card{
            margin-right: 0;
  }
  [dir="rtl"] .gw-card-slider__wrap + .gw-card-slider__wrap .gw-slider--card{
            margin-left: 0;
  }
  [dir="ltr"] .gw-card-slider__wrap + .gw-card-slider__wrap .gw-slider--card{
            margin-left: 0;
  }
  [dir="rtl"] .gw-card-slider__wrap + .gw-card-slider__wrap .gw-slider--card{
            margin-right: 0;
  }
  .gw-card-slider__wrap + .gw-card-slider__wrap .gw-slider--card {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-card-slider__wrap + .gw-card-slider__wrap .gw-slider--card{
            margin-right: 0;
  }
  [dir="rtl"] .gw-card-slider__wrap + .gw-card-slider__wrap .gw-slider--card{
            margin-left: 0;
  }
  [dir="ltr"] .gw-card-slider__wrap + .gw-card-slider__wrap .gw-slider--card{
            margin-left: 0;
  }
  [dir="rtl"] .gw-card-slider__wrap + .gw-card-slider__wrap .gw-slider--card{
            margin-right: 0;
  }
  .gw-card-slider__wrap + .gw-card-slider__wrap .gw-slider--card {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
}
@media screen {
  .gw-card-slider__wrap .gw-card {
    text-align: left;
  }
}
@media screen {
  .gw-card-slider__wrap [class$="--block-link"] {
    background-color: rgb(var(--themeGrey1));
    text-align: center;
  }
}
@media screen {
  .gw-card-slider__wrap .gw-slider__slides {
    display: inline-flex;
  }
}
@media screen {
  .gw-slider .gw-card:not(.gw-card--fullwidth) {
    height: 100%;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    width: 18.5rem;
  }
}
@media screen {
  [class*=card-grid].gw-grid {
    justify-content: center;
  }
  [class*=card-grid].gw-grid > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    [class*=card-grid].gw-grid {
      grid-template-columns: repeat(auto-fit, minmax(12.5rem, 18.5rem));
    }
  }
}
@media screen {
  [class*=card-grid].gw-grid .gw-card {
    height: 100%;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    width: 100%;
  }
}
@media screen {
  .gw-card-grid__wrap .gw-grid {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  .gw-card-grid__wrap > h2 {
    text-align: center;
  }
}
@media screen {
  .gw-card--quiet {
    background-color: transparent;
    border: 0;
  }
  [data-whatintent=mouse] .gw-card--quiet:hover, [data-whatintent=keyboard] .gw-card--quiet:focus-within, [data-whatintent=touch] .gw-card--quiet:focus-within {
    box-shadow: none;
    transform: none;
    transition: none;
  }
}
@media screen {
  [dir="ltr"] .gw-card--fullwidth{
            margin-right: auto;
  }
  [dir="rtl"] .gw-card--fullwidth{
            margin-left: auto;
  }
  [dir="ltr"] .gw-card--fullwidth{
            margin-left: auto;
  }
  [dir="rtl"] .gw-card--fullwidth{
            margin-right: auto;
  }
  .gw-card--fullwidth {
    height: auto;
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
  [data-whatintent=mouse] .gw-card--fullwidth:hover, [data-whatintent=keyboard] .gw-card--fullwidth:focus-within, [data-whatintent=touch] .gw-card--fullwidth:focus-within {
    box-shadow: none;
    transform: none;
    transition: none;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-card--fullwidth {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
    max-width: var(--themeMaxContentWidth);
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-card--fullwidth[class*="--media-inline"] {
    -moz-column-gap: 3.75rem;
         column-gap: 3.75rem;
  }
}
@media screen {
  .gw-card--fullwidth .gw-card__content {
    padding: 0 1.25rem;
    width: auto;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-card--fullwidth .gw-card__content {
    padding: 0;
  }
}
@media screen {
  .gw-card {
    background-color: rgb(var(--themeWhite));
    border: 0.0625rem solid transparent;
    border-radius: 0.5rem;
  }
  [data-whatintent=mouse] .gw-card:hover, [data-whatintent=keyboard] .gw-card:focus-within, [data-whatintent=touch] .gw-card:focus-within {
    box-shadow: none;
    transform: none;
  }

  [data-whatintent=mouse] .gw-card--quiet:hover, [data-whatintent=keyboard] .gw-card--quiet:focus-within, [data-whatintent=touch] .gw-card--quiet:focus-within {
    box-shadow: none;
  }

  .gw-layout__fullbleed .gw-card--fullwidth {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-layout__fullbleed .gw-card--fullwidth{
            padding-right: 5rem;
  }
  [dir="rtl"] .gw-layout__fullbleed .gw-card--fullwidth{
            padding-left: 5rem;
  }
  [dir="ltr"] .gw-layout__fullbleed .gw-card--fullwidth{
            padding-left: 5rem;
  }
  [dir="rtl"] .gw-layout__fullbleed .gw-card--fullwidth{
            padding-right: 5rem;
  }
  .gw-layout__fullbleed .gw-card--fullwidth {
    -webkit-padding-end: 5rem;
            padding-inline-end: 5rem;
            padding-inline-start: 5rem;
    -webkit-padding-start: 5rem;
  }
}
@media screen {
  [data-whatintent=mouse] .gw-card--fullwidth:hover, [data-whatintent=keyboard] .gw-card--fullwidth:focus-within, [data-whatintent=touch] .gw-card--fullwidth:focus-within {
    box-shadow: none;
  }
}
@media screen {
  .gw-card--media-inline, .gw-card--media-inline-alt {
    background-color: rgb(var(--themeWhite));
    -webkit-padding-after: 1.875rem;
    -webkit-padding-before: 1.875rem;
            padding-bottom: 1.875rem;
            padding-top: 1.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-card--media-inline, .gw-card--media-inline-alt {
    -webkit-padding-after: 3.75rem;
    -webkit-padding-before: 3.75rem;
            padding-bottom: 3.75rem;
            padding-top: 3.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-card--media-inline .gw-card__content, .gw-card--media-inline-alt .gw-card__content {
    justify-content: center;
  }
}
@media screen {
  .gw-card__title, .gw-card__title > a:not([class]) {
    color: rgb(var(--themeColorText));
  }
}
@media screen {
  .gw-card__media img {
    border-radius: 0.5rem;
  }
}
@media screen {
  .gw-clean-car-rating,
.gw-clean-car-rating * {
    box-sizing: border-box;
  }
}
@media screen {
  .gw-clean-car-rating {
    background: rgb(var(--themeGrey1));
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 2rem auto;
    max-width: 50rem;
    padding: 0.75rem;
    width: 100%;
    /* stylelint-disable no-duplicate-selectors */
    /* stylelint-enable no-duplicate-selectors */
  }
  .gw-clean-car-rating__container {
    background-color: rgb(var(--themeWhite));
    padding: 1.875rem;
  }
  .gw-clean-car-rating__container > .gw-clean-car-rating {
    margin: 0;
    max-width: unset;
  }
  [dir="ltr"] .gw-clean-car-rating__readout{
            padding-left: 1.25rem;
  }
  [dir="rtl"] .gw-clean-car-rating__readout{
            padding-right: 1.25rem;
  }
  .gw-clean-car-rating__readout {
    background: rgb(var(--themeAccentPositiveAlt));
    color: rgb(var(--themeColorText));
    flex: 0 0 100%;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    padding: 0.75rem;
            padding-inline-start: 1.25rem;
    -webkit-padding-start: 1.25rem;
    position: relative;
  }
  [dir="ltr"] .gw-clean-car-rating__readout:before{
    left: 0;
  }
  [dir="rtl"] .gw-clean-car-rating__readout:before{
    right: 0;
  }
  .gw-clean-car-rating__readout:before {
    background: rgb(var(--themeAccentPositive));
    content: "";
    height: 100%;
    top: 0;
    inset-inline-start: 0;
    position: absolute;
    width: 0.75rem;
  }
  .gw-clean-car-rating__column {
    background: rgb(var(--themeWhite));
    flex: 0 0 100%;
    padding: 1.25rem 1.25rem 0;
  }
}
@media screen and (min-width: 850px) {
  .gw-clean-car-rating__column {
    flex: 0 0 calc(50% - 5px);
    padding: 1.25rem;
  }
}
@media screen {
  .gw-clean-car-rating__column:nth-child(3) {
    padding: 0.75rem 1.25rem 1.25rem;
  }
}
@media screen {
  .gw-clean-car-rating__column-item {
    display: flex;
    flex-flow: column;
    position: relative;
  }
  .gw-clean-car-rating__column-item:not(:last-child) {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-clean-car-rating__column-item[data-tooltip] {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [dir="ltr"] .gw-clean-car-rating__column-item[data-tooltip]:before{
    left: 100%;
  }
  [dir="rtl"] .gw-clean-car-rating__column-item[data-tooltip]:before{
    right: 100%;
  }
  .gw-clean-car-rating__column-item[data-tooltip]:before {
    background: rgb(var(--themeGrey1));
    content: attr(data-tooltip);
    display: none;
    bottom: 100%;
    inset-inline-start: 100%;
    min-width: 9.5rem;
    padding: 0.75rem;
    position: absolute;
    text-align: center;
    transform: translateX(calc(-50% - 0.75rem));
    z-index: 1;
  }
  [dir="ltr"] .gw-clean-car-rating__column-item[data-tooltip]:after{
    right: 0;
  }
  [dir="rtl"] .gw-clean-car-rating__column-item[data-tooltip]:after{
    left: 0;
  }
  [dir="ltr"] .gw-clean-car-rating__column-item[data-tooltip]:after{
            padding-left: 0;
  }
  [dir="rtl"] .gw-clean-car-rating__column-item[data-tooltip]:after{
            padding-right: 0;
  }
  .gw-clean-car-rating__column-item[data-tooltip]:after {
    background: rgb(var(--themeColorBrand));
    border-radius: 6rem;
    color: rgb(var(--themeWhite));
    content: "i";
    display: flex;
    font-family: "Times New Roman", serif;
    font-size: 1rem;
    font-weight: 900;
    height: 1.25rem;
    inset-inline-end: 0;
    justify-content: space-around;
    line-height: 1.2;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
    position: absolute;
    top: 2px;
    width: 1.25rem;
  }
  .gw-clean-car-rating__column-item[data-tooltip]:hover:before {
    display: block;
  }
  .gw-clean-car-rating__column-item--left img {
    display: none;
  }
  .gw-clean-car-rating__column-item--right {
    -webkit-margin-before: 0.3125rem;
            margin-top: 0.3125rem;
    min-height: 23px;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-clean-car-rating__column-item--right:after {
    color: rgb(var(--themeColorBrandAlt));
    display: inline-block;
    font-family: FontAwesome, sans-serif;
    font-size: 1rem;
  }
}
@media screen {
  .gw-clean-car-rating__eligibility {
    background: rgb(var(--themeWhite));
    display: flex;
    flex-flow: row wrap;
    -webkit-margin-before: 0.75rem;
            margin-top: 0.75rem;
    padding: 1.25rem;
    width: 100%;
  }
  .gw-clean-car-rating__eligibility--check {
    flex: 0 0 100%;
    -webkit-margin-before: 0.75rem;
            margin-top: 0.75rem;
  }
  .gw-clean-car-rating__eligibility :first-child {
    -webkit-margin-before: 0;
            margin-top: 0;
  }
}
@media screen {
  .gw-clean-car-rating .icon-true,
.gw-clean-car-rating .icon-false {
    position: relative;
  }
  [dir="ltr"] .gw-clean-car-rating .icon-true:after,[dir="ltr"] 
.gw-clean-car-rating .icon-false:after{
    left: 0;
  }
  [dir="rtl"] .gw-clean-car-rating .icon-true:after,[dir="rtl"] 
.gw-clean-car-rating .icon-false:after{
    right: 0;
  }
  .gw-clean-car-rating .icon-true:after,
.gw-clean-car-rating .icon-false:after {
    color: rgb(var(--themeColorBrandAlt));
    font-family: FontAwesome, sans-serif;
    font-size: 1rem;
    top: 0;
    inset-inline-start: 0;
    position: absolute;
  }
}
@media screen {
  [dir="ltr"] .gw-clean-car-rating .icon-true{
            padding-left: 1.25rem;
  }
  [dir="rtl"] .gw-clean-car-rating .icon-true{
            padding-right: 1.25rem;
  }
  .gw-clean-car-rating .icon-true {
            padding-inline-start: 1.25rem;
    -webkit-padding-start: 1.25rem;
  }
  .gw-clean-car-rating .icon-true:after {
    color: rgb(var(--themeAccentPositiveAlt));
    content: "\f14a";
  }
}
@media screen {
  [dir="ltr"] .gw-clean-car-rating .icon-false{
            padding-left: 1.25rem;
  }
  [dir="rtl"] .gw-clean-car-rating .icon-false{
            padding-right: 1.25rem;
  }
  .gw-clean-car-rating .icon-false {
            padding-inline-start: 1.25rem;
    -webkit-padding-start: 1.25rem;
  }
  .gw-clean-car-rating .icon-false:after {
    color: rgb(var(--themeAccentNegativeAlt));
    content: "\f2d3";
    opacity: 0.3;
  }
}
@media screen {
  .gw-clean-car-rating .srnone:after {
    content: "Vehicle has no rating";
    font-family: sans-serif;
  }
}
@media screen {
  .gw-clean-car-rating .sr000:after {
    content: "\f006\f006\f006\f006\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr05:after,
.gw-clean-car-rating .sr100:after {
    content: "\f123\f006\f006\f006\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr10:after,
.gw-clean-car-rating .sr200:after {
    content: "\f005\f006\f006\f006\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr15:after,
.gw-clean-car-rating .sr300:after {
    content: "\f005\f123\f006\f006\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr20:after,
.gw-clean-car-rating .sr400:after {
    content: "\f005\f005\f006\f006\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr25:after,
.gw-clean-car-rating .sr500:after {
    content: "\f005\f005\f123\f006\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr30:after,
.gw-clean-car-rating .sr600:after {
    content: "\f005\f005\f005\f006\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr35:after,
.gw-clean-car-rating .sr700:after {
    content: "\f005\f005\f005\f123\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr40:after,
.gw-clean-car-rating .sr800:after {
    content: "\f005\f005\f005\f005\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr45:after,
.gw-clean-car-rating .sr900:after {
    content: "\f005\f005\f005\f005\f123\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr50:after,
.gw-clean-car-rating .sr1000:after {
    content: "\f005\f005\f005\f005\f005\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr55:after,
.gw-clean-car-rating .sr1100:after {
    content: "\f005\f005\f005\f005\f005\f123";
  }
}
@media screen {
  .gw-clean-car-rating .sr60:after,
.gw-clean-car-rating .sr1200:after {
    content: "\f005\f005\f005\f005\f005\f005";
  }
}
@media screen {
  .gw-clean-car-rating .sr0:after,
.gw-clean-car-rating .sr00:after {
    content: "\f006\f006\f006\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr01:after {
    content: "\f123\f006\f006\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr02:after {
    content: "\f005\f006\f006\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr03:after {
    content: "\f005\f123\f006\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr04:after {
    content: "\f005\f005\f006\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr05:after {
    content: "\f005\f005\f123\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr06:after {
    content: "\f005\f005\f005\f006\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr07:after {
    content: "\f005\f005\f005\f123\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr08:after {
    content: "\f005\f005\f005\f005\f006";
  }
}
@media screen {
  .gw-clean-car-rating .sr09:after {
    content: "\f005\f005\f005\f005\f123";
  }
}
@media screen {
  .gw-clean-car-rating .sr010:after {
    content: "\f005\f005\f005\f005\f005";
  }
}
@media screen {
  .gw-clean-car-rating .sr0:after,
.gw-clean-car-rating .sr01:after,
.gw-clean-car-rating .sr02:after,
.gw-clean-car-rating .sr03:after,
.gw-clean-car-rating .sr04:after,
.gw-clean-car-rating .sr05:after,
.gw-clean-car-rating .sr06:after,
.gw-clean-car-rating .sr07:after,
.gw-clean-car-rating .sr08:after,
.gw-clean-car-rating .sr09:after,
.gw-clean-car-rating .sr010:after {
    display: inline-block;
    font-family: FontAwesome, sans-serif;
    font-size: 1rem;
  }
}
@media screen {
  .gw-clean-car-rating__readout {
    background: rgb(var(--themeAccentPositive));
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-clean-car-rating__readout:before {
    background: rgb(var(--themeAccentPositiveAlt));
  }
}
@media screen {
  .gw-contact-card {
    border: 0.0625rem solid rgb(var(--themeGrey2));
    padding: 1.875rem;
  }
  .gw-contact-card .gw-card__title {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-contact-card a:not([class]) {
    text-decoration: none;
  }
  .gw-contact-card a:not([class]):hover {
    text-decoration: underline;
  }
  .gw-contact-card .gw-button--whatsapp {
    font-weight: normal;
    padding: 0;
  }
  [dir="ltr"] .gw-contact-card .gw-button--whatsapp > .gw-button__inner .gw-icon{
            margin-right: 0.375rem;
  }
  [dir="rtl"] .gw-contact-card .gw-button--whatsapp > .gw-button__inner .gw-icon{
            margin-left: 0.375rem;
  }
  .gw-contact-card .gw-button--whatsapp > .gw-button__inner .gw-icon {
    -webkit-margin-end: 0.375rem;
            margin-inline-end: 0.375rem;
  }
  .gw-contact-card address {
    font-size: 1rem;
  }
  [dir="ltr"] .gw-contact-card .gw-ratings{
            margin-right: -1.875rem;
  }
  [dir="rtl"] .gw-contact-card .gw-ratings{
            margin-left: -1.875rem;
  }
  [dir="ltr"] .gw-contact-card .gw-ratings{
            margin-left: -1.875rem;
  }
  [dir="rtl"] .gw-contact-card .gw-ratings{
            margin-right: -1.875rem;
  }
  [dir="ltr"] .gw-contact-card .gw-ratings{
            padding-right: 1.875rem;
  }
  [dir="rtl"] .gw-contact-card .gw-ratings{
            padding-left: 1.875rem;
  }
  [dir="ltr"] .gw-contact-card .gw-ratings{
            padding-left: 1.875rem;
  }
  [dir="rtl"] .gw-contact-card .gw-ratings{
            padding-right: 1.875rem;
  }
  .gw-contact-card .gw-ratings {
    -webkit-margin-after: -1.875rem;
            margin-bottom: -1.875rem;
    -webkit-margin-end: -1.875rem;
            margin-inline-end: -1.875rem;
            margin-inline-start: -1.875rem;
    -webkit-margin-start: -1.875rem;
    -webkit-padding-end: 1.875rem;
            padding-inline-end: 1.875rem;
            padding-inline-start: 1.875rem;
    -webkit-padding-start: 1.875rem;
  }
  .gw-contact-card--person [class$=__content] {
    -webkit-margin-before: 1.25rem;
            margin-top: 1.25rem;
    order: 2;
  }
  .gw-contact-card--person [class$=__media] {
    order: 1;
  }
  .gw-contact-card--person > .gw-button-group {
    order: 3;
  }
  .gw-contact-card--person .gw-card__content {
    order: 2;
  }
}
@media screen {
  .gw-contact-card {
    background-color: rgb(var(--themeWhite));
    border: none;
    box-shadow: none;
  }
  .gw-contact-card .gw-card__actions {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
  .gw-contact-card--person {
    background-color: rgb(var(--themeGrey1));
    border: 0;
    padding: 0;
  }
  [dir="ltr"] .gw-contact-card--person .gw-card__content{
            padding-right: 1.875rem;
  }
  [dir="rtl"] .gw-contact-card--person .gw-card__content{
            padding-left: 1.875rem;
  }
  [dir="ltr"] .gw-contact-card--person .gw-card__content{
            padding-left: 1.875rem;
  }
  [dir="rtl"] .gw-contact-card--person .gw-card__content{
            padding-right: 1.875rem;
  }
  .gw-contact-card--person .gw-card__content {
    -webkit-padding-end: 1.875rem;
            padding-inline-end: 1.875rem;
            padding-inline-start: 1.875rem;
    -webkit-padding-start: 1.875rem;
    text-align: center;
  }
  .gw-contact-card--person .gw-card__content a:not([class]) {
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  [dir="ltr"] .gw-contact-card--person .gw-card__media .gw-image{
    border-bottom-right-radius: 0;
  }
  [dir="rtl"] .gw-contact-card--person .gw-card__media .gw-image{
    border-bottom-left-radius: 0;
  }
  [dir="ltr"] .gw-contact-card--person .gw-card__media .gw-image{
    border-bottom-left-radius: 0;
  }
  [dir="rtl"] .gw-contact-card--person .gw-card__media .gw-image{
    border-bottom-right-radius: 0;
  }
  [dir="ltr"] .gw-contact-card--person .gw-card__media .gw-image{
    border-top-right-radius: 0.5rem;
  }
  [dir="rtl"] .gw-contact-card--person .gw-card__media .gw-image{
    border-top-left-radius: 0.5rem;
  }
  [dir="ltr"] .gw-contact-card--person .gw-card__media .gw-image{
    border-top-left-radius: 0.5rem;
  }
  [dir="rtl"] .gw-contact-card--person .gw-card__media .gw-image{
    border-top-right-radius: 0.5rem;
  }
  .gw-contact-card--person .gw-card__media .gw-image {
    border-end-end-radius: 0;
    border-end-start-radius: 0;
    border-start-end-radius: 0.5rem;
    border-start-start-radius: 0.5rem;
    width: 100%;
  }
}
@media screen {
  [dir="ltr"] .gw-cookie-consent{
    right: 0;
  }
  [dir="rtl"] .gw-cookie-consent{
    left: 0;
  }
  [dir="ltr"] .gw-cookie-consent{
    left: 0;
  }
  [dir="rtl"] .gw-cookie-consent{
    right: 0;
  }
  .gw-cookie-consent {
    background-color: rgb(var(--themeColorBackground));
    border: 0.0625rem solid rgb(var(--themeGrey2));
    color: rgb(var(--themeColorText));
    display: none;
    bottom: 3.75rem;
    inset-inline-end: 0;
    inset-inline-start: 0;
    margin: 0 auto;
    max-width: 40rem;
    padding: 1.875rem;
    position: fixed;
    width: 95vw;
  }
  .gw-cookie-consent > span:not([class]) {
    display: block;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    text-align: center;
  }
  .gw-cookie-consent > span:not([class]) > .gw-button {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-cookie-consent .gw-button-group {
    align-items: center;
    justify-content: center;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-cookie-consent--overlay {
    margin: 0;
    max-width: inherit;
    width: inherit;
  }
  .gw-cookie-consent--overlay .gw-modal__inner {
    padding: 1.875rem;
    text-align: center;
    width: auto;
  }
  .gw-cookie-consent--overlay .gw-modal__inner .gw-button-group {
    align-items: center;
    justify-content: center;
    margin: 1.875rem 0;
  }
}
@media screen {
  .gw-cookie-consent {
    background-color: rgb(var(--themeGrey1));
  }
}
@media screen {
  .gw-counter {
    align-items: center;
    display: flex;
    padding: 0 1.875rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .gw-counter--container {
    align-items: end;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .gw-counter__text, .gw-counter__count {
    display: inline;
  }
  [dir="ltr"] .gw-counter__count{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-counter__count{
            margin-right: 0.75rem;
  }
  .gw-counter__count {
    color: rgb(var(--themeColorBrand));
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 2.5rem;
    font-size: clamp(2.5rem, 0.85rem + (2 * 3.5vw), 6rem);
    line-height: 1.2;
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
  [dir=rtl] .gw-counter__count {
    font-size: clamp(2.5rem, calc(0.85rem + (2 * 3.5vw)) * 1.125, 6rem);
  }
  .gw-counter__text {
    font-size: 100%;
    font-size: clamp(0.20625rem, 0.65rem + 0.2vw, 1rem);
    line-height: 1.6;
    text-transform: uppercase;
    transform: scale(-1);
    -ms-writing-mode: tb-lr;
        writing-mode: vertical-lr;
  }
  [dir=rtl] .gw-counter__text {
    font-size: clamp(0.20625rem, calc(0.65rem + 0.2vw) * 1.125, 1rem);
  }
}
@media screen {
  .gw-cta-card {
    /* stylelint-disable max-nesting-depth */
    background-color: rgb(var(--themeColorBrand));
    overflow: hidden;
    padding: 3.75rem 1.875rem;
    position: relative;
    width: 100%;
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-cta-card {
    padding: 3.75rem;
  }
  .gw-cta-card__content {
    width: 50%;
  }
}
@media screen {
  [dir="ltr"] .gw-cta-card__background-img{
    right: 0;
  }
  [dir="rtl"] .gw-cta-card__background-img{
    left: 0;
  }
  .gw-cta-card__background-img {
    bottom: 0;
    inset-inline-end: 0;
    position: absolute;
    transform: translateX(33%);
    width: 33%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-cta-card__background-img {
    top: 0;
    transform: none;
    width: auto;
  }
}
@media screen {
  [dir="ltr"] .gw-cta-card__button.gw-button[class*="--text"].gw-button--primary{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-cta-card__button.gw-button[class*="--text"].gw-button--primary{
            margin-left: 0.75rem;
  }
  .gw-cta-card__button.gw-button[class*="--text"].gw-button--primary {
    background-color: rgb(var(--themeGrey1));
    border-color: rgb(var(--themeGrey1));
    color: rgb(var(--themeColorBrand));
    -webkit-margin-before: 0.75rem;
            margin-top: 0.75rem;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-cta-card__button.gw-button[class*="--text"].gw-button--primary {
    -webkit-margin-before: 0;
            margin-top: 0;
  }
}
@media screen {
  .gw-cta-card__button.gw-button[class*="--text"].gw-button--primary:hover {
    background-color: rgb(var(--themeColorBrandAlt));
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorTextAlt));
  }
}
@media screen {
  .gw-cta-card.gw-card:hover {
    box-shadow: none;
    transform: none;
  }
}
@media screen {
  .gw-cta-card__content {
    display: block;
  }
}
@media screen {
  .gw-cta-card__title, .gw-cta-card__text {
    color: rgb(var(--themeWhite));
    -webkit-margin-after: 2.5rem;
            margin-bottom: 2.5rem;
  }
}
@media screen {
  .gw-feature-card {
    max-width: 30rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-feature-card {
    width: unset;
  }
}
@media screen {
  .gw-feature-card__divider, .gw-feature-card__title {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-feature-card__divider, .gw-feature-card__title {
    -webkit-margin-after: 2.5rem;
            margin-bottom: 2.5rem;
  }
}
@media screen {
  .gw-feature-card__desc {
    color: rgb(var(--themeBlack));
    font-family: var(--themeFontFamilyBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .gw-feature-card__divider {
    background-color: rgb(var(--themeColorBrand));
    height: 3px;
    width: 60px;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-feature-card__divider {
    height: 5px;
    width: 100px;
  }
}
@media screen {
  .gw-feature-card__divider + p {
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-feature-card__divider + p {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
}
@media screen {
  .gw-feature-card__feature {
    align-items: center;
    display: flex;
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
  }
  .gw-feature-card__feature:last-child {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  [dir="ltr"] .gw-feature-card__icon{
            margin-right: 1.875rem;
  }
  [dir="rtl"] .gw-feature-card__icon{
            margin-left: 1.875rem;
  }
  .gw-feature-card__icon {
    align-items: center;
    background-color: rgb(var(--themeGrey1));
    display: flex;
    height: 3rem;
    justify-content: center;
    -webkit-margin-end: 1.875rem;
            margin-inline-end: 1.875rem;
    width: 3rem;
  }
  .gw-feature-card__icon .gw-icon {
    fill: rgb(var(--themeColorBrand));
    transform: scale(1.5);
  }
}
@media screen {
  .gw-feature-card__title {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-feature-card__title {
    font-size: 2rem;
  }
}
@media screen {
  .gw-feature-card.gw-card {
    padding: 0;
  }
  .gw-feature-card.gw-card:hover {
    box-shadow: none;
    transform: none;
  }
}
@media screen {
  .gw-filter-sidebar {
    background-color: rgb(var(--themeWhite));
    height: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-filter-sidebar {
    height: auto;
    max-width: 20.625rem;
    width: 0;
  }
}
@media screen {
  .gw-filter-sidebar__accordion {
    align-items: center;
    background: rgb(var(--themeGrey1));
    border-radius: 0.1875rem;
    color: rgb(var(--themeColorText));
    cursor: pointer;
    display: flex;
    font-size: 1.25rem;
    -webkit-margin-after: 0.25rem;
            margin-bottom: 0.25rem;
    padding: 1rem;
  }
  .gw-filter-sidebar__accordion.is-disabled {
    background-color: rgb(var(--themeGrey2));
    color: rgb(var(--themeColorTextAlt));
    cursor: not-allowed;
    opacity: 0.5;
  }
  .gw-filter-sidebar__accordion-content {
    display: none;
    padding: 1.25rem;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-filter-sidebar__accordion-content.show {
    display: flex;
  }
  .gw-filter-sidebar__accordion-content.gw-filter-sidebar__accordion-content--select {
    -webkit-padding-before: 0.75rem;
            padding-top: 0.75rem;
    /* stylelint-disable-next-line max-nesting-depth */
  }
  .gw-filter-sidebar__accordion-content.gw-filter-sidebar__accordion-content--select.show {
    display: block;
  }
  .gw-filter-sidebar__accordion-content .gw-form__fieldset {
    width: 100%;
    /* stylelint-disable-next-line max-nesting-depth */
  }
  .gw-filter-sidebar__accordion-content .gw-form__fieldset--minmax {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .gw-filter-sidebar__accordion-content .gw-filter-sidebar__column {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-filter-sidebar__accordion-content .gw-filter-sidebar__column div.gw-form__field {
    background-color: transparent;
    border: none;
    border-radius: 0;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    padding: 0;
    width: 100%;
  }
  .gw-filter-sidebar__accordion-content .gw-filter-sidebar__column .gw-form__label {
    padding: 0.75rem;
    text-align: left;
  }
  [dir="ltr"] .gw-filter-sidebar__accordion-content .gw-filter-sidebar__column .gw-form__label > span{
            margin-right: auto;
  }
  [dir="rtl"] .gw-filter-sidebar__accordion-content .gw-filter-sidebar__column .gw-form__label > span{
            margin-left: auto;
  }
  .gw-filter-sidebar__accordion-content .gw-filter-sidebar__column .gw-form__label > span {
    color: rgb(var(--themeGrey3));
    font-size: 1rem;
    -webkit-margin-end: auto;
            margin-inline-end: auto;
  }
  .gw-filter-sidebar__accordion-content .gw-form__input,
.gw-filter-sidebar__accordion-content .gw-form__select {
    background-color: transparent;
    color: rgb(var(--themeColorText));
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-filter-sidebar__accordion-content .gw-form__input[type=number],
.gw-filter-sidebar__accordion-content .gw-form__select[type=number] {
    -moz-appearance: textfield;
    background: linear-gradient(90deg, rgb(var(--themeGrey1)) 2rem, transparent 2rem);
    text-indent: 1.875rem;
  }
  .gw-filter-sidebar__accordion-content .gw-form__input[type=number]::-webkit-outer-spin-button, .gw-filter-sidebar__accordion-content .gw-form__input[type=number]::-webkit-inner-spin-button,
.gw-filter-sidebar__accordion-content .gw-form__select[type=number]::-webkit-outer-spin-button,
.gw-filter-sidebar__accordion-content .gw-form__select[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .gw-filter-sidebar__accordion-content .gw-form__fieldset--checkbox.gw-form__fieldset--checkbox-custom .gw-form__input[type=checkbox]:focus {
    outline: 0.0625rem solid rgb(var(--themeColorBrand));
  }
  .gw-filter-sidebar__accordion-content .gw-form__range,
.gw-filter-sidebar__accordion-content .gw-form__range--multiple {
    width: 100%;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-filter-sidebar__accordion-content .gw-form__range .gw-form__label,
.gw-filter-sidebar__accordion-content .gw-form__range--multiple .gw-form__label {
    background-color: transparent;
    -webkit-padding-before: 0;
            padding-top: 0;
  }
  .gw-filter-sidebar__accordion-content .gw-form__range.gw-form__range--unit-conversions .gw-form__fieldset,
.gw-filter-sidebar__accordion-content .gw-form__range--multiple.gw-form__range--unit-conversions .gw-form__fieldset {
    position: inherit;
  }
  [dir="ltr"] .gw-filter-sidebar__accordion-content .gw-form__range.gw-form__range--unit-conversions .gw-form__fieldset > .gw-form__field > .gw-form__label,[dir="ltr"] 
.gw-filter-sidebar__accordion-content .gw-form__range--multiple.gw-form__range--unit-conversions .gw-form__fieldset > .gw-form__field > .gw-form__label{
            padding-left: 0;
  }
  [dir="rtl"] .gw-filter-sidebar__accordion-content .gw-form__range.gw-form__range--unit-conversions .gw-form__fieldset > .gw-form__field > .gw-form__label,[dir="rtl"] 
.gw-filter-sidebar__accordion-content .gw-form__range--multiple.gw-form__range--unit-conversions .gw-form__fieldset > .gw-form__field > .gw-form__label{
            padding-right: 0;
  }
  .gw-filter-sidebar__accordion-content .gw-form__range.gw-form__range--unit-conversions .gw-form__fieldset > .gw-form__field > .gw-form__label,
.gw-filter-sidebar__accordion-content .gw-form__range--multiple.gw-form__range--unit-conversions .gw-form__fieldset > .gw-form__field > .gw-form__label {
    -webkit-padding-before: 1.25rem;
            padding-top: 1.25rem;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
  .gw-filter-sidebar__accordion-content .gw-button--multi-toggle {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
  .gw-filter-sidebar__accordion-pricing {
    flex-wrap: wrap;
  }
  [dir="ltr"] .gw-filter-sidebar__accordion__notification{
            margin-left: 1.25rem;
  }
  [dir="rtl"] .gw-filter-sidebar__accordion__notification{
            margin-right: 1.25rem;
  }
  .gw-filter-sidebar__accordion__notification {
    align-items: center;
    background-color: rgb(var(--themeColorBrand));
    border-radius: 50%;
    color: rgb(var(--themeWhite));
    display: flex;
    font-size: 0.875rem;
    height: 1.25rem;
    justify-content: center;
            margin-inline-start: 1.25rem;
    -webkit-margin-start: 1.25rem;
    padding: 0.75rem;
    width: 1.25rem;
  }
  [dir="ltr"] .gw-filter-sidebar__accordion > .gw-icon{
            margin-left: auto;
  }
  [dir="rtl"] .gw-filter-sidebar__accordion > .gw-icon{
            margin-right: auto;
  }
  .gw-filter-sidebar__accordion > .gw-icon {
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
  .gw-filter-sidebar__accordion.active {
    margin: 0;
  }
  .gw-filter-sidebar__accordion.active > .gw-icon {
    transform: rotate(180deg);
  }
}
@media screen {
  .gw-filter-sidebar__button--reset, .gw-filter-sidebar__button--submit {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    margin-block: 1.875rem;
  }
  [dir="ltr"] .gw-filter-sidebar__button--text{
            margin-left: auto;
  }
  [dir="rtl"] .gw-filter-sidebar__button--text{
            margin-right: auto;
  }
  .gw-filter-sidebar__button--text {
    color: rgb(var(--themeColorBrandAlt));
    cursor: pointer;
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
    text-decoration: none;
  }
  .gw-filter-sidebar__button--text:hover {
    text-decoration: underline;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-filter-sidebar__content > .gw-filter-sidebar__button--submit,
.gw-filter-sidebar__content > .gw-filter-sidebar__button--reset {
    display: none;
  }
}
@media screen {
  .gw-filter-sidebar__features > .gw-accordion:nth-last-child(2) {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .gw-filter-sidebar__finance-options__info {
    width: 100%;
  }
}
@media screen {
  .gw-filter-sidebar__finance-type-button {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
}
@media screen {
  .gw-filter-sidebar__header {
    align-items: baseline;
    display: flex;
    margin: 1.875rem 0;
  }
  .gw-filter-sidebar__header--main {
    -webkit-margin-before: 3.75rem;
            margin-top: 3.75rem;
  }
  .gw-filter-sidebar__header:first-of-type {
    -webkit-margin-before: 0;
            margin-top: 0;
  }
}
@media screen {
  .gw-filter-sidebar__heading {
    color: rgb(var(--themeColorBrand));
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.25rem;
  }
}
@media screen {
  .gw-filter-sidebar .gw-form__fieldset--minmax > .gw-form__field--container {
    display: flex;
  }
}
@media screen {
  .gw-filter-sidebar__payment-filter {
    width: 100%;
  }
  .gw-filter-sidebar__payment-filter > .gw-filter-sidebar__accordion-content {
    padding: 1.25rem;
  }
  [dir="ltr"] .gw-filter-sidebar__payment-filter .gw-form__field > .gw-form__label > .gw-filter-sidebar__currency-label{
    left: 0.7rem;
  }
  [dir="rtl"] .gw-filter-sidebar__payment-filter .gw-form__field > .gw-form__label > .gw-filter-sidebar__currency-label{
    right: 0.7rem;
  }
  .gw-filter-sidebar__payment-filter .gw-form__field > .gw-form__label > .gw-filter-sidebar__currency-label {
    font-size: 1rem;
    bottom: 0.7rem;
    inset-inline-start: 0.7rem;
    position: absolute;
  }
}
@media screen {
  .gw-filter-sidebar__pricing-buttons.gw-button-group.gw-button-group--toggle {
    justify-content: inherit;
    margin: 0;
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
    padding: 0;
  }
  .gw-filter-sidebar__pricing-buttons.gw-button-group.gw-button-group--toggle .gw-button--toggle.is-selected {
    background-color: rgba(var(--themeColorBrandAlt), 0.05);
    border-color: rgb(var(--themeColorBrandAlt));
    box-shadow: none;
    color: rgb(var(--themeColorBrandAlt));
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-filter-sidebar__pricing-buttons.gw-button-group.gw-button-group--toggle .gw-button--toggle.is-selected:hover:not(:disabled) {
    background-color: rgba(var(--themeColorBrandAlt), 0.05);
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorBrandAlt));
  }
}
@media screen {
  .gw-filter-sidebar__buttons {
    align-items: center;
    background-color: rgb(var(--themeWhite));
    display: flex;
  }
  .gw-filter-sidebar__buttons .gw-filter-sidebar__button--submit, .gw-filter-sidebar__buttons .gw-filter-sidebar__button--reset {
    /* stylelint-disable-next-line max-nesting-depth */
    display: none;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-filter-sidebar__buttons .gw-filter-sidebar__button--submit, .gw-filter-sidebar__buttons .gw-filter-sidebar__button--reset {
    display: block;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    visibility: hidden;
  }
}
@media screen {
  .gw-filter-sidebar .gw-filter-sidebar__toggle {
    align-items: center;
    color: rgb(var(--themeColorBrand));
    display: flex;
  }
  .gw-filter-sidebar .gw-filter-sidebar__toggle--close {
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  [dir="ltr"] .gw-filter-sidebar .gw-filter-sidebar__toggle > .gw-icon{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-filter-sidebar .gw-filter-sidebar__toggle > .gw-icon{
            margin-left: 0.75rem;
  }
  .gw-filter-sidebar .gw-filter-sidebar__toggle > .gw-icon {
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
}
@media screen {
  .gw-filter-sidebar__toggle[data-id=products-filter-button] {
    align-items: center;
    color: rgb(var(--themeColorBrand));
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1rem;
    height: 2.5rem;
    top: 1.25rem;
    right: 0;
    bottom: 0;
    left: 1.25rem;
    inset: 1.25rem 0 0 1.25rem;
    justify-content: flex-start;
    width: auto;
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-filter-sidebar__toggle[data-id=products-filter-button] {
    transition: width 0.25s linear;
  }
}
@media screen {
  [dir="ltr"] .gw-filter-sidebar__toggle[data-id=products-filter-button].open{
            margin-right: 0;
  }
  [dir="rtl"] .gw-filter-sidebar__toggle[data-id=products-filter-button].open{
            margin-left: 0;
  }
  [dir="ltr"] .gw-filter-sidebar__toggle[data-id=products-filter-button].open{
            margin-left: 0;
  }
  [dir="rtl"] .gw-filter-sidebar__toggle[data-id=products-filter-button].open{
            margin-right: 0;
  }
  .gw-filter-sidebar__toggle[data-id=products-filter-button].open {
    background: white;
    height: 5.5rem;
    -webkit-margin-after: 0;
    -webkit-margin-before: -1.25rem;
            margin-bottom: 0;
            margin-top: -1.25rem;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
    padding: 1.25rem;
    width: 20.625rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-filter-sidebar__toggle[data-id=products-filter-button].open{
            margin-left: -5rem;
  }
  [dir="rtl"] .gw-filter-sidebar__toggle[data-id=products-filter-button].open{
            margin-right: -5rem;
  }
  [dir="ltr"] .gw-filter-sidebar__toggle[data-id=products-filter-button].open{
            padding-left: 5rem;
  }
  [dir="rtl"] .gw-filter-sidebar__toggle[data-id=products-filter-button].open{
            padding-right: 5rem;
  }
  .gw-filter-sidebar__toggle[data-id=products-filter-button].open {
            margin-inline-start: -5rem;
    -webkit-margin-start: -5rem;
            padding-inline-start: 5rem;
    -webkit-padding-start: 5rem;
    transition: width 0.25s linear;
  }
}
@media screen {
  .gw-filter-sidebar__toggle[data-id=products-filter-button].open .gw-button__inner > span {
    display: none;
  }
}
@media screen {
  .gw-filter-sidebar__toggle[data-id=products-filter-button].open:active {
    transform: scale(1);
  }
}
@media screen {
  .gw-filter-sidebar__toggle[data-id=products-filter-button].open + .gw-filter-sidebar__button--submit {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-filter-sidebar__toggle[data-id=products-filter-button].open + .gw-filter-sidebar__button--submit {
    visibility: visible;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-filter-sidebar__toggle[data-id=products-filter-button].open + .gw-filter-sidebar__button--submit + .gw-filter-sidebar__button--reset {
    visibility: visible;
  }
}
@media screen {
  .gw-filter-sidebar__toggle[data-id=products-filter-button] svg.gw-icon {
    color: rgb(var(--themeColorBrand));
    height: 1.5rem;
    stroke: rgb(var(--themeColorBrand));
    width: 1.5rem;
  }
  .gw-filter-sidebar__toggle[data-id=products-filter-button] svg.gw-icon--close {
    display: none;
  }
  .gw-filter-sidebar__toggle[data-id=products-filter-button] svg.gw-icon--filter {
    display: block;
  }
}
@media screen {
  .gw-filter-sidebar__toggle[data-id=products-filter-button] .gw-filter-sidebar__toggle--close {
    display: none;
  }
}
@media screen {
  .gw-filter-sidebar__toggle[data-id=products-filter-button] .gw-filter-sidebar__toggle--open {
    display: block;
  }
}
@media screen {
  [dir="ltr"] .gw-filter-sidebar__toggle[data-id=products-filter-button] .gw-filter-sidebar__toggle--close,[dir="ltr"] 
.gw-filter-sidebar__toggle[data-id=products-filter-button] .gw-filter-sidebar__toggle--open{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-filter-sidebar__toggle[data-id=products-filter-button] .gw-filter-sidebar__toggle--close,[dir="rtl"] 
.gw-filter-sidebar__toggle[data-id=products-filter-button] .gw-filter-sidebar__toggle--open{
            margin-right: 0.75rem;
  }
  .gw-filter-sidebar__toggle[data-id=products-filter-button] .gw-filter-sidebar__toggle--close,
.gw-filter-sidebar__toggle[data-id=products-filter-button] .gw-filter-sidebar__toggle--open {
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
}
@media screen {
  .gw-filter-sidebar + .gw-modal__overlay[id=finance-options-modal] .gw-modal__subtitle, .gw-filter-sidebar + .gw-modal__overlay[id=finance-options-modal] .gw-modal__title {
    text-transform: none;
  }
  .gw-filter-sidebar + .gw-modal__overlay[id=finance-options-modal] .gw-modal__subtitle {
    color: rgb(var(--themeGrey3));
    font-size: 1.25rem;
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
  }
  .gw-filter-sidebar + .gw-modal__overlay[id=finance-options-modal] .gw-modal__title {
    -webkit-margin-after: 2.5rem;
            margin-bottom: 2.5rem;
  }
  .gw-filter-sidebar + .gw-modal__overlay[id=finance-options-modal] .gw-modal .gw-form {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable max-nesting-depth, no-descending-specificity */
  }
  .gw-filter-sidebar + .gw-modal__overlay[id=finance-options-modal] .gw-modal .gw-form__field {
    border: 0.0625rem solid;
    border-radius: 0.1875rem;
    padding: 1.875rem;
  }
  .gw-filter-sidebar + .gw-modal__overlay[id=finance-options-modal] .gw-modal .gw-form__field .gw-button {
    display: block;
    -webkit-margin-before: 1.875rem;
            margin-top: 1.875rem;
  }
  .gw-filter-sidebar + .gw-modal__overlay[id=finance-options-modal] .gw-modal .gw-form__label {
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
  }
  .gw-filter-sidebar + .gw-modal__overlay[id=finance-options-modal] .gw-modal .gw-form__label-text {
    color: rgb(var(--themeGrey3));
    font-size: 1.25rem;
    margin: 0;
    text-transform: none;
  }
}
@media screen {
  .gw-filter-sidebar > .gw-product-search-content > *,
.gw-filter-sidebar hr {
    transform: scaleX(0);
    transform-origin: left center;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-filter-sidebar > .gw-product-search-content > *,
.gw-filter-sidebar hr {
    transition: all 0.2s linear;
  }
}
@media screen {
  .gw-filter-sidebar.show {
    height: auto;
    -webkit-margin-before: -1.25rem;
            margin-top: -1.25rem;
    overflow: auto;
    padding: 1.25rem;
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-filter-sidebar.show {
    height: calc(100vh - 4rem);
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    transition: width 0.25s linear;
  }
}
@media screen {
  .gw-filter-sidebar.show .gw-icon--close {
    display: block;
  }
}
@media screen {
  .gw-filter-sidebar.show .gw-icon--filter {
    display: none;
  }
}
@media screen {
  .gw-filter-sidebar.show .gw-filter-sidebar__toggle--close {
    display: block;
  }
}
@media screen {
  .gw-filter-sidebar.show .gw-filter-sidebar__toggle--open {
    display: none;
  }
}
@media screen {
  .gw-filter-sidebar.show > .gw-product-search-content {
    display: block;
  }
}
@media screen {
  .gw-filter-sidebar.show > .gw-product-search-content > *,
.gw-filter-sidebar.show hr {
    transform: scaleX(1);
    transform-origin: left center;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-filter-sidebar.show > .gw-product-search-content > *,
.gw-filter-sidebar.show hr {
    transition: all 0.2s linear;
  }
}
@media screen {
  [dir="ltr"] .gw-filter-sidebar__accordion{
            padding-right: 0;
  }
  [dir="rtl"] .gw-filter-sidebar__accordion{
            padding-left: 0;
  }
  [dir="ltr"] .gw-filter-sidebar__accordion{
            padding-left: 0;
  }
  [dir="rtl"] .gw-filter-sidebar__accordion{
            padding-right: 0;
  }
  .gw-filter-sidebar__accordion {
    background-color: transparent;
    -webkit-border-before: 0.0625rem solid rgb(var(--themeGrey3));
            border-top: 0.0625rem solid rgb(var(--themeGrey3));
    border-radius: 0;
    color: rgb(var(--themeColorBrand));
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.25rem;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    -webkit-padding-end: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
  .gw-filter-sidebar__accordion .gw-icon {
    color: rgb(var(--themeColorBrandAlt));
  }
  .gw-filter-sidebar__accordion.is-disabled {
    background-color: transparent;
    -webkit-border-before: 0.0625rem solid rgb(var(--themeGrey3));
            border-top: 0.0625rem solid rgb(var(--themeGrey3));
    color: rgb(var(--themeGrey3));
    opacity: 1;
  }
  .gw-filter-sidebar__accordion.is-disabled .gw-icon {
    color: rgb(var(--themeGrey3));
  }
  .gw-filter-sidebar__accordion-content {
    padding: 0;
  }
  .gw-filter-sidebar__heading {
    font-size: 1.5rem;
  }
  [dir="ltr"] .gw-filter-sidebar__currency-label + .gw-form__select{
            padding-left: 1.875rem;
  }
  [dir="rtl"] .gw-filter-sidebar__currency-label + .gw-form__select{
            padding-right: 1.875rem;
  }
  .gw-filter-sidebar__currency-label + .gw-form__select {
            padding-inline-start: 1.875rem;
    -webkit-padding-start: 1.875rem;
  }
  [dir="ltr"] .gw-filter-sidebar .gw-form__label{
            padding-right: 0;
  }
  [dir="rtl"] .gw-filter-sidebar .gw-form__label{
            padding-left: 0;
  }
  [dir="ltr"] .gw-filter-sidebar .gw-form__label{
            padding-left: 0;
  }
  [dir="rtl"] .gw-filter-sidebar .gw-form__label{
            padding-right: 0;
  }
  .gw-filter-sidebar .gw-form__label {
    -webkit-padding-end: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
  .gw-filter-sidebar hr {
    display: none;
  }
}
@media screen {
  .gw-mf-filters__makes {
    padding: 2.5rem;
  }
  [dir="ltr"] .gw-layout__inner .gw-mf-filters__makes{
            margin-right: -1.875rem;
  }
  [dir="rtl"] .gw-layout__inner .gw-mf-filters__makes{
            margin-left: -1.875rem;
  }
  [dir="ltr"] .gw-layout__inner .gw-mf-filters__makes{
            margin-left: -1.875rem;
  }
  [dir="rtl"] .gw-layout__inner .gw-mf-filters__makes{
            margin-right: -1.875rem;
  }
  .gw-layout__inner .gw-mf-filters__makes {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    -webkit-margin-end: -1.875rem;
            margin-inline-end: -1.875rem;
            margin-inline-start: -1.875rem;
    -webkit-margin-start: -1.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-layout__inner .gw-mf-filters__makes{
            margin-right: -5rem;
  }
  [dir="rtl"] .gw-layout__inner .gw-mf-filters__makes{
            margin-left: -5rem;
  }
  [dir="ltr"] .gw-layout__inner .gw-mf-filters__makes{
            margin-left: -5rem;
  }
  [dir="rtl"] .gw-layout__inner .gw-mf-filters__makes{
            margin-right: -5rem;
  }
  .gw-layout__inner .gw-mf-filters__makes {
    -webkit-margin-end: -5rem;
            margin-inline-end: -5rem;
            margin-inline-start: -5rem;
    -webkit-margin-start: -5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-mf-filters__makes {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
}
@media screen {
  .gw-mf-filters__makes--home-page {
    background-color: rgb(var(--themeGrey1));
  }
}
@media screen {
  .gw-mf-filters__makes--new-vehicles {
    background-color: rgb(var(--themeColorBrandAlt));
  }
}
@media screen {
  .gw-mf-filters__content {
    align-content: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 78.75rem;
    width: 100%;
  }
}
@media screen {
  .gw-mf-filters__item {
    background-color: white;
    border-radius: 0.5rem;
    display: flex;
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    margin: 0 0.625rem 1.25rem 0.625rem;
    min-width: 8.75rem;
    padding: 1.25rem;
    text-decoration: none;
  }
  .gw-mf-filters__item:hover {
    background-color: rgba(var(--themeWhite), 0.75);
    cursor: pointer;
  }
  .gw-mf-filters__item span {
    color: rgb(var(--themeColorText));
    line-height: 2.25rem;
    text-decoration: none;
  }
  .gw-mf-filters__item img {
    margin: auto;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .gw-mf-filters__item span + img {
    font-size: 0;
    line-height: 0;
  }
  .gw-mf-filters__makes--home-page .gw-mf-filters__item img {
    height: auto;
    max-height: 6.25rem;
    max-width: 6.25rem;
    width: 100%;
  }
  .gw-mf-filters__makes--new-vehicles .gw-mf-filters__item img {
    height: 100%;
    max-height: 2.5rem;
    max-width: 6.25rem;
    width: auto;
  }
}
@media screen {
  .gw-flex {
    display: flex;
    flex-wrap: wrap;
  }
  [dir="ltr"] .gw-flex > *{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-flex > *{
            margin-left: 0.75rem;
  }
  .gw-flex > * {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
  [dir="ltr"] .gw-flex > :last-child{
            margin-right: 0;
  }
  [dir="rtl"] .gw-flex > :last-child{
            margin-left: 0;
  }
  .gw-flex > :last-child {
    -webkit-margin-after: 0;
            margin-bottom: 0;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
  .gw-flex--column {
    flex-direction: column;
  }
  [dir="ltr"] .gw-flex--column > *{
            margin-right: 0;
  }
  [dir="rtl"] .gw-flex--column > *{
            margin-left: 0;
  }
  .gw-flex--column > * {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
  .gw-flex--row-reverse {
    flex-direction: row-reverse;
  }
  [dir="ltr"] .gw-flex--row-reverse > *{
            margin-right: 0;
  }
  [dir="rtl"] .gw-flex--row-reverse > *{
            margin-left: 0;
  }
  [dir="ltr"] .gw-flex--row-reverse > *{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-flex--row-reverse > *{
            margin-right: 0.75rem;
  }
  .gw-flex--row-reverse > * {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
  [dir="ltr"] .gw-flex--row-reverse > :last-child{
            margin-left: 0;
  }
  [dir="rtl"] .gw-flex--row-reverse > :last-child{
            margin-right: 0;
  }
  .gw-flex--row-reverse > :last-child {
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
  .gw-flex--column-reverse {
    flex-direction: column-reverse;
  }
  [dir="ltr"] .gw-flex--column-reverse > *{
            margin-right: 0;
  }
  [dir="rtl"] .gw-flex--column-reverse > *{
            margin-left: 0;
  }
  .gw-flex--column-reverse > * {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
  .gw-flex--column-reverse > :first-child {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-flex--column-reverse > :last-child {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-flex--justify-end {
    justify-content: flex-end;
  }
  [dir="ltr"] .gw-flex--justify-end > *{
            margin-right: 0;
  }
  [dir="rtl"] .gw-flex--justify-end > *{
            margin-left: 0;
  }
  [dir="ltr"] .gw-flex--justify-end > *{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-flex--justify-end > *{
            margin-right: 0.75rem;
  }
  .gw-flex--justify-end > * {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
  [dir="ltr"] .gw-flex--justify-end > :first-child{
            margin-left: 0;
  }
  [dir="rtl"] .gw-flex--justify-end > :first-child{
            margin-right: 0;
  }
  .gw-flex--justify-end > :first-child {
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
  [dir="ltr"] .gw-flex--justify-end.gw-flex--row-reverse > *{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-flex--justify-end.gw-flex--row-reverse > *{
            margin-left: 0.75rem;
  }
  [dir="ltr"] .gw-flex--justify-end.gw-flex--row-reverse > *{
            margin-left: 0;
  }
  [dir="rtl"] .gw-flex--justify-end.gw-flex--row-reverse > *{
            margin-right: 0;
  }
  .gw-flex--justify-end.gw-flex--row-reverse > * {
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
  [dir="ltr"] .gw-flex--justify-end.gw-flex--row-reverse > :first-child{
            margin-right: 0;
  }
  [dir="rtl"] .gw-flex--justify-end.gw-flex--row-reverse > :first-child{
            margin-left: 0;
  }
  .gw-flex--justify-end.gw-flex--row-reverse > :first-child {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
  .gw-flex--justify-space-between {
    justify-content: space-between;
  }
  .gw-flex--justify-center {
    justify-content: center;
  }
  [dir="ltr"] .gw-flex--justify-space-between,[dir="ltr"]  .gw-flex--justify-center{
            margin-right: -0.375rem;
  }
  [dir="rtl"] .gw-flex--justify-space-between,[dir="rtl"]  .gw-flex--justify-center{
            margin-left: -0.375rem;
  }
  [dir="ltr"] .gw-flex--justify-space-between,[dir="ltr"]  .gw-flex--justify-center{
            margin-left: -0.375rem;
  }
  [dir="rtl"] .gw-flex--justify-space-between,[dir="rtl"]  .gw-flex--justify-center{
            margin-right: -0.375rem;
  }
  .gw-flex--justify-space-between, .gw-flex--justify-center {
    -webkit-margin-end: -0.375rem;
            margin-inline-end: -0.375rem;
            margin-inline-start: -0.375rem;
    -webkit-margin-start: -0.375rem;
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  [dir="ltr"] .gw-flex--justify-space-between > *,[dir="ltr"] 
.gw-flex--justify-space-between > :first-child,[dir="ltr"] 
.gw-flex--justify-space-between > :last-child,[dir="ltr"] 
.gw-flex--justify-center > *,[dir="ltr"] 
.gw-flex--justify-center > :first-child,[dir="ltr"] 
.gw-flex--justify-center > :last-child{
            margin-right: 0.375rem;
  }
  [dir="rtl"] .gw-flex--justify-space-between > *,[dir="rtl"] 
.gw-flex--justify-space-between > :first-child,[dir="rtl"] 
.gw-flex--justify-space-between > :last-child,[dir="rtl"] 
.gw-flex--justify-center > *,[dir="rtl"] 
.gw-flex--justify-center > :first-child,[dir="rtl"] 
.gw-flex--justify-center > :last-child{
            margin-left: 0.375rem;
  }
  [dir="ltr"] .gw-flex--justify-space-between > *,[dir="ltr"] 
.gw-flex--justify-space-between > :first-child,[dir="ltr"] 
.gw-flex--justify-space-between > :last-child,[dir="ltr"] 
.gw-flex--justify-center > *,[dir="ltr"] 
.gw-flex--justify-center > :first-child,[dir="ltr"] 
.gw-flex--justify-center > :last-child{
            margin-left: 0.375rem;
  }
  [dir="rtl"] .gw-flex--justify-space-between > *,[dir="rtl"] 
.gw-flex--justify-space-between > :first-child,[dir="rtl"] 
.gw-flex--justify-space-between > :last-child,[dir="rtl"] 
.gw-flex--justify-center > *,[dir="rtl"] 
.gw-flex--justify-center > :first-child,[dir="rtl"] 
.gw-flex--justify-center > :last-child{
            margin-right: 0.375rem;
  }
  .gw-flex--justify-space-between > *,
.gw-flex--justify-space-between > :first-child,
.gw-flex--justify-space-between > :last-child, .gw-flex--justify-center > *,
.gw-flex--justify-center > :first-child,
.gw-flex--justify-center > :last-child {
    -webkit-margin-end: 0.375rem;
            margin-inline-end: 0.375rem;
            margin-inline-start: 0.375rem;
    -webkit-margin-start: 0.375rem;
  }
  .gw-flex--align-flex-start {
    align-items: flex-start;
  }
  .gw-flex--align-flex-end {
    align-items: flex-end;
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-flex--align-flex-end > * {
    -webkit-margin-after: 0;
    -webkit-margin-before: 0.75rem;
            margin-bottom: 0;
            margin-top: 0.75rem;
  }
  .gw-flex--align-center {
    align-items: center;
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-flex--align-center > * {
    -webkit-margin-after: 0.375rem;
    -webkit-margin-before: 0.375rem;
            margin-bottom: 0.375rem;
            margin-top: 0.375rem;
  }
}
@media screen {
  .gw-float::before, .gw-float::after {
    clear: both;
    content: "";
    display: table;
  }
  [dir="ltr"] .gw-float__content{
    float: left;
  }
  [dir="rtl"] .gw-float__content{
    float: right;
  }
  [dir="ltr"] .gw-float__content{
            margin-right: 1.25rem;
  }
  [dir="rtl"] .gw-float__content{
            margin-left: 1.25rem;
  }
  .gw-float__content {
    float: inline-start;
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
    -webkit-margin-end: 1.25rem;
            margin-inline-end: 1.25rem;
    max-width: 64rem;
    width: 50%;
  }
  [dir="ltr"] .gw-float--reverse .gw-float__content{
    float: right;
  }
  [dir="rtl"] .gw-float--reverse .gw-float__content{
    float: left;
  }
  [dir="ltr"] .gw-float--reverse .gw-float__content{
            margin-right: 0;
  }
  [dir="rtl"] .gw-float--reverse .gw-float__content{
            margin-left: 0;
  }
  [dir="ltr"] .gw-float--reverse .gw-float__content{
            margin-left: 1.25rem;
  }
  [dir="rtl"] .gw-float--reverse .gw-float__content{
            margin-right: 1.25rem;
  }
  .gw-float--reverse .gw-float__content {
    float: inline-end;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 1.25rem;
    -webkit-margin-start: 1.25rem;
  }
}
@media screen {
  .gw-footer {
    display: none;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer {
    align-items: flex-start;
    background-color: rgb(var(--themeGrey3));
    color: rgb(var(--themeColorTextAlt));
    display: flex;
    flex-wrap: wrap;
    padding: 1.875rem 1.875rem;
  }
}
@media screen and (min-width: 48.0625em) and (min-width: 48.0625em) {
  .gw-footer {
    padding: 2.5rem 5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer > * {
    min-width: 12.5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  @supports (grid-area: auto) {
    .gw-footer {
      grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
    }
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer > * {
    margin: 0.9375rem;
  }
}
@media screen and (min-width: 48.0625em) {
  @supports (grid-area: auto) {
    .gw-footer {
      display: grid;
      gap: 1.875rem;
    }
    .gw-footer > * {
      height: 100%;
      margin: 0;
    }
  }
}
@media screen {
  .gw-footer--mobile {
    background-color: rgb(var(--themeGrey3));
    color: rgb(var(--themeColorTextAlt));
    -webkit-margin-after: 0;
            margin-bottom: 0;
    padding: 1.875rem 1.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer--mobile {
    display: none;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer--mobile {
    padding: 2.5rem 5rem;
  }
}
@media screen {
  .gw-footer--mobile .gw-accordion__title {
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.25rem;
  }
}
@media screen {
  .gw-footer--mobile .gw-accordion__button {
    -webkit-border-after: 1px solid rgb(var(--themeColorTextAlt));
            border-bottom: 1px solid rgb(var(--themeColorTextAlt));
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .gw-footer--mobile .gw-accordion__button,
.gw-footer--mobile .gw-accordion__button:hover,
.gw-footer--mobile .gw-accordion__button:focus,
.gw-footer--mobile .gw-accordion__content {
    background-color: transparent;
  }
}
@media screen {
  .gw-footer--mobile .gw-button--whatsapp {
    color: rgb(var(--themeColorTextAlt));
  }
  [dir="ltr"] .gw-footer--mobile .gw-button--whatsapp .gw-button__inner > .gw-icon{
            margin-left: 0;
  }
  [dir="rtl"] .gw-footer--mobile .gw-button--whatsapp .gw-button__inner > .gw-icon{
            margin-right: 0;
  }
  .gw-footer--mobile .gw-button--whatsapp .gw-button__inner > .gw-icon {
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
}
@media screen {
  .gw-footer__group-title {
    -webkit-border-after: 0.0625rem solid;
            border-bottom: 0.0625rem solid;
    font-size: 1.25rem;
    -webkit-padding-after: 0.75rem;
            padding-bottom: 0.75rem;
  }
}
@media screen {
  .gw-footer__list + * {
    -webkit-margin-before: 0.75rem;
            margin-top: 0.75rem;
  }
}
@media screen {
  .gw-footer__item {
    font-size: 1rem;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-footer__item--logo .gw-image {
    flex-shrink: 1;
    height: 1.625rem;
    width: auto;
  }
}
@media screen {
  .gw-footer__link {
    color: currentColor;
    font-size: 1rem;
    -webkit-padding-after: 0.375rem;
    -webkit-padding-before: 0.375rem;
            padding-bottom: 0.375rem;
            padding-top: 0.375rem;
    text-decoration: none;
  }
  .gw-footer__link:hover {
    text-decoration: underline;
  }
  .gw-footer__link.gw-button--whatsapp {
    color: rgb(var(--themeColorTextAlt));
  }
}
@media screen {
  .gw-footer__group-content {
    font-size: 1rem;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-footer__group-content a {
    color: currentColor;
  }
  .gw-footer__group-content > span {
    display: block;
  }
}
@media screen {
  .gw-footer__smallprint {
    font-size: 0.6875rem;
  }
}
@media screen {
  .gw-footer--supplementary {
    background-color: rgb(var(--themeColorBackgroundAlt));
    color: rgb(var(--themeColorTextAlt));
    padding: 1.25rem 1.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer--supplementary {
    padding: 1.25rem 5rem;
  }
}
@media screen {
  .gw-footer--supplementary-social {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .gw-footer--supplementary-social > a {
    margin-top: 0;
  }
}
@media screen {
  .gw-footer--supplementary .gw-social {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
  [dir="ltr"] .gw-footer--supplementary .gw-social__title{
            margin-right: 1.25rem;
  }
  [dir="rtl"] .gw-footer--supplementary .gw-social__title{
            margin-left: 1.25rem;
  }
  .gw-footer--supplementary .gw-social__title {
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1rem;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    -webkit-margin-end: 1.25rem;
            margin-inline-end: 1.25rem;
  }
  [dir="ltr"] .gw-footer--supplementary .gw-social__icons{
            margin-left: -0.75rem;
  }
  [dir="rtl"] .gw-footer--supplementary .gw-social__icons{
            margin-right: -0.75rem;
  }
  .gw-footer--supplementary .gw-social__icons {
            margin-inline-start: -0.75rem;
    -webkit-margin-start: -0.75rem;
  }
}
@media screen and (max-width: 30em) {
  .gw-footer--supplementary .gw-footer__item {
    flex-basis: 100%;
  }
}
@media screen {
  .gw-footer--supplementary .gw-footer__list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
  [dir="ltr"] .gw-footer--supplementary .gw-footer__list > *:not(:last-child){
            margin-right: 1.25rem;
  }
  [dir="rtl"] .gw-footer--supplementary .gw-footer__list > *:not(:last-child){
            margin-left: 1.25rem;
  }
  .gw-footer--supplementary .gw-footer__list > *:not(:last-child) {
    -webkit-margin-end: 1.25rem;
            margin-inline-end: 1.25rem;
  }
  .gw-footer--supplementary .gw-footer__list--social {
    flex-grow: 1;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [dir="ltr"] .gw-footer--supplementary .gw-footer__list--social > *:not(:last-child){
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-footer--supplementary .gw-footer__list--social > *:not(:last-child){
            margin-left: 0.75rem;
  }
  .gw-footer--supplementary .gw-footer__list--social > *:not(:last-child) {
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
}
@media screen and (max-width: 30em) {
  .gw-footer--supplementary .gw-footer__list--social .gw-footer__item {
    flex-basis: auto;
  }
}
@media screen {
  .gw-footer__manufacturer .gw-image {
    height: 3.75rem;
  }
}
@media screen {
  .gw-footer--gc {
    background-color: rgb(var(--themeColorFooter));
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer--gc .gw-layout__inner {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-inline: 5rem;
  }
}
@media screen {
  .gw-footer--gc .gw-footer__border--desktop {
    /* stylelint-disable-next-line max-nesting-depth */
    display: none;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer--gc .gw-footer__border--desktop {
    background: linear-gradient(120deg, rgb(var(--themeColorBrand)) 0%, rgb(var(--themeColorBrand)) 60%, rgb(var(--themeColorSecondary1)) 60%, rgb(var(--themeColorSecondary1)) 70%, rgb(var(--themeColorSecondary2)) 70%, rgb(var(--themeColorSecondary2)) 80%, rgb(var(--themeColorSecondary3)) 80%, rgb(var(--themeColorSecondary3)) 90%, rgb(var(--themeColorSecondary4)) 90%, rgb(var(--themeColorSecondary4)) 100%);
    display: block;
    height: 0.5rem;
    width: 100%;
  }
}
@media screen {
  .gw-footer--gc .gw-footer__border--mobile {
    /* stylelint-disable-next-line max-nesting-depth */
    background: linear-gradient(120deg, rgb(var(--themeColorBrand)) 0%, rgb(var(--themeColorBrand)) 40%, rgb(var(--themeColorSecondary1)) 40%, rgb(var(--themeColorSecondary1)) 55%, rgb(var(--themeColorSecondary2)) 55%, rgb(var(--themeColorSecondary2)) 70%, rgb(var(--themeColorSecondary3)) 70%, rgb(var(--themeColorSecondary3)) 85%, rgb(var(--themeColorSecondary4)) 85%, rgb(var(--themeColorSecondary4)) 100%);
    display: block;
    height: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer--gc .gw-footer__border--mobile {
    display: none;
  }
}
@media screen {
  .gw-footer--gc .gw-footer--pre-footer {
    /* stylelint-disable max-nesting-depth */
    -webkit-border-after: 0.0625rem solid rgba(var(--themeWhite), 0.5);
            border-bottom: 0.0625rem solid rgba(var(--themeWhite), 0.5);
    color: rgb(var(--themeColorFooterFont));
    margin-left: 1.875rem;
    margin-right: 1.875rem;
    margin-inline: 1.875rem;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    padding-block: 1.875rem;
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer--gc .gw-footer--pre-footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-inline: 5rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    padding-block: 2.5rem;
  }
}
@media screen {
  .gw-footer--gc .gw-footer--pre-footer .gw-footer__logo {
    display: block;
    max-width: 15rem;
  }
}
@media screen {
  .gw-footer--gc .gw-footer--pre-footer .gw-footer__address {
    -webkit-margin-before: 0.75rem;
            margin-top: 0.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-footer--gc .gw-footer--pre-footer .gw-footer__address{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-footer--gc .gw-footer--pre-footer .gw-footer__address{
            margin-right: 0.75rem;
  }
  .gw-footer--gc .gw-footer--pre-footer .gw-footer__address {
    flex-grow: 1;
    -webkit-margin-before: 0;
            margin-top: 0;
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
}
@media screen {
  .gw-footer--gc .gw-footer--pre-footer .gw-footer__address p {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .gw-footer--gc .gw-footer__group-title {
    -webkit-border-after: 0;
            border-bottom: 0;
  }
}
@media screen {
  .gw-footer--gc .gw-footer--mobile .gw-accordion {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-footer--gc .gw-footer--mobile .gw-accordion__button {
    -webkit-border-after: 0.0625rem solid rgba(var(--themeWhite), 0.5);
            border-bottom: 0.0625rem solid rgba(var(--themeWhite), 0.5);
  }
  [data-whatintent=mouse] .gw-footer--gc .gw-footer--mobile .gw-accordion__button:hover, [data-whatintent=keyboard] .gw-footer--gc .gw-footer--mobile .gw-accordion__button:focus {
    color: rgb(var(--themeGrey5));
  }
}
@media screen {
  .gw-footer--gc .gw-form {
    -webkit-margin-after: 0;
            margin-bottom: 0;
    -webkit-padding-before: 0;
            padding-top: 0;
  }
  [dir="ltr"] .gw-footer--gc .gw-form__input{
            padding-left: 0.75rem;
  }
  [dir="rtl"] .gw-footer--gc .gw-form__input{
            padding-right: 0.75rem;
  }
  .gw-footer--gc .gw-form__input {
    /* stylelint-disable-next-line max-nesting-depth */
    background-color: rgba(var(--themeGrey5), 0.3);
    border: 0.0625rem solid rgba(var(--themeWhite), 0.5);
    border-radius: 20rem;
            padding-inline-start: 0.75rem;
    -webkit-padding-start: 0.75rem;
  }
  [data-whatintent=mouse] .gw-footer--gc .gw-form__input:focus {
    border: 0.0625rem solid rgb(var(--themeGrey1));
  }

  .gw-footer--gc .gw-form .gw-button[class*="--text"] {
    border-radius: 20rem;
    width: 100%;
  }
}
@media screen {
  .gw-footer--gc .gw-footer--supplementary .gw-footer__list {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-footer--gc .gw-footer--supplementary .gw-footer__list .gw-footer__item--copyright {
    flex-grow: 1;
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer--gc .gw-footer--supplementary .gw-footer__list .gw-footer__item--copyright {
    width: auto;
  }
}
@media screen {
  .gw-footer--gc .gw-footer--supplementary-social {
    /* stylelint-disable max-nesting-depth */
    -webkit-border-after: 0.0625rem solid rgba(var(--themeWhite), 0.5);
            border-bottom: 0.0625rem solid rgba(var(--themeWhite), 0.5);
    margin-left: 1.875rem;
    margin-right: 1.875rem;
    margin-inline: 1.875rem;
    padding: 1.25rem 0;
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer--gc .gw-footer--supplementary-social {
    display: flex;
    justify-content: flex-end;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-inline: 5rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-block: 1.25rem;
  }
}
@media screen {
  [dir="ltr"] .gw-footer--gc .gw-footer--supplementary-social .gw-social .gw-button--facebook{
            margin-right: -0.75rem;
  }
  [dir="rtl"] .gw-footer--gc .gw-footer--supplementary-social .gw-social .gw-button--facebook{
            margin-left: -0.75rem;
  }
  .gw-footer--gc .gw-footer--supplementary-social .gw-social .gw-button--facebook {
    -webkit-margin-end: -0.75rem;
            margin-inline-end: -0.75rem;
  }
  .gw-footer--gc .gw-footer--supplementary-social .gw-social .gw-button--facebook .gw-icon {
    border: 0.0625rem solid rgb(var(--themeColorTextAlt));
    border-radius: 20rem;
    height: 2.5em;
    width: 2.5em;
  }
  [dir="ltr"] .gw-footer--gc .gw-footer--supplementary-social .gw-social .gw-button:not(.gw-button--facebook){
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-footer--gc .gw-footer--supplementary-social .gw-social .gw-button:not(.gw-button--facebook){
            margin-right: 0.75rem;
  }
  .gw-footer--gc .gw-footer--supplementary-social .gw-social .gw-button:not(.gw-button--facebook) {
    border: 0.0625rem solid rgb(var(--themeColorTextAlt));
    border-radius: 20rem;
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
    padding: 0.5rem;
  }
  .gw-footer--gc .gw-footer--supplementary-social .gw-social .gw-button:not(.gw-button--facebook) .gw-icon {
    height: 1.5em;
    width: 1.5em;
  }
}
@media screen {
  .gw-footer {
    background-color: rgb(var(--themeColorFooter));
    -webkit-border-before: 0.0625rem solid rgb(var(--themeGrey3));
            border-top: 0.0625rem solid rgb(var(--themeGrey3));
    color: rgb(var(--themeColorFooterFont));
    grid-template-columns: repeat(auto-fit, minmax(12.5rem, 14rem));
  }
  .gw-footer .gw-button--whatsapp {
    color: rgb(var(--themeColorFooterFont));
  }
  .gw-footer--gc {
    background-color: rgb(var(--themeColorFooter));
  }
  .gw-footer > :last-child {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  .gw-footer--mobile {
    background-color: rgb(var(--themeColorFooter));
    -webkit-border-before: solid 0.0625rem rgb(var(--themeGrey3));
            border-top: solid 0.0625rem rgb(var(--themeGrey3));
    color: rgb(var(--themeColorFooterFont));
    padding: 0;
  }
  .gw-footer--mobile .gw-accordion__title {
    font-weight: inherit;
  }
  .gw-footer--mobile .gw-accordion__title .gw-accordion__button {
    -webkit-border-before: 0;
    border-bottom-color: rgb(var(--themeGrey3));
            border-top: 0;
    padding: 1.25rem 1.875rem;
    text-transform: none;
  }
  .gw-footer--mobile .gw-button--whatsapp {
    color: rgb(var(--themeColorFooterFont));
  }
  .gw-footer--supplementary {
    background-color: rgb(var(--themeColorSupplementary));
    -webkit-border-before: 0.0625rem solid rgb(var(--themeGrey3));
            border-top: 0.0625rem solid rgb(var(--themeGrey3));
    color: rgb(var(--themeColorFooterFont));
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer--supplementary .gw-social__icons {
    padding: 0;
    width: auto;
  }
}
@media screen {
  .gw-footer--supplementary .gw-footer__item, .gw-footer--supplementary .gw-footer__link {
    font-size: 0.6875rem;
  }
}
@media screen {
  .gw-footer--supplementary .gw-social {
    background-color: rgb(var(--themeColorFooterSocial));
  }
  .gw-footer--supplementary .gw-social__title {
    color: rgb(var(--themeColorText));
    font-family: var(--themeFontFamilyBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1rem;
    text-transform: none;
  }
  [dir="ltr"] .gw-footer--supplementary .gw-social__icons{
            margin-left: -0.375rem;
  }
  [dir="rtl"] .gw-footer--supplementary .gw-social__icons{
            margin-right: -0.375rem;
  }
  .gw-footer--supplementary .gw-social__icons {
    justify-content: flex-start;
            margin-inline-start: -0.375rem;
    -webkit-margin-start: -0.375rem;
    padding: 0.75rem 0;
    width: 100%;
  }
  .gw-footer--supplementary .gw-social__icon {
    margin: 0.375rem;
  }
}
@media screen and (max-width: 48em) {
  .gw-footer--supplementary-social {
    -webkit-border-before: 0;
            border-top: 0;
    padding: 1.875rem;
  }
}
@media screen {
  .gw-footer__group-title {
    border: none;
    color: rgb(var(--themeColorText));
    font-family: var(--themeFontFamilyBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1rem;
  }
}
@media screen {
  .gw-footer__manufacturer {
    padding: 0.75rem 0;
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer__manufacturer {
    padding: 0;
    width: auto;
  }
}
@media screen {
  .gw-footer__manufacturer .gw-image {
    height: 2rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-footer__manufacturer .gw-image {
    height: 3.75rem;
  }
}
@media screen {
  [dir="ltr"] .gw-form{
            margin-right: auto;
  }
  [dir="rtl"] .gw-form{
            margin-left: auto;
  }
  [dir="ltr"] .gw-form{
            margin-left: auto;
  }
  [dir="rtl"] .gw-form{
            margin-right: auto;
  }
  .gw-form {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
    max-width: var(--themeMaxContentWidth);
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-form__field {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
    max-width: 100%;
  }
  .gw-form__field--small {
    max-width: 10rem;
  }
  .gw-form__field--checkbox {
    display: flex;
  }
  .gw-form__legend {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
  .gw-form__legend > * {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-form__label {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }
  [dir="ltr"] .gw-form__label > span:first-child{
            padding-right: 1.25rem;
  }
  [dir="rtl"] .gw-form__label > span:first-child{
            padding-left: 1.25rem;
  }
  .gw-form__label > span:first-child {
    -webkit-margin-after: 0.375rem;
            margin-bottom: 0.375rem;
    -webkit-padding-end: 1.25rem;
            padding-inline-end: 1.25rem;
  }
  .gw-form__label + * {
    -webkit-margin-before: 0.75rem;
            margin-top: 0.75rem;
  }
  .gw-form__field--checkbox .gw-form__label {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .gw-form__field--checkbox .gw-form__label span {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-form__input {
    background-color: rgb(var(--themeColorBackground));
    border: 0.0625rem solid rgb(var(--themeGrey2));
    height: 3rem;
    margin: 0;
    padding: 0.75rem;
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
  }
  [data-whatintent=keyboard] .gw-form__input:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] .gw-form__input:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] .gw-form__input:focus {
    outline: 0;
  }

  .gw-form__input:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
  [data-whatintent=mouse] .gw-form__input:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
  }

  .gw-form__input:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  .gw-form__input:valid:required, .gw-form__input:valid:not(:empty):required {
    background-color: rgb(var(--themeWhite));
    border-color: rgb(var(--themeAccentPositive));
  }
  .gw-form__input:valid:required ~ .gw-form__icon:not(.gw-form__icon--error), .gw-form__input:valid:not(:empty):required ~ .gw-form__icon:not(.gw-form__icon--error) {
    display: inline-block;
    fill: rgb(var(--themeAccentPositive));
  }
  .gw-form__input:invalid:required:focus {
    border-color: rgb(var(--themeAccentNegative));
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNegative));
  }
  .gw-form__input:invalid:required:focus ~ .gw-form__icon--error {
    display: inline-block;
    fill: rgb(var(--themeAccentNegative));
  }
  .gw-form__input:invalid:required:focus ~ .gw-form__error {
    display: block;
  }
  [dir="ltr"] .gw-form__input[type=checkbox],[dir="ltr"]  .gw-form__input[type=radio]{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-form__input[type=checkbox],[dir="rtl"]  .gw-form__input[type=radio]{
            margin-left: 0.75rem;
  }
  .gw-form__input[type=checkbox], .gw-form__input[type=radio] {
    font-size: 1rem;
    height: 1rem;
    line-height: 1;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
  /* stylelint-disable max-nesting-depth */
  [data-whatintent=mouse] .gw-form__input[type=checkbox]:focus, [data-whatintent=keyboard] .gw-form__input[type=checkbox]:focus, [data-whatintent=mouse] .gw-form__input[type=radio]:focus, [data-whatintent=keyboard] .gw-form__input[type=radio]:focus {
    box-shadow: 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
  }

  /* stylelint-enable max-nesting-depth */
  .gw-form__input[type=checkbox]:invalid:required:focus, .gw-form__input[type=radio]:invalid:required:focus {
    box-shadow: 0 0 0 0.25rem rgb(var(--themeAccentNegative));
  }
  [dir="ltr"] .gw-form__select{
            padding-right: 2.5rem;
  }
  [dir="rtl"] .gw-form__select{
            padding-left: 2.5rem;
  }
  .gw-form__select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: rgb(var(--themeColorBackground));
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" style="fill: rgb(0, 58, 82)"><path d="M20,40l2.8-2.8L50,64.3l27.2-27.1L80,40L50,70L20,40z"/></svg>');
    background-position: right 0.5em top 50%;
    background-repeat: no-repeat;
    background-size: 2em;
    border: 0.0625rem solid rgb(var(--themeGrey2));
    height: 3rem;
    padding: 0.75rem;
    -webkit-padding-end: 2.5rem;
            padding-inline-end: 2.5rem;
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [dir=rtl] .gw-form__select {
    background-position: left 0.5em top 50%;
  }

  [data-whatintent=keyboard] .gw-form__select:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] .gw-form__select:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] .gw-form__select:focus {
    outline: 0;
  }

  .gw-form__select:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
  [data-whatintent=mouse] .gw-form__select:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
  }

  .gw-form__select:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  .gw-form__select:valid:required, .gw-form__select:valid:not(:empty):required {
    background-color: rgb(var(--themeWhite));
    border-color: rgb(var(--themeAccentPositive));
  }
  .gw-form__select:valid:required ~ .gw-form__icon:not(.gw-form__icon--error), .gw-form__select:valid:not(:empty):required ~ .gw-form__icon:not(.gw-form__icon--error) {
    display: inline-block;
    fill: rgb(var(--themeAccentPositive));
  }
  .gw-form__select:invalid:required:focus {
    border-color: rgb(var(--themeAccentNegative));
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNegative));
  }
  .gw-form__select:invalid:required:focus ~ .gw-form__icon--error {
    display: inline-block;
    fill: rgb(var(--themeAccentNegative));
  }
  .gw-form__select:invalid:required:focus ~ .gw-form__error {
    display: block;
  }
  .gw-form__select option {
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif, sans-serif;
    font-weight: normal;
  }
  .gw-form__select--multi {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
  }
  .gw-form__select--multi .gw-form__select {
    width: 100%;
  }
  [dir="ltr"] .gw-form__select--checkboxes{
            padding-left: 0.9375rem;
  }
  [dir="rtl"] .gw-form__select--checkboxes{
            padding-right: 0.9375rem;
  }
  .gw-form__select--checkboxes {
    background-color: rgb(var(--themeGrey1));
    display: none;
    top: 3.75rem;
    max-height: 15.625rem;
    min-width: 220px;
    overflow-y: auto;
    -webkit-padding-before: 0.9375rem;
            padding-top: 0.9375rem;
            padding-inline-start: 0.9375rem;
    -webkit-padding-start: 0.9375rem;
    width: 100%;
    z-index: 1;
    /* stylelint-disable-next-line max-nesting-depth */
  }
  .gw-form__select--checkboxes.show {
    display: block;
  }
  .gw-form__select--checkboxes .gw-form__label {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .gw-form__select--overlay {
    top: 0;
    bottom: 0;
    inset-block: 0;
    left: 0;
    right: 0;
    inset-inline: 0;
    position: absolute;
  }
  .gw-form__select[multiple] {
    background-position: right 1em top 0.4em;
    height: unset;
  }
  .gw-form__select[multiple] option {
    padding: 0 0 0.75rem;
  }
  .gw-form__select.selectize-control {
    border: 0;
    height: unset;
    padding: 0;
  }
  .gw-form__select.selectize-control .selectize-dropdown {
    height: unset;
    padding: 0;
  }
  .gw-form__select.selectize-control .selectize-dropdown .option {
    color: rgb(var(--themeColorText));
    font-size: 1rem;
    padding: 0.75rem 0.375rem;
  }
  .gw-form__select.selectize-control .selectize-input {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" style="fill: rgb(31, 23, 50)"><path d="M20,40l2.8-2.8L50,64.3l27.2-27.1L80,40L50,70L20,40z"/></svg>');
    background-position: right 0.5em top 50%;
    background-repeat: no-repeat;
    background-size: 2rem;
    border: 0.0625rem solid rgb(var(--themeGrey2));
    border-radius: 2px;
    box-shadow: none;
    display: flex;
    height: 3rem;
    padding: 0.6rem;
  }
  .gw-form__textarea {
    background-color: rgb(var(--themeColorBackground));
    border: 0.0625rem solid rgb(var(--themeGrey2));
    height: 3rem;
    height: auto;
    padding: 0.75rem;
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
  }
  [data-whatintent=keyboard] .gw-form__textarea:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] .gw-form__textarea:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] .gw-form__textarea:focus {
    outline: 0;
  }

  .gw-form__textarea:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
  [data-whatintent=mouse] .gw-form__textarea:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
  }

  .gw-form__textarea:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  .gw-form__textarea:valid:required, .gw-form__textarea:valid:not(:empty):required {
    background-color: rgb(var(--themeWhite));
    border-color: rgb(var(--themeAccentPositive));
  }
  .gw-form__textarea:valid:required ~ .gw-form__icon:not(.gw-form__icon--error), .gw-form__textarea:valid:not(:empty):required ~ .gw-form__icon:not(.gw-form__icon--error) {
    display: inline-block;
    fill: rgb(var(--themeAccentPositive));
  }
  .gw-form__textarea:invalid:required:focus {
    border-color: rgb(var(--themeAccentNegative));
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNegative));
  }
  .gw-form__textarea:invalid:required:focus ~ .gw-form__icon--error {
    display: inline-block;
    fill: rgb(var(--themeAccentNegative));
  }
  .gw-form__textarea:invalid:required:focus ~ .gw-form__error {
    display: block;
  }
  .gw-form__fieldset--checkbox, .gw-form__fieldset--radio {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
  .gw-form__fieldset--checkbox .gw-form__legend, .gw-form__fieldset--radio .gw-form__legend {
    -webkit-margin-after: 0.375rem;
            margin-bottom: 0.375rem;
  }
  .gw-form__fieldset--checkbox .gw-form__field, .gw-form__fieldset--radio .gw-form__field {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-form__fieldset--checkbox .gw-grid, .gw-form__fieldset--radio .gw-grid {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-form__fieldset--checkbox .gw-grid .gw-form__field, .gw-form__fieldset--radio .gw-grid .gw-form__field {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-form__fieldset--checkbox .gw-form__label, .gw-form__fieldset--radio .gw-form__label {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [dir="ltr"] .gw-form__fieldset--checkbox .gw-form__label span,[dir="ltr"]  .gw-form__fieldset--radio .gw-form__label span{
            padding-right: 0;
  }
  [dir="rtl"] .gw-form__fieldset--checkbox .gw-form__label span,[dir="rtl"]  .gw-form__fieldset--radio .gw-form__label span{
            padding-left: 0;
  }
  .gw-form__fieldset--checkbox .gw-form__label span, .gw-form__fieldset--radio .gw-form__label span {
    -webkit-margin-after: 0;
            margin-bottom: 0;
    -webkit-padding-end: 0;
            padding-inline-end: 0;
  }
  .gw-grid .gw-form__fieldset {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-form__field--checkbox-custom, .gw-form__fieldset--checkbox-custom .gw-form__field {
    background-color: rgb(var(--themeColorBackground));
    border: 0.0625rem solid rgb(var(--themeGrey2));
    height: 3rem;
    height: auto;
    padding: 0.75rem;
    padding: 0.375rem;
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
  }
  [data-whatintent=keyboard] .gw-form__field--checkbox-custom:focus, [data-whatintent=keyboard] .gw-form__fieldset--checkbox-custom .gw-form__field:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] .gw-form__field--checkbox-custom:focus, [data-whatintent=touch] .gw-form__fieldset--checkbox-custom .gw-form__field:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] .gw-form__field--checkbox-custom:focus, [data-whatintent=mouse] .gw-form__fieldset--checkbox-custom .gw-form__field:focus {
    outline: 0;
  }

  .gw-form__field--checkbox-custom:focus, .gw-form__fieldset--checkbox-custom .gw-form__field:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
  [data-whatintent=mouse] .gw-form__field--checkbox-custom:focus, [data-whatintent=mouse] .gw-form__fieldset--checkbox-custom .gw-form__field:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
  }

  .gw-form__field--checkbox-custom:disabled, .gw-form__fieldset--checkbox-custom .gw-form__field:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  .gw-form__field--checkbox-custom:valid:required, .gw-form__field--checkbox-custom:valid:not(:empty):required, .gw-form__fieldset--checkbox-custom .gw-form__field:valid:required, .gw-form__fieldset--checkbox-custom .gw-form__field:valid:not(:empty):required {
    background-color: rgb(var(--themeWhite));
    border-color: rgb(var(--themeAccentPositive));
  }
  .gw-form__field--checkbox-custom:valid:required ~ .gw-form__icon:not(.gw-form__icon--error), .gw-form__field--checkbox-custom:valid:not(:empty):required ~ .gw-form__icon:not(.gw-form__icon--error), .gw-form__fieldset--checkbox-custom .gw-form__field:valid:required ~ .gw-form__icon:not(.gw-form__icon--error), .gw-form__fieldset--checkbox-custom .gw-form__field:valid:not(:empty):required ~ .gw-form__icon:not(.gw-form__icon--error) {
    display: inline-block;
    fill: rgb(var(--themeAccentPositive));
  }
  .gw-form__field--checkbox-custom:invalid:required:focus, .gw-form__fieldset--checkbox-custom .gw-form__field:invalid:required:focus {
    border-color: rgb(var(--themeAccentNegative));
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNegative));
  }
  .gw-form__field--checkbox-custom:invalid:required:focus ~ .gw-form__icon--error, .gw-form__fieldset--checkbox-custom .gw-form__field:invalid:required:focus ~ .gw-form__icon--error {
    display: inline-block;
    fill: rgb(var(--themeAccentNegative));
  }
  .gw-form__field--checkbox-custom:invalid:required:focus ~ .gw-form__error, .gw-form__fieldset--checkbox-custom .gw-form__field:invalid:required:focus ~ .gw-form__error {
    display: block;
  }
  .gw-form__field--checkbox-custom .gw-form__label, .gw-form__fieldset--checkbox-custom .gw-form__field .gw-form__label {
    flex-direction: row;
    font-size: 0.875rem;
    justify-content: space-between;
  }
  [dir="ltr"] .gw-form__field--checkbox-custom .gw-form__input,[dir="ltr"]  .gw-form__fieldset--checkbox-custom .gw-form__field .gw-form__input{
            margin-right: 0;
  }
  [dir="rtl"] .gw-form__field--checkbox-custom .gw-form__input,[dir="rtl"]  .gw-form__fieldset--checkbox-custom .gw-form__field .gw-form__input{
            margin-left: 0;
  }
  [dir="ltr"] .gw-form__field--checkbox-custom .gw-form__input,[dir="ltr"]  .gw-form__fieldset--checkbox-custom .gw-form__field .gw-form__input{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-form__field--checkbox-custom .gw-form__input,[dir="rtl"]  .gw-form__fieldset--checkbox-custom .gw-form__field .gw-form__input{
            margin-right: 0.75rem;
  }
  .gw-form__field--checkbox-custom .gw-form__input, .gw-form__fieldset--checkbox-custom .gw-form__field .gw-form__input {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
  .gw-form__buttons {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-form__range {
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-form__range .gw-form__label {
    background-color: rgb(var(--themeGrey1));
    font-size: 1rem;
    padding: 0.75rem;
  }
  .gw-form__range .gw-form__range__minmax {
    text-transform: uppercase;
  }
  .gw-form__range [type=range] {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0;
    height: auto;
    max-width: inherit;
    padding: 0.75rem 0;
    width: 100%;
  }
  /* stylelint-disable max-nesting-depth */
  [data-whatintent=keyboard] .gw-form__range [type=range]:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }

  /* stylelint-enable max-nesting-depth */
  .gw-form__range [type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: rgb(var(--themeColorBrand));
    border: 0;
    border-radius: 50%;
    height: 2rem;
    -webkit-margin-before: -0.75rem;
            margin-top: -0.75rem;
    width: 2rem;
  }
  .gw-form__range [type=range]::-webkit-slider-runnable-track {
    background-color: rgb(var(--themeColorBackground));
    cursor: pointer;
    height: 0.25rem;
    width: 100%;
  }
  .gw-form__range [type=range]::-moz-range-thumb {
    background-color: rgb(var(--themeColorBrand));
    border: 0;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
  }
  .gw-form__range [type=range]::-moz-range-track {
    background-color: rgb(var(--themeColorBackground));
    cursor: pointer;
    height: 0.25rem;
    width: 100%;
  }
  .gw-form__range__bubble {
    display: inline-block;
    margin: 0 auto;
  }
  .gw-form__range--multiple {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    max-width: unset;
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-form__range--multiple > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    .gw-form__range--multiple {
      grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr));
    }
  }
  .gw-form__range--multiple > * {
    margin: 0;
  }
  @supports (grid-area: auto) {
    .gw-form__range--multiple {
      display: grid;
      gap: 0;
    }
    .gw-form__range--multiple > * {
      height: 100%;
      margin: 0;
    }
  }
  .gw-form__range--multiple > .gw-form__label {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [dir="ltr"] .gw-form__range--multiple > .gw-form__label:first-of-type{
            padding-right: 0.375rem;
  }
  [dir="rtl"] .gw-form__range--multiple > .gw-form__label:first-of-type{
            padding-left: 0.375rem;
  }
  .gw-form__range--multiple > .gw-form__label:first-of-type {
    -webkit-padding-end: 0.375rem;
            padding-inline-end: 0.375rem;
  }
  [dir="ltr"] .gw-form__range--multiple > .gw-form__label:last-of-type{
            padding-left: 0.375rem;
  }
  [dir="rtl"] .gw-form__range--multiple > .gw-form__label:last-of-type{
            padding-right: 0.375rem;
  }
  .gw-form__range--multiple > .gw-form__label:last-of-type {
            padding-inline-start: 0.375rem;
    -webkit-padding-start: 0.375rem;
  }
  .gw-form__range--multiple > .gw-form__label:last-of-type .gw-form__range__label {
    color: transparent;
  }
  .gw-form__range--multiple .gw-form__range__minmax {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-form__range--multiple .gw-form__range__minmax:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
  .gw-form__range--unit-conversions {
    /* stylelint-disable max-nesting-depth, no-descending-specificity */
    position: relative;
    /* stylelint-enable max-nesting-depth, no-descending-specificity */
  }
  .gw-form__range--unit-conversions > .gw-form__label {
    -webkit-padding-before: 3.75rem;
            padding-top: 3.75rem;
  }
  [dir="ltr"] .gw-form__range--unit-conversions .gw-form__fieldset{
    left: 0.75rem;
  }
  [dir="rtl"] .gw-form__range--unit-conversions .gw-form__fieldset{
    right: 0.75rem;
  }
  .gw-form__range--unit-conversions .gw-form__fieldset {
    display: none;
    height: auto;
    top: 0;
    inset-inline-start: 0.75rem;
    position: absolute;
    z-index: 1;
  }
  .gw-form__range--unit-conversions .gw-form__legend {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-form__range--unit-conversions .gw-form__legend:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
  .gw-form__range--unit-conversions .gw-form__field {
    display: inline-block;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-form.is-js-enabled .gw-button--secondary[type=submit] {
    display: none;
  }
  [dir="ltr"] .gw-form__icon{
    right: 0;
  }
  [dir="rtl"] .gw-form__icon{
    left: 0;
  }
  .gw-form__icon {
    display: none;
    height: 1rem;
    top: 0;
    inset-inline-end: 0;
    position: absolute;
    width: 1rem;
  }
  .gw-form__errors {
    color: rgb(var(--themeAccentNegative));
  }
  .gw-form__errors__title {
    color: inherit;
  }
  .gw-form__field--has-error :not(:disabled) {
    border-color: rgb(var(--themeAccentNegative));
  }
  .gw-form__field--has-error :not(:disabled):not(:valid):focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNegative));
  }
  .gw-form__error {
    color: rgb(var(--themeAccentNegative));
    display: none;
    -webkit-margin-before: 0.375rem;
            margin-top: 0.375rem;
  }
  .is-disabled .gw-form__error {
    display: none;
  }

  .gw-form__field--checkbox .gw-form__error, .gw-form__fieldset--checkbox .gw-form__error {
    bottom: -1.25rem;
    position: absolute;
  }

  .gw-form .gw-grid {
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
  }
  .gw-form .gw-grid > * {
    min-width: 20rem;
  }
  @supports (grid-area: auto) {
    .gw-form .gw-grid {
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    }
  }
}
@media screen and (max-width: 30em) {
  .gw-form .gw-grid {
    grid-template-columns: 1fr;
  }
  .gw-form .gw-grid > * {
    min-width: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-form .gw-grid > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    .gw-form .gw-grid {
      grid-template-columns: repeat(2, minmax(12.5rem, 1fr));
    }
  }
}
@media screen {
  .gw-form .gw-grid > .gw-form__field:not(.gw-form__range--multiple) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    max-width: initial;
  }
}
@media screen {
  .gw-form .gw-grid.gw-grid--form-ranges > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    .gw-form .gw-grid.gw-grid--form-ranges {
      grid-template-columns: repeat(auto-fill, minmax(12.5rem, 25rem));
    }
  }
}
@media screen {
  .gw-form .gw-grid .gw-button {
    height: 3rem;
  }
}
@media screen {
  [dir="ltr"] .gw-form[id=site-search]{
            margin-right: 0;
  }
  [dir="rtl"] .gw-form[id=site-search]{
            margin-left: 0;
  }
  [dir="ltr"] .gw-form[id=site-search]{
            margin-left: 0;
  }
  [dir="rtl"] .gw-form[id=site-search]{
            margin-right: 0;
  }
  .gw-form[id=site-search] {
    /* stylelint-disable no-descending-specificity */
    align-items: center;
    background-color: rgb(var(--themeGrey1));
    display: flex;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
    max-width: none;
    /* stylelint-enable no-descending-specificity */
  }
  [dir="ltr"] .gw-form[id=site-search] .gw-icon{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-form[id=site-search] .gw-icon{
            margin-right: 0.75rem;
  }
  .gw-form[id=site-search] .gw-icon {
    color: rgb(var(--themeColorBrand));
    height: 1.5rem;
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
    width: 1.5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-form[id=site-search] .gw-icon{
            margin-left: 1.25rem;
  }
  [dir="rtl"] .gw-form[id=site-search] .gw-icon{
            margin-right: 1.25rem;
  }
  .gw-form[id=site-search] .gw-icon {
    height: 2rem;
            margin-inline-start: 1.25rem;
    -webkit-margin-start: 1.25rem;
    width: 2rem;
  }
}
@media screen {
  .gw-form[id=site-search] .gw-form__field {
    margin: 0;
    max-width: 100%;
    min-width: 0;
    width: 100%;
  }
}
@media screen {
  .gw-form[id=site-search] .gw-form__label {
    margin: 0;
  }
  .gw-form[id=site-search] .gw-form__label span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-form[id=site-search] .gw-form__label span:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
}
@media screen {
  .gw-form[id=site-search] .gw-form__input {
    background-color: transparent;
    border: 0;
    height: 3rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-form[id=site-search] .gw-form__input {
    height: 4rem;
  }
}
@media screen {
  .gw-form[id=site-search] .gw-button {
    background-color: rgb(var(--themeGrey3));
    color: rgb(var(--themeColorTextAlt));
    height: 3rem;
    padding: 0.75rem;
  }
}
@media screen and (max-width: 48em) {
  .gw-form[id=site-search] .gw-button {
    font-size: 0.6875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-form[id=site-search] .gw-button {
    height: 4rem;
  }
}
@media screen {
  .gw-form [id*=gw-robo],
.gw-form [name*=gw-robo] {
    display: none;
  }
}
@media screen {
  .gw-form__title {
    text-align: center;
  }
  .gw-form__description {
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 100%;
    font-size: clamp(1rem, 1rem + 0.2vw, 1.25rem);
    line-height: 1.6;
    text-align: center;
  }
  [dir=rtl] .gw-form__description {
    font-size: clamp(1rem, calc(1rem + 0.2vw) * 1.125, 1.25rem);
  }
  .gw-form__label {
    color: rgb(var(--themeColorTextSecondary));
    font-size: 1rem;
  }
  .gw-form__select {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" style="fill: rgb(0, 9, 91)"><path d="M20,40l2.8-2.8L50,64.3l27.2-27.1L80,40L50,70L20,40z"/></svg>');
  }
  .gw-form__select--checkboxes {
    background-color: rgb(var(--themeWhite));
  }
  .gw-form__select.selectize-control .selectize-input {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" style="fill: rgb(0, 9, 91)"><path d="M20,40l2.8-2.8L50,64.3l27.2-27.1L80,40L50,70L20,40z"/></svg>');
    border-radius: 0.5rem;
  }
  .gw-form__input, .gw-form__select, .gw-form__textarea {
    background-color: rgb(var(--themeWhite));
    border-color: rgb(var(--themeGrey3));
    border-radius: 0.5rem;
    color: rgb(var(--themeBlack));
  }
  .gw-form__input ::-moz-placeholder, .gw-form__select ::-moz-placeholder, .gw-form__textarea ::-moz-placeholder {
    color: rgb(var(--themeColorTextSecondary));
  }
  .gw-form__input :-ms-input-placeholder, .gw-form__select :-ms-input-placeholder, .gw-form__textarea :-ms-input-placeholder {
    color: rgb(var(--themeColorTextSecondary));
  }
  .gw-form__input ::placeholder, .gw-form__select ::placeholder, .gw-form__textarea ::placeholder {
    color: rgb(var(--themeColorTextSecondary));
  }
  .gw-form__input:focus, .gw-form__input:required:focus, .gw-form__select:focus, .gw-form__select:required:focus, .gw-form__textarea:focus, .gw-form__textarea:required:focus {
    border-color: rgb(var(--themeGrey5));
  }
  /* stylelint-disable-next-line max-nesting-depth */
  [data-whatintent=mouse] .gw-form__input:focus, [data-whatintent=mouse] .gw-form__input:required:focus, [data-whatintent=mouse] .gw-form__select:focus, [data-whatintent=mouse] .gw-form__select:required:focus, [data-whatintent=mouse] .gw-form__textarea:focus, [data-whatintent=mouse] .gw-form__textarea:required:focus {
    box-shadow: none;
  }

  [data-whatintent=keyboard] .gw-form__input:focus:focus, [data-whatintent=keyboard] .gw-form__input:required:focus:focus, [data-whatintent=keyboard] .gw-form__select:focus:focus, [data-whatintent=keyboard] .gw-form__select:required:focus:focus, [data-whatintent=keyboard] .gw-form__textarea:focus:focus, [data-whatintent=keyboard] .gw-form__textarea:required:focus:focus {
    box-shadow: none;
    outline: 0.125rem solid rgb(var(--themeBlack));
    outline-offset: 0.375rem;
    outline-style: solid;
  }

  [data-whatintent=keyboard] .gw-form__input[type=checkbox]:focus, [data-whatintent=keyboard] .gw-form__input[type=radio]:focus, [data-whatintent=keyboard] .gw-form__select[type=checkbox]:focus, [data-whatintent=keyboard] .gw-form__select[type=radio]:focus, [data-whatintent=keyboard] .gw-form__textarea[type=checkbox]:focus, [data-whatintent=keyboard] .gw-form__textarea[type=radio]:focus {
    box-shadow: none;
    outline: 0.125rem solid rgb(var(--themeBlack));
    outline-offset: 0.375rem;
    outline-style: solid;
  }

  .gw-form__input[type=checkbox]:invalid:required:focus, .gw-form__input[type=radio]:invalid:required:focus, .gw-form__select[type=checkbox]:invalid:required:focus, .gw-form__select[type=radio]:invalid:required:focus, .gw-form__textarea[type=checkbox]:invalid:required:focus, .gw-form__textarea[type=radio]:invalid:required:focus {
    box-shadow: none;
    outline-color: rgb(var(--themeAccentNegative));
  }
  /* stylelint-disable max-nesting-depth */
  [data-whatintent=mouse] .gw-form__input[type=range]:focus, [data-whatintent=keyboard] .gw-form__input[type=range]:focus, [data-whatintent=mouse] .gw-form__select[type=range]:focus, [data-whatintent=keyboard] .gw-form__select[type=range]:focus, [data-whatintent=mouse] .gw-form__textarea[type=range]:focus, [data-whatintent=keyboard] .gw-form__textarea[type=range]:focus {
    background-color: transparent;
    box-shadow: none;
  }

  /* stylelint-enable max-nesting-depth */
  .gw-form__input[type=range]::-webkit-slider-runnable-track, .gw-form__input[type=range]::-moz-range-track, .gw-form__select[type=range]::-webkit-slider-runnable-track, .gw-form__select[type=range]::-moz-range-track, .gw-form__textarea[type=range]::-webkit-slider-runnable-track, .gw-form__textarea[type=range]::-moz-range-track {
    background-color: rgb(var(--themeGrey2));
    height: 0.625rem;
  }
  .gw-form__input[type=range]::-webkit-slider-thumb, .gw-form__input[type=range]::-moz-range-thumb, .gw-form__select[type=range]::-webkit-slider-thumb, .gw-form__select[type=range]::-moz-range-thumb, .gw-form__textarea[type=range]::-webkit-slider-thumb, .gw-form__textarea[type=range]::-moz-range-thumb {
    background-color: rgb(var(--themeGrey3));
  }
  .gw-form__range .gw-form__label {
    background-color: transparent;
  }
  .gw-form__range__bubble {
    background-color: rgb(var(--themeWhite));
    border: 0.0625rem solid rgb(var(--themeGrey2));
    border-radius: 0.5rem;
    color: rgb(var(--themeBlack));
    padding: 0.75rem;
  }
  .gw-form__field--checkbox-custom, .gw-form__fieldset--checkbox-custom .gw-form__field {
    background-color: rgb(var(--themeWhite));
    border-radius: 0.5rem;
  }
  .gw-form__field--checkbox-custom .gw-form__label, .gw-form__fieldset--checkbox-custom .gw-form__field .gw-form__label {
    color: rgb(var(--themeColorBrand));
  }
  /* stylelint-disable max-nesting-depth, no-descending-specificity */
  [data-whatintent=mouse] .gw-form__field--checkbox-custom [type=checkbox]:focus, [data-whatintent=keyboard] .gw-form__field--checkbox-custom [type=checkbox]:focus, [data-whatintent=mouse] .gw-form__fieldset--checkbox-custom .gw-form__field [type=checkbox]:focus, [data-whatintent=keyboard] .gw-form__fieldset--checkbox-custom .gw-form__field [type=checkbox]:focus {
    outline: none;
  }

  /* stylelint-enable max-nesting-depth, no-descending-specificity */
  .gw-form[id=site-search] .gw-form__input {
    border-radius: 0;
  }
  .gw-form[id=site-search] .gw-form__label {
    font-size: 1rem;
  }
  [dir="ltr"] .gw-form[id=site-search] .gw-button{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-form[id=site-search] .gw-button{
            margin-left: 0.75rem;
  }
  .gw-form[id=site-search] .gw-button {
    background-color: rgb(var(--themeColorBrandAlt));
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorTextAlt));
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    height: auto;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
  .gw-form[id=site-search] .gw-button:hover:not(:disabled) {
    background-color: rgb(var(--themeColorLinkHover));
    border-color: rgb(var(--themeColorLinkHover));
    color: rgb(var(--themeColorTextAlt));
    opacity: 1;
    text-decoration: none;
    transform: scale(1);
  }
  .gw-form .gw-form__fieldset--checkbox-custom [type=checkbox],
.gw-form .gw-form__field--checkbox-custom [type=checkbox] {
    visibility: hidden;
  }
  .gw-form .gw-form__fieldset--checkbox-custom [class*=gw-form__field], .gw-form .gw-form__fieldset--checkbox-custom[class*=gw-form__field],
.gw-form .gw-form__field--checkbox-custom [class*=gw-form__field],
.gw-form .gw-form__field--checkbox-custom[class*=gw-form__field] {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-form .gw-form__fieldset--checkbox-custom [class*=gw-form__field].is-checked, .gw-form .gw-form__fieldset--checkbox-custom[class*=gw-form__field].is-checked,
.gw-form .gw-form__field--checkbox-custom [class*=gw-form__field].is-checked,
.gw-form .gw-form__field--checkbox-custom[class*=gw-form__field].is-checked {
    background-color: rgb(var(--themeColorBrand));
  }
  .gw-form .gw-form__fieldset--checkbox-custom [class*=gw-form__field].is-checked .gw-form__label, .gw-form .gw-form__fieldset--checkbox-custom[class*=gw-form__field].is-checked .gw-form__label,
.gw-form .gw-form__field--checkbox-custom [class*=gw-form__field].is-checked .gw-form__label,
.gw-form .gw-form__field--checkbox-custom[class*=gw-form__field].is-checked .gw-form__label {
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-form__field--has-error:not(:valid):focus, .gw-form__field--has-error:not(:invalid):focus {
    box-shadow: 0 0.5rem 0.75rem rgba(var(--themeBlack), 0.2);
  }
  .gw-form :disabled {
    background-color: rgb(var(--themeGrey1));
    color: rgb(var(--themeColorTextSecondary));
    opacity: 1;
  }
  .gw-form :disabled.gw-form__select {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" style="fill: rgb(107, 119, 134)"><path d="M20,40l2.8-2.8L50,64.3l27.2-27.1L80,40L50,70L20,40z"/></svg>');
  }
  [dir="ltr"] .gw-form .is-disabled.gw-form__fieldset--checkbox span,[dir="ltr"]  .gw-form .is-disabled.gw-form__fieldset--radio span{
            margin-left: 1.875rem;
  }
  [dir="rtl"] .gw-form .is-disabled.gw-form__fieldset--checkbox span,[dir="rtl"]  .gw-form .is-disabled.gw-form__fieldset--radio span{
            margin-right: 1.875rem;
  }
  .gw-form .is-disabled.gw-form__fieldset--checkbox span, .gw-form .is-disabled.gw-form__fieldset--radio span {
    background-color: rgb(var(--themeGrey1));
    color: rgb(var(--themeColorTextSecondary));
            margin-inline-start: 1.875rem;
    -webkit-margin-start: 1.875rem;
    padding: 0 0.375rem;
    position: relative;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [dir="ltr"] .gw-form .is-disabled.gw-form__fieldset--checkbox span:after,[dir="ltr"]  .gw-form .is-disabled.gw-form__fieldset--radio span:after{
    left: -1.875rem;
  }
  [dir="rtl"] .gw-form .is-disabled.gw-form__fieldset--checkbox span:after,[dir="rtl"]  .gw-form .is-disabled.gw-form__fieldset--radio span:after{
    right: -1.875rem;
  }
  .gw-form .is-disabled.gw-form__fieldset--checkbox span:after, .gw-form .is-disabled.gw-form__fieldset--radio span:after {
    background-color: rgb(var(--themeGrey1));
    content: "";
    height: 1rem;
    top: 0.125rem;
    inset-inline-start: -1.875rem;
    position: absolute;
    width: 1rem;
  }
  .gw-form .is-disabled.gw-form__fieldset--checkbox input, .gw-form .is-disabled.gw-form__fieldset--radio input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-form .is-disabled.gw-form__fieldset--checkbox input:focus, .gw-form .is-disabled.gw-form__fieldset--radio input:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
  .gw-form .is-disabled.gw-form__fieldset--radio span {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-form .is-disabled.gw-form__fieldset--radio span:after {
    border-radius: 50%;
  }
}
@media screen {
  .gw-gallery {
    overflow-x: hidden;
    /* stylelint-disable order/order, max-nesting-depth */
    /* stylelint-enable order/order, max-nesting-depth */
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-gallery + .gw-gallery {
    -webkit-margin-before: -3.5rem;
            margin-top: -3.5rem;
  }
  .gw-gallery--portfolio.gw-slider {
    -webkit-padding-after: 0;
            padding-bottom: 0;
  }
  .gw-gallery--portfolio.gw-slider [data-button-type] {
    background-color: rgba(var(--themeColorBackgroundAlt), 0.6);
    bottom: 0.75rem;
    width: 5rem;
  }
  .gw-gallery--portfolio.gw-slider [data-button-type] > svg {
    opacity: 0.5;
  }
  .gw-gallery--portfolio.gw-slider [data-button-type]:hover:not(:disabled) {
    opacity: inherit;
  }
  .gw-gallery--portfolio.gw-slider [data-button-type]:hover:not(:disabled) > svg {
    opacity: 1;
  }
  [dir="ltr"] .gw-gallery--portfolio[data-display-slide-count] > .gw-button-group > span{
    left: 50%;
  }
  [dir="rtl"] .gw-gallery--portfolio[data-display-slide-count] > .gw-button-group > span{
    right: 50%;
  }
  .gw-gallery--portfolio[data-display-slide-count] > .gw-button-group > span {
    color: rgb(var(--themeWhite));
    bottom: 0.75rem;
    inset-inline-start: 50%;
    margin: 0;
    position: absolute;
    transform: translate(-50%, -25%);
    z-index: 2;
  }
  [dir="ltr"] .gw-gallery--portfolio[data-display-slide-count] [data-button-type=previous]{
    left: 50%;
  }
  [dir="rtl"] .gw-gallery--portfolio[data-display-slide-count] [data-button-type=previous]{
    right: 50%;
  }
  .gw-gallery--portfolio[data-display-slide-count] [data-button-type=previous] {
    border-radius: 20rem 0 0 20rem;
    inset-inline-start: 50%;
    justify-content: flex-start;
  }
  [dir="ltr"] .gw-gallery--portfolio[data-display-slide-count] [data-button-type=next]{
    right: 50%;
  }
  [dir="rtl"] .gw-gallery--portfolio[data-display-slide-count] [data-button-type=next]{
    left: 50%;
  }
  .gw-gallery--portfolio[data-display-slide-count] [data-button-type=next] {
    border-radius: 0 20rem 20rem 0;
    inset-inline-end: 50%;
    justify-content: flex-end;
  }
  .gw-gallery--portfolio-thumbs.gw-slider {
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-gallery--portfolio-thumbs.gw-slider [data-button-type] {
    position: inherit;
    transform: none;
  }
  .gw-gallery--portfolio-thumbs.gw-slider .gw-button-group {
    align-items: center;
    display: flex;
    gap: 0 0.75rem;
    justify-content: center;
    -webkit-margin-before: 1.25rem;
            margin-top: 1.25rem;
  }
  .gw-gallery--portfolio-thumbs.gw-slider .gw-slider__thumbnail-guide {
    background-color: rgb(var(--themeGrey2));
    height: 0.25rem;
    width: 1.5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-gallery--portfolio-thumbs.gw-slider .gw-slider__thumbnail-guide {
    width: 3rem;
  }
}
@media screen {
  .gw-gallery--portfolio-thumbs.gw-slider .gw-slider__thumbnail-guide--container {
    display: flex;
    gap: 0 0.5rem;
    margin: 0;
  }
}
@media screen {
  .gw-gallery--portfolio-thumbs.gw-slider .gw-slider__thumbnail-guide.is-selected {
    background-color: rgb(var(--themeColorBrand));
  }
}
@media screen {
  .gw-gallery--portfolio-thumbs.gw-slider .gw-slider__slide:hover {
    cursor: pointer;
  }
}
@media screen {
  .gw-gallery--portfolio > .gw-icon--container {
    align-items: center;
    background-color: rgba(var(--themeGrey2), 0.6);
    display: flex;
    height: 100%;
    justify-content: center;
    margin-left: 0.375rem;
    margin-right: 0.375rem;
    margin-inline: 0.375rem;
    pointer-events: none;
    position: absolute;
    width: calc(100% - 0.75rem);
    z-index: 1;
  }
  .gw-gallery--portfolio > .gw-icon--container > .gw-icon {
    fill: rgb(var(--themeWhite));
    height: 30%;
    width: 30%;
  }
}
@media screen {
  .gw-gallery__link {
    display: block;
    width: 100%;
  }
  [data-whatintent=keyboard] .gw-gallery__link:focus {
    box-shadow: 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
  }
}
@media screen {
  .gw-gallery__figure {
    height: 100%;
    position: relative;
  }
}
@media screen {
  .gw-gallery .gw-image {
    height: 100%;
    width: 100%;
  }
}
@media screen {
  .gw-gallery__caption {
    align-items: center;
    background-color: rgba(var(--themeColorBackgroundAlt), 0.5);
    bottom: 0;
    color: rgb(var(--themeColorTextAlt));
    display: flex;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: opacity 0.5s ease-in-out;
    width: 100%;
  }
  .gw-gallery__link:hover .gw-gallery__caption, .gw-gallery__link:focus .gw-gallery__caption {
    opacity: 1;
  }
}
@media screen {
  .gw-gallery.gw-grid {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
  }
  .gw-gallery.gw-grid > * {
    min-width: 15rem;
  }
  @supports (grid-area: auto) {
    .gw-gallery.gw-grid {
      grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    }
  }
  .gw-gallery.gw-grid > * {
    margin: 0.125rem;
  }
  @supports (grid-area: auto) {
    .gw-gallery.gw-grid {
      display: grid;
      gap: 0.25rem;
    }
    .gw-gallery.gw-grid > * {
      height: 100%;
      margin: 0;
    }
  }
}
@media screen {
  [dir="ltr"] .gw-gallery[class*="--layout-thumbs"]{
            margin-right: auto;
  }
  [dir="rtl"] .gw-gallery[class*="--layout-thumbs"]{
            margin-left: auto;
  }
  [dir="ltr"] .gw-gallery[class*="--layout-thumbs"]{
            margin-left: auto;
  }
  [dir="rtl"] .gw-gallery[class*="--layout-thumbs"]{
            margin-right: auto;
  }
  .gw-gallery[class*="--layout-thumbs"] {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
    width: min(var(--themeMaxContentWidth), 100%);
  }
}
@media screen {
  @supports (grid-area: auto) {
    @media screen and (max-width: 30em) {
      .gw-gallery[class*="--layout-thumbs"] {
        display: block;
      }
      .gw-gallery[class*="--layout-thumbs"] > * + * {
        -webkit-margin-before: 0.25rem;
                margin-top: 0.25rem;
      }
    }
    .gw-gallery[class*="--layout-thumbs-2"] > * {
      min-width: calc(50% - (0.25rem / 2));
    }
    @supports (grid-area: auto) {
      .gw-gallery[class*="--layout-thumbs-2"] {
        grid-template-columns: repeat(2, minmax(calc(50% - (0.25rem / 2)), calc(50% - (0.25rem / 2))));
      }
    }
    .gw-gallery.gw-gallery--layout-thumbs-3 {
      grid-template-areas: "large large ." "large large .";
    }
    .gw-gallery.gw-gallery--layout-thumbs-3 > :first-child {
      grid-area: large;
    }
    .gw-gallery.gw-gallery--layout-thumbs-3-alt {
      grid-template-areas: ". large large" ". large large";
    }
    .gw-gallery.gw-gallery--layout-thumbs-3-alt > :last-child {
      grid-area: large;
    }
    .gw-gallery[class*="--layout-thumbs-3"] > * {
      min-width: calc(33.3333333333% - (2 * 0.25rem / 3));
    }
    @supports (grid-area: auto) {
      .gw-gallery[class*="--layout-thumbs-3"] {
        grid-template-columns: repeat(3, minmax(calc(33.3333333333% - (2 * 0.25rem / 3)), calc(33.3333333333% - (2 * 0.25rem / 3))));
      }
    }
    @media screen and (max-width: 48em) {
      .gw-gallery[class*="--layout-thumbs-3"] {
        grid-template-areas: "large large" ". .";
      }
      .gw-gallery[class*="--layout-thumbs-3"] > * {
        min-width: calc(50% - (0.25rem / 2));
      }
      @supports (grid-area: auto) {
        .gw-gallery[class*="--layout-thumbs-3"] {
          grid-template-columns: repeat(2, minmax(calc(50% - (0.25rem / 2)), calc(50% - (0.25rem / 2))));
        }
      }
    }
  }
}
@media screen {
  .gw-gallery__tabs.gw-tabs.is-js-enabled .gw-tabs__content {
    background-color: transparent;
    border: 0;
    padding: 0;
  }
  .gw-gallery__tabs.gw-tabs .gw-tabs__tablist {
    justify-content: center;
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
  }
  .gw-gallery__tabs.gw-tabs .gw-tabs__button {
    border: 0.0625rem solid rgb(var(--themeColorBrand));
    bottom: 0;
    margin: 0;
  }
  .gw-gallery__tabs.gw-tabs [aria-selected=true] {
    background-color: rgb(var(--themeColorBrand));
    border-bottom-color: rgb(var(--themeColorBrand));
    bottom: 0;
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-gallery__tabs.gw-tabs [aria-selected=false] {
    background-color: transparent;
  }
}
@media screen {
  .gw-gallery.gw-slider:not(.gw-gallery--portfolio):not(.gw-gallery--gallery2) {
    -webkit-padding-after: 3.75rem;
            padding-bottom: 3.75rem;
  }
  .gw-gallery.gw-slider .gw-slider__slide {
    display: flex;
    justify-content: center;
  }
  .gw-gallery.gw-slider .gw-gallery__caption {
    height: auto;
    opacity: 1;
    padding: 0.75rem;
  }
  .gw-gallery.gw-slider [role=tablist] {
    bottom: 3.75rem;
  }
}
@media screen {
  .gw-gallery__modal.gw-modal__overlay {
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-gallery__modal.gw-modal__overlay .gw-modal__inner {
    background-color: rgb(var(--themeWhite));
    max-height: 98%;
    max-width: 64rem;
    padding: 0;
    -webkit-padding-before: 3.75rem;
            padding-top: 3.75rem;
    width: 95%;
  }
  .gw-gallery__modal.gw-modal__overlay [data-modal-close] {
    background-color: unset;
    color: inherit;
    z-index: 1;
  }
  .gw-gallery__modal.gw-modal__overlay [data-modal-title] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-gallery__modal.gw-modal__overlay [data-modal-title]:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
  .gw-gallery__modal.gw-modal__overlay [data-modal-title]:focus {
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    width: 1px;
  }
  .gw-gallery__modal.gw-modal__overlay .gw-slider {
    -webkit-padding-after: 5rem;
            padding-bottom: 5rem;
  }
  .gw-gallery__modal.gw-modal__overlay .gw-slider [data-button-type] {
    bottom: 0.75rem;
    top: unset;
  }
  .gw-gallery__modal.gw-modal__overlay .gw-slider [data-button-type=previous] {
    left: 48%;
    right: auto;
    transform: translateX(-100%);
  }
  .gw-gallery__modal.gw-modal__overlay .gw-slider [data-button-type=next] {
    left: auto;
    right: 48%;
    transform: translateX(100%);
  }
  .gw-gallery__modal.gw-modal__overlay .gw-gallery {
    margin: 0;
  }
  .gw-gallery__modal.gw-modal__overlay .gw-slider__slide {
    display: block;
    overflow: hidden;
    -webkit-padding-before: 75%;
            padding-top: 75%;
    position: relative;
    width: 100%;
  }
  @supports (aspect-ratio: auto) {
    .gw-gallery__modal.gw-modal__overlay .gw-slider__slide {
      -webkit-padding-before: unset !important;
              padding-top: unset !important;
      width: unset;
    }
  }
  [dir="ltr"] .gw-gallery__modal.gw-modal__overlay .gw-slider__slide > :first-child,[dir="ltr"] 
.gw-gallery__modal.gw-modal__overlay .gw-slider__slide > br + *{
    left: 0;
  }
  [dir="rtl"] .gw-gallery__modal.gw-modal__overlay .gw-slider__slide > :first-child,[dir="rtl"] 
.gw-gallery__modal.gw-modal__overlay .gw-slider__slide > br + *{
    right: 0;
  }
  .gw-gallery__modal.gw-modal__overlay .gw-slider__slide > :first-child,
.gw-gallery__modal.gw-modal__overlay .gw-slider__slide > br + * {
    height: 100%;
    top: 0;
    inset-inline-start: 0;
    position: absolute;
    width: 100%;
  }
  @supports (aspect-ratio: auto) {
    .gw-gallery__modal.gw-modal__overlay .gw-slider__slide > :first-child,
.gw-gallery__modal.gw-modal__overlay .gw-slider__slide > br + * {
      /* stylelint-disable property-no-unknown */
      aspect-ratio: unset;
      /* stylelint-enable property-no-unknown */
      height: unset;
      position: unset;
    }
  }
  .gw-gallery__modal.gw-modal__overlay .gw-slider__slide.gw-slider__slide {
    width: 100%;
  }
  .gw-gallery__modal.gw-modal__overlay .gw-image {
    max-height: 98vh;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
@media screen and (max-height: 60em) {
  .gw-gallery__modal.gw-modal__overlay .gw-image {
    max-height: 70vh;
  }
}
@media screen {
  .gw-gallery__modal.gw-modal__overlay .gw-modal__ajax {
    margin: 0;
  }
}
@media screen {
  .gw-gallery__wrap {
    max-width: 64rem;
  }
}
@media screen {
  [dir="ltr"] .gw-gallery--gallery2 .gw-slider__slide,[dir="ltr"]  .gw-gallery--gallery2-thumbs .gw-slider__slide{
            padding-right: 0.375rem;
  }
  [dir="rtl"] .gw-gallery--gallery2 .gw-slider__slide,[dir="rtl"]  .gw-gallery--gallery2-thumbs .gw-slider__slide{
            padding-left: 0.375rem;
  }
  [dir="ltr"] .gw-gallery--gallery2 .gw-slider__slide,[dir="ltr"]  .gw-gallery--gallery2-thumbs .gw-slider__slide{
            padding-left: 0.375rem;
  }
  [dir="rtl"] .gw-gallery--gallery2 .gw-slider__slide,[dir="rtl"]  .gw-gallery--gallery2-thumbs .gw-slider__slide{
            padding-right: 0.375rem;
  }
  .gw-gallery--gallery2 .gw-slider__slide, .gw-gallery--gallery2-thumbs .gw-slider__slide {
    -webkit-padding-end: 0.375rem;
            padding-inline-end: 0.375rem;
            padding-inline-start: 0.375rem;
    -webkit-padding-start: 0.375rem;
  }
  .gw-gallery--gallery2 .gw-gallery__link, .gw-gallery--gallery2-thumbs .gw-gallery__link {
    display: block;
    overflow: hidden;
    -webkit-padding-before: 75%;
            padding-top: 75%;
    position: relative;
    width: 100%;
  }
  @supports (aspect-ratio: auto) {
    .gw-gallery--gallery2 .gw-gallery__link, .gw-gallery--gallery2-thumbs .gw-gallery__link {
      -webkit-padding-before: unset !important;
              padding-top: unset !important;
      width: unset;
    }
  }
  [dir="ltr"] .gw-gallery--gallery2 .gw-gallery__link > :first-child,[dir="ltr"] 
.gw-gallery--gallery2 .gw-gallery__link > br + *,[dir="ltr"] 
.gw-gallery--gallery2-thumbs .gw-gallery__link > :first-child,[dir="ltr"] 
.gw-gallery--gallery2-thumbs .gw-gallery__link > br + *{
    left: 0;
  }
  [dir="rtl"] .gw-gallery--gallery2 .gw-gallery__link > :first-child,[dir="rtl"] 
.gw-gallery--gallery2 .gw-gallery__link > br + *,[dir="rtl"] 
.gw-gallery--gallery2-thumbs .gw-gallery__link > :first-child,[dir="rtl"] 
.gw-gallery--gallery2-thumbs .gw-gallery__link > br + *{
    right: 0;
  }
  .gw-gallery--gallery2 .gw-gallery__link > :first-child,
.gw-gallery--gallery2 .gw-gallery__link > br + *, .gw-gallery--gallery2-thumbs .gw-gallery__link > :first-child,
.gw-gallery--gallery2-thumbs .gw-gallery__link > br + * {
    height: 100%;
    top: 0;
    inset-inline-start: 0;
    position: absolute;
    width: 100%;
  }
  @supports (aspect-ratio: auto) {
    .gw-gallery--gallery2 .gw-gallery__link > :first-child,
.gw-gallery--gallery2 .gw-gallery__link > br + *, .gw-gallery--gallery2-thumbs .gw-gallery__link > :first-child,
.gw-gallery--gallery2-thumbs .gw-gallery__link > br + * {
      /* stylelint-disable property-no-unknown */
      aspect-ratio: 1.3333333333;
      /* stylelint-enable property-no-unknown */
      height: unset;
      position: unset;
    }
  }
  .gw-gallery--gallery2 .gw-gallery__link.gw-gallery__link, .gw-gallery--gallery2-thumbs .gw-gallery__link.gw-gallery__link {
    width: 100%;
  }
}
@media screen {
  .gw-gallery--gallery2 + .gw-gallery--gallery2-thumbs {
    -webkit-margin-before: -3rem;
            margin-top: -3rem;
  }
}
@media screen {
  .gw-gallery--gallery2-thumbs .gw-slider__slide {
    flex-basis: 25%;
  }
}
@media screen and (max-width: 30em) {
  .gw-gallery--gallery2-thumbs .gw-slider__slide {
    flex-basis: 33.333%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-gallery--gallery2-thumbs .gw-slider__slide {
    flex-basis: 20%;
  }
}
@media screen and (min-width: 64em) {
  .gw-gallery--gallery2-thumbs .gw-slider__slide {
    flex-basis: 16.667%;
  }
}
@media screen {
  .gw-gallery--portfolio {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-gallery--portfolio.gw-slider [data-button-type] {
    background-color: rgb(var(--themeBlack));
  }
  .gw-gallery--portfolio.gw-slider [data-button-type]:hover:not(:disabled) {
    background-color: rgba(var(--themeBlack), 0.7);
  }
  .gw-gallery--portfolio-thumbs.gw-slider .gw-slider__thumbnail-guide {
    border-radius: 2rem;
  }
  .gw-gallery--portfolio-thumbs.gw-slider .gw-slider__thumbnail-guide.is-selected {
    background-color: rgb(var(--themeColorBrandAlt));
  }
}
@media screen {
  .gw-gallery-slider {
    overflow-y: hidden;
  }
  .gw-gallery-slider .gw-button-group:not([role=tablist]) {
    display: none;
  }
  .gw-gallery-slider.gw-slider .gw-slider__slide {
    display: flex;
    justify-content: center;
  }
  .gw-gallery-slider.gw-slider [role=tab] {
    border-color: transparent;
    border-radius: 0;
    border-width: 0.25rem;
    height: auto;
    margin: 0;
    max-height: 7.03125rem;
    max-width: 9.375rem;
    padding: 0;
    width: auto;
  }
  .gw-gallery-slider.gw-slider [role=tab][aria-selected=true] {
    background-color: transparent;
    border-color: rgb(var(--themeColorBrand));
  }
  .gw-gallery-slider [data-slide-tab] {
    display: block;
    line-height: 0;
  }
}
@media screen {
  .gw-grid {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  .gw-grid > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    .gw-grid {
      grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
    }
  }
  .gw-grid > * {
    margin: 0.9375rem;
  }
  @supports (grid-area: auto) {
    .gw-grid {
      display: grid;
      gap: 1.875rem;
    }
    .gw-grid > * {
      height: 100%;
      margin: 0;
    }
  }
  ul.gw-grid {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }

  .gw-grid > li {
    max-width: none;
  }
  .gw-grid--no-gap {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
  }
  .gw-grid--no-gap > * {
    margin: 0;
  }
  @supports (grid-area: auto) {
    .gw-grid--no-gap {
      display: grid;
      gap: 0;
    }
    .gw-grid--no-gap > * {
      height: 100%;
      margin: 0;
    }
  }
  .gw-grid--centered {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .gw-grid--centered > * {
    margin: 0.9375rem;
  }
  @supports (grid-area: auto) {
    .gw-grid--centered {
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      justify-content: center;
    }
    .gw-grid--centered > * {
      flex-grow: 0;
      margin: 0.9375rem;
    }
  }
}
@media screen and (min-width: 64em) {
  .gw-grid--asymmetric {
    display: grid;
    gap: 1.875rem;
    grid-template-areas: "col1 col2";
    grid-template-columns: calc(60% - 1.875rem / 2) calc(40% - 1.875rem / 2);
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  .gw-grid--asymmetric > :first-child {
    grid-area: col1;
  }
  .gw-grid--asymmetric > :last-child {
    grid-area: col2;
  }
}
@media screen and (min-width: 64em) {
  .gw-grid--asymmetric-alt {
    display: grid;
    gap: 1.875rem;
    grid-template-areas: "col1 col2";
    grid-template-areas: "col2 col1";
    grid-template-columns: calc(60% - 1.875rem / 2) calc(40% - 1.875rem / 2);
    grid-template-columns: calc(40% - 1.875rem / 2) calc(60% - 1.875rem / 2);
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  .gw-grid--asymmetric-alt > :first-child {
    grid-area: col1;
  }
  .gw-grid--asymmetric-alt > :last-child {
    grid-area: col2;
  }
}
@media screen {
  .gw-guide-card {
    max-width: 30rem;
    width: auto;
  }
  .gw-guide-card--grid {
    display: block;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-guide-card--grid {
    display: grid;
    grid-template-columns: repeat(3, fit-content(30rem));
  }
}
@media screen {
  .gw-guide-card.gw-card:hover {
    box-shadow: none;
  }
}
@media screen {
  .gw-guide-card [class$=__content] {
    order: 2;
  }
}
@media screen {
  .gw-guide-card .gw-card__title {
    font-size: 1.5rem;
    margin: 1.875rem 0;
  }
  .gw-guide-card .gw-card__title a {
    color: rgb(var(--themeBlack));
  }
}
@media screen {
  .gw-header-card {
    padding: 1rem;
  }
  .gw-header-card__upper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header-card__upper {
    flex-flow: row nowrap;
  }
}
@media screen {
  .gw-header-card__lower {
    background: rgb(var(--themeColorBackgroundAlt));
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header-card__lower {
    flex-flow: row wrap;
    margin: -12rem 0 4rem 0;
    padding: 6rem 5rem 0.5rem 5rem;
  }
}
@media screen {
  .gw-header-card__lower > div {
    flex: 0 1 50%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header-card__lower > div {
    flex: 0 0 calc(50% - 2rem);
  }
}
@media screen {
  .gw-header-card__image {
    max-width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header-card__image {
    max-width: 50%;
  }
}
@media screen {
  .gw-header-card__sub-title {
    flex: 0 1 100%;
    -webkit-margin-before: 3rem;
            margin-top: 3rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-header-card__sub-title{
            margin-right: 50%;
  }
  [dir="rtl"] .gw-header-card__sub-title{
            margin-left: 50%;
  }
  .gw-header-card__sub-title {
    -webkit-margin-after: 5rem;
    -webkit-margin-before: 0;
            margin-bottom: 5rem;
            margin-top: 0;
    -webkit-margin-end: 50%;
            margin-inline-end: 50%;
  }
}
@media screen {
  .gw-header {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .is-js-sticky .gw-header {
    align-items: center;
    display: grid;
    grid-template-areas: "primary secondary";
    grid-template-columns: auto 1fr;
    grid-template-rows: minmax(4rem, auto);
    position: -webkit-sticky;
    position: sticky;
  }
  .is-js-sticky .gw-header .gw-header__contact {
    display: block;
  }
  .is-js-sticky .gw-header .gw-header--primary {
    grid-template-areas: "home nav";
    grid-template-columns: 1fr auto;
    padding: 0;
  }
  .is-js-sticky .gw-header .gw-header--primary .gw-header__nav {
    align-items: center;
  }
  .is-js-sticky .gw-header .gw-header--secondary {
    background-color: rgb(var(--themeColorBackground));
    grid-template-areas: "search offers contact";
    grid-template-columns: 3fr auto 2.5fr;
  }
}
@media screen and (min-width: 48.0625em) {
  .is-js-sticky .gw-header {
    -webkit-padding-before: 0;
            padding-top: 0;
  }
}
@media screen {
  [dir="ltr"] .is-js-sticky .gw-header__home{
            margin-right: 0;
  }
  [dir="rtl"] .is-js-sticky .gw-header__home{
            margin-left: 0;
  }
  .is-js-sticky .gw-header__home {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
  .is-js-sticky .gw-header__home .gw-image {
    height: 1.625rem;
  }
  .is-js-sticky .gw-header__home .gw-image.is-hidden-on-large {
    /* stylelint-disable-next-line declaration-no-important */
    display: block !important;
  }
  .is-js-sticky .gw-header__home .gw-image + .gw-image {
    display: none;
  }
}
@media screen {
  .is-js-sticky .gw-header__offers {
    height: 100%;
  }
}
@media screen {
  .is-js-sticky .gw-header__logo {
    display: none;
  }
}
@media screen {
  .gw-header--primary {
    background-color: rgb(var(--themeColorBackground));
    display: grid;
    grid-area: primary;
    grid-template-areas: "home nav logo";
    grid-template-columns: auto 4fr 2fr;
    grid-template-rows: minmax(3rem, auto);
    padding: 0.75rem 0;
  }
}
@media screen and (max-width: 48em) {
  .gw-header--primary {
    grid-template-columns: auto auto minmax(50vw, 1fr);
  }
}
@media screen and (max-width: 30em) {
  .gw-header--primary {
    grid-template-columns: minmax(auto, 20vw) auto minmax(50vw, 1fr);
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header--primary {
    grid-template-columns: auto 1fr auto;
  }
}
@media screen and (min-width: 64em) {
  .gw-header--primary {
    grid-template-rows: minmax(4rem, auto);
  }
}
@media screen {
  .gw-header--secondary {
    background-color: rgb(var(--themeGrey1));
    display: grid;
    grid-area: secondary;
    grid-template-areas: "offers contact" "search search";
    grid-template-columns: 0.4fr 3fr;
    grid-template-rows: minmax(3rem, auto) auto;
    row-gap: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header--secondary {
    grid-template-columns: 0.5fr 3fr;
  }
}
@media screen and (min-width: 64em) {
  .gw-header--secondary {
    grid-template-areas: "search offers contact";
    grid-template-columns: 4fr auto 2.5fr;
    grid-template-rows: minmax(4rem, auto);
  }
}
@media screen {
  .gw-header__home {
    align-items: center;
    grid-area: home;
    justify-content: flex-start;
    margin: 0 0.75rem;
    padding: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-header__home{
            margin-right: 1.25rem;
  }
  [dir="rtl"] .gw-header__home{
            margin-left: 1.25rem;
  }
  [dir="ltr"] .gw-header__home{
            margin-left: 1.25rem;
  }
  [dir="rtl"] .gw-header__home{
            margin-right: 1.25rem;
  }
  .gw-header__home {
    -webkit-margin-end: 1.25rem;
            margin-inline-end: 1.25rem;
            margin-inline-start: 1.25rem;
    -webkit-margin-start: 1.25rem;
  }
}
@media screen {
  .gw-header__home .gw-image {
    flex-shrink: 1;
    height: 1.625rem;
    width: auto;
  }
}
@media screen and (max-width: 30em) {
  .gw-header__home .gw-image {
    height: auto;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header__home .gw-image {
    height: 2.125rem;
  }
}
@media screen {
  .gw-header__home .gw-image + .gw-image {
    /* stylelint-disable-next-line max-nesting-depth */
    display: none;
  }
}
@media screen and (min-width: 64em) {
  .gw-header__home .gw-image + .gw-image {
    display: block;
  }
}
@media screen {
  .gw-header__nav {
    grid-area: nav;
  }
}
@media screen {
  .gw-header__logo {
    align-items: center;
    color: rgb(var(--themeColorBrandAlt));
    display: flex;
    grid-area: logo;
    justify-content: flex-end;
    margin: 0 1.25rem;
    /* stylelint-disable-next-line no-descending-specificity */
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-header__logo{
            margin-right: 1.25rem;
  }
  [dir="rtl"] .gw-header__logo{
            margin-left: 1.25rem;
  }
  [dir="ltr"] .gw-header__logo{
            margin-left: 1.875rem;
  }
  [dir="rtl"] .gw-header__logo{
            margin-right: 1.875rem;
  }
  .gw-header__logo {
    -webkit-margin-end: 1.25rem;
            margin-inline-end: 1.25rem;
            margin-inline-start: 1.875rem;
    -webkit-margin-start: 1.875rem;
  }
}
@media screen {
  .gw-header__logo .gw-image {
    max-height: 3.125rem;
    max-width: 15.625rem;
  }
}
@media screen {
  .gw-header__logo * {
    font-size: 100%;
    font-size: clamp(1rem, 1rem + 0.2vw, 1.25rem);
    line-height: 1.6;
    margin: 0;
  }
  [dir=rtl] .gw-header__logo * {
    font-size: clamp(1rem, calc(1rem + 0.2vw) * 1.125, 1.25rem);
  }
}
@media screen {
  .gw-header__search {
    grid-area: search;
  }
}
@media screen {
  .gw-header__offers {
    align-items: center;
    display: flex;
    grid-area: offers;
    justify-content: center;
    max-height: 4rem;
    max-width: 10rem;
    padding: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header__offers {
    padding: inherit;
  }
}
@media screen {
  .gw-header__contact {
    grid-area: contact;
  }
}
@media screen {
  .gw-header__login {
    grid-area: login;
  }
}
@media screen {
  .gw-header--vertical {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header--vertical {
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
  }
}
@media screen {
  .gw-header--vertical .gw-header__home {
    margin: 0;
    padding: 0.75rem;
  }
}
@media screen {
  .gw-header--gc {
    /* stylelint-disable max-nesting-depth */
    background-color: transparent;
    display: grid;
    grid-template-areas: "home . nav login";
    grid-template-columns: auto 1fr auto auto;
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .is-js-sticky .gw-header--gc {
    background-color: rgb(var(--themeWhite));
    grid-template-areas: "home . login nav";
    grid-template-columns: auto 1fr auto auto;
  }
}
@media screen {
  .is-js-sticky .gw-header--gc .gw-header__login.gw-button[class*="--text"] {
    background-color: rgba(var(--themeColorBrand), 0.1);
    color: rgb(var(--themeColorBrand));
  }
  .is-js-sticky .gw-header--gc .gw-header__login.gw-button[class*="--text"]:hover {
    background-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorTextAlt));
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header--gc {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-inline: 5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header--gc .gw-header__home {
    margin: 0;
  }
}
@media screen {
  .gw-header--gc .gw-header__home .gw-image {
    height: 2rem;
  }
}
@media screen and (max-width: 48em) {
  .gw-header--gc .gw-header__home .gw-image.is-hidden-on-large {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
  .gw-header--gc .gw-header__home .gw-image + .gw-image {
    display: block;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header--gc .gw-header__home .gw-image {
    height: 3rem;
  }
}
@media screen {
  .gw-header--gc .gw-header__login {
    display: none;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header--gc .gw-header__login {
    display: flex;
  }
}
@media screen {
  .gw-header--gc .gw-header__login.gw-button[class*="--text"] {
    align-self: center;
    background-color: rgba(var(--themeWhite), 0.2);
    border: 0;
    border-radius: 20rem;
    color: rgb(var(--themeColorTextAlt));
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0.75rem;
  }
}
@media screen {
  .gw-header {
    -webkit-border-after: 0.0625rem solid rgba(var(--themeBlack), 0.2);
            border-bottom: 0.0625rem solid rgba(var(--themeBlack), 0.2);
  }
}
@media screen and (min-width: 48.0625em) {
  .is-js-sticky .gw-header {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .is-js-sticky .gw-header .gw-header--primary {
    height: 100%;
  }
}
@media screen {
  .is-js-sticky .gw-header .gw-header__search,
.is-js-sticky .gw-header .gw-header__contact,
.is-js-sticky .gw-header .gw-header__offers .gw-button.gw-button--text {
    box-shadow: none;
  }
}
@media screen {
  .is-js-sticky .gw-header .gw-header--secondary {
    background-color: rgb(var(--themeWhite));
  }
}
@media screen {
  .gw-header--primary {
    background-color: rgb(var(--themeWhite));
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-header__search, .gw-header__contact {
    box-shadow: 0 -0.0625rem 0 0 rgba(var(--themeBlack), 0.2);
  }
}
@media screen {
  .gw-header__offers {
    border-radius: 0;
  }
  .gw-header__offers.gw-button--primary[class*="--text"], .gw-header__offers.gw-button--primary[class*="--icon"] {
    background-color: rgb(var(--themeGrey5));
    border-color: rgb(var(--themeGrey5));
    font-family: var(--themeFontFamilySemiBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-inline: 1.25rem;
    text-transform: capitalize;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-header__offers.gw-button--primary[class*="--text"]:hover, .gw-header__offers.gw-button--primary[class*="--icon"]:hover {
    background-color: rgb(var(--themeColorLinkHover));
    border-color: rgb(var(--themeColorLinkHover));
    transform: none;
  }
}
@media screen {
  .gw-header__logo {
    color: rgb(var(--themeColorBrand));
  }
}
@media screen {
  .gw-icon {
    fill: currentColor;
    flex-shrink: 0;
    height: 1em;
    overflow: visible;
    width: 1em;
  }
  .gw-icon--small {
    height: 1rem;
    width: 1rem;
  }
  .gw-icon--medium {
    height: 1.5rem;
    width: 1.5rem;
  }
  .gw-icon--large {
    height: 2rem;
    width: 2rem;
  }
}
@media screen {
  .gw-icon-card-grid {
    grid-gap: 3.75rem;
  }

  [class$=card-grid--media-inline].gw-grid {
    justify-content: start;
  }

  [class$=card-grid--media-inline-alt].gw-grid {
    justify-content: end;
  }

  .gw-icon-card.gw-card:hover {
    box-shadow: none;
    transform: none;
  }
  .gw-icon-card .gw-card__icon {
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
  }
  .gw-icon-card .gw-card__icon .gw-icon {
    fill: rgb(var(--themeColorBrand));
    height: 7.5rem;
    width: 7.5rem;
  }
}
@media screen {
  .gw-image {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media screen {
  .gw-info-card {
    -webkit-margin-after: 0;
            margin-bottom: 0;
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-info-card {
    -moz-column-gap: 1.875rem;
         column-gap: 1.875rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen {
  .gw-info-card.gw-card:hover {
    box-shadow: none;
    transform: none;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-info-card__column:nth-child(2):last-child {
    grid-column: 2/4;
  }
}
@media screen {
  .gw-info-card__column .gw-card {
    -webkit-margin-after: 2.5rem;
            margin-bottom: 2.5rem;
    padding: 0;
    width: 100%;
  }
  .gw-info-card__column .gw-card:hover {
    box-shadow: none;
    transform: none;
  }
  .gw-info-card__column .gw-card__title h4 {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
}
@media screen {
  .gw-info-card__title h2 {
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
  }
}
@media screen {
  .gw-info-grid {
    gap: 3.75rem;
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-info-grid {
    gap: 9.375rem;
    grid-template-columns: fit-content(30rem) fit-content(30rem);
  }
}
@media screen {
  [dir="ltr"] .gw-languages-menu{
    text-align: right;
  }
  [dir="rtl"] .gw-languages-menu{
    text-align: left;
  }
  .gw-languages-menu {
    padding: 0.375rem;
    text-align: end;
  }
  [dir="ltr"] .gw-languages-menu > a{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-languages-menu > a{
            margin-left: 0.75rem;
  }
  .gw-languages-menu > a {
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
}
@media screen {
  [class*=gw-layout] p:not([class]),
[class*=gw-layout] li:not([class]),
[class*=gw-layout] dd,
[class*=gw-layout] small {
    max-width: 100%;
  }
}
@media screen {
  .gw-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    min-height: 100vh;
    /* stylelint-disable order/order */
    /* stylelint-enable order/order */
    /* stylelint-disable order/order */
  }
  [dir="ltr"] .gw-layout__header__overlay{
    right: 0;
  }
  [dir="rtl"] .gw-layout__header__overlay{
    left: 0;
  }
  [dir="ltr"] .gw-layout__header__overlay{
    left: 0;
  }
  [dir="rtl"] .gw-layout__header__overlay{
    right: 0;
  }
  .gw-layout__header__overlay {
    background-color: rgba(var(--themeColorBackgroundAlt), 0.6);
    display: none;
    height: 100vh;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    height: 100vh;
    bottom: 0;
    top: -100vh;
    top: 3rem;
    inset-inline-end: 0;
    inset-inline-start: 0;
    position: absolute;
  }
  .has-open-overlay--alt .gw-layout__header__overlay {
    height: 100vh;
    top: 6rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .has-open-overlay--alt .gw-layout__header__overlay {
    top: 9.5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .is-js-sticky.has-open-overlay--alt .gw-layout__header__overlay {
    top: 4rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-layout__header__overlay {
    top: 4.75rem;
  }
}
@media screen {
  [dir="ltr"] .gw-layout__footer__overlay{
    right: 0;
  }
  [dir="rtl"] .gw-layout__footer__overlay{
    left: 0;
  }
  [dir="ltr"] .gw-layout__footer__overlay{
    left: 0;
  }
  [dir="rtl"] .gw-layout__footer__overlay{
    right: 0;
  }
  .gw-layout__footer__overlay {
    background-color: rgba(var(--themeColorBackgroundAlt), 0.6);
    display: none;
    height: 100vh;
    bottom: 0;
    top: -100vh;
    inset-inline-end: 0;
    inset-inline-start: 0;
    position: absolute;
    z-index: 1000;
  }
}
@media screen {
  .gw-layout__inner {
    height: 100%;
  }
}
@media screen {
  .gw-layout__header.has-open-overlay .gw-layout__header__overlay {
    display: block;
  }
  .gw-layout__header.has-open-overlay ~ .gw-layout__sticky--footer {
    z-index: 0;
  }
}
@media screen {
  .gw-layout__footer {
    -webkit-margin-after: 2.5rem;
            margin-bottom: 2.5rem;
    /* stylelint-disable order/order */
    /* stylelint-enable order/order */
  }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .gw-layout__footer {
      -webkit-margin-after: 0;
              margin-bottom: 0;
    }
  }
}
@media screen {
  .gw-layout__main {
    flex-grow: 1;
    position: relative;
    width: 100%;
    z-index: 0;
  }
}
@media screen and (min-width: 64em) {
  .gw-layout__main {
    scroll-margin: 1px 0 0;
  }
}
@media screen {
  .gw-layout__main > .gw-layout__inner {
    padding: 0 1.875rem;
    -webkit-padding-after: 1.25rem;
            padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-layout__main > .gw-layout__inner {
    padding: 0 5rem;
    -webkit-padding-after: 3.75rem;
            padding-bottom: 3.75rem;
  }
}
@media screen {
  .gw-layout__main > .gw-layout__inner > :last-child {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .gw-layout__sticky {
    z-index: 1;
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-layout__sticky.is-js-sticky {
    width: 100%;
  }
  .gw-layout__sticky.is-js-sticky[class*="--header"] {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48em) and (orientation: portrait), screen and (min-width: 64em) and (orientation: landscape) {
  .gw-layout__sticky.is-js-sticky[class*="--header"] {
    position: static;
    position: -webkit-sticky;
    position: sticky;
  }
}
@media screen {
  .gw-layout__sticky.is-js-sticky[class*="--footer"] {
    position: fixed;
    position: -webkit-sticky;
    position: sticky;
  }
}
@media screen {
  .gw-layout__sticky--header {
    top: 0;
  }
  .gw-layout__sticky--header.is-js-sticky {
    /* stylelint-disable-next-line max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-layout__sticky--header.is-js-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
@media screen {
  .gw-layout__sticky--footer {
    bottom: 0;
  }
  .gw-layout__sticky--footer.has-open-overlay {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-layout__sticky--footer.has-open-overlay .gw-layout__footer__overlay {
    display: block;
  }
}
@media screen {
  .gw-layout__fullbleed {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
    margin-inline: -1.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-layout__fullbleed {
    margin-left: -5rem;
    margin-right: -5rem;
    margin-inline: -5rem;
  }
}
@media screen {
  .gw-layout__has-no-max-width {
    /* stylelint-disable-next-line scss/comment-no-empty */
  }
}
@media screen {
  .gw-layout__has-max-width {
    margin: 0 auto;
    max-width: var(--themeMaxContentWidth);
  }
}
@media screen {
  .gw-breadcrumb + .gw-layout__has-max-width,
.gw-breadcrumb + .gw-layout__has-no-max-width,
.gw-cms__content-image + .gw-layout__has-max-width,
.gw-cms__content-image + .gw-layout__has-no-max-width {
    -webkit-margin-before: 3.75rem;
            margin-top: 3.75rem;
  }
}
@media screen {
  .gw-layout__inner > .gw-layout__has-max-width:first-of-type,
.gw-layout__inner > .gw-layout__has-no-max-width:first-of-type {
    -webkit-margin-before: 3.75rem;
            margin-top: 3.75rem;
  }
}
@media screen {
  .gw-layout__has-centered-content {
    text-align: center;
  }
  [dir="ltr"] .gw-layout__has-centered-content > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .gw-layout__has-centered-content > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .gw-layout__has-centered-content > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .gw-layout__has-centered-content > :not([class]){
            margin-right: auto;
  }
  .gw-layout__has-centered-content > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-layout__sticky--header .gw-layout__inner {
    gap: 0.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-layout--has-vertical-header {
    display: grid;
    grid-template-areas: "sidebar main" "sidebar footer" "sidebar sticky-footer";
    grid-template-columns: 10rem minmax(0, 1fr);
    grid-template-rows: 1fr auto auto;
  }
  .gw-layout--has-vertical-header .gw-layout__header {
    grid-area: sidebar;
    height: 100%;
    position: fixed;
    width: 10rem;
    z-index: 1;
  }
  .gw-layout--has-vertical-header .gw-layout__main {
    grid-area: main;
  }
  .gw-layout--has-vertical-header .gw-layout__footer {
    grid-area: footer;
  }
  .gw-layout--has-vertical-header .gw-layout__sticky {
    grid-area: sticky-footer;
  }
  [dir="ltr"] .gw-layout--has-vertical-header .gw-layout__header__overlay{
    left: 10rem;
  }
  [dir="rtl"] .gw-layout--has-vertical-header .gw-layout__header__overlay{
    right: 10rem;
  }
  .gw-layout--has-vertical-header .gw-layout__header__overlay {
    top: 0;
    inset-inline-start: 10rem;
  }
}
@media screen {
  [dir="ltr"] .gw-map{
    left: 0;
  }
  [dir="rtl"] .gw-map{
    right: 0;
  }
  .gw-map {
    /* stylelint-disable order/order, property-no-unknown */
    height: 50%;
    top: 0;
    inset-inline-start: 0;
    position: absolute;
    width: 100%;
    /* stylelint-enable order/order, property-no-unknown */
  }
}
@media screen and (max-width: 30em) {
  .gw-map {
    height: 35%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-map {
    height: 50%;
  }
}
@media screen and (min-width: 64em) {
  .gw-map {
    height: 100%;
  }
}
@media screen {
  @supports (aspect-ratio: auto) {
    .gw-map {
      aspect-ratio: var(--themeMediaAspectRatio);
      height: unset;
      position: unset;
    }
    @media screen and (min-width: 64em) {
      .gw-map {
        aspect-ratio: 2.5;
      }
    }
  }
}
@media screen {
  .gw-map__wrap {
    background-color: rgb(var(--themeGrey1));
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
    overflow: hidden;
    -webkit-padding-before: 60%;
            padding-top: 60%;
    position: relative;
  }
}
@media screen and (max-width: 30em) {
  .gw-map__wrap {
    -webkit-padding-before: 50%;
            padding-top: 50%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-map__wrap {
    -webkit-padding-before: 45%;
            padding-top: 45%;
  }
}
@media screen and (min-width: 64em) {
  .gw-map__wrap {
    -webkit-padding-before: 40%;
            padding-top: 40%;
  }
}
@media screen {
  @supports (aspect-ratio: auto) {
    .gw-map__wrap {
      -webkit-padding-before: unset;
              padding-top: unset;
    }
  }
}
@media screen {
  .gw-map__overlay {
    max-width: none;
    position: relative;
  }
}
@media screen and (min-width: 64em) {
  [dir="ltr"] .gw-map__overlay{
    left: 5rem;
  }
  [dir="rtl"] .gw-map__overlay{
    right: 5rem;
  }
  .gw-map__overlay {
    background-color: rgba(var(--themeColorBackground), 0.9);
    top: 1.875rem;
    inset-inline-start: 5rem;
    max-width: 33%;
    position: absolute;
  }
}
@media screen {
  .gw-map__overlay .gw-contact-card {
    background-color: transparent;
    border: 0;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    max-width: none;
    padding: 1.25rem 1.875rem;
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-map__overlay .gw-contact-card {
    padding: 1.25rem 5rem;
  }
}
@media screen and (min-width: 64em) {
  .gw-map__overlay .gw-contact-card {
    padding: 1.25rem;
  }
}
@media screen {
  .gw-map__overlay address br:last-child {
    display: none;
  }
}
@media screen {
  .gw-map__overlay .gw-contact-card {
    box-shadow: none;
  }
}
@media screen {
  [dir="ltr"] .gw-modal{
    right: 0;
  }
  [dir="rtl"] .gw-modal{
    left: 0;
  }
  [dir="ltr"] .gw-modal{
    left: 0;
  }
  [dir="rtl"] .gw-modal{
    right: 0;
  }
  .gw-modal {
    align-items: center;
    display: flex;
    flex-direction: column;
    bottom: 0;
    top: 0;
    inset-inline-end: 0;
    inset-inline-start: 0;
    justify-content: center;
    pointer-events: none;
    position: fixed;
  }
  [data-modal-ajax-content] {
    display: none;
  }
  .gw-modal__ajax [data-modal-ajax-content] {
    display: block;
  }

  [dir="ltr"] .gw-modal__overlay{
    right: 0;
  }

  [dir="rtl"] .gw-modal__overlay{
    left: 0;
  }

  [dir="ltr"] .gw-modal__overlay{
    left: 0;
  }

  [dir="rtl"] .gw-modal__overlay{
    right: 0;
  }

  .gw-modal__overlay {
    background-color: rgba(var(--themeColorBackgroundAlt), 0.8);
    bottom: 0;
    top: 0;
    inset-inline-end: 0;
    inset-inline-start: 0;
    position: fixed;
    z-index: 1000;
  }
  .gw-modal__overlay.gw-modal__overlay--full-width {
    background-color: transparent;
  }
  .gw-modal__inner {
    background-color: rgb(var(--themeColorBackground));
    max-height: 80%;
    overflow-y: auto;
    padding: 3.75rem 1.875rem 1.875rem;
    pointer-events: all;
    position: relative;
    width: 80%;
  }
  [dir="ltr"] .gw-modal__inner > [data-modal-close]{
    right: 0.375rem;
  }
  [dir="rtl"] .gw-modal__inner > [data-modal-close]{
    left: 0.375rem;
  }
  .gw-modal__inner > [data-modal-close] {
    top: 0.375rem;
    inset-inline-end: 0.375rem;
    position: absolute;
  }
  .gw-modal--full-width {
    top: auto;
    z-index: 1000;
  }
  .gw-modal--full-width .gw-modal__inner {
    background-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorTextAlt));
    padding: 3.75rem 1.875rem 1.875rem;
    width: 100%;
  }
  .gw-modal--full-width .gw-modal__inner a {
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-modal--full-width .gw-modal__inner p {
    max-width: 100%;
  }
  .gw-modal--full-width .gw-modal__title {
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-modal--full-width .gw-modal__title:focus-visible {
    outline: none;
  }
  .gw-modal--full-width__video .gw-button-group {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-modal--full-width__video .gw-grid--asymmetric {
    gap: 0;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-modal--full-width__video .gw-modal__inner {
    max-height: 60vh;
  }
}
@media screen and (min-width: 64em) {
  .gw-modal--full-width__video .gw-modal__inner {
    max-height: unset;
  }
}
@media screen {
  .gw-modal--floating-button + .gw-button--floating--modal--container .gw-button--floating--modal.gw-button[class*="--text"] {
    background-color: rgb(var(--themeColorBrand));
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [dir="ltr"] .gw-modal--floating-button + .gw-button--floating--modal--container .gw-button--floating--modal.gw-button[class*="--text"] .gw-icon{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-modal--floating-button + .gw-button--floating--modal--container .gw-button--floating--modal.gw-button[class*="--text"] .gw-icon{
            margin-right: 0.75rem;
  }
  .gw-modal--floating-button + .gw-button--floating--modal--container .gw-button--floating--modal.gw-button[class*="--text"] .gw-icon {
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
  .gw-modal--floating-button + .gw-button--floating--modal--container .gw-button--floating--modal.gw-button[class*="--text"] span {
    height: unset;
    opacity: 1;
    width: unset;
  }
}
@media screen {
  .gw-modal__ajax {
    -webkit-margin-after: 2.5rem;
            margin-bottom: 2.5rem;
  }
  .gw-modal__inner {
    background-color: rgb(var(--themeGrey1));
  }
}
@media screen {
  .gw-navigation {
    /* stylelint-disable max-nesting-depth, no-descending-specificity */
    overflow: hidden;
    /* stylelint-enable max-nesting-depth, no-descending-specificity */
    /* stylelint-disable order/order, max-nesting-depth */
    /* stylelint-enable order/order, max-nesting-depth */
  }
  [dir=rtl] .show-mobile-nav [data-dropdown-open] .gw-navigation__dropdown {
    transition: right 0.3s ease-in-out;
  }
  [dir=rtl] .show-mobile-nav .gw-icon {
    transform: rotate(180deg);
  }

  [data-whatintent=keyboard] .gw-navigation__dropdown {
    display: none !important;
  }

  [dir="ltr"] .gw-navigation__dropdown{
    right: auto;
  }

  [dir="rtl"] .gw-navigation__dropdown{
    left: auto;
  }

  [dir="ltr"] .gw-navigation__dropdown{
    left: 0;
  }

  [dir="rtl"] .gw-navigation__dropdown{
    right: 0;
  }

  .gw-navigation__dropdown {
    background-color: rgb(var(--themeColorBackground));
    color: rgb(var(--themeColorText));
    display: none;
    height: calc(100vh - 3rem);
    top: 3rem;
    inset-inline-end: auto;
    inset-inline-start: 0;
    max-height: calc(100vh - 3rem);
    overflow-y: auto;
    -webkit-padding-after: 0.75rem;
    -webkit-padding-before: 1.25rem;
            padding-bottom: 0.75rem;
            padding-top: 1.25rem;
    position: fixed;
    width: 100vw;
    z-index: 1000;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation__dropdown {
    height: auto;
    top: 4.75rem;
    max-height: calc(95vh - 4.75rem);
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-navigation__dropdown{
            padding-right: 8.75rem;
  }
  [dir="rtl"] .gw-navigation__dropdown{
            padding-left: 8.75rem;
  }
  [dir="ltr"] .gw-navigation__dropdown{
            padding-left: 8.75rem;
  }
  [dir="rtl"] .gw-navigation__dropdown{
            padding-right: 8.75rem;
  }
  .gw-navigation__dropdown {
    -webkit-padding-end: 8.75rem;
            padding-inline-end: 8.75rem;
            padding-inline-start: 8.75rem;
    -webkit-padding-start: 8.75rem;
  }
  [dir="ltr"] .gw-navigation__dropdown .gw-navigation__dropdown:not(.gw-navigation__dropdown--overlay){
    right: 0;
  }
  [dir="rtl"] .gw-navigation__dropdown .gw-navigation__dropdown:not(.gw-navigation__dropdown--overlay){
    left: 0;
  }
  [dir="ltr"] .gw-navigation__dropdown .gw-navigation__dropdown:not(.gw-navigation__dropdown--overlay){
    left: 0;
  }
  [dir="rtl"] .gw-navigation__dropdown .gw-navigation__dropdown:not(.gw-navigation__dropdown--overlay){
    right: 0;
  }
  [dir="ltr"] .gw-navigation__dropdown .gw-navigation__dropdown:not(.gw-navigation__dropdown--overlay){
            padding-right: 0;
  }
  [dir="rtl"] .gw-navigation__dropdown .gw-navigation__dropdown:not(.gw-navigation__dropdown--overlay){
            padding-left: 0;
  }
  [dir="ltr"] .gw-navigation__dropdown .gw-navigation__dropdown:not(.gw-navigation__dropdown--overlay){
            padding-left: 0;
  }
  [dir="rtl"] .gw-navigation__dropdown .gw-navigation__dropdown:not(.gw-navigation__dropdown--overlay){
            padding-right: 0;
  }
  .gw-navigation__dropdown .gw-navigation__dropdown:not(.gw-navigation__dropdown--overlay) {
    border: 0;
    display: block;
    top: 0;
    inset-inline-end: 0;
    inset-inline-start: 0;
    max-height: initial;
    overflow: initial;
    -webkit-padding-after: 0;
    -webkit-padding-before: 0;
            padding-bottom: 0;
            padding-top: 0;
    -webkit-padding-end: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
    position: relative;
    width: auto;
  }
}
@media screen {
  .gw-navigation__list {
    display: flex;
    height: 100%;
  }
  .has-nav-overflow .gw-navigation__list {
    flex-wrap: wrap;
    height: auto;
    min-height: 100%;
  }

  .has-nav-overflow .show-mobile-nav .gw-navigation__list {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation__list {
    align-items: center;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation__list--nested {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
  }
  .gw-navigation__list--nested > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    .gw-navigation__list--nested {
      grid-template-columns: repeat(auto-fill, minmax(12.5rem, 12.5rem));
    }
  }
  .gw-navigation__list--nested > * {
    margin: 0.9375rem;
  }
  @supports (grid-area: auto) {
    .gw-navigation__list--nested {
      display: grid;
      gap: 1.875rem;
    }
    .gw-navigation__list--nested > * {
      height: 100%;
      margin: 0;
    }
  }
}
@media screen {
  .gw-navigation__list--nested .gw-navigation__list--nested {
    display: block;
  }
}
@media screen {
  .gw-navigation__list .gw-button-group {
    background-color: rgb(var(--themeGrey1));
    -webkit-margin-after: 0;
            margin-bottom: 0;
    padding: 0.75rem 0.75rem 0;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-navigation__list .gw-button-group{
            margin-right: -8.75rem;
  }
  [dir="rtl"] .gw-navigation__list .gw-button-group{
            margin-left: -8.75rem;
  }
  [dir="ltr"] .gw-navigation__list .gw-button-group{
            margin-left: -8.75rem;
  }
  [dir="rtl"] .gw-navigation__list .gw-button-group{
            margin-right: -8.75rem;
  }
  .gw-navigation__list .gw-button-group {
    -webkit-margin-end: -8.75rem;
            margin-inline-end: -8.75rem;
            margin-inline-start: -8.75rem;
    -webkit-margin-start: -8.75rem;
  }
}
@media screen {
  [dir="ltr"] .gw-navigation__item{
            padding-right: 1.25rem;
  }
  [dir="rtl"] .gw-navigation__item{
            padding-left: 1.25rem;
  }
  .gw-navigation__item {
    -webkit-border-after: 0.0625rem solid rgb(var(--themeGrey2));
            border-bottom: 0.0625rem solid rgb(var(--themeGrey2));
    flex-shrink: 0;
    font-size: 1rem;
    -webkit-padding-end: 1.25rem;
            padding-inline-end: 1.25rem;
  }
  .has-nav-overflow .gw-navigation__item {
    -webkit-margin-after: 0.375rem;
            margin-bottom: 0.375rem;
  }

  .gw-navigation.is-js-enabled .gw-navigation__item {
    flex-shrink: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation__item {
    align-items: center;
    border: 0;
    display: flex;
    flex-shrink: 1;
    height: 100%;
  }
}
@media screen {
  .gw-navigation__item:first-of-type {
    -webkit-border-before: 0.0625rem solid rgb(var(--themeGrey2));
            border-top: 0.0625rem solid rgb(var(--themeGrey2));
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation__item:first-of-type {
    border: 0;
  }
}
@media screen {
  .gw-navigation__item:hover > .gw-navigation__dropdown {
    display: block;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation__item .gw-navigation__item {
    height: auto;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    -webkit-padding-after: 0.75rem;
            padding-bottom: 0.75rem;
  }
}
@media screen {
  .gw-navigation__item .gw-navigation__item--no-grandchild {
    display: block;
  }
}
@media screen {
  .gw-navigation__item--buttons {
    display: none;
  }
}
@media screen {
  .gw-navigation__item.is-hoisted {
    display: none;
  }
}
@media screen {
  .gw-navigation__link {
    background-color: inherit;
    color: rgb(var(--themeColorBrand));
    display: inline-block;
    font-weight: normal;
    line-height: 1;
    padding: 0.9375rem 0.75rem;
    text-decoration: none;
    width: 100%;
  }
  .has-nav-overflow .gw-navigation__link {
    -webkit-padding-after: 0.375rem;
    -webkit-padding-before: 0.375rem;
            padding-bottom: 0.375rem;
            padding-top: 0.375rem;
  }

  .has-nav-overflow .show-mobile-nav .gw-navigation__link {
    padding: 0.9375rem 0.75rem;
  }

  .gw-navigation__list--nested .gw-navigation__list--nested .gw-navigation__link {
    -webkit-padding-after: 0.375rem;
    -webkit-padding-before: 0.375rem;
            padding-bottom: 0.375rem;
            padding-top: 0.375rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation__link {
    padding: 0;
    width: auto;
  }
}
@media screen {
  [dir="ltr"] .gw-navigation__link + .gw-button--icon{
            margin-left: 0.25rem;
  }
  [dir="rtl"] .gw-navigation__link + .gw-button--icon{
            margin-right: 0.25rem;
  }
  .gw-navigation__link + .gw-button--icon {
    color: rgb(var(--themeColorBrand));
            margin-inline-start: 0.25rem;
    -webkit-margin-start: 0.25rem;
    padding: 0.375rem;
  }
  .gw-navigation__link + .gw-button--icon:hover {
    color: rgb(var(--themeColorBrandAlt));
  }
}
@media screen {
  .gw-navigation__link:hover {
    color: rgb(var(--themeColorBrandAlt));
    text-decoration: underline;
  }
  .gw-navigation__link:hover + .gw-button--icon {
    color: rgb(var(--themeColorBrandAlt));
  }
}
@media screen {
  .gw-navigation__link.gw-button {
    border: 0;
  }
}
@media screen {
  .gw-navigation__link--has-submenu .gw-button__inner {
    flex-direction: row-reverse;
  }
  [dir="ltr"] .gw-navigation__link--has-submenu .gw-icon{
            margin-right: 0;
  }
  [dir="rtl"] .gw-navigation__link--has-submenu .gw-icon{
            margin-left: 0;
  }
  [dir="ltr"] .gw-navigation__link--has-submenu .gw-icon{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-navigation__link--has-submenu .gw-icon{
            margin-right: 0.75rem;
  }
  .gw-navigation__link--has-submenu .gw-icon {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    height: 1em;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
    width: 1em;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation__link--has-submenu .gw-icon {
    display: none;
  }
}
@media screen {
  .gw-navigation__title {
    -webkit-border-after: 0.0625rem solid rgb(var(--themeGrey2));
            border-bottom: 0.0625rem solid rgb(var(--themeGrey2));
    color: rgb(var(--themeColorText));
    display: block;
    font-size: 1rem;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    -webkit-padding-after: 0.75rem;
            padding-bottom: 0.75rem;
  }
  .gw-navigation__title.is-hidden-on-medium {
    -webkit-border-before: 0.0625rem solid rgb(var(--themeGrey2));
            border-top: 0.0625rem solid rgb(var(--themeGrey2));
    font-size: 1.5rem;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    padding: 0.75rem;
  }
}
@media screen {
  .gw-navigation__button--back {
    border: 0;
    -webkit-border-before: 0.0625rem solid rgb(var(--themeGrey2));
            border-top: 0.0625rem solid rgb(var(--themeGrey2));
    padding: 0.9375rem 0.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation__button--back {
    display: none;
  }
}
@media screen {
  .gw-navigation__button--back .gw-button__inner {
    justify-content: start;
  }
}
@media screen {
  .gw-navigation .gw-button--text-icon {
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation .gw-button--text-icon {
    width: auto;
  }
}
@media screen {
  .gw-navigation .gw-button--text-icon .gw-icon {
    height: 1em;
    width: 1em;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation [data-dropdown-open] [data-dropdown-open] {
    display: block;
  }
}
@media screen {
  [dir="ltr"] .gw-navigation [data-dropdown-open] > .gw-icon{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-navigation [data-dropdown-open] > .gw-icon{
            margin-right: 0.75rem;
  }
  .gw-navigation [data-dropdown-open] > .gw-icon {
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation [data-dropdown-open] > .gw-icon {
    display: none;
  }
}
@media screen {
  .gw-navigation [data-dropdown-open=true] > .gw-navigation__link--has-submenu {
    color: rgb(var(--themeColorBrandAlt));
  }
  .gw-navigation [data-dropdown-open=true] > .gw-navigation__link--has-submenu + .gw-button--icon {
    color: rgb(var(--themeColorBrandAlt));
    transform: rotate(180deg);
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-navigation .gw-product-filter{
            margin-right: -8.75rem;
  }
  [dir="rtl"] .gw-navigation .gw-product-filter{
            margin-left: -8.75rem;
  }
  [dir="ltr"] .gw-navigation .gw-product-filter{
            margin-left: -8.75rem;
  }
  [dir="rtl"] .gw-navigation .gw-product-filter{
            margin-right: -8.75rem;
  }
  .gw-navigation .gw-product-filter {
    -webkit-margin-end: -8.75rem;
            margin-inline-end: -8.75rem;
            margin-inline-start: -8.75rem;
    -webkit-margin-start: -8.75rem;
  }
}
@media screen {
  .gw-navigation .gw-product-filter.gw-accordion {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-navigation .gw-product-filter.gw-accordion .gw-grid {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (max-width: 48em) {
  .gw-navigation .gw-product-filter.gw-accordion .gw-grid {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .gw-navigation .gw-product-filter.gw-accordion .gw-grid > * {
    margin: 0.9375rem;
  }
  @supports (grid-area: auto) {
    .gw-navigation .gw-product-filter.gw-accordion .gw-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      justify-content: center;
    }
    .gw-navigation .gw-product-filter.gw-accordion .gw-grid > * {
      flex-grow: 0;
      margin: 0.9375rem;
    }
  }
  .gw-navigation .gw-product-filter.gw-accordion .gw-grid .gw-image {
    max-width: 12.5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-navigation .gw-product-filter .gw-tabs__content{
            margin-right: 8.75rem;
  }
  [dir="rtl"] .gw-navigation .gw-product-filter .gw-tabs__content{
            margin-left: 8.75rem;
  }
  [dir="ltr"] .gw-navigation .gw-product-filter .gw-tabs__content{
            margin-left: 8.75rem;
  }
  [dir="rtl"] .gw-navigation .gw-product-filter .gw-tabs__content{
            margin-right: 8.75rem;
  }
  .gw-navigation .gw-product-filter .gw-tabs__content {
    -webkit-margin-end: 8.75rem;
            margin-inline-end: 8.75rem;
            margin-inline-start: 8.75rem;
    -webkit-margin-start: 8.75rem;
  }
}
@media screen {
  .gw-navigation .gw-product-filter .gw-accordion__button {
    border-color: rgb(var(--themeGrey2));
    -webkit-margin-after: 0;
            margin-bottom: 0;
    padding: 0.9375rem 0.75rem;
  }
}
@media screen {
  .gw-navigation .gw-product-filter .gw-accordion__content {
    padding: 0 0.75rem;
  }
}
@media screen {
  .gw-navigation .gw-product-filter .gw-accordion__title:first-of-type .gw-accordion__button {
    -webkit-border-before: 0.0625rem solid rgb(var(--themeGrey2));
            border-top: 0.0625rem solid rgb(var(--themeGrey2));
  }
}
@media screen {
  .gw-navigation .gw-product-filter .gw-accordion__title:last-of-type .gw-accordion__button {
    -webkit-border-after: 0;
            border-bottom: 0;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-navigation .gw-product-filter .gw-accordion__title:last-of-type + .gw-accordion__content {
    -webkit-margin-before: 0.75rem;
            margin-top: 0.75rem;
  }
}
@media screen {
  .gw-navigation .gw-product-filter--no-tabs {
    padding: 0.75rem;
    /* stylelint-disable-next-line no-descending-specificity */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation .gw-product-filter--no-tabs {
    padding: 0 8.75rem 8.75rem;
  }
}
@media screen and (max-width: 48em) {
  .gw-navigation .gw-product-filter--no-tabs .gw-grid {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* stylelint-disable-next-line max-nesting-depth */
  }
  .gw-navigation .gw-product-filter--no-tabs .gw-grid > * {
    margin: 0.9375rem;
  }
  @supports (grid-area: auto) {
    .gw-navigation .gw-product-filter--no-tabs .gw-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      justify-content: center;
    }
    .gw-navigation .gw-product-filter--no-tabs .gw-grid > * {
      flex-grow: 0;
      margin: 0.9375rem;
    }
  }
  .gw-navigation .gw-product-filter--no-tabs .gw-grid .gw-image {
    max-width: 12.5rem;
  }
}
@media screen {
  .gw-navigation__dropdown--overlay {
    /* stylelint-disable no-descending-specificity, max-nesting-depth */
    /* stylelint-enable no-descending-specificity, max-nesting-depth */
  }
  .gw-navigation__dropdown--overlay__header {
    align-items: center;
    background-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorTextAlt));
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-navigation__dropdown--overlay__header{
            margin-right: -8.75rem;
  }
  [dir="rtl"] .gw-navigation__dropdown--overlay__header{
            margin-left: -8.75rem;
  }
  [dir="ltr"] .gw-navigation__dropdown--overlay__header{
            margin-left: -8.75rem;
  }
  [dir="rtl"] .gw-navigation__dropdown--overlay__header{
            margin-right: -8.75rem;
  }
  .gw-navigation__dropdown--overlay__header {
    -webkit-margin-end: -8.75rem;
            margin-inline-end: -8.75rem;
            margin-inline-start: -8.75rem;
    -webkit-margin-start: -8.75rem;
  }
}
@media screen {
  .gw-navigation__dropdown--overlay__header .gw-button {
    background-color: rgb(var(--themeColorBrand));
    border: 0;
    width: auto;
  }
}
@media screen {
  .gw-navigation__dropdown--overlay__header .gw-button__inner {
    justify-content: flex-start;
  }
}
@media screen {
  .gw-navigation__dropdown--overlay__header > span {
    margin: 0 auto;
  }
}
@media screen {
  .gw-navigation__dropdown--overlay__content {
    padding: 0 0.75rem;
  }
  .gw-navigation__dropdown--overlay__content .gw-grid > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    .gw-navigation__dropdown--overlay__content .gw-grid {
      grid-template-columns: repeat(auto-fill, minmax(12.5rem, 12.5rem));
    }
  }
}
@media screen and (max-width: 48em) {
  .gw-navigation__dropdown--overlay__content .gw-grid {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .gw-navigation__dropdown--overlay__content .gw-grid > * {
    margin: 0.9375rem;
  }
  @supports (grid-area: auto) {
    .gw-navigation__dropdown--overlay__content .gw-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      justify-content: center;
    }
    .gw-navigation__dropdown--overlay__content .gw-grid > * {
      flex-grow: 0;
      margin: 0.9375rem;
    }
  }
  .gw-navigation__dropdown--overlay__content .gw-grid .gw-image {
    max-width: 12.5rem;
  }
}
@media screen {
  .gw-navigation [id=toggle-mobile-nav] {
    display: none;
    height: 3rem;
    padding: 0;
    position: relative;
    width: 3rem;
  }
  .is-js-sticky .gw-navigation [id=toggle-mobile-nav] {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation [id=toggle-mobile-nav] {
    height: 4rem;
    width: 4rem;
  }
}
@media screen {
  .gw-navigation [id=toggle-mobile-nav] .gw-icon {
    fill: rgb(var(--themeColorBrand));
  }
}
@media screen {
  .gw-navigation [id=navigation-wrap],
.gw-navigation div[id^=gw-navigation] {
    height: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation [id=navigation-wrap].is-hidden,
.gw-navigation div[id^=gw-navigation].is-hidden {
    display: block !important;
  }
}
@media screen {
  .gw-navigation [id=navigation-wrap] {
    overflow-x: auto;
  }
}
@media screen and (min-width: 48.0625em) {
  .is-js-sticky div[id^=gw-navigation].is-hidden {
    display: none !important;
  }
}
@media screen {
  .gw-navigation .show-mobile-nav {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    /* stylelint-disable order/order, max-nesting-depth, declaration-no-important */
    /* stylelint-enable order/order, max-nesting-depth, declaration-no-important */
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav > .gw-navigation__list{
    right: auto;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav > .gw-navigation__list{
    left: auto;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav > .gw-navigation__list{
    left: 0;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav > .gw-navigation__list{
    right: 0;
  }
  .gw-navigation .show-mobile-nav > .gw-navigation__list {
    background-color: rgb(var(--themeColorBackground));
    color: rgb(var(--themeColorText));
    display: none;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 3rem);
    top: 3rem;
    inset-inline-end: auto;
    inset-inline-start: 0;
    max-height: calc(100vh - 3rem);
    overflow-y: auto;
    -webkit-padding-after: 0.75rem;
    -webkit-padding-before: 1.25rem;
    -webkit-padding-before: 0;
            padding-bottom: 0.75rem;
            padding-top: 1.25rem;
            padding-top: 0;
    position: fixed;
    width: 100vw;
    z-index: 1000;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation .show-mobile-nav > .gw-navigation__list {
    height: auto;
    top: 4.75rem;
    max-height: calc(95vh - 4.75rem);
  }
}
@media screen {
  .gw-navigation .show-mobile-nav > .gw-navigation__list > .gw-navigation__item:last-child {
    -webkit-margin-after: 10rem;
            margin-bottom: 10rem;
  }
}
@media screen {
  .gw-navigation .show-mobile-nav .gw-navigation__dropdown {
    height: 100%;
    max-height: 100%;
  }
  .gw-navigation .show-mobile-nav .gw-navigation__dropdown > :last-child {
    -webkit-margin-after: 10rem;
            margin-bottom: 10rem;
  }
}
@media screen {
  .gw-navigation .show-mobile-nav .gw-navigation__link {
    padding: 0.9375rem 0.75rem;
    width: 100%;
  }
}
@media screen {
  [dir="ltr"] .gw-navigation .show-mobile-nav [data-dropdown-open] .gw-navigation__dropdown{
    right: auto;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav [data-dropdown-open] .gw-navigation__dropdown{
    left: auto;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav [data-dropdown-open] .gw-navigation__dropdown{
    left: 100vw;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav [data-dropdown-open] .gw-navigation__dropdown{
    right: 100vw;
  }
  .gw-navigation .show-mobile-nav [data-dropdown-open] .gw-navigation__dropdown {
    display: block;
    inset-inline-end: auto;
    inset-inline-start: 100vw;
    transition: left 0.3s ease-in-out;
  }
}
@media screen {
  [dir="ltr"] .gw-navigation .show-mobile-nav [data-dropdown-open=true] > .gw-navigation__dropdown--is-open{
    right: auto;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav [data-dropdown-open=true] > .gw-navigation__dropdown--is-open{
    left: auto;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav [data-dropdown-open=true] > .gw-navigation__dropdown--is-open{
    left: 0;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav [data-dropdown-open=true] > .gw-navigation__dropdown--is-open{
    right: 0;
  }
  .gw-navigation .show-mobile-nav [data-dropdown-open=true] > .gw-navigation__dropdown--is-open {
    inset-inline-end: auto;
    inset-inline-start: 0;
    -webkit-padding-before: 0;
            padding-top: 0;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav [data-dropdown-open=true] > .gw-navigation__dropdown--overlay{
    right: auto;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav [data-dropdown-open=true] > .gw-navigation__dropdown--overlay{
    left: auto;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav [data-dropdown-open=true] > .gw-navigation__dropdown--overlay{
    left: 0;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav [data-dropdown-open=true] > .gw-navigation__dropdown--overlay{
    right: 0;
  }
  .gw-navigation .show-mobile-nav [data-dropdown-open=true] > .gw-navigation__dropdown--overlay {
    inset-inline-end: auto;
    inset-inline-start: 0;
  }
}
@media screen {
  .gw-navigation .show-mobile-nav .is-hoisted {
    display: list-item;
  }
}
@media screen {
  .gw-navigation .show-mobile-nav .gw-product-filter--no-tabs {
    padding: 0.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-navigation .show-mobile-nav .gw-navigation__dropdown{
            padding-right: 0;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav .gw-navigation__dropdown{
            padding-left: 0;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav .gw-navigation__dropdown{
            padding-left: 0;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav .gw-navigation__dropdown{
            padding-right: 0;
  }
  .gw-navigation .show-mobile-nav .gw-navigation__dropdown {
    top: 4.75rem;
    min-height: calc(95vh - 4.75rem);
    -webkit-padding-end: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
    position: fixed;
    width: 100vw;
  }
  .gw-navigation .show-mobile-nav > .gw-navigation__list {
    align-items: unset;
    top: 4rem;
    min-height: calc(95vh - 4.75rem);
  }
  .gw-navigation .show-mobile-nav .gw-navigation__list--nested {
    display: block;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav .gw-navigation__item{
            padding-right: 0;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav .gw-navigation__item{
            padding-left: 0;
  }
  .gw-navigation .show-mobile-nav .gw-navigation__item {
    align-items: unset;
    -webkit-border-after: 0.0625rem solid rgb(var(--themeGrey2));
            border-bottom: 0.0625rem solid rgb(var(--themeGrey2));
    display: list-item;
    font-size: unset;
    -webkit-padding-end: 0;
            padding-inline-end: 0;
  }
  .gw-navigation .show-mobile-nav .gw-navigation__item.is-hidden-on-medium {
    display: list-item !important;
  }
  .gw-navigation .show-mobile-nav .gw-navigation__item .gw-navigation__item {
    -webkit-padding-after: 0;
            padding-bottom: 0;
  }
  .gw-navigation .show-mobile-nav .gw-navigation__item .is-hidden-on-small {
    display: none;
  }
  .gw-navigation .show-mobile-nav .gw-navigation__link.is-hidden-on-medium {
    display: inline-block !important;
  }
  .gw-navigation .show-mobile-nav .gw-navigation__link--has-submenu .gw-icon {
    display: unset;
  }
  .gw-navigation .show-mobile-nav .gw-navigation__title.is-hidden-on-medium {
    -webkit-border-before: 0;
            border-top: 0;
    display: block !important;
  }
  .gw-navigation .show-mobile-nav [data-dropdown-open] [data-dropdown-open] {
    display: list-item;
  }
  .gw-navigation .show-mobile-nav [data-back-button] {
    -webkit-border-after: 0.0625rem solid rgb(var(--themeGrey2));
    -webkit-border-before: 0;
            border-bottom: 0.0625rem solid rgb(var(--themeGrey2));
            border-top: 0;
    display: inline-block;
    width: 100%;
  }
  .gw-navigation .show-mobile-nav [data-back-button] + .gw-navigation__title {
    -webkit-border-after: 0.0625rem solid rgb(var(--themeGrey2));
            border-bottom: 0.0625rem solid rgb(var(--themeGrey2));
  }
  .gw-navigation .show-mobile-nav .gw-button-group {
    margin: 0;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav .gw-product-filter{
            margin-right: 0;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav .gw-product-filter{
            margin-left: 0;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav .gw-product-filter{
            margin-left: 0;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav .gw-product-filter{
            margin-right: 0;
  }
  .gw-navigation .show-mobile-nav .gw-product-filter {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav .gw-product-filter .gw-tabs__content{
            margin-right: 0;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav .gw-product-filter .gw-tabs__content{
            margin-left: 0;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav .gw-product-filter .gw-tabs__content{
            margin-left: 0;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav .gw-product-filter .gw-tabs__content{
            margin-right: 0;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav .gw-product-filter .gw-tabs__content{
            padding-right: 0.75rem;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav .gw-product-filter .gw-tabs__content{
            padding-left: 0.75rem;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav .gw-product-filter .gw-tabs__content{
            padding-left: 0.75rem;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav .gw-product-filter .gw-tabs__content{
            padding-right: 0.75rem;
  }
  .gw-navigation .show-mobile-nav .gw-product-filter .gw-tabs__content {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
    -webkit-padding-end: 0.75rem;
            padding-inline-end: 0.75rem;
            padding-inline-start: 0.75rem;
    -webkit-padding-start: 0.75rem;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav .gw-navigation__dropdown--overlay__header{
            margin-right: 0;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav .gw-navigation__dropdown--overlay__header{
            margin-left: 0;
  }
  [dir="ltr"] .gw-navigation .show-mobile-nav .gw-navigation__dropdown--overlay__header{
            margin-left: 0;
  }
  [dir="rtl"] .gw-navigation .show-mobile-nav .gw-navigation__dropdown--overlay__header{
            margin-right: 0;
  }
  .gw-navigation .show-mobile-nav .gw-navigation__dropdown--overlay__header {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
}
@media screen {
  .gw-navigation.is-js-enabled {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (max-width: 48em) {
  .gw-navigation.is-js-enabled .gw-button:not(.is-hidden-on-small) {
    display: inline-block;
  }
  .gw-navigation.is-js-enabled .gw-button--icon:not(.is-hidden-on-small) {
    display: inline-flex;
  }
  [dir="ltr"] .gw-navigation.is-js-enabled .gw-navigation__item{
            padding-right: 0;
  }
  [dir="rtl"] .gw-navigation.is-js-enabled .gw-navigation__item{
            padding-left: 0;
  }
  .gw-navigation.is-js-enabled .gw-navigation__item {
    -webkit-padding-end: 0;
            padding-inline-end: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation.is-js-enabled .gw-navigation__list > [data-dropdown-open=true] .gw-navigation__dropdown {
    display: block;
  }
  .gw-navigation.is-js-enabled .gw-navigation__list > [data-dropdown-open=false]:hover .gw-navigation__dropdown {
    display: none;
  }
}
@media screen {
  .gw-navigation.is-js-enabled .gw-navigation__item--buttons {
    display: block;
  }
}
@media screen {
  .gw-navigation--vertical {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation--vertical {
    overflow-y: auto;
  }
  .gw-navigation--vertical [data-dropdown-open=true] {
    background-color: rgb(var(--themeGrey1));
  }
  .gw-navigation--vertical [data-dropdown-open=true] > .gw-navigation__link--has-submenu {
    color: rgb(var(--themeColorBrand));
  }
  .gw-navigation--vertical [data-dropdown-open=true] > .gw-navigation__link--has-submenu + .gw-button--icon {
    color: rgb(var(--themeColorBrand));
    opacity: 1;
  }
  [dir="ltr"] .gw-navigation--vertical .gw-navigation__item{
            padding-left: 0.75rem;
  }
  [dir="rtl"] .gw-navigation--vertical .gw-navigation__item{
            padding-right: 0.75rem;
  }
  .gw-navigation--vertical .gw-navigation__item {
            padding-inline-start: 0.75rem;
    -webkit-padding-start: 0.75rem;
    width: 100%;
  }
  [dir="ltr"] .gw-navigation--vertical .gw-navigation__dropdown{
    left: 10rem;
  }
  [dir="rtl"] .gw-navigation--vertical .gw-navigation__dropdown{
    right: 10rem;
  }
  [dir="ltr"] .gw-navigation--vertical .gw-navigation__dropdown{
            padding-right: 0.75rem;
  }
  [dir="rtl"] .gw-navigation--vertical .gw-navigation__dropdown{
            padding-left: 0.75rem;
  }
  [dir="ltr"] .gw-navigation--vertical .gw-navigation__dropdown{
            padding-left: 0.75rem;
  }
  [dir="rtl"] .gw-navigation--vertical .gw-navigation__dropdown{
            padding-right: 0.75rem;
  }
  .gw-navigation--vertical .gw-navigation__dropdown {
    background-color: rgb(var(--themeGrey1));
    height: 100%;
    top: 0;
    inset-inline-start: 10rem;
    max-height: 100vh;
    -webkit-padding-end: 0.75rem;
            padding-inline-end: 0.75rem;
            padding-inline-start: 0.75rem;
    -webkit-padding-start: 0.75rem;
    width: auto;
  }
  [dir="ltr"] .gw-navigation--vertical .gw-navigation__dropdown .gw-button-group{
            margin-right: 0;
  }
  [dir="rtl"] .gw-navigation--vertical .gw-navigation__dropdown .gw-button-group{
            margin-left: 0;
  }
  [dir="ltr"] .gw-navigation--vertical .gw-navigation__dropdown .gw-button-group{
            margin-left: 0;
  }
  [dir="rtl"] .gw-navigation--vertical .gw-navigation__dropdown .gw-button-group{
            margin-right: 0;
  }
  .gw-navigation--vertical .gw-navigation__dropdown .gw-button-group {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
    max-width: 12.5rem;
  }
  [dir="ltr"] .gw-navigation--vertical .gw-navigation__dropdown .gw-navigation__item{
            padding-left: 0;
  }
  [dir="rtl"] .gw-navigation--vertical .gw-navigation__dropdown .gw-navigation__item{
            padding-right: 0;
  }
  .gw-navigation--vertical .gw-navigation__dropdown .gw-navigation__item {
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
  .gw-navigation--vertical .gw-navigation__link:hover {
    color: rgb(var(--themeColorBrand));
    opacity: 1;
  }
  .gw-navigation--vertical .gw-navigation__list {
    align-items: start;
    flex-direction: column;
  }
  .gw-navigation--vertical .gw-navigation__list--nested {
    width: 12.5rem;
  }
  .gw-navigation--vertical .gw-button--icon.is-hidden-on-small .gw-icon {
    transform: rotate(-90deg);
  }
  [dir=rtl] .gw-navigation--vertical .gw-button--icon.is-hidden-on-small .gw-icon {
    transform: rotate(90deg);
  }

  [dir="ltr"] .gw-navigation--vertical .gw-product-filter{
            margin-right: 0;
  }

  [dir="rtl"] .gw-navigation--vertical .gw-product-filter{
            margin-left: 0;
  }

  [dir="ltr"] .gw-navigation--vertical .gw-product-filter{
            margin-left: 0;
  }

  [dir="rtl"] .gw-navigation--vertical .gw-product-filter{
            margin-right: 0;
  }

  .gw-navigation--vertical .gw-product-filter {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
  [dir="ltr"] .gw-navigation--vertical .gw-product-filter + .gw-navigation__list--nested{
            padding-left: 8.75rem;
  }
  [dir="rtl"] .gw-navigation--vertical .gw-product-filter + .gw-navigation__list--nested{
            padding-right: 8.75rem;
  }
  .gw-navigation--vertical .gw-product-filter + .gw-navigation__list--nested {
            padding-inline-start: 8.75rem;
    /* stylelint-disable-next-line prettier/prettier */
    -webkit-padding-start: 8.75rem;
  }
}
@media screen {
  [dir="ltr"] .gw-navigation{
            padding-left: 0.75rem;
  }
  [dir="rtl"] .gw-navigation{
            padding-right: 0.75rem;
  }
  .gw-navigation {
            padding-inline-start: 0.75rem;
    -webkit-padding-start: 0.75rem;
  }
  [dir="ltr"] .is-js-sticky .gw-navigation{
            padding-left: 0;
  }
  [dir="rtl"] .is-js-sticky .gw-navigation{
            padding-right: 0;
  }
  .is-js-sticky .gw-navigation {
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-layout__sticky--header .gw-navigation {
    align-items: center;
    display: flex;
  }
}
@media screen {
  .gw-navigation__link {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation__link {
    font-size: 1rem;
  }
}
@media screen {
  [dir="ltr"] .gw-navigation__link[class*="--text"]{
            padding-right: 0;
  }
  [dir="rtl"] .gw-navigation__link[class*="--text"]{
            padding-left: 0;
  }
  [dir="ltr"] .gw-navigation__link[class*="--text"]{
            padding-left: 0;
  }
  [dir="rtl"] .gw-navigation__link[class*="--text"]{
            padding-right: 0;
  }
  .gw-navigation__link[class*="--text"] {
    border-radius: 0;
    font-family: inherit;
    font-size: 0.875rem;
    letter-spacing: inherit;
    -webkit-padding-end: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation__link[class*="--text"] {
    font-size: 1rem;
  }
}
@media screen {
  [dir="ltr"] .show-mobile-nav .gw-navigation__link[class*="--text"]{
            padding-right: 0.75rem;
  }
  [dir="rtl"] .show-mobile-nav .gw-navigation__link[class*="--text"]{
            padding-left: 0.75rem;
  }
  [dir="ltr"] .show-mobile-nav .gw-navigation__link[class*="--text"]{
            padding-left: 0.75rem;
  }
  [dir="rtl"] .show-mobile-nav .gw-navigation__link[class*="--text"]{
            padding-right: 0.75rem;
  }
  .show-mobile-nav .gw-navigation__link[class*="--text"] {
    -webkit-padding-end: 0.75rem;
            padding-inline-end: 0.75rem;
            padding-inline-start: 0.75rem;
    -webkit-padding-start: 0.75rem;
  }
}
@media screen {
  .gw-navigation__list .gw-button[class*="--text"],
.gw-navigation__list .gw-navigation__link {
    border-radius: 0.5rem;
    color: rgb(var(--themeColorBrand));
    font-family: var(--themeFontFamilySemiBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
}
@media screen and (min-width: 48.0625em) {
  [data-whatintent=keyboard] .gw-navigation__list .gw-button[class*="--text"]:focus,
[data-whatintent=keyboard] .gw-navigation__list .gw-navigation__link:focus {
    box-shadow: none;
    outline: 0.125rem solid rgb(var(--themeBlack));
    outline-offset: 0.375rem;
    outline-style: solid;
  }

  [data-whatintent=mouse] .gw-navigation__list .gw-button[class*="--text"]:hover,
[data-whatintent=mouse] .gw-navigation__list .gw-navigation__link:hover {
    opacity: 0.8;
  }

  .gw-navigation__list .gw-button[class*="--text"]:active,
.gw-navigation__list .gw-navigation__link:active {
    transform: scale(0.975);
  }
}
@media screen {
  .gw-navigation__list .gw-button[class*="--text"]:hover,
.gw-navigation__list .gw-navigation__link:hover {
    color: rgb(var(--themeColorBrandAlt));
    opacity: unset;
    text-decoration: none;
  }
}
@media screen {
  .gw-navigation__list .gw-button-group {
    background-color: transparent;
  }
  .gw-navigation__list .gw-button-group .gw-button--primary {
    background-color: rgb(var(--themeColorBrandAlt));
    border-color: rgb(var(--themeColorBrandAlt));
    border-radius: 2rem;
    color: rgb(var(--themeColorTextAlt));
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  .gw-navigation__list .gw-button-group .gw-button--primary:hover:not(:disabled) {
    background-color: rgb(var(--themeColorLinkHover));
    border-color: rgb(var(--themeColorLinkHover));
    color: rgb(var(--themeColorTextAlt));
    opacity: 1;
    text-decoration: none;
    transform: scale(1);
  }
}
@media screen {
  .gw-navigation__list--nested {
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation__list--nested > * {
    min-width: 17rem;
  }
  @supports (grid-area: auto) {
    .gw-navigation__list--nested {
      grid-template-columns: repeat(auto-fit, minmax(17rem, 17rem));
    }
  }
}
@media screen {
  .gw-navigation__list--nested .gw-navigation__link {
    color: rgb(var(--themeColorText));
  }
}
@media screen {
  .gw-navigation__list .gw-icon {
    height: 0.875rem;
    width: 0.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-navigation__list .gw-icon {
    height: 1rem;
    width: 1rem;
  }
}
@media screen {
  .gw-navigation__list .gw-accordion__title {
    font-size: 0.875rem;
  }
}
@media screen {
  .gw-navigation__title {
    border-color: rgba(var(--themeBlack), 0.2);
    color: rgb(var(--themeColorBrand));
    font-family: var(--themeFontFamilySemiBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.25rem;
  }
}
@media screen {
  .gw-navigation__dropdown {
    background-color: rgb(var(--themeWhite));
  }
}
@media screen {
  .gw-navigation__dropdown--overlay {
    /* stylelint-disable-next-line no-descending-specificity */
  }
  .gw-navigation__dropdown--overlay .gw-button[class*="--text"] {
    color: rgb(var(--themeColorTextAlt));
  }
}
@media screen {
  .gw-navigation__button--back {
    background-color: rgb(var(--themeWhite));
    border-radius: 0;
  }
  [dir="ltr"] .gw-navigation__button--back[class*="--text"]{
            padding-right: 0.75rem;
  }
  [dir="rtl"] .gw-navigation__button--back[class*="--text"]{
            padding-left: 0.75rem;
  }
  [dir="ltr"] .gw-navigation__button--back[class*="--text"]{
            padding-left: 0.75rem;
  }
  [dir="rtl"] .gw-navigation__button--back[class*="--text"]{
            padding-right: 0.75rem;
  }
  .gw-navigation__button--back[class*="--text"] {
    font-family: inherit;
    font-size: 0.875rem;
    letter-spacing: inherit;
    -webkit-padding-end: 0.75rem;
            padding-inline-end: 0.75rem;
            padding-inline-start: 0.75rem;
    -webkit-padding-start: 0.75rem;
  }
}
@media screen {
  .gw-navigation .show-mobile-nav > .gw-navigation__list {
    background-color: rgb(var(--themeWhite));
  }
}
@media screen {
  .gw-nav-in-page__list {
    display: flex;
    flex-direction: column;
  }
  .gw-nav-in-page__item {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-nav-in-page__link {
    text-decoration: none;
  }
  .gw-nav-in-page__link:hover {
    color: rgb(var(--themeColorBrandAlt));
    text-decoration: underline;
  }
}
@media screen {
  .gw-news-card {
    align-items: center;
    background-color: rgb(var(--themeGrey1));
    border: 0;
    padding: 0;
  }
  .gw-news-card [class$=__content] {
    -webkit-margin-before: 1.25rem;
            margin-top: 1.25rem;
    order: 2;
  }
  .gw-news-card [class$=__media] {
    order: 1;
  }
  .gw-news-card > .gw-button-group {
    order: 3;
  }
  .gw-news-card .gw-card__media {
    width: 100%;
  }
  .gw-news-card .gw-card__content {
    -webkit-margin-before: 0;
            margin-top: 0;
    order: 2;
    padding: 0 1.25rem;
    width: 100%;
  }
  [dir="ltr"] .gw-news-card .gw-toolbar{
            margin-right: -1.25rem;
  }
  [dir="rtl"] .gw-news-card .gw-toolbar{
            margin-left: -1.25rem;
  }
  [dir="ltr"] .gw-news-card .gw-toolbar{
            margin-left: -1.25rem;
  }
  [dir="rtl"] .gw-news-card .gw-toolbar{
            margin-right: -1.25rem;
  }
  .gw-news-card .gw-toolbar {
    flex-direction: row-reverse;
    justify-content: space-between;
    -webkit-margin-end: -1.25rem;
            margin-inline-end: -1.25rem;
            margin-inline-start: -1.25rem;
    -webkit-margin-start: -1.25rem;
  }
  [dir="ltr"] .gw-news-card .gw-toolbar__item{
            padding-left: 1.25rem;
  }
  [dir="rtl"] .gw-news-card .gw-toolbar__item{
            padding-right: 1.25rem;
  }
  .gw-news-card .gw-toolbar__item {
            padding-inline-start: 1.25rem;
    -webkit-padding-start: 1.25rem;
  }
  .gw-news-card .gw-share__fallback {
    right: 0;
  }
  .gw-news-card-grid.gw-grid > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    .gw-news-card-grid.gw-grid {
      grid-template-columns: repeat(auto-fit, minmax(12.5rem, 18.75rem));
    }
  }
  .gw-news-card--block-link {
    justify-content: center;
    padding: 0 1.25rem;
  }
}
@media screen {
  .gw-news-card {
    background-color: rgb(var(--themeWhite));
    border: 0.0625rem solid rgb(var(--themeGrey2));
    border-radius: 0.5rem;
    box-shadow: none;
  }
  .gw-news-card:hover {
    border: 0.0625rem solid rgb(var(--themeGrey3));
  }
  [dir="ltr"] .gw-news-card .gw-card__media .gw-image{
    border-bottom-right-radius: 0;
  }
  [dir="rtl"] .gw-news-card .gw-card__media .gw-image{
    border-bottom-left-radius: 0;
  }
  [dir="ltr"] .gw-news-card .gw-card__media .gw-image{
    border-bottom-left-radius: 0;
  }
  [dir="rtl"] .gw-news-card .gw-card__media .gw-image{
    border-bottom-right-radius: 0;
  }
  [dir="ltr"] .gw-news-card .gw-card__media .gw-image{
    border-top-right-radius: 0.5rem;
  }
  [dir="rtl"] .gw-news-card .gw-card__media .gw-image{
    border-top-left-radius: 0.5rem;
  }
  [dir="ltr"] .gw-news-card .gw-card__media .gw-image{
    border-top-left-radius: 0.5rem;
  }
  [dir="rtl"] .gw-news-card .gw-card__media .gw-image{
    border-top-right-radius: 0.5rem;
  }
  .gw-news-card .gw-card__media .gw-image {
    border-end-end-radius: 0;
    border-end-start-radius: 0;
    border-start-end-radius: 0.5rem;
    border-start-start-radius: 0.5rem;
  }
  .gw-news-card--block-link {
    background-color: rgb(var(--themeGrey1));
    color: rgb(var(--themeColorLink));
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    text-decoration: none;
  }
  .gw-news-card--block-link .gw-card__title {
    color: rgb(var(--themeColorLink));
    text-align: center;
  }
}
@media screen {
  .gw-pagination {
    justify-content: center;
  }
  .gw-pagination:empty {
    display: none;
  }
}
@media screen {
  .gw-price-card {
    max-width: 350px;
    padding: 0;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [dir="ltr"] .gw-price-card__check-icon{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-price-card__check-icon{
            margin-left: 0.75rem;
  }
  .gw-price-card__check-icon {
    fill: rgb(var(--themeColorBrand));
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-price-card__check-icon {
    display: none;
  }
}
@media screen {
  .gw-price-card__content {
    background-color: rgb(var(--themeGrey1));
    padding: 1.875rem;
  }
}
@media screen {
  .gw-price-card__desc {
    min-height: 6rem;
  }
}
@media screen {
  .gw-price-card__feature {
    align-items: center;
    display: flex;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
}
@media screen {
  .gw-price-card__feature-desc--container {
    display: none;
  }
}
@media screen {
  .gw-price-card__feature-desc {
    background-color: rgb(var(--themeGrey1));
    border: 0.0625rem solid rgb(var(--themeGrey3));
    font-size: 0.875rem;
    padding: 1.25rem;
    position: absolute;
    transform: translateY(-50%);
    width: 200px;
  }
}
@media screen {
  .gw-price-card__feature-group {
    padding: 1.875rem;
  }
  .gw-price-card__feature-group h3 {
    font-size: 1.25rem;
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
}
@media screen {
  .gw-price-card__price {
    margin: 0;
  }
  .gw-price-card__price span {
    color: rgb(var(--themeColorBrand));
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 2.5rem;
  }
  .gw-price-card__price--additional {
    -webkit-margin-after: 0;
            margin-bottom: 0;
    min-height: 3.75rem;
  }
  .gw-price-card__price--additional span {
    color: rgb(var(--themeColorBrand));
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-price-card__price--additional span {
    font-size: 1.5rem;
  }
}
@media screen {
  .gw-price-card__price.hide {
    display: none;
  }
}
@media screen {
  [dir="ltr"] .gw-price-card__question-icon{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-price-card__question-icon{
            margin-right: 0.75rem;
  }
  .gw-price-card__question-icon {
    display: none;
    fill: rgb(var(--themeColorBrand));
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-price-card__question-icon {
    display: block;
  }
}
@media screen {
  .gw-price-card__question-icon:hover {
    cursor: pointer;
  }
}
@media screen {
  .gw-price-card__question-icon:hover + .gw-price-card__feature-desc--container {
    display: block;
  }
}
@media screen {
  .gw-price-card__title {
    font-size: 1.5rem;
    -webkit-padding-after: 1.25rem;
            padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-price-card__title {
    -webkit-border-after: 0.0625rem solid rgb(var(--themeGrey3));
            border-bottom: 0.0625rem solid rgb(var(--themeGrey3));
    -webkit-padding-after: 1.875rem;
            padding-bottom: 1.875rem;
  }
}
@media screen {
  .gw-price-card .gw-accordion {
    margin: 0;
  }
  .gw-price-card .gw-accordion__button:focus, .gw-price-card .gw-accordion.is-js-enabled .gw-accordion__content:focus {
    background-color: transparent;
  }
  .gw-price-card .gw-accordion__button__inner {
    font-size: 1.25rem;
  }
}
@media screen {
  .gw-price-card .gw-button {
    margin: 0;
    text-align: center;
    width: 100%;
  }
}
@media screen {
  .gw-price-card.gw-card:hover {
    box-shadow: none;
    transform: none;
  }
}
@media screen {
  .gw-price-grid {
    display: block;
  }
  .gw-price-grid__caption {
    padding: 2.5rem 0;
  }
  .gw-price-grid--container {
    -webkit-border-after: 0.0625rem solid rgb(var(--themeGrey3));
            border-bottom: 0.0625rem solid rgb(var(--themeGrey3));
    display: flex;
    flex-wrap: wrap;
    gap: 1.875rem;
  }
  .gw-price-grid__toggle {
    -webkit-margin-after: 2.5rem;
            margin-bottom: 2.5rem;
  }
}
@media screen and (min-width: 64em) {
  .gw-price-grid__toggle {
    align-items: center;
    display: flex;
  }
}
@media screen {
  .gw-price-grid .gw-button-group--toggle {
    flex: 0 0 30rem;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
  }
  .gw-layout__inner .gw-price-grid .gw-button-group--toggle {
    margin: 0;
  }
}
@media screen {
  .gw-product-card {
    align-items: center;
    background-color: rgb(var(--themeGrey1));
    border: 0;
    padding: 0;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-product-card .gw-card__content {
    flex-grow: inherit;
    -webkit-margin-before: 0;
            margin-top: 0;
    padding: 0 1.25rem;
    width: 100%;
  }
  .gw-product-card .gw-card__title > a {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    -webkit-padding-after: 1px;
            padding-bottom: 1px;
  }
  .gw-product-card__block-link .gw-product-card .gw-card__title {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-product-card .gw-card__title span {
    display: block;
  }
  .gw-product-card__specs {
    display: flex;
    flex-wrap: wrap;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  [dir="ltr"] .gw-product-card__specs span{
            margin-right: 1.25rem;
  }
  [dir="rtl"] .gw-product-card__specs span{
            margin-left: 1.25rem;
  }
  .gw-product-card__specs span {
    -webkit-margin-end: 1.25rem;
            margin-inline-end: 1.25rem;
  }
  [dir="ltr"] .gw-product-card__specs :last-child{
            margin-right: 0;
  }
  [dir="rtl"] .gw-product-card__specs :last-child{
            margin-left: 0;
  }
  .gw-product-card__specs :last-child {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
  .gw-product-card__block-link {
    text-align: center;
    text-decoration: none;
  }
  .gw-product-card__block-link:hover {
    text-decoration: underline;
  }
  .gw-product-card__block-link > * {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-product-card [class*=__price] {
    font-size: 1.5rem;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-product-card [class*=__price--text] {
    font-size: 1rem;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-product-card [class$=__price--discount] {
    color: rgba(var(--themeColorBrand), 0.8);
  }
  .gw-product-card__legal {
    font-size: 0.875rem;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-product-card__legal--extra span:not(:empty) {
    position: relative;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-product-card__legal--extra span:not(:empty):after {
    content: ".";
  }
  .gw-product-card .gw-card__actions {
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 1.25rem;
    width: 100%;
  }
  [dir="ltr"] .gw-product-card .gw-card__actions .gw-button--text{
            margin-right: 0;
  }
  [dir="rtl"] .gw-product-card .gw-card__actions .gw-button--text{
            margin-left: 0;
  }
  .gw-product-card .gw-card__actions .gw-button--text {
    color: rgb(var(--themeColorTextAlt));
    -webkit-margin-end: 0;
            margin-inline-end: 0;
    text-align: center;
    width: 100%;
  }
  .gw-product-card .gw-card__actions .gw-button--text.gw-button--secondary {
    color: rgb(var(--themeColorBrand));
  }
  .gw-product-card__table {
    font-size: 0.6875rem;
    width: 100%;
  }
  [dir="ltr"] .gw-product-card__table.gw-table th,[dir="ltr"] 
.gw-product-card__table.gw-table td{
            border-right: 0;
  }
  [dir="rtl"] .gw-product-card__table.gw-table th,[dir="rtl"] 
.gw-product-card__table.gw-table td{
            border-left: 0;
  }
  [dir="ltr"] .gw-product-card__table.gw-table th,[dir="ltr"] 
.gw-product-card__table.gw-table td{
            border-left: 0;
  }
  [dir="rtl"] .gw-product-card__table.gw-table th,[dir="rtl"] 
.gw-product-card__table.gw-table td{
            border-right: 0;
  }
  .gw-product-card__table.gw-table th,
.gw-product-card__table.gw-table td {
    -webkit-border-end: 0;
            border-inline-end: 0;
            border-inline-start: 0;
    -webkit-border-start: 0;
    padding: 0.375rem;
  }
  .gw-product-card__error {
    color: rgb(var(--themeAccentNegative));
    flex-basis: 100%;
    font-size: 0.875rem;
  }
  .gw-product-card--overview {
    background: transparent;
  }
  .gw-product-card--filter {
    background: none;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    max-width: 100%;
    text-decoration: none;
  }
  .gw-product-card--filter:hover {
    text-decoration: underline;
  }
  .gw-product-card--filter .gw-card__media {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-product-card--filter .gw-card__title {
    font-size: 1rem;
    margin: 0;
  }
  .gw-product-card--filter .gw-product-card__price {
    background: none;
    color: rgb(var(--themeColorText));
    font-size: 0.875rem;
    margin: 0 0 0.75rem;
    padding: 0;
  }
  .gw-product-card > .gw-button-group {
    margin: 0;
    padding: 0 1.25rem;
    width: 100%;
  }
  .gw-product-card--block-link {
    justify-content: center;
    padding: 0 1.25rem;
  }
  .gw-product-card-grid.gw-grid > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    .gw-product-card-grid.gw-grid {
      grid-template-columns: repeat(auto-fit, minmax(12.5rem, 21.875rem));
    }
  }
  .gw-product-card-grid--stock ~ * > .gw-cms {
    margin: auto;
    max-width: calc(var(--themeMaxLineLength) / 2);
    text-align: center;
  }
}
@media screen and (min-width: 64em) {
  .gw-product-card-grid--stock ~ * > .gw-cms {
    max-width: calc(var(--themeMaxLineLength) / 1.5);
  }
}
@media screen and (min-width: 90em) {
  .gw-product-card-grid--stock ~ * > .gw-cms {
    max-width: var(--themeMaxLineLength);
  }
}
@media screen {
  .gw-product-card-grid--stock > li > .gw-product-card .gw-button[data-quick-view] {
    border: 0;
    padding: 0;
  }
  .gw-product-card-grid--stock > li > .gw-product-card .gw-button[data-quick-view]:hover {
    background-color: transparent;
  }
  .gw-product-card-grid--stock > li > .gw-product-card .gw-button[data-quick-view] .gw-icon {
    fill: rgb(var(--themeColorBrandAlt));
    margin: 0;
  }
  .gw-product-card-grid--stock > li > .gw-product-card .gw-product-card__price > .gw-button-group {
    display: inline;
  }
  .gw-product-card-grid--stock > li > .gw-product-card .gw-product-card__pricing--finance {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-product-card-grid--stock > li > .gw-product-card .gw-product-card__pricing--finance .gw-product-card__price--discount,
.gw-product-card-grid--stock > li > .gw-product-card .gw-product-card__pricing--finance .gw-product-card__price--was {
    font-size: 1rem;
  }
  .gw-product-card-grid--stock > li > .gw-product-card .gw-product-card__pricing--finance .gw-product-card__price--was {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
}
@media screen {
  .gw-product-card {
    background-color: rgb(var(--themeWhite));
    border: 0.0625rem solid rgb(var(--themeGrey2));
    border-radius: 0.5rem;
    box-shadow: none;
  }
  .gw-product-card:hover {
    border: 0.0625rem solid rgb(var(--themeGrey3));
  }
  .gw-product-card .gw-card__actions .gw-button--text.gw-button--secondary {
    color: rgb(var(--themeColorBrandAlt));
  }
  [dir="ltr"] .gw-product-card .gw-card__media .gw-image{
    border-bottom-right-radius: 0;
  }
  [dir="rtl"] .gw-product-card .gw-card__media .gw-image{
    border-bottom-left-radius: 0;
  }
  [dir="ltr"] .gw-product-card .gw-card__media .gw-image{
    border-bottom-left-radius: 0;
  }
  [dir="rtl"] .gw-product-card .gw-card__media .gw-image{
    border-bottom-right-radius: 0;
  }
  [dir="ltr"] .gw-product-card .gw-card__media .gw-image{
    border-top-right-radius: 0.5rem;
  }
  [dir="rtl"] .gw-product-card .gw-card__media .gw-image{
    border-top-left-radius: 0.5rem;
  }
  [dir="ltr"] .gw-product-card .gw-card__media .gw-image{
    border-top-left-radius: 0.5rem;
  }
  [dir="rtl"] .gw-product-card .gw-card__media .gw-image{
    border-top-right-radius: 0.5rem;
  }
  .gw-product-card .gw-card__media .gw-image {
    border-end-end-radius: 0;
    border-end-start-radius: 0;
    border-start-end-radius: 0.5rem;
    border-start-start-radius: 0.5rem;
  }
  .gw-product-card--filter {
    border-color: transparent;
    border-radius: 0.5rem;
    box-shadow: none;
    padding: 0.75rem;
  }
  [data-whatintent=mouse] .gw-product-card--filter:hover {
    opacity: 1;
  }

  .gw-product-card--filter .gw-card__content {
    text-align: center;
  }
  [dir="ltr"] .gw-product-card--filter .gw-card__media .gw-image{
    border-top-right-radius: 0;
  }
  [dir="rtl"] .gw-product-card--filter .gw-card__media .gw-image{
    border-top-left-radius: 0;
  }
  [dir="ltr"] .gw-product-card--filter .gw-card__media .gw-image{
    border-top-left-radius: 0;
  }
  [dir="rtl"] .gw-product-card--filter .gw-card__media .gw-image{
    border-top-right-radius: 0;
  }
  .gw-product-card--filter .gw-card__media .gw-image {
    border-start-end-radius: 0;
    border-start-start-radius: 0;
  }
  .gw-product-card--filter:hover {
    text-decoration: none;
  }
  .gw-product-card--filter .gw-product-card__price {
    color: rgb(var(--themeColorTextSecondary));
  }
  .gw-product-card .gw-card__title {
    color: rgb(var(--themeColorText));
  }
  .gw-product-card .gw-card__title > a:hover {
    text-decoration: none;
  }
  .gw-product-card .gw-card__title span {
    color: rgb(var(--themeColorTextSecondary));
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1rem;
    -webkit-margin-before: 0.75rem;
            margin-top: 0.75rem;
    text-transform: none;
  }
  .gw-product-card .gw-card__content {
    -webkit-padding-after: 0.75rem;
            padding-bottom: 0.75rem;
  }
  .gw-product-card__block-link {
    -webkit-margin-before: 0.75rem;
            margin-top: 0.75rem;
  }
  .gw-product-card__block-link:hover {
    color: rgb(var(--themeColorBrandAlt));
    text-decoration: none;
  }
  .gw-product-card__block-link > p:not([class]) {
    color: rgb(var(--themeColorTextSecondary));
  }
  .gw-product-card__legal {
    color: rgb(var(--themeColorTextSecondary));
  }
  .gw-product-card [class$=__specs] {
    font-size: 0.875rem;
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  [dir="ltr"] .gw-product-card [class$=__specs] span{
            margin-right: 0.2rem;
  }
  [dir="rtl"] .gw-product-card [class$=__specs] span{
            margin-left: 0.2rem;
  }
  .gw-product-card [class$=__specs] span {
    color: rgb(var(--themeColorTextSecondary));
    -webkit-margin-end: 0.2rem;
            margin-inline-end: 0.2rem;
  }
  .gw-product-card [class$=__specs] span:not(:last-child):after {
    content: "•";
  }
  .gw-product-card:not(.gw-product-card--filter) [class*=__price] {
    align-items: center;
    font-family: var(--themeFontFamilySemiBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-product-card:not(.gw-product-card--filter) [class$=__price--was] {
    color: rgb(var(--themeColorTextSecondary));
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.25rem;
    font-weight: normal;
  }
  .gw-product-card:not(.gw-product-card--filter) [class$=__price--discount] {
    color: rgb(var(--themeAccentNegative));
    font-family: var(--themeFontFamilySemiBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-product-card:not(.gw-product-card--filter) [class$=__price--text] {
    font-size: 1rem;
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.3;
  }
  .gw-product-card > .gw-button-group {
    align-content: flex-start;
    border-radius: 0 0 0.5rem 0.5rem;
    -webkit-margin-before: auto;
            margin-top: auto;
    -webkit-padding-before: 0.75rem;
            padding-top: 0.75rem;
  }
  .gw-product-card--overview {
    border-color: transparent;
  }
  .gw-product-card--overview > .gw-button-group {
    height: 100%;
    -webkit-margin-before: unset;
            margin-top: unset;
  }
  [dir="ltr"] .gw-product-card--overview .gw-card__media .gw-image{
    border-top-right-radius: 0;
  }
  [dir="rtl"] .gw-product-card--overview .gw-card__media .gw-image{
    border-top-left-radius: 0;
  }
  [dir="ltr"] .gw-product-card--overview .gw-card__media .gw-image{
    border-top-left-radius: 0;
  }
  [dir="rtl"] .gw-product-card--overview .gw-card__media .gw-image{
    border-top-right-radius: 0;
  }
  .gw-product-card--overview .gw-card__media .gw-image {
    border-start-end-radius: 0;
    border-start-start-radius: 0;
  }
}
@media screen {
  .gw-product-filter.gw-tabs .gw-tabs__tablist {
    background-color: rgb(var(--themeColorBrand));
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-product-filter.gw-tabs .gw-tabs__tablist{
            padding-right: 8.75rem;
  }
  [dir="rtl"] .gw-product-filter.gw-tabs .gw-tabs__tablist{
            padding-left: 8.75rem;
  }
  [dir="ltr"] .gw-product-filter.gw-tabs .gw-tabs__tablist{
            padding-left: 8.75rem;
  }
  [dir="rtl"] .gw-product-filter.gw-tabs .gw-tabs__tablist{
            padding-right: 8.75rem;
  }
  .gw-product-filter.gw-tabs .gw-tabs__tablist {
    -webkit-padding-end: 8.75rem;
            padding-inline-end: 8.75rem;
            padding-inline-start: 8.75rem;
    -webkit-padding-start: 8.75rem;
  }
}
@media screen {
  .gw-product-filter.gw-tabs .gw-tabs__button {
    border: 0;
    -webkit-border-after: transparent 0.25rem solid;
            border-bottom: transparent 0.25rem solid;
    color: rgb(var(--themeColorTextAlt));
    padding: 0.75rem 0;
  }
  .gw-product-filter.gw-tabs .gw-tabs__button[aria-selected=true] {
    background-color: rgb(var(--themeColorBrand));
    border-color: inherit;
  }
}
@media screen {
  .gw-product-filter.gw-tabs.is-js-enabled .gw-tabs__content {
    border: 0;
    padding: 0;
  }
}
@media screen {
  .gw-product-filter.gw-accordion .gw-accordion__button {
    color: rgb(var(--themeColorBrand));
  }
  .gw-product-filter.gw-accordion .gw-accordion__button:hover {
    background-color: transparent;
  }
  .gw-product-filter.gw-accordion .gw-accordion__content {
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
  }
}
@media screen {
  .gw-product-filter .gw-grid {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
  .gw-product-filter .gw-grid > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    .gw-product-filter .gw-grid {
      grid-template-columns: repeat(auto-fill, minmax(12.5rem, 12.5rem));
    }
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-product-filter--alt.gw-tabs {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-product-filter--alt.gw-tabs .gw-tabs__tablist {
    background-color: transparent;
  }
  .gw-product-filter--alt.gw-tabs .gw-tabs__button {
    border: 0.0625rem solid rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorBrand));
    padding: 0.75rem;
    width: auto;
  }
  .gw-product-filter--alt.gw-tabs .gw-tabs__button[aria-selected=true] {
    background-color: rgb(var(--themeColorBrand));
    border-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorTextAlt));
  }
}
@media screen {
  .gw-product-filter.gw-tabs--vertical {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  [dir="ltr"] .gw-product-filter.gw-tabs--vertical.is-js-enabled{
            padding-right: 1.25rem;
  }
  [dir="rtl"] .gw-product-filter.gw-tabs--vertical.is-js-enabled{
            padding-left: 1.25rem;
  }
  .gw-product-filter.gw-tabs--vertical.is-js-enabled {
    max-height: 60vh;
    overflow-y: auto;
    -webkit-padding-end: 1.25rem;
            padding-inline-end: 1.25rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-product-filter.gw-tabs--vertical.is-js-enabled {
    max-height: 75vh;
  }
}
@media screen {
  [dir="ltr"] .gw-product-filter.gw-tabs--vertical .gw-tabs__tablist{
            margin-right: 1.875rem;
  }
  [dir="rtl"] .gw-product-filter.gw-tabs--vertical .gw-tabs__tablist{
            margin-left: 1.875rem;
  }
  .gw-product-filter.gw-tabs--vertical .gw-tabs__tablist {
    background-color: rgb(var(--themeWhite));
    justify-content: flex-start;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    -webkit-margin-end: 1.875rem;
            margin-inline-end: 1.875rem;
    -webkit-padding-before: 0.75rem;
            padding-top: 0.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-product-filter.gw-tabs--vertical .gw-tabs__tablist{
            padding-right: 0;
  }
  [dir="rtl"] .gw-product-filter.gw-tabs--vertical .gw-tabs__tablist{
            padding-left: 0;
  }
  [dir="ltr"] .gw-product-filter.gw-tabs--vertical .gw-tabs__tablist{
            padding-left: 0;
  }
  [dir="rtl"] .gw-product-filter.gw-tabs--vertical .gw-tabs__tablist{
            padding-right: 0;
  }
  .gw-product-filter.gw-tabs--vertical .gw-tabs__tablist {
    -webkit-padding-end: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
}
@media screen {
  [dir="ltr"] .gw-product-filter.gw-tabs--vertical .gw-tabs__button{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-product-filter.gw-tabs--vertical .gw-tabs__button{
            margin-right: 0.75rem;
  }
  [dir="ltr"] .gw-product-filter.gw-tabs--vertical .gw-tabs__button{
            padding-right: 1.25rem;
  }
  [dir="rtl"] .gw-product-filter.gw-tabs--vertical .gw-tabs__button{
            padding-left: 1.25rem;
  }
  [dir="ltr"] .gw-product-filter.gw-tabs--vertical .gw-tabs__button{
            padding-left: 0.5rem;
  }
  [dir="rtl"] .gw-product-filter.gw-tabs--vertical .gw-tabs__button{
            padding-right: 0.5rem;
  }
  .gw-product-filter.gw-tabs--vertical .gw-tabs__button {
    border: 0;
    color: rgb(var(--themeColorBrand));
    font-size: 0.875rem;
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
    -webkit-padding-after: 0.375rem;
    -webkit-padding-before: 0.375rem;
            padding-bottom: 0.375rem;
            padding-top: 0.375rem;
    -webkit-padding-end: 1.25rem;
            padding-inline-end: 1.25rem;
            padding-inline-start: 0.5rem;
    -webkit-padding-start: 0.5rem;
    text-align: inherit;
    width: calc(100% - 0.75rem);
  }
  .gw-product-filter.gw-tabs--vertical .gw-tabs__button[aria-selected=true] {
    background-color: rgb(var(--themeGrey1));
  }
}
@media screen {
  .gw-product-filter.gw-tabs--vertical .gw-tabs__content {
    -webkit-margin-before: 0.75rem;
            margin-top: 0.75rem;
  }
}
@media screen {
  .gw-product-filter.gw-tabs--vertical .gw-grid {
    align-items: center;
    gap: 0.75rem;
  }
}
@media screen {
  .gw-product-filter.gw-tabs:not(.gw-tabs--vertical) .gw-tabs__tablist {
    background-color: transparent;
    flex-wrap: wrap;
    -webkit-padding-before: 0.75rem;
            padding-top: 0.75rem;
  }
  .gw-product-filter.gw-tabs:not(.gw-tabs--vertical) .gw-tabs__button {
    background-color: transparent;
    background-color: transparent;
    border: 0.0625rem solid;
    border-color: rgb(var(--themeColorBrandAlt));
    border-color: rgb(var(--themeGrey3));
    border-radius: 2rem;
    border-radius: 0.5rem;
    color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorTextSecondary));
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    line-height: 1;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    padding: 0.75rem 1.875rem;
  }
  .gw-product-filter.gw-tabs:not(.gw-tabs--vertical) .gw-tabs__button:hover:not(:disabled) {
    border-color: rgb(var(--themeColorLinkHover));
    color: rgb(var(--themeColorLinkHover));
    text-decoration: none;
    transform: scale(1);
  }
  [data-whatintent=keyboard] .gw-product-filter.gw-tabs:not(.gw-tabs--vertical) .gw-tabs__button:focus {
    box-shadow: none;
    outline: 0.125rem solid rgb(var(--themeBlack));
    outline-offset: 0.375rem;
    outline-style: solid;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-product-filter.gw-tabs:not(.gw-tabs--vertical) .gw-tabs__button {
    font-size: 1rem;
  }
}
@media screen {
  .gw-product-filter.gw-tabs:not(.gw-tabs--vertical) .gw-tabs__button:hover:not(:disabled) {
    background-color: transparent;
    border-color: rgb(var(--themeGrey4));
    color: rgb(var(--themeColorTextSecondary));
    opacity: 1;
    transform: scale(1);
  }
}
@media screen {
  .gw-product-filter.gw-tabs:not(.gw-tabs--vertical) .gw-tabs__button.is-selected {
    border-color: rgb(var(--themeColorBrandAlt));
    box-shadow: none;
    color: rgb(var(--themeColorText));
  }
}
@media screen {
  .gw-product-filter.gw-tabs:not(.gw-tabs--vertical) .gw-tabs__button.is-selected:hover {
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorText));
  }
}
@media screen {
  .gw-product-filter.gw-tabs:not(.gw-tabs--vertical) .gw-tabs__button[aria-selected=true] {
    background-color: transparent;
    border: 0.0625rem solid;
    border-color: rgb(var(--themeColorBrandAlt));
    border-radius: 2rem;
    border-radius: 0.5rem;
    border-width: 0.125rem;
    box-shadow: none;
    color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorText));
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    line-height: 1;
    padding: 0.75rem 1.875rem;
  }
  .gw-product-filter.gw-tabs:not(.gw-tabs--vertical) .gw-tabs__button[aria-selected=true]:hover:not(:disabled) {
    border-color: rgb(var(--themeColorLinkHover));
    color: rgb(var(--themeColorLinkHover));
    text-decoration: none;
    transform: scale(1);
  }
}
@media screen {
  .gw-product-filter.gw-tabs:not(.gw-tabs--vertical) .gw-tabs__button[aria-selected=true]:hover:not(:disabled) {
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorText));
  }
}
@media screen {
  .gw-product-filter .gw-tabs__content {
    background-color: transparent;
  }
}
@media screen {
  .gw-product-gallery .gw-tabs__content {
    background-color: transparent;
  }
}
@media screen {
  .gw-product-leader-image {
    display: grid;
    gap: 0;
    grid-template-areas: "a b";
    grid-template-columns: repeat(2, 50%);
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  .gw-product-leader-image--single-image {
    display: block;
  }
  .gw-product-leader-image .gw-image {
    width: 100%;
  }
  .gw-product-leader-image :first-child {
    grid-area: a;
  }
  .gw-product-leader-image :last-child {
    grid-area: b;
  }
}
@media screen {
  .gw-product-search .gw-form {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-product-search .gw-form__legend {
    text-align: center;
    width: 100%;
  }
  .gw-product-search .gw-form__label > span:not([class]):first-child {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-product-search .gw-form__label > span:not([class]):first-child:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
  .gw-product-search .gw-stock-filter-ajax {
    width: 15rem;
  }
  .gw-product-search .gw-button {
    margin: 0;
  }
  .gw-product-search .gw-grid {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .gw-product-search .gw-grid > * {
    margin: 0.9375rem;
  }
  @supports (grid-area: auto) {
    .gw-product-search .gw-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      justify-content: center;
    }
    .gw-product-search .gw-grid > * {
      flex-grow: 0;
      margin: 0.9375rem;
    }
  }
}
@media screen and (max-width: 48em) {
  [dir="ltr"] .gw-product-search .gw-grid > *{
            margin-right: auto;
  }
  [dir="rtl"] .gw-product-search .gw-grid > *{
            margin-left: auto;
  }
  [dir="ltr"] .gw-product-search .gw-grid > *{
            margin-left: auto;
  }
  [dir="rtl"] .gw-product-search .gw-grid > *{
            margin-right: auto;
  }
  .gw-product-search .gw-grid > * {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
    min-width: 0;
  }
}
@media screen {
  .gw-product-search-content {
    display: block;
    flex-basis: 100%;
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
  }
  .gw-product-search-content.is-js-enabled {
    display: none;
  }
  .gw-product-search-content .gw-product-filter,
.gw-product-search-content .gw-form__range .gw-form__label {
    background-color: transparent;
  }
  .gw-product-search-content .gw-tabs__title + .gw-tabs__content .gw-form__range {
    display: none;
  }
}
@media screen {
  .gw-product-search-results__modal .gw-product-filter {
    background-color: rgb(var(--themeWhite));
  }
  .gw-product-search-results__modal .gw-form__range .gw-form__label {
    background-color: rgb(var(--themeGrey1));
  }
  .gw-product-search-results__modal .gw-product-search-content {
    display: block;
  }
  .gw-product-search-results__modal .gw-tabs__title + .gw-tabs__content .gw-form__range {
    display: flex;
  }
}
@media screen {
  .gw-product-search {
    background-color: rgb(var(--themeGrey5));
    padding: 1.875rem 1.25rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-product-search {
    padding: 2.5rem 0;
  }
}
@media screen {
  .gw-product-search .gw-grid {
    align-items: flex-end;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-product-search .gw-grid {
    -webkit-margin-after: 1.25rem;
    -webkit-margin-before: 1.25rem;
            margin-bottom: 1.25rem;
            margin-top: 1.25rem;
  }
}
@media screen {
  .gw-product-search .gw-form__legend {
    color: rgb(var(--themeWhite));
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.5rem;
    font-size: clamp(1.25rem, 1rem + 1.5vw, 1.5rem);
    line-height: 1.3;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-product-search .gw-form__legend {
    font-size: 2rem;
    font-size: clamp(1.5rem, 1rem + 2vw, 2rem);
    line-height: 1.2;
    text-align: center;
  }
  [dir=rtl] .gw-product-search .gw-form__legend {
    font-size: clamp(1.5rem, calc(1rem + 2vw) * 1.125, 2rem);
  }
}
@media screen and (max-width: 48em) {
  [dir="ltr"] .gw-product-search .gw-form__legend{
    text-align: left;
  }
  [dir="rtl"] .gw-product-search .gw-form__legend{
    text-align: right;
  }
  .gw-product-search .gw-form__legend {
    text-align: start;
  }
}
@media screen {
  [dir=rtl] .gw-product-search .gw-form__legend {
    font-size: clamp(1.25rem, calc(1rem + 1.5vw) * 1.125, 1.5rem);
  }
}
@media screen {
  .gw-product-search .gw-form__label > span:not([class]):first-child {
    color: rgb(var(--themeWhite));
    font-size: 0.875rem;
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: auto;
  }
}
@media screen {
  [dir="ltr"] .gw-product-search .gw-form__field{
            margin-left: 0;
  }
  [dir="rtl"] .gw-product-search .gw-form__field{
            margin-right: 0;
  }
  .gw-product-search .gw-form__field {
            margin-inline-start: 0;
    -webkit-margin-start: 0;
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-product-search .gw-form__field{
            margin-left: inherit;
  }
  [dir="rtl"] .gw-product-search .gw-form__field{
            margin-right: inherit;
  }
  .gw-product-search .gw-form__field {
            margin-inline-start: inherit;
    -webkit-margin-start: inherit;
    width: inherit;
  }
}
@media screen {
  .gw-product-search .gw-stock-filter-ajax {
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-product-search .gw-stock-filter-ajax {
    width: inherit;
  }
}
@media screen {
  [dir="ltr"] .gw-product-search .gw-form__buttons{
            margin-left: 0;
  }
  [dir="rtl"] .gw-product-search .gw-form__buttons{
            margin-right: 0;
  }
  .gw-product-search .gw-form__buttons {
    margin-top: 1.875rem;
    margin-bottom: 0;
    margin-block: 1.875rem 0;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-product-search .gw-form__buttons{
            margin-left: 0.75rem;
  }
  [dir="rtl"] .gw-product-search .gw-form__buttons{
            margin-right: 0.75rem;
  }
  .gw-product-search .gw-form__buttons {
    margin-top: 0.75rem;
    margin-bottom: 0;
    margin-block: 0.75rem 0;
            margin-inline-start: 0.75rem;
    -webkit-margin-start: 0.75rem;
  }
}
@media screen {
  .gw-product-search .gw-form__select:disabled,
.gw-product-search .gw-form__select.is-disabled {
    background-color: rgb(var(--themeGrey3));
    color: rgb(var(--themeGrey4));
  }
}
@media screen {
  [dir="ltr"] .gw-product-search-results{
            margin-right: -1.875rem;
  }
  [dir="rtl"] .gw-product-search-results{
            margin-left: -1.875rem;
  }
  [dir="ltr"] .gw-product-search-results{
            margin-left: -1.875rem;
  }
  [dir="rtl"] .gw-product-search-results{
            margin-right: -1.875rem;
  }
  .gw-product-search-results {
    background-color: rgb(var(--themeWhite));
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
    -webkit-margin-end: -1.875rem;
            margin-inline-end: -1.875rem;
            margin-inline-start: -1.875rem;
    -webkit-margin-start: -1.875rem;
    padding: 1.25rem 1.875rem 0;
    /* stylelint-disable-next-line no-descending-specificity */
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-product-search-results{
            margin-right: -5rem;
  }
  [dir="rtl"] .gw-product-search-results{
            margin-left: -5rem;
  }
  [dir="ltr"] .gw-product-search-results{
            margin-left: -5rem;
  }
  [dir="rtl"] .gw-product-search-results{
            margin-right: -5rem;
  }
  [dir="ltr"] .gw-product-search-results{
            padding-right: 5rem;
  }
  [dir="rtl"] .gw-product-search-results{
            padding-left: 5rem;
  }
  [dir="ltr"] .gw-product-search-results{
            padding-left: 5rem;
  }
  [dir="rtl"] .gw-product-search-results{
            padding-right: 5rem;
  }
  .gw-product-search-results {
    -webkit-margin-end: -5rem;
            margin-inline-end: -5rem;
            margin-inline-start: -5rem;
    -webkit-margin-start: -5rem;
    -webkit-padding-end: 5rem;
            padding-inline-end: 5rem;
            padding-inline-start: 5rem;
    -webkit-padding-start: 5rem;
  }
}
@media screen {
  .gw-product-search-results h1,
.gw-product-search-results [id=products-search-filters] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-product-search-results h1:focus,
.gw-product-search-results [id=products-search-filters]:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
}
@media screen {
  .gw-product-search-results__form {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    max-width: 100%;
    position: relative;
  }
}
@media screen and (max-width: 30em) {
  .gw-product-search-results__form {
    align-items: flex-start;
    flex-flow: row wrap;
  }
}
@media screen {
  [dir="ltr"] .gw-product-search-results__form > *{
            margin-right: 1rem;
  }
  [dir="rtl"] .gw-product-search-results__form > *{
            margin-left: 1rem;
  }
  .gw-product-search-results__form > * {
    -webkit-margin-after: 1rem;
            margin-bottom: 1rem;
    -webkit-margin-end: 1rem;
            margin-inline-end: 1rem;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [dir="ltr"] .gw-product-search-results__form > *:last-child{
            margin-right: 0;
  }
  [dir="rtl"] .gw-product-search-results__form > *:last-child{
            margin-left: 0;
  }
  .gw-product-search-results__form > *:last-child {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
}
@media screen {
  .gw-product-search-results__form > .gw-button {
    display: inline-flex;
  }
}
@media screen {
  .gw-product-search-results__form .has-no-condition .gw-icon {
    display: none;
  }
}
@media screen {
  .gw-product-search-results__form > .gw-form__field {
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-product-search-results__form > .gw-form__field{
            margin-left: auto;
  }
  [dir="rtl"] .gw-product-search-results__form > .gw-form__field{
            margin-right: auto;
  }
  .gw-product-search-results__form > .gw-form__field {
            margin-inline-start: auto;
    -webkit-margin-start: auto;
    width: auto;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  [dir="ltr"] .gw-product-search-results__form > .gw-form__field:not(.is-hidden-on-medium) + .gw-form__field{
            margin-left: 0;
  }
  [dir="rtl"] .gw-product-search-results__form > .gw-form__field:not(.is-hidden-on-medium) + .gw-form__field{
            margin-right: 0;
  }
  .gw-product-search-results__form > .gw-form__field:not(.is-hidden-on-medium) + .gw-form__field {
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
}
@media screen {
  .gw-product-search-results__form > .gw-form__field .gw-form__label {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-product-search-results__form > .gw-form__field .gw-form__label > span:not([class]):first-child {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-product-search-results__form > .gw-form__field .gw-form__label > span:not([class]):first-child:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
}
@media screen {
  .gw-product-search-results__form > .gw-form__field .gw-form__select {
    border-radius: 0.1875rem;
    font-size: 1rem;
    height: auto;
    padding: 0.75rem 2.5rem 0.75rem 0.75rem;
  }
}
@media screen {
  [dir="ltr"] .gw-product-search-results__form [role=region][aria-live]{
            margin-left: auto;
  }
  [dir="rtl"] .gw-product-search-results__form [role=region][aria-live]{
            margin-right: auto;
  }
  .gw-product-search-results__form [role=region][aria-live] {
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-product-search-results__form [role=region][aria-live]{
            margin-left: 0;
  }
  [dir="rtl"] .gw-product-search-results__form [role=region][aria-live]{
            margin-right: 0;
  }
  .gw-product-search-results__form [role=region][aria-live] {
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
}
@media screen {
  .gw-product-search-results__form [role=region][aria-live] .gw-button--small {
    padding: 0.625rem 1.25rem 0.625rem 0.625rem;
  }
}
@media screen {
  [dir="ltr"] .gw-product-search-results [data-id=products-filter-button]{
            padding-right: 0;
  }
  [dir="rtl"] .gw-product-search-results [data-id=products-filter-button]{
            padding-left: 0;
  }
  [dir="ltr"] .gw-product-search-results [data-id=products-filter-button]{
            padding-left: 0;
  }
  [dir="rtl"] .gw-product-search-results [data-id=products-filter-button]{
            padding-right: 0;
  }
  .gw-product-search-results [data-id=products-filter-button] {
    border: 0;
    color: rgb(var(--themeColorBrand));
    -webkit-padding-end: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
  .gw-product-search-results [data-id=products-filter-button] .gw-icon {
    height: 2rem;
    width: 2rem;
  }
}
@media screen {
  .gw-product-search-results [data-id=stock-compare] {
    border: 0;
    color: rgb(var(--themeColorBrand));
  }
  .gw-product-search-results [data-id=stock-compare] .gw-icon {
    fill: transparent;
    height: 2rem;
    stroke: rgb(var(--themeColorBrand));
    stroke-width: 0.25rem;
    width: 2rem;
  }
  .gw-product-search-results [data-id=stock-compare].is-selected .gw-icon {
    fill: rgb(var(--themeColorBrand));
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-product-search-results--stock{
            padding-left: 0;
  }
  [dir="rtl"] .gw-product-search-results--stock{
            padding-right: 0;
  }
  .gw-product-search-results--stock {
    background-color: rgb(var(--themeColorBackground));
    top: 0;
    -webkit-padding-before: 0;
            padding-top: 0;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-product-search-results--stock + * > section[aria-labelledby=products-search-results] {
    margin-top: 2.5rem;
  }
}
@media screen {
  .gw-product-search-results--stock .gw-product-search-results__filters {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-product-search-results--stock .gw-product-search-results__filters {
    display: grid;
    grid-template-columns: 20.625rem 1fr;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-product-search-results--stock .gw-product-search-results__filters .gw-filter-sidebar__toggle{
            padding-left: 1.25rem;
  }
  [dir="rtl"] .gw-product-search-results--stock .gw-product-search-results__filters .gw-filter-sidebar__toggle{
            padding-right: 1.25rem;
  }
  .gw-product-search-results--stock .gw-product-search-results__filters .gw-filter-sidebar__toggle {
    background-color: transparent;
    border-radius: 0;
    height: 100%;
    margin: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-block: 1.25rem;
            padding-inline-start: 1.25rem;
    -webkit-padding-start: 1.25rem;
  }
}
@media screen {
  .gw-product-search-results--stock .gw-product-search-results__filters .gw-filter-sidebar__toggle .gw-icon:nth-child(2) {
    display: none;
  }
}
@media screen {
  .gw-product-search-results--stock .gw-product-search-results__filters .gw-filter-sidebar__toggle:active {
    transform: none;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-product-search-results--stock .gw-product-search-results__filters .gw-filter-sidebar__toggle.open {
    background-color: white;
    margin: 0;
  }
}
@media screen {
  .gw-product-search-results--stock .gw-product-search-results__filters .gw-filter-sidebar__toggle.open .gw-icon:first-child {
    display: none;
  }
  .gw-product-search-results--stock .gw-product-search-results__filters .gw-filter-sidebar__toggle.open .gw-icon:nth-child(2) {
    display: block;
  }
}
@media screen {
  .gw-product-search-results--stock .gw-product-search-results__form {
    /* stylelint-disable max-nesting-depth */
    align-items: start;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
    margin-block: 0;
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-product-search-results--stock .gw-product-search-results__form{
            margin-left: 1.875rem;
  }
  [dir="rtl"] .gw-product-search-results--stock .gw-product-search-results__form{
            margin-right: 1.875rem;
  }
  .gw-product-search-results--stock .gw-product-search-results__form {
    align-items: center;
    justify-content: center;
            margin-inline-start: 1.875rem;
    -webkit-margin-start: 1.875rem;
    -webkit-padding-before: 1.25rem;
            padding-top: 1.25rem;
    width: 100%;
  }
}
@media screen {
  [dir="ltr"] .gw-product-search-results--stock .gw-product-search-results__form [role=region][aria-live]{
            margin-left: 0;
  }
  [dir="rtl"] .gw-product-search-results--stock .gw-product-search-results__form [role=region][aria-live]{
            margin-right: 0;
  }
  .gw-product-search-results--stock .gw-product-search-results__form [role=region][aria-live] {
            margin-inline-start: 0;
    -webkit-margin-start: 0;
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-product-search-results--stock .gw-product-search-results__form [role=region][aria-live] {
    width: auto;
  }
}
@media screen {
  [dir="ltr"] .gw-product-search-results--stock .gw-product-search-results__form [role=region][aria-live] .gw-button--small{
            padding-left: 0;
  }
  [dir="rtl"] .gw-product-search-results--stock .gw-product-search-results__form [role=region][aria-live] .gw-button--small{
            padding-right: 0;
  }
  .gw-product-search-results--stock .gw-product-search-results__form [role=region][aria-live] .gw-button--small {
    background-color: transparent;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-product-search-results--stock .gw-product-search-results__form [role=region][aria-live] .gw-button--small{
            padding-left: 0.625rem;
  }
  [dir="rtl"] .gw-product-search-results--stock .gw-product-search-results__form [role=region][aria-live] .gw-button--small{
            padding-right: 0.625rem;
  }
  .gw-product-search-results--stock .gw-product-search-results__form [role=region][aria-live] .gw-button--small {
            padding-inline-start: 0.625rem;
    -webkit-padding-start: 0.625rem;
  }
}
@media screen {
  .gw-product-search-results--stock .gw-product-search-results__form [role=region][aria-live] .gw-button--small:hover {
    color: rgb(var(--themeColorBrand));
    text-decoration: underline;
  }
}
@media screen {
  .gw-product-search-results--stock .gw-product-search-results__form > .gw-form__field {
    width: 45%;
  }
}
@media screen and (max-width: 30em) {
  .gw-product-search-results--stock .gw-product-search-results__form > .gw-form__field {
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-product-search-results--stock .gw-product-search-results__form > .gw-form__field {
    width: auto;
  }
}
@media screen {
  .gw-product-search-results .gw-form__buttons {
    flex-basis: 100%;
  }
}
@media screen {
  .gw-product-search-results .gw-grid > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    .gw-product-search-results .gw-grid {
      grid-template-columns: repeat(auto-fill, minmax(12.5rem, 25rem));
    }
  }
  .gw-product-search-results .gw-grid .gw-form__field {
    justify-content: flex-start;
  }
}
@media screen {
  .gw-product-search-results .gw-tabs .gw-grid > *,
.gw-product-search-results .gw-accordion .gw-grid > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    .gw-product-search-results .gw-tabs .gw-grid,
.gw-product-search-results .gw-accordion .gw-grid {
      grid-template-columns: repeat(auto-fit, minmax(12.5rem, 12.5rem));
    }
  }
}
@media screen {
  [dir="ltr"] .gw-product-search-results [role=region][aria-live]{
            margin-right: 0;
  }
  [dir="rtl"] .gw-product-search-results [role=region][aria-live]{
            margin-left: 0;
  }
  .gw-product-search-results [role=region][aria-live] {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
}
@media screen {
  [dir="ltr"] .gw-product-search-results [id=products-search-filters]{
            margin-right: 0;
  }
  [dir="rtl"] .gw-product-search-results [id=products-search-filters]{
            margin-left: 0;
  }
  .gw-product-search-results [id=products-search-filters] {
    flex-basis: 100%;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
}
@media screen {
  .gw-product-search-results + [aria-labelledby=products-search-results] [id=products-search-results] {
    font-size: 1.25rem;
  }
}
@media screen {
  .gw-product-search-results.is-js-enabled [data-id=products-filter-content] {
    display: none;
  }
}
@media screen {
  [dir="ltr"] .gw-product-search-results__modal .gw-modal__inner{
            padding-right: 0.625rem;
  }
  [dir="rtl"] .gw-product-search-results__modal .gw-modal__inner{
            padding-left: 0.625rem;
  }
  .gw-product-search-results__modal .gw-modal__inner {
    max-height: 98%;
    -webkit-padding-end: 0.625rem;
            padding-inline-end: 0.625rem;
    width: 98%;
  }
}
@media screen and (max-width: 48em) {
  .gw-product-search-results__modal .gw-button[class*="--text"] {
    font-size: 0.875rem;
    padding: 0.75rem;
  }
}
@media screen {
  .gw-product-search-results .gw-button--toggle.is-selected:not(.has-no-condition) {
    /* stylelint-disable-next-line max-nesting-depth */
  }
  .gw-product-search-results .gw-button--toggle.is-selected:not(.has-no-condition):nth-child(1) {
    display: block;
  }
  .gw-product-search-results .gw-button--toggle[class*="--text"].is-selected {
    background-color: rgba(var(--themeColorBrandAlt), 0.05);
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorBrandAlt));
    /* stylelint-disable-next-line max-nesting-depth */
  }
  .gw-product-search-results .gw-button--toggle[class*="--text"].is-selected:hover:not(:disabled) {
    background-color: rgba(var(--themeColorBrandAlt), 0.05);
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorBrandAlt));
  }
}
@media screen {
  .gw-product-search-results [data-id=stock-compare] {
    color: rgb(var(--themeColorBrandAlt));
    font-family: var(--themeFontFamilySemiBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  .gw-product-search-results [data-id=stock-compare] .gw-icon {
    stroke: rgb(var(--themeColorBrandAlt));
    stroke-width: 0.15rem;
  }
  .gw-product-search-results [data-id=stock-compare].is-selected .gw-icon {
    fill: rgb(var(--themeColorBrandAlt));
  }
  .gw-product-search-results [data-id=products-filter-button] {
    color: rgb(var(--themeColorBrand));
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  .gw-product-search-results [data-id=products-filter-button].is-selected {
    background-color: transparent;
    color: inherit;
  }
  .gw-product-search-results .gw-form__select {
    background-color: rgb(var(--themeWhite));
  }
  .gw-product-search-results .gw-button--toggle[class*="--text"].is-selected {
    background-color: rgb(var(--themeWhite));
    color: rgb(var(--themeColorText));
    /* stylelint-disable-next-line max-nesting-depth */
  }
  .gw-product-search-results .gw-button--toggle[class*="--text"].is-selected:hover:not(:disabled) {
    background-color: rgb(var(--themeWhite));
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorText));
  }
}
@media screen {
  .gw-product-specs {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  .gw-product-specs__list {
    display: flex;
    flex-wrap: wrap;
  }
  .gw-tabs__content .gw-product-specs__list {
    -webkit-padding-before: 0.75rem;
            padding-top: 0.75rem;
  }
  .gw-product-specs__item {
    align-items: center;
    background-color: rgb(var(--themeGrey3));
    color: rgb(var(--themeColorTextAlt));
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1.25rem;
  }
  .gw-product-specs__item .gw-icon {
    height: 1.5em;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    width: 1.5em;
  }
  .gw-product-specs__price {
    align-items: flex-start;
    flex-basis: 100%;
    flex-grow: 3;
    justify-content: center;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-product-specs__price {
    flex-basis: 25%;
  }
}
@media screen {
  .gw-product-specs__price strong {
    font-size: 2rem;
    font-size: clamp(1.5rem, 1rem + 2vw, 2rem);
    line-height: 1.2;
  }
  [dir=rtl] .gw-product-specs__price strong {
    font-size: clamp(1.5rem, calc(1rem + 2vw) * 1.125, 2rem);
  }
}
@media screen {
  .gw-product-specs__actions {
    padding: 0;
  }
  .gw-product-specs__actions__list {
    background-color: rgb(var(--themeColorBrand));
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
  }
}
@media screen {
  .gw-product-specs__action {
    background-color: transparent;
  }
  .gw-product-specs__action .gw-button {
    height: 100%;
    padding: 1.25rem;
    width: 100%;
  }
}
@media screen {
  .gw-product-summary {
    background-color: rgb(var(--themeWhite));
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-product-summary > * {
    margin: 0 0.75rem;
  }
}
@media screen and (min-width: 64em) {
  .gw-product-summary > * {
    margin: 0 1.875rem;
  }
}
@media screen {
  .gw-product-summary.hide-at-tablet {
    display: block;
  }
}
@media screen and (min-width: 64em) {
  .gw-product-summary.hide-at-tablet {
    display: none;
  }
}
@media screen {
  .gw-product-summary.display-at-tablet {
    display: none;
    top: 5rem;
  }
}
@media screen and (min-width: 64em) {
  .gw-product-summary.display-at-tablet {
    display: block;
  }
}
@media screen {
  .gw-product-summary__name {
    font-size: 2.5rem;
    font-size: clamp(2rem, 1rem + 2.5vw, 2.5rem);
    line-height: 1.2;
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
  [dir=rtl] .gw-product-summary__name {
    font-size: clamp(2rem, calc(1rem + 2.5vw) * 1.125, 2.5rem);
  }
  .gw-product-summary__name span {
    display: block;
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    text-transform: capitalize;
    word-wrap: break-word;
  }
}
@media screen and (min-width: 64em) {
  .gw-product-summary__name span {
    -webkit-margin-before: 1.25rem;
            margin-top: 1.25rem;
  }
}
@media screen {
  .gw-product-summary__name:not(h1) {
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 2.5rem;
    font-size: clamp(2rem, 1rem + 2.5vw, 2.5rem);
    font-size: 1.25rem;
    line-height: 1.2;
  }
  [dir=rtl] .gw-product-summary__name:not(h1) {
    font-size: clamp(2rem, calc(1rem + 2.5vw) * 1.125, 2.5rem);
  }
}
@media screen {
  .gw-product-summary__price {
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.5rem;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    padding: 0.75rem;
  }
  .gw-product-summary__price--extra {
    font-size: 0.875rem;
  }
  .gw-product-summary__price--extra span:not(:empty) {
    position: relative;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-product-summary__price--extra span:not(:empty):after {
    content: ".";
  }
  .gw-product-summary__price--original {
    display: flex;
    justify-content: space-between;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-product-summary__price--original span:first-child {
    font-size: 1rem;
    font-weight: normal;
  }
  .gw-product-summary__price--original span:nth-child(2) {
    font-size: 1.5rem;
    font-size: clamp(1.25rem, 1rem + 1.5vw, 1.5rem);
    font-weight: bold;
    line-height: 1.3;
  }
  [dir=rtl] .gw-product-summary__price--original span:nth-child(2) {
    font-size: clamp(1.25rem, calc(1rem + 1.5vw) * 1.125, 1.5rem);
  }
  .gw-product-summary__price--discount {
    color: rgb(var(--themeAccentNeutralAlt));
    display: flex;
    justify-content: space-between;
    -webkit-margin-after: 0;
            margin-bottom: 0;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-product-summary__price--discount span:first-child {
    font-size: 1rem;
    font-weight: normal;
  }
  .gw-product-summary__price--discount span:nth-child(2) {
    font-size: 1.5rem;
    font-size: clamp(1.25rem, 1rem + 1.5vw, 1.5rem);
    font-weight: bold;
    line-height: 1.3;
  }
  [dir=rtl] .gw-product-summary__price--discount span:nth-child(2) {
    font-size: clamp(1.25rem, calc(1rem + 1.5vw) * 1.125, 1.5rem);
  }
  .gw-product-summary__price--was {
    display: flex;
    font-size: 1.5rem;
    font-size: clamp(1.25rem, 1rem + 1.5vw, 1.5rem);
    font-weight: bold;
    justify-content: flex-end;
    line-height: 1.3;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  [dir=rtl] .gw-product-summary__price--was {
    font-size: clamp(1.25rem, calc(1rem + 1.5vw) * 1.125, 1.5rem);
  }
  .gw-product-summary__price.is-selected {
    background-color: rgb(var(--themeGrey1));
  }
}
@media screen {
  .gw-product-summary__finance {
    align-items: center;
    display: flex;
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.25rem;
    justify-content: space-between;
    padding: 0.75rem;
  }
  .gw-product-summary__finance span:first-child {
    font-size: 1rem;
    font-weight: normal;
  }
  .gw-product-summary__finance span:nth-child(2) {
    font-size: 1.5rem;
    font-size: clamp(1.25rem, 1rem + 1.5vw, 1.5rem);
    font-weight: bold;
    line-height: 1.3;
  }
  [dir=rtl] .gw-product-summary__finance span:nth-child(2) {
    font-size: clamp(1.25rem, calc(1rem + 1.5vw) * 1.125, 1.5rem);
  }
  .gw-product-summary__finance.is-selected {
    background-color: rgb(var(--themeGrey1));
  }
}
@media screen {
  .gw-product-summary__smallprint .gw-table {
    font-size: 1rem;
  }
}
@media screen {
  .gw-product-summary__price--extra, .gw-product-summary__smallprint {
    font-size: 0.6875rem;
  }
}
@media screen {
  .gw-product-summary .gw-toolbar {
    margin: 0 0 1.25rem;
    -webkit-padding-before: 1.25rem;
            padding-top: 1.25rem;
  }
  .gw-product-summary .gw-toolbar.gw-toolbar--stock {
    margin: 0 0 1.25rem;
  }
  .gw-product-summary .gw-toolbar.gw-toolbar--stock .gw-button-group {
    flex-flow: row wrap;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .gw-product-summary__reserve {
    align-items: center;
    background-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorTextAlt));
    display: flex;
    flex-flow: column nowrap;
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    gap: 0.75rem;
    margin: 0;
    padding: 1.875rem 0.75rem;
    text-align: center;
  }
}
@media screen and (min-width: 64em) {
  .gw-product-summary__reserve {
    padding: 1.875rem;
  }
}
@media screen {
  .gw-product-summary__reserve p {
    font-size: 1.25rem;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .gw-product-summary__reserve .gw-button--primary {
    background-color: rgb(var(--themeColorBrandAlt));
    border-radius: 20rem;
    min-width: 15rem;
  }
}
@media screen {
  .gw-product-summary > .gw-button-group {
    align-items: flex-start;
    flex-flow: column nowrap;
    justify-content: start;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    margin-block: 1.875rem;
    -webkit-padding-after: 1.875rem;
            padding-bottom: 1.875rem;
  }
}
@media screen and (min-width: 64em) {
  .gw-product-summary > .gw-button-group {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    margin-block: 1.25rem;
    -webkit-padding-after: 1.25rem;
            padding-bottom: 1.25rem;
  }
}
@media screen {
  .gw-product-summary > .gw-button-group:last-child {
    -webkit-margin-before: 2.5rem;
            margin-top: 2.5rem;
    -webkit-padding-after: 2.5rem;
            padding-bottom: 2.5rem;
  }
}
@media screen {
  .gw-product-summary > .gw-button-group__payment-options > a {
    color: rgb(var(--themeColorBrandAlt));
  }
}
@media screen {
  .gw-product-summary > .gw-button-group > a {
    -webkit-margin-after: 0;
            margin-bottom: 0;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-product-summary > .gw-button-group > a + a {
    -webkit-margin-before: 1.25rem;
            margin-top: 1.25rem;
  }
}
@media screen {
  .gw-product-summary > .gw-button-group .gw-button--text:not(.gw-button--primary),
.gw-product-summary > .gw-button-group .gw-button--whatsapp {
    border: none;
    color: rgb(var(--themeColorBrand));
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 0.875rem;
    padding: 0;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-product-summary > .gw-button-group .gw-button--text:not(.gw-button--primary) .gw-button__inner,
.gw-product-summary > .gw-button-group .gw-button--whatsapp .gw-button__inner {
    justify-content: start;
  }
  .gw-product-summary > .gw-button-group .gw-button--text:not(.gw-button--primary) .gw-button__inner .gw-icon,
.gw-product-summary > .gw-button-group .gw-button--whatsapp .gw-button__inner .gw-icon {
    fill: rgb(var(--themeAccentNeutral));
    height: 1.6em;
    width: 1.5em;
  }
}
@media screen {
  .gw-product-summary .gw-button--primary {
    display: block;
    text-align: center;
  }
}
@media screen {
  .gw-product-summary__name span {
    color: rgb(var(--themeGrey3));
  }
  .gw-product-summary__price, .gw-product-summary__finance {
    align-items: center;
    background-color: transparent;
    border: 0.0625rem solid rgb(var(--themeGrey3));
    border-radius: 0.5rem;
    color: rgb(var(--themeColorTextSecondary));
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-product-summary__price span:nth-child(2), .gw-product-summary__finance span:nth-child(2) {
    font-size: 100%;
    font-size: clamp(1rem, 1rem + 0.2vw, 1.25rem);
    line-height: 1.6;
  }
  [dir=rtl] .gw-product-summary__price span:nth-child(2), [dir=rtl] .gw-product-summary__finance span:nth-child(2) {
    font-size: clamp(1rem, calc(1rem + 0.2vw) * 1.125, 1.25rem);
  }
  .gw-product-summary__price--discount, .gw-product-summary__finance--discount {
    align-items: center;
    color: rgb(var(--themeAccentNegative));
    font-family: var(--themeFontFamilySemiBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  .gw-product-summary__price--extra, .gw-product-summary__finance--extra {
    align-items: center;
    color: rgb(var(--themeColorTextSecondary));
  }
  .gw-product-summary__price--original, .gw-product-summary__finance--original {
    align-items: center;
  }
  .gw-product-summary__price.is-selected, .gw-product-summary__finance.is-selected {
    align-items: center;
    background-color: transparent;
    border: 0.125rem solid rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorText));
  }
  .gw-product-summary__smallprint {
    color: rgb(var(--themeColorTextSecondary));
  }
  .gw-product-summary > .gw-button-group .gw-button--whatsapp,
.gw-product-summary > .gw-button-group .gw-button--text:not(.gw-button--primary) {
    border: none;
    color: rgb(var(--themeColorTextSecondary));
    padding: 0;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-product-summary > .gw-button-group .gw-button--whatsapp .gw-button__inner,
.gw-product-summary > .gw-button-group .gw-button--text:not(.gw-button--primary) .gw-button__inner {
    justify-content: start;
  }
  .gw-product-summary > .gw-button-group .gw-button--whatsapp .gw-button__inner .gw-icon,
.gw-product-summary > .gw-button-group .gw-button--text:not(.gw-button--primary) .gw-button__inner .gw-icon {
    fill: rgb(var(--themeColorTextSecondary));
    height: 1rem;
    width: 1rem;
  }
  .gw-product-summary .gw-button-group__payment-options {
    font-family: var(--themeFontFamilySemiBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    -webkit-padding-after: 0;
            padding-bottom: 0;
  }
}
@media screen {
  [aria-labelledby=product-variants] {
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
    padding: 1.25rem;
  }
  [aria-labelledby=product-variants] .gw-product-card__media {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
}
@media screen {
  .gw-promotion-card {
    align-items: center;
    background-color: rgb(var(--themeGrey1));
    border: 0;
    padding: 0;
  }
  .gw-promotion-card [class$=__content] {
    -webkit-margin-before: 1.25rem;
            margin-top: 1.25rem;
    order: 2;
  }
  .gw-promotion-card [class$=__media] {
    order: 1;
  }
  .gw-promotion-card > .gw-button-group {
    order: 3;
  }
  .gw-promotion-card .gw-card__media {
    width: 100%;
  }
  .gw-promotion-card .gw-card__content {
    -webkit-margin-before: 0;
            margin-top: 0;
    order: 2;
    padding: 0 1.25rem;
    width: 100%;
  }
  [dir="ltr"] .gw-promotion-card .gw-toolbar{
            margin-right: -1.25rem;
  }
  [dir="rtl"] .gw-promotion-card .gw-toolbar{
            margin-left: -1.25rem;
  }
  [dir="ltr"] .gw-promotion-card .gw-toolbar{
            margin-left: -1.25rem;
  }
  [dir="rtl"] .gw-promotion-card .gw-toolbar{
            margin-right: -1.25rem;
  }
  .gw-promotion-card .gw-toolbar {
    flex-direction: row-reverse;
    justify-content: space-between;
    -webkit-margin-end: -1.25rem;
            margin-inline-end: -1.25rem;
            margin-inline-start: -1.25rem;
    -webkit-margin-start: -1.25rem;
  }
  [dir="ltr"] .gw-promotion-card .gw-toolbar__item{
            padding-left: 1.25rem;
  }
  [dir="rtl"] .gw-promotion-card .gw-toolbar__item{
            padding-right: 1.25rem;
  }
  .gw-promotion-card .gw-toolbar__item {
            padding-inline-start: 1.25rem;
    -webkit-padding-start: 1.25rem;
  }
  .gw-promotion-card .gw-share__fallback {
    right: 0;
  }
  .gw-promotion-card .gw-card__text {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-promotion-card__tags {
    color: rgb(var(--themeColorBrand));
    font-size: 0.875rem;
    text-transform: uppercase;
  }
  .gw-promotion-card .gw-card__actions {
    align-items: flex-end;
    justify-content: space-between;
    -webkit-margin-after: 0.75rem;
    -webkit-margin-before: 1.25rem;
            margin-bottom: 0.75rem;
            margin-top: 1.25rem;
    padding: 0 1.25rem;
    width: 100%;
  }
  [dir="ltr"] .gw-promotion-card .gw-card__actions .gw-button--text{
            margin-right: 0;
  }
  [dir="rtl"] .gw-promotion-card .gw-card__actions .gw-button--text{
            margin-left: 0;
  }
  .gw-promotion-card .gw-card__actions .gw-button--text {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
    text-align: center;
    width: 100%;
  }
  .gw-promotion-card .gw-card__actions .is-selected {
    background-color: rgb(var(--themeColorBrand));
    border-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-promotion-card--secondary {
    background: transparent;
    text-decoration: none;
  }
  .gw-promotion-card--secondary:hover {
    text-decoration: none;
  }
  .gw-promotion-card--secondary .gw-card__title {
    color: rgb(var(--themeColorBrand));
    margin: 1.25rem 0;
    text-align: center;
  }
  .gw-promotion-card--secondary .gw-card__media {
    padding: 1.25rem 1.25rem 0;
  }
  .gw-promotion-card-grid.gw-grid > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    .gw-promotion-card-grid.gw-grid {
      grid-template-columns: repeat(auto-fit, minmax(12.5rem, 18.75rem));
    }
  }
  .gw-promotion-card-grid--secondary.gw-grid > * {
    min-width: 12.5rem;
  }
  @supports (grid-area: auto) {
    .gw-promotion-card-grid--secondary.gw-grid {
      grid-template-columns: repeat(auto-fit, minmax(12.5rem, 12.5rem));
    }
  }
  .gw-promotion-card--block-link {
    justify-content: center;
    padding: 0 1.25rem;
  }
}
@media screen {
  .gw-promotion-card {
    background-color: rgb(var(--themeWhite));
    border: 0.0625rem solid rgb(var(--themeGrey2));
    border-radius: 0.5rem;
    box-shadow: none;
  }
  .gw-promotion-card:hover {
    border: 0.0625rem solid rgb(var(--themeGrey3));
  }
  [dir="ltr"] .gw-promotion-card .gw-card__media .gw-image{
    border-bottom-right-radius: 0;
  }
  [dir="rtl"] .gw-promotion-card .gw-card__media .gw-image{
    border-bottom-left-radius: 0;
  }
  [dir="ltr"] .gw-promotion-card .gw-card__media .gw-image{
    border-bottom-left-radius: 0;
  }
  [dir="rtl"] .gw-promotion-card .gw-card__media .gw-image{
    border-bottom-right-radius: 0;
  }
  [dir="ltr"] .gw-promotion-card .gw-card__media .gw-image{
    border-top-right-radius: 0.5rem;
  }
  [dir="rtl"] .gw-promotion-card .gw-card__media .gw-image{
    border-top-left-radius: 0.5rem;
  }
  [dir="ltr"] .gw-promotion-card .gw-card__media .gw-image{
    border-top-left-radius: 0.5rem;
  }
  [dir="rtl"] .gw-promotion-card .gw-card__media .gw-image{
    border-top-right-radius: 0.5rem;
  }
  .gw-promotion-card .gw-card__media .gw-image {
    border-end-end-radius: 0;
    border-end-start-radius: 0;
    border-start-end-radius: 0.5rem;
    border-start-start-radius: 0.5rem;
  }
  .gw-promotion-card .gw-card__title {
    color: rgb(var(--themeColorText));
    text-transform: none;
  }
  .gw-promotion-card .gw-card__text {
    color: rgb(var(--themeColorTextSecondary));
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-promotion-card .gw-card__actions {
    -webkit-padding-after: 0.75rem;
    -webkit-padding-before: 0.75rem;
            padding-bottom: 0.75rem;
            padding-top: 0.75rem;
  }
  .gw-promotion-card .gw-button-group.gw-card__actions {
    -webkit-margin-after: 0.75rem;
    -webkit-margin-before: 1.25rem;
            margin-bottom: 0.75rem;
            margin-top: 1.25rem;
  }
  .gw-promotion-card__tags {
    color: rgb(var(--themeColorTextSecondary));
    text-transform: capitalize;
  }
  .gw-promotion-card--secondary {
    background: rgb(var(--themeWhite));
  }
  .gw-promotion-card--secondary .gw-card__title {
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 200;
    letter-spacing: 0.01875rem;
    text-transform: none;
  }
  [dir="ltr"] .gw-promotion-card--secondary .gw-card__media .gw-image{
    border-top-right-radius: 0;
  }
  [dir="rtl"] .gw-promotion-card--secondary .gw-card__media .gw-image{
    border-top-left-radius: 0;
  }
  [dir="ltr"] .gw-promotion-card--secondary .gw-card__media .gw-image{
    border-top-left-radius: 0;
  }
  [dir="rtl"] .gw-promotion-card--secondary .gw-card__media .gw-image{
    border-top-right-radius: 0;
  }
  .gw-promotion-card--secondary .gw-card__media .gw-image {
    border-start-end-radius: 0;
    border-start-start-radius: 0;
  }
  .gw-promotion-card--block-link {
    background-color: rgb(var(--themeGrey1));
    color: rgb(var(--themeColorLink));
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    text-decoration: none;
  }
  .gw-promotion-card--block-link .gw-card__title {
    color: rgb(var(--themeColorLink));
    text-align: center;
  }
}
@media screen {
  [dir="ltr"] .gw-promotion-filter{
            margin-right: -1.875rem;
  }
  [dir="rtl"] .gw-promotion-filter{
            margin-left: -1.875rem;
  }
  [dir="ltr"] .gw-promotion-filter{
            margin-left: -1.875rem;
  }
  [dir="rtl"] .gw-promotion-filter{
            margin-right: -1.875rem;
  }
  .gw-promotion-filter {
    background-color: rgb(var(--themeGrey1));
    justify-content: center;
    -webkit-margin-before: 3.75rem;
            margin-top: 3.75rem;
    -webkit-margin-end: -1.875rem;
            margin-inline-end: -1.875rem;
            margin-inline-start: -1.875rem;
    -webkit-margin-start: -1.875rem;
    padding: 1.5rem;
    -webkit-padding-after: 0.75rem;
            padding-bottom: 0.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-promotion-filter{
            margin-right: -5rem;
  }
  [dir="rtl"] .gw-promotion-filter{
            margin-left: -5rem;
  }
  [dir="ltr"] .gw-promotion-filter{
            margin-left: -5rem;
  }
  [dir="rtl"] .gw-promotion-filter{
            margin-right: -5rem;
  }
  .gw-promotion-filter {
    -webkit-margin-end: -5rem;
            margin-inline-end: -5rem;
            margin-inline-start: -5rem;
    -webkit-margin-start: -5rem;
  }
}
@media screen {
  .gw-promotion-filter {
    background-color: rgb(var(--themeWhite));
  }
}
@media screen {
  .gw-quick-view-grid {
    grid-auto-flow: dense;
  }
  .gw-quick-view-grid .gw-product-card.is-selected {
    border: 0.0625rem solid rgb(var(--themeColorBrand));
  }
  .gw-quick-view-grid .gw-button--toggle .gw-icon {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-quick-view-grid .gw-button--toggle .gw-icon:first-of-type {
    display: block;
  }
  .gw-quick-view-grid .gw-button--toggle .gw-icon + .gw-icon {
    display: none;
  }
  .gw-quick-view__item {
    border: 0.0625rem solid rgb(var(--themeColorBrand));
    grid-column: 1/-1;
    -webkit-padding-before: 2.5rem;
            padding-top: 2.5rem;
    position: relative;
  }
  [data-whatintent=keyboard] .gw-quick-view__item:focus {
    box-shadow: 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }
}
@media screen and (min-width: 64em) {
  [dir="ltr"] .gw-quick-view__item{
            padding-right: 2.5rem;
  }
  [dir="rtl"] .gw-quick-view__item{
            padding-left: 2.5rem;
  }
  .gw-quick-view__item {
    -webkit-padding-before: 0;
            padding-top: 0;
    -webkit-padding-end: 2.5rem;
            padding-inline-end: 2.5rem;
  }
}
@media screen {
  [dir="ltr"] .gw-quick-view__close{
    right: 0;
  }
  [dir="rtl"] .gw-quick-view__close{
    left: 0;
  }
  .gw-quick-view__close {
    color: rgb(var(--themeColorBrand));
    font-size: 1.25rem;
    top: 0;
    inset-inline-end: 0;
    position: absolute;
  }
}
@media screen {
  .gw-quick-view__media {
    display: block;
    overflow: hidden;
    -webkit-padding-before: 66.667%;
            padding-top: 66.667%;
    position: relative;
    width: 100%;
  }
  @supports (aspect-ratio: auto) {
    .gw-quick-view__media {
      -webkit-padding-before: unset !important;
              padding-top: unset !important;
      width: unset;
    }
  }
  [dir="ltr"] .gw-quick-view__media > :first-child,[dir="ltr"] 
.gw-quick-view__media > br + *{
    left: 0;
  }
  [dir="rtl"] .gw-quick-view__media > :first-child,[dir="rtl"] 
.gw-quick-view__media > br + *{
    right: 0;
  }
  .gw-quick-view__media > :first-child,
.gw-quick-view__media > br + * {
    height: 100%;
    top: 0;
    inset-inline-start: 0;
    position: absolute;
    width: 100%;
  }
  @supports (aspect-ratio: auto) {
    .gw-quick-view__media > :first-child,
.gw-quick-view__media > br + * {
      /* stylelint-disable property-no-unknown */
      aspect-ratio: 1.5;
      /* stylelint-enable property-no-unknown */
      height: unset;
      position: unset;
    }
  }
}
@media screen {
  .gw-quick-view__content {
    padding: 0 1.25rem 1.25rem;
  }
}
@media screen and (min-width: 64em) {
  [dir="ltr"] .gw-quick-view__content{
            padding-right: 0;
  }
  [dir="rtl"] .gw-quick-view__content{
            padding-left: 0;
  }
  [dir="ltr"] .gw-quick-view__content{
            padding-left: 0;
  }
  [dir="rtl"] .gw-quick-view__content{
            padding-right: 0;
  }
  .gw-quick-view__content {
    -webkit-padding-end: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
}
@media screen {
  .gw-quick-view--finance {
    padding: 2.5rem;
  }
  .gw-quick-view--finance__description {
    -webkit-margin-after: 2.5rem;
            margin-bottom: 2.5rem;
  }
  .gw-quick-view--finance__title {
    margin: 0;
  }
  .gw-quick-view--finance__subtitle {
    color: rgb(var(--themeGrey4));
    font-size: 1.25rem;
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
    text-transform: none;
  }
  [dir="ltr"] .gw-quick-view--finance .gw-button{
            margin-right: 1.875rem;
  }
  [dir="rtl"] .gw-quick-view--finance .gw-button{
            margin-left: 1.875rem;
  }
  .gw-quick-view--finance .gw-button {
    -webkit-margin-end: 1.875rem;
            margin-inline-end: 1.875rem;
  }
  .gw-quick-view--finance .gw-product-summary__price,
.gw-quick-view--finance .gw-product-summary__finance {
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
}
@media screen and (min-width: 64em) {
  .gw-quick-view .gw-grid--asymmetric {
    display: grid;
    gap: 1.875rem;
    grid-template-areas: "col1 col2";
    grid-template-columns: calc(45% - 1.875rem / 2) calc(55% - 1.875rem / 2);
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  .gw-quick-view .gw-grid--asymmetric > :first-child {
    grid-area: col1;
  }
  .gw-quick-view .gw-grid--asymmetric > :last-child {
    grid-area: col2;
  }
}
@media screen {
  .gw-quick-view .gw-grid--asymmetric.gw-grid--asymmetric {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .gw-quick-view .gw-product-summary__name {
    color: rgb(var(--themeColorBrand));
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.25rem;
  }
}
@media screen {
  [dir="ltr"] .gw-quick-view .gw-toolbar,[dir="ltr"] 
.gw-quick-view .gw-product-specs{
            margin-right: -1.25rem;
  }
  [dir="rtl"] .gw-quick-view .gw-toolbar,[dir="rtl"] 
.gw-quick-view .gw-product-specs{
            margin-left: -1.25rem;
  }
  [dir="ltr"] .gw-quick-view .gw-toolbar,[dir="ltr"] 
.gw-quick-view .gw-product-specs{
            margin-left: -1.25rem;
  }
  [dir="rtl"] .gw-quick-view .gw-toolbar,[dir="rtl"] 
.gw-quick-view .gw-product-specs{
            margin-right: -1.25rem;
  }
  .gw-quick-view .gw-toolbar,
.gw-quick-view .gw-product-specs {
    -webkit-margin-end: -1.25rem;
            margin-inline-end: -1.25rem;
            margin-inline-start: -1.25rem;
    -webkit-margin-start: -1.25rem;
    width: 100%;
  }
}
@media screen and (min-width: 64em) {
  [dir="ltr"] .gw-quick-view .gw-toolbar,[dir="ltr"] 
.gw-quick-view .gw-product-specs{
            margin-right: 0;
  }
  [dir="rtl"] .gw-quick-view .gw-toolbar,[dir="rtl"] 
.gw-quick-view .gw-product-specs{
            margin-left: 0;
  }
  .gw-quick-view .gw-toolbar,
.gw-quick-view .gw-product-specs {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
}
@media screen {
  .gw-quick-view .gw-product-specs {
    background-color: rgb(var(--themeGrey1));
    font-size: 0.875rem;
  }
  .gw-quick-view .gw-product-specs__item {
    background: none;
    color: rgb(var(--themeColorText));
    flex-grow: 0;
    padding: 0.75rem;
  }
  .gw-quick-view .gw-product-specs__item span:first-of-type {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-quick-view .gw-product-specs__item span:first-of-type:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
}
@media screen {
  .gw-quick-view .gw-button-group {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .gw-quick-view .gw-button-group .gw-button {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .gw-quick-view {
    background-color: rgb(var(--themeWhite));
  }
  .gw-quick-view__item {
    background-color: rgb(var(--themeWhite));
    border-radius: 0.1875rem;
    box-shadow: 0 0.625rem 0.625rem rgba(var(--themeBlack), 0.1);
  }
  [dir="ltr"] .gw-quick-view .gw-product-specs{
            margin-left: -1.25rem;
  }
  [dir="rtl"] .gw-quick-view .gw-product-specs{
            margin-right: -1.25rem;
  }
  [dir="ltr"] .gw-quick-view .gw-product-specs{
            padding-right: 0.625rem;
  }
  [dir="rtl"] .gw-quick-view .gw-product-specs{
            padding-left: 0.625rem;
  }
  [dir="ltr"] .gw-quick-view .gw-product-specs{
            padding-left: 0.625rem;
  }
  [dir="rtl"] .gw-quick-view .gw-product-specs{
            padding-right: 0.625rem;
  }
  .gw-quick-view .gw-product-specs {
            margin-inline-start: -1.25rem;
    -webkit-margin-start: -1.25rem;
    -webkit-padding-end: 0.625rem;
            padding-inline-end: 0.625rem;
            padding-inline-start: 0.625rem;
    -webkit-padding-start: 0.625rem;
  }
  [dir="ltr"] .gw-quick-view__item .gw-quick-view .gw-product-specs{
            margin-right: -1.25rem;
  }
  [dir="rtl"] .gw-quick-view__item .gw-quick-view .gw-product-specs{
            margin-left: -1.25rem;
  }
  .gw-quick-view__item .gw-quick-view .gw-product-specs {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    -webkit-margin-end: -1.25rem;
            margin-inline-end: -1.25rem;
  }
}
@media screen and (min-width: 64em) {
  [dir="ltr"] .gw-quick-view__item .gw-quick-view .gw-product-specs{
            margin-right: -2.5rem;
  }
  [dir="rtl"] .gw-quick-view__item .gw-quick-view .gw-product-specs{
            margin-left: -2.5rem;
  }
  .gw-quick-view__item .gw-quick-view .gw-product-specs {
    -webkit-margin-end: -2.5rem;
            margin-inline-end: -2.5rem;
  }
}
@media screen and (min-width: 64em) {
  [dir="ltr"] .gw-quick-view .gw-product-specs{
            margin-left: -1.875rem;
  }
  [dir="rtl"] .gw-quick-view .gw-product-specs{
            margin-right: -1.875rem;
  }
  [dir="ltr"] .gw-quick-view .gw-product-specs{
            padding-left: 1.25rem;
  }
  [dir="rtl"] .gw-quick-view .gw-product-specs{
            padding-right: 1.25rem;
  }
  .gw-quick-view .gw-product-specs {
            margin-inline-start: -1.875rem;
    -webkit-margin-start: -1.875rem;
            padding-inline-start: 1.25rem;
    -webkit-padding-start: 1.25rem;
  }
}
@media screen {
  .gw-quick-view .gw-product-summary__name span {
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1rem;
    text-transform: none;
  }
}
@media screen {
  .gw-quick-view .gw-product-summary__price {
    font-size: 1.25rem;
    font-weight: 700;
  }
}
@media screen {
  .gw-quick-view .gw-product-summary__finance {
    font-size: 1rem;
  }
}
@media screen {
  .gw-quick-view .gw-product-summary__price--extra,
.gw-quick-view .gw-product-summary__smallprint {
    font-size: 0.6875rem;
  }
}
@media screen {
  .gw-quick-view .gw-button-group {
    justify-content: flex-end;
  }
}
@media screen {
  .gw-quick-view-grid .gw-button.gw-button--toggle.is-selected {
    box-shadow: none;
  }
  .gw-quick-view-grid .gw-product-card.is-selected {
    position: relative;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-quick-view-grid .gw-product-card.is-selected:before, .gw-quick-view-grid .gw-product-card.is-selected:after {
    border: solid transparent;
    border-top-color: rgb(var(--themeColorBrand));
    border-width: 1rem 1rem 0 1rem;
    content: "";
    height: 0;
    bottom: -1rem;
    position: absolute;
    transform: translateX(calc(-50% + 0.0625rem));
    width: 0;
  }
  .gw-quick-view-grid .gw-product-card.is-selected:after {
    border-top-color: rgb(var(--themeGrey1));
    border-width: 0.9375rem 0.9375rem 0 0.9375rem;
    bottom: -0.9375rem;
    transform: translateX(-50%);
  }
}
@media screen {
  .gw-ratings {
    background-color: rgb(var(--themeGrey1));
    padding: 0.75rem;
  }
  .gw-ratings__text {
    font-size: 1rem;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  @supports ((-webkit-background-clip: text) or (background-clip: text)) {
    .gw-ratings__text span {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    .gw-ratings__text span:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      width: auto;
    }
  }
  .gw-ratings__stars {
    display: none;
    /* stylelint-disable order/order */
    /* stylelint-enable order/order */
  }
  @supports ((-webkit-background-clip: text) or (background-clip: text)) {
    .gw-ratings__stars {
      display: inline-block;
      font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      font-size: 1.5rem;
      line-height: 1;
    }
    .gw-ratings__stars:before {
      background: linear-gradient(to right, rgb(var(--themeAccentNeutral)) var(--rating), rgb(var(--themeColorText)) var(--rating));
      -webkit-background-clip: text;
              background-clip: text;
      content: "★★★★★";
      letter-spacing: -3px;
      -webkit-text-fill-color: transparent;
    }
  }
  .gw-ratings-banner {
    align-items: center;
    border: 0.0625rem solid rgb(var(--themeGrey2));
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
    padding: 1.25rem;
  }
  .gw-ratings-banner__branch {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  [dir="ltr"] .gw-ratings-banner .gw-ratings{
            margin-right: auto;
  }
  [dir="rtl"] .gw-ratings-banner .gw-ratings{
            margin-left: auto;
  }
  .gw-ratings-banner .gw-ratings {
    align-items: center;
    background: none;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    -webkit-margin-end: auto;
            margin-inline-end: auto;
    padding: 0;
  }
  .gw-ratings-banner__inner {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-ratings-signpost {
    align-items: center;
    background-color: rgba(var(--themeColorBrandAlt), 0.9);
    color: rgb(var(--themeColorTextAlt));
    display: inline-flex;
    font-size: 1rem;
    padding: 0.75rem;
    text-decoration: none;
  }
  .gw-ratings-signpost:hover {
    text-decoration: underline;
  }
  [dir="ltr"] .gw-ratings-signpost .gw-icon{
            margin-left: 0.375rem;
  }
  [dir="rtl"] .gw-ratings-signpost .gw-icon{
            margin-right: 0.375rem;
  }
  .gw-ratings-signpost .gw-icon {
    fill: rgb(var(--themeAccentNeutral));
    height: 1rem;
            margin-inline-start: 0.375rem;
    -webkit-margin-start: 0.375rem;
    width: 1rem;
  }
}
@media screen {
  .gw-ratings-signpost__wrap {
    display: none;
  }
  /* stylelint-disable max-nesting-depth */
  .has-open-modal .gw-ratings-signpost__wrap {
    display: none;
  }

  /* stylelint-enable max-nesting-depth */
}
@media screen and (min-width: 48.0625em) {
  .gw-ratings-signpost__wrap {
    display: block;
    top: 50%;
    position: fixed;
    transform: rotate(-90deg);
    transform-origin: 0 0;
    z-index: 999;
  }
}
@media screen {
  .gw-responsive-media {
    display: block;
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
    overflow: hidden;
    -webkit-padding-before: 56.25%;
            padding-top: 56.25%;
    position: relative;
    width: 100%;
  }
  .gw-banner .gw-responsive-media, .gw-card .gw-responsive-media, [class*=gw-grid] .gw-responsive-media {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }

  @supports (aspect-ratio: auto) {
    .gw-responsive-media {
      -webkit-padding-before: unset !important;
              padding-top: unset !important;
      width: unset;
    }
  }
  [dir="ltr"] .gw-responsive-media > :first-child,[dir="ltr"] 
.gw-responsive-media > br + *{
    left: 0;
  }
  [dir="rtl"] .gw-responsive-media > :first-child,[dir="rtl"] 
.gw-responsive-media > br + *{
    right: 0;
  }
  .gw-responsive-media > :first-child,
.gw-responsive-media > br + * {
    height: 100%;
    top: 0;
    inset-inline-start: 0;
    position: absolute;
    width: 100%;
  }
  @supports (aspect-ratio: auto) {
    .gw-responsive-media > :first-child,
.gw-responsive-media > br + * {
      /* stylelint-disable property-no-unknown */
      aspect-ratio: var(--themeMediaAspectRatio);
      /* stylelint-enable property-no-unknown */
      height: unset;
      position: unset;
    }
  }
  .gw-responsive-media--productvideo {
    padding: 3.75rem;
    /* TODO: Remove this once we find a fix for padding-top: unset */
    /* stylelint-disable-next-line declaration-no-important */
    -webkit-padding-before: 3.75rem !important;
            padding-top: 3.75rem !important;
    text-align: center;
  }
}
@media screen and (max-width: 48em) {
  [dir="ltr"] .gw-responsive-media--productvideo{
            margin-right: -5rem;
  }
  [dir="rtl"] .gw-responsive-media--productvideo{
            margin-left: -5rem;
  }
  [dir="ltr"] .gw-responsive-media--productvideo{
            margin-left: -5rem;
  }
  [dir="rtl"] .gw-responsive-media--productvideo{
            margin-right: -5rem;
  }
  .gw-responsive-media--productvideo {
    background-color: rgb(var(--themeWhite));
    -webkit-margin-end: -5rem;
            margin-inline-end: -5rem;
            margin-inline-start: -5rem;
    -webkit-margin-start: -5rem;
    padding: 3.75rem;
  }
}
@media screen {
  .gw-responsive-media--productvideo iframe {
    max-width: var(--themeMaxContentWidth);
  }
}
@media screen {
  .gw-share {
    display: none;
    position: relative;
  }
  .gw-share.is-js-enabled {
    display: block;
  }
  .gw-share__fallback {
    background-color: rgb(var(--themeWhite));
    border: 0.0625rem solid rgb(var(--themeGrey2));
    margin: 0 0.75rem;
    padding: 0.75rem;
    position: absolute;
    z-index: 1;
  }
  .gw-share__fallback .gw-form__label,
.gw-share__fallback .gw-form__input,
.gw-share__fallback .gw-button {
    font-size: 0.875rem;
  }
  .gw-share__fallback .gw-form__input {
    height: auto;
    padding: 0.75rem 1.25rem;
  }
  .gw-share__fallback .gw-button {
    padding: 0.75rem;
  }
}
@media screen {
  .gw-show-hide__button {
    font-size: 0.6875rem;
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
  .gw-show-hide.is-js-enabled .gw-show-hide__content {
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
  }
  [data-whatintent=keyboard] .gw-show-hide.is-js-enabled .gw-show-hide__content:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] .gw-show-hide.is-js-enabled .gw-show-hide__content:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] .gw-show-hide.is-js-enabled .gw-show-hide__content:focus {
    outline: 0;
  }

  .gw-show-hide.is-js-enabled .gw-show-hide__content:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
}
@media screen {
  .gw-show-hide .gw-button {
    background-color: transparent;
    border: 0.0625rem solid;
    border-color: rgb(var(--themeColorBrandAlt));
    border-radius: 2rem;
    color: rgb(var(--themeColorBrandAlt));
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    line-height: 1;
    padding: 0.75rem 1.875rem;
  }
  .gw-show-hide .gw-button:hover:not(:disabled) {
    border-color: rgb(var(--themeColorLinkHover));
    color: rgb(var(--themeColorLinkHover));
    text-decoration: none;
    transform: scale(1);
  }
}
@media screen {
  .gw-skiplinks {
    position: absolute;
  }
  .gw-skiplinks__link {
    background-color: rgb(var(--themeColorBrand));
    border: 0;
    clip: rect(0 0 0 0);
    color: #fff;
    display: inline-block;
    height: 1px;
    left: 1rem;
    margin: -1px;
    min-width: 10vw;
    overflow: hidden;
    padding: 0;
    padding: 1rem;
    position: absolute;
    text-align: center;
    width: 1px;
    z-index: 2;
  }
  .gw-skiplinks__link:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
}
@media screen {
  .gw-slider {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
    overflow-x: auto;
    position: relative;
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
  }
  [data-whatintent=keyboard] .gw-slider:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] .gw-slider:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] .gw-slider:focus {
    outline: 0;
  }

  .gw-slider:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
  [data-whatintent=keyboard] .gw-slider:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }

  [data-whatintent=touch] .gw-slider:focus {
    background-color: transparent;
  }

  [dir="ltr"] .gw-slider__slides{
    right: 0;
  }

  [dir="rtl"] .gw-slider__slides{
    left: 0;
  }

  [dir="ltr"] .gw-slider__slides{
    left: 0;
  }

  [dir="rtl"] .gw-slider__slides{
    right: 0;
  }

  .gw-slider__slides {
    display: flex;
    inset-inline-end: 0;
    inset-inline-start: 0;
    min-height: 3.5rem;
    position: relative;
    transition: left 0.5s ease-in-out, right 0.5s ease-in-out;
  }
  .gw-slider[data-slide-no-animations=true] .gw-slider__slides {
    transition: none;
  }
  .gw-slider__slide {
    flex-basis: 100%;
    flex-shrink: 0;
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
  }
  [data-whatintent=keyboard] .gw-slider__slide:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] .gw-slider__slide:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] .gw-slider__slide:focus {
    outline: 0;
  }

  .gw-slider__slide:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
  [data-whatintent=keyboard] .gw-slider__slide:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
    outline-offset: -0.25rem;
  }

  [data-whatintent=touch] .gw-slider__slide:focus {
    background-color: transparent;
  }

  .gw-slider .gw-button-group {
    margin: 0;
  }
  [dir="ltr"] .gw-slider [data-button-type]{
    right: 0;
  }
  [dir="rtl"] .gw-slider [data-button-type]{
    left: 0;
  }
  [dir="ltr"] .gw-slider [data-button-type]{
    left: auto;
  }
  [dir="rtl"] .gw-slider [data-button-type]{
    right: auto;
  }
  .gw-slider [data-button-type] {
    background-color: rgba(var(--themeColorBrand), 0.6);
    color: rgb(var(--themeColorTextAlt));
    height: calc(1em + (0.75rem * 2));
    inset-inline-end: 0;
    inset-inline-start: auto;
    margin: 0;
    position: absolute;
    z-index: 1;
  }
  [dir="ltr"] .gw-slider [data-button-type=next]{
    right: 49.5%;
  }
  [dir="rtl"] .gw-slider [data-button-type=next]{
    left: 49.5%;
  }
  .gw-slider [data-button-type=next] {
    top: auto;
    bottom: 0.75rem;
    inset-block: auto 0.75rem;
    inset-inline-end: 49.5%;
    transform: translateX(100%);
  }
  /* stylelint-disable max-nesting-depth */
  [dir=rtl] .gw-slider [data-button-type=next] {
    transform: translateX(-100%);
  }

  /* stylelint-enable max-nesting-depth */
  [dir="ltr"] .gw-slider [data-button-type=previous]{
    right: auto;
  }
  [dir="rtl"] .gw-slider [data-button-type=previous]{
    left: auto;
  }
  [dir="ltr"] .gw-slider [data-button-type=previous]{
    left: 49.5%;
  }
  [dir="rtl"] .gw-slider [data-button-type=previous]{
    right: 49.5%;
  }
  .gw-slider [data-button-type=previous] {
    background-color: rgba(var(--themeColorBackgroundAlt), 0.6);
    top: auto;
    bottom: 0.75rem;
    inset-block: auto 0.75rem;
    inset-inline-end: auto;
    inset-inline-start: 49.5%;
    transform: translateX(-100%);
  }
  .gw-slider [role=tablist] {
    display: none;
  }
  .gw-slider [role=tab] {
    background-color: rgb(var(--themeColorBackground));
    border: 0.125rem solid rgb(var(--themeColorBrand));
    border-radius: 50%;
    height: 1em;
    margin: 0.75rem 0.375rem;
    padding: 0.75rem;
    pointer-events: all;
  }
  .gw-slider [role=tab][aria-selected=true] {
    background-color: rgb(var(--themeColorBrand));
  }
  .gw-slider[data-slide-custom-tabs=true] [role=tablist] {
    display: flex;
  }
  .gw-slider--banner {
    /* stylelint-disable-next-line no-descending-specificity */
  }
}
@media screen and (max-width: 48em) {
  .gw-slider--banner [role=tablist] {
    display: none;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-slider--banner .gw-slider__slides {
    max-height: calc(100vw / var(--themeMediaAspectRatio));
  }
}
@media screen {
  .gw-slider--banner .gw-slider__slides {
    -webkit-margin-after: -1.875rem;
            margin-bottom: -1.875rem;
  }
}
@media screen {
  .gw-slider--banner .gw-slider__slide {
    background-color: rgba(var(--themeColorBrand), 0.6);
  }
}
@media screen {
  .gw-slider--banner .gw-banner__inner + .gw-banner__overlay {
    background-color: transparent;
  }
}
@media screen {
  .gw-slider--card .gw-slider__slide {
    flex-basis: 18.5rem;
    padding: 0.9375rem;
  }
}
@media screen {
  .gw-slider.is-js-enabled {
    overflow: hidden;
  }
}
@media screen {
  .gw-brand-list--slider .gw-button[data-button-type=previous]:active {
    transform: translateX(-100%);
  }
  .gw-brand-list--slider .gw-button-group {
    display: none;
  }
  .gw-brand-list--slider .gw-slider__slide {
    display: flex;
    justify-content: center;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-brand-list--slider .gw-slider__slide .gw-slider__slide--brand {
    filter: grayscale(1);
    padding: 0.5rem;
    transition: filter 0.3s ease;
  }
  [dir="ltr"] .gw-brand-list--slider .gw-slider__slide:first-child{
            padding-left: 0;
  }
  [dir="rtl"] .gw-brand-list--slider .gw-slider__slide:first-child{
            padding-right: 0;
  }
  .gw-brand-list--slider .gw-slider__slide:first-child {
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
  [dir="ltr"] .gw-brand-list--slider .gw-slider__slide:first-child .gw-slider__slide--brand{
            padding-left: 0;
  }
  [dir="rtl"] .gw-brand-list--slider .gw-slider__slide:first-child .gw-slider__slide--brand{
            padding-right: 0;
  }
  .gw-brand-list--slider .gw-slider__slide:first-child .gw-slider__slide--brand {
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
  .gw-brand-list--slider .gw-slider__slide:hover .gw-slider__slide--brand {
    filter: grayscale(0);
    transition: filter 0.3s ease;
  }
}
@media screen {
  .gw-slider {
    -webkit-padding-after: 3.25rem;
            padding-bottom: 3.25rem;
  }
  .gw-slider [data-button-type] {
    background-color: rgb(var(--themeBlack));
    border-radius: 20rem;
    bottom: 0;
    top: auto;
  }
  .gw-slider [data-button-type]:hover {
    background-color: rgba(var(--themeBlack), 0.7);
  }
  [dir="ltr"] .gw-slider [data-button-type=next]{
    right: 49.5%;
  }
  [dir="rtl"] .gw-slider [data-button-type=next]{
    left: 49.5%;
  }
  .gw-slider [data-button-type=next] {
    inset-inline-end: 49.5%;
    transform: translateX(100%);
  }
  /* stylelint-disable max-nesting-depth */
  [dir=rtl] .gw-slider [data-button-type=next] {
    transform: translateX(-100%);
  }

  /* stylelint-enable max-nesting-depth */
  [dir="ltr"] .gw-slider [data-button-type=previous]{
    right: auto;
  }
  [dir="rtl"] .gw-slider [data-button-type=previous]{
    left: auto;
  }
  [dir="ltr"] .gw-slider [data-button-type=previous]{
    left: 49.5%;
  }
  [dir="rtl"] .gw-slider [data-button-type=previous]{
    right: 49.5%;
  }
  .gw-slider [data-button-type=previous] {
    inset-inline-end: auto;
    inset-inline-start: 49.5%;
    transform: translateX(-100%);
  }
  /* stylelint-disable max-nesting-depth */
  [dir=rtl] .gw-slider [data-button-type=previous] {
    transform: translateX(100%);
  }

  /* stylelint-enable max-nesting-depth */
  .gw-slider [role=tab] {
    background-color: rgb(var(--themeGrey1));
  }
  .gw-slider [role=tab][aria-selected=true] {
    border-color: rgb(var(--themeWhite));
  }
  .gw-slider--no-buttons {
    -webkit-margin-after: 2.5rem;
            margin-bottom: 2.5rem;
    -webkit-padding-after: 0;
            padding-bottom: 0;
  }
  .gw-slider [role=tablist] {
    display: none;
  }
  .gw-slider--banner {
    -webkit-padding-after: 0;
            padding-bottom: 0;
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-slider--banner .gw-slider__slides {
    z-index: 0;
  }
  [dir="ltr"] .gw-slider--banner .gw-banner--hero:before{
    left: 0;
  }
  [dir="rtl"] .gw-slider--banner .gw-banner--hero:before{
    right: 0;
  }
  .gw-slider--banner .gw-banner--hero:before {
    content: "";
    height: 100%;
    top: 0;
    inset-inline-start: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .gw-slider--banner .gw-banner__overlay {
    z-index: 1;
    /* stylelint-disable-next-line max-nesting-depth */
  }
  .gw-slider--banner .gw-banner__overlay:not([class*="--skin"]) {
    background-color: transparent;
  }
}
@media screen {
  .gw-slider--banner .gw-slider__slide {
    background-color: rgba(var(--themeGrey4), 0.8);
    -webkit-padding-after: 2.5rem;
            padding-bottom: 2.5rem;
  }
  /* stylelint-disable max-nesting-depth */
  [data-whatintent=touch] .gw-slider--banner .gw-slider__slide:focus {
    background-color: rgba(var(--themeGrey4), 0.8);
  }

  /* stylelint-enable max-nesting-depth */
}
@media screen and (min-width: 48.0625em) {
  .gw-slider--banner .gw-slider__slide {
    background-color: transparent;
    -webkit-padding-after: 0;
            padding-bottom: 0;
  }
}
@media screen {
  .gw-slider--banner [data-button-type] {
    bottom: 1.25rem;
    bottom: 0.75rem;
    top: auto;
  }
}
@media screen {
  .gw-slider--banner [data-button-type=next] {
    transform: translateX(100%);
  }
  /* stylelint-disable max-nesting-depth */
  [dir=rtl] .gw-slider--banner [data-button-type=next] {
    transform: translateX(-100%);
  }

  /* stylelint-enable max-nesting-depth */
}
@media screen {
  [dir="ltr"] .gw-slider--banner [data-button-type=previous]{
    right: auto;
  }
  [dir="rtl"] .gw-slider--banner [data-button-type=previous]{
    left: auto;
  }
  .gw-slider--banner [data-button-type=previous] {
    inset-inline-end: auto;
    transform: translateX(-100%);
  }
  /* stylelint-disable max-nesting-depth */
  [dir=rtl] .gw-slider--banner [data-button-type=previous] {
    transform: translateX(100%);
  }

  /* stylelint-enable max-nesting-depth */
}
@media screen {
  .gw-slider--banner-thumbs {
    -webkit-padding-after: 3.25rem;
            padding-bottom: 3.25rem;
  }
  .gw-slider--banner-thumbs [data-button-type] {
    bottom: 0;
  }
  [dir="ltr"] .gw-slider--banner-thumbs [data-button-type=next]{
    right: calc(50% - 1.25rem);
  }
  [dir="rtl"] .gw-slider--banner-thumbs [data-button-type=next]{
    left: calc(50% - 1.25rem);
  }
  .gw-slider--banner-thumbs [data-button-type=next] {
    inset-inline-end: calc(50% - 1.25rem);
  }
  [dir="ltr"] .gw-slider--banner-thumbs [data-button-type=previous]{
    right: auto;
  }
  [dir="rtl"] .gw-slider--banner-thumbs [data-button-type=previous]{
    left: auto;
  }
  [dir="ltr"] .gw-slider--banner-thumbs [data-button-type=previous]{
    left: calc(50% - 1.25rem);
  }
  [dir="rtl"] .gw-slider--banner-thumbs [data-button-type=previous]{
    right: calc(50% - 1.25rem);
  }
  .gw-slider--banner-thumbs [data-button-type=previous] {
    inset-inline-end: auto;
    inset-inline-start: calc(50% - 1.25rem);
  }
}
@media screen {
  .gw-slider.gw-gallery--gallery2 {
    -webkit-padding-after: 0;
            padding-bottom: 0;
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-slider.gw-gallery--gallery2 [data-button-type] {
    bottom: 1.25rem;
    bottom: 0.75rem;
    top: auto;
  }
  [dir="ltr"] .gw-slider.gw-gallery--gallery2 [data-button-type=next]{
    right: 50%;
  }
  [dir="rtl"] .gw-slider.gw-gallery--gallery2 [data-button-type=next]{
    left: 50%;
  }
  .gw-slider.gw-gallery--gallery2 [data-button-type=next] {
    border-radius: 0 2rem 2rem 0;
    inset-inline-end: 50%;
    transform: translateX(100%);
  }
  /* stylelint-disable max-nesting-depth */
  [dir=rtl] .gw-slider.gw-gallery--gallery2 [data-button-type=next] {
    border-radius: 2rem 0 0 2rem;
    transform: translateX(-100%);
  }

  /* stylelint-enable max-nesting-depth */
  [dir="ltr"] .gw-slider.gw-gallery--gallery2 [data-button-type=previous]{
    right: auto;
  }
  [dir="rtl"] .gw-slider.gw-gallery--gallery2 [data-button-type=previous]{
    left: auto;
  }
  [dir="ltr"] .gw-slider.gw-gallery--gallery2 [data-button-type=previous]{
    left: 50%;
  }
  [dir="rtl"] .gw-slider.gw-gallery--gallery2 [data-button-type=previous]{
    right: 50%;
  }
  .gw-slider.gw-gallery--gallery2 [data-button-type=previous] {
    border-radius: 2rem 0 0 2rem;
    inset-inline-end: auto;
    inset-inline-start: 50%;
    transform: translateX(-100%);
  }
  /* stylelint-disable max-nesting-depth */
  [dir=rtl] .gw-slider.gw-gallery--gallery2 [data-button-type=previous] {
    border-radius: 0 2rem 2rem 0;
    transform: translateX(100%);
  }

  /* stylelint-enable max-nesting-depth */
  .gw-slider.gw-gallery--gallery2-thumbs {
    -webkit-padding-after: 3.25rem;
            padding-bottom: 3.25rem;
  }
  .gw-slider.gw-gallery--gallery2-thumbs [data-button-type] {
    bottom: 0;
  }
  [dir="ltr"] .gw-slider.gw-gallery--gallery2-thumbs [data-button-type=next]{
    right: calc(50% - 1.25rem);
  }
  [dir="rtl"] .gw-slider.gw-gallery--gallery2-thumbs [data-button-type=next]{
    left: calc(50% - 1.25rem);
  }
  .gw-slider.gw-gallery--gallery2-thumbs [data-button-type=next] {
    inset-inline-end: calc(50% - 1.25rem);
  }
  [dir="ltr"] .gw-slider.gw-gallery--gallery2-thumbs [data-button-type=previous]{
    right: auto;
  }
  [dir="rtl"] .gw-slider.gw-gallery--gallery2-thumbs [data-button-type=previous]{
    left: auto;
  }
  [dir="ltr"] .gw-slider.gw-gallery--gallery2-thumbs [data-button-type=previous]{
    left: calc(50% - 1.25rem);
  }
  [dir="rtl"] .gw-slider.gw-gallery--gallery2-thumbs [data-button-type=previous]{
    right: calc(50% - 1.25rem);
  }
  .gw-slider.gw-gallery--gallery2-thumbs [data-button-type=previous] {
    inset-inline-end: auto;
    inset-inline-start: calc(50% - 1.25rem);
  }
}
@media screen {
  .gw-social {
    padding: 1.25rem;
  }
  .gw-social__title {
    font-size: 2rem;
    font-size: clamp(1.5rem, 1rem + 2vw, 2rem);
    line-height: 1.2;
  }
  [dir=rtl] .gw-social__title {
    font-size: clamp(1.5rem, calc(1rem + 2vw) * 1.125, 2rem);
  }
  .gw-social__icons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
  .gw-social__icon {
    margin: 0.75rem;
    padding: 0;
  }
}
@media screen {
  .gw-social {
    background-color: rgb(var(--themeWhite));
    text-align: center;
  }
  .gw-social__icons {
    justify-content: center;
  }
}
@media screen {
  .gw-sticky-footer {
    background-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorTextAlt));
    min-height: 2.5rem;
    transition: min-height 0.5s ease-in-out;
  }
  .gw-sticky-footer__list {
    display: flex;
    position: relative;
  }
  .gw-sticky-footer__item {
    flex-grow: 1;
    min-height: 4rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-sticky-footer__item {
    min-height: 2.5rem;
  }
}
@media screen {
  .gw-sticky-footer__item > * {
    width: 100%;
  }
}
@media screen {
  .gw-sticky-footer__button {
    background-color: rgb(var(--themeColorBackground));
    color: rgb(var(--themeColorBrand));
    height: 100%;
    text-transform: unset;
  }
  [data-whatintent=keyboard] .gw-sticky-footer__button:focus, [data-whatintent=touch] .gw-sticky-footer__button:focus {
    position: relative;
  }
}
@media screen and (max-width: 48em) {
  .gw-sticky-footer__button {
    padding: 0.5rem;
  }
}
@media screen and (max-width: 48em) {
  .gw-sticky-footer__button .gw-button__inner {
    height: 100%;
    justify-content: center;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-sticky-footer__button .gw-button__inner span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-sticky-footer__button .gw-button__inner span:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
  .gw-sticky-footer__button .gw-button__inner .gw-icon {
    margin: 0;
  }
}
@media screen {
  .gw-sticky-footer {
    background-color: rgb(var(--themeGrey5));
  }
  [dir="ltr"] .gw-sticky-footer__button{
            border-right: 0.0625rem solid rgb(var(--themeGrey4));
  }
  [dir="rtl"] .gw-sticky-footer__button{
            border-left: 0.0625rem solid rgb(var(--themeGrey4));
  }
  .gw-sticky-footer__button {
    background-color: rgb(var(--themeGrey5));
    border: 0;
    -webkit-border-end: 0.0625rem solid rgb(var(--themeGrey4));
            border-inline-end: 0.0625rem solid rgb(var(--themeGrey4));
    border-radius: 0;
    color: rgb(var(--themeWhite));
    font-family: var(--themeFontFamilySemiBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    padding: 1.25rem;
  }
  [dir="ltr"] .gw-sticky-footer__button[class*="--text"]{
            padding-right: 0;
  }
  [dir="rtl"] .gw-sticky-footer__button[class*="--text"]{
            padding-left: 0;
  }
  [dir="ltr"] .gw-sticky-footer__button[class*="--text"]{
            padding-left: 0;
  }
  [dir="rtl"] .gw-sticky-footer__button[class*="--text"]{
            padding-right: 0;
  }
  .gw-sticky-footer__button[class*="--text"] {
    color: rgb(var(--themeWhite));
    font-family: var(--themeFontFamilySemiBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    -webkit-padding-end: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
  .gw-sticky-footer__button .gw-button__inner {
    justify-content: center;
  }
  .gw-sticky-footer__button:hover {
    background-color: rgb(var(--themeColorLinkHover));
  }
  .gw-sticky-footer__button:hover[class*="--text"]:not(:disabled) {
    color: rgb(var(--themeWhite));
    text-decoration: none;
  }
  [data-whatintent=mouse] .gw-sticky-footer__button:hover {
    opacity: 1;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-sticky-footer__button .gw-icon {
    height: 1rem;
    width: 1rem;
  }
}
@media screen {
  [dir="ltr"] .gw-sticky-footer__item:last-child .gw-sticky-footer__button{
            border-right: 0;
  }
  [dir="rtl"] .gw-sticky-footer__item:last-child .gw-sticky-footer__button{
            border-left: 0;
  }
  .gw-sticky-footer__item:last-child .gw-sticky-footer__button {
    -webkit-border-end: 0;
            border-inline-end: 0;
  }
}
@media screen {
  .gw-filter-sidebar__accordion-content .gw-stock-filter-ajax > .gw-form__label {
    -webkit-padding-before: 1.875rem;
            padding-top: 1.875rem;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-filter-sidebar__accordion-content .gw-stock-filter-ajax > .gw-form__label > h5 {
    font-size: 1rem;
  }
}
@media screen {
  .gw-stock-label {
    font-size: 0.875rem;
    bottom: 0;
    padding: 0.375rem 0.75rem;
    position: absolute;
    width: 100%;
  }
  .gw-stock-label > * {
    margin-bottom: 0;
  }
  .gw-stock-label--primary {
    background-color: rgba(var(--themeColorBrand), 0.6);
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-stock-label--secondary {
    background-color: rgba(var(--themeColorBrandAlt), 0.6);
    color: rgb(var(--themeBlack));
  }
  .gw-stock-label--positive {
    background-color: rgba(var(--themeAccentPositive), 0.6);
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-stock-label--negative {
    background-color: rgba(var(--themeAccentNegative), 0.6);
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-stock-label--neutral {
    background-color: rgba(var(--themeAccentNeutral), 0.6);
    color: rgb(var(--themeBlack));
  }
}
@media screen {
  .gw-stock-label--secondary {
    color: rgb(var(--themeColorTextAlt));
  }
  .gw-stock-label--neutral {
    color: rgb(var(--themeColorTextAlt));
  }
}
@media screen {
  .gw-swatch {
    background-color: rgb(var(--themeWhite));
    overflow-y: hidden;
  }
  .gw-swatch__title {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-swatch__title:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
  .gw-swatch .gw-button-group:not([role=tablist]) {
    display: none;
  }
  .gw-swatch.gw-slider [role=tab] {
    border-color: transparent;
    border-radius: 0;
    border-width: 0.25rem;
    height: 2.5rem;
    margin: 0;
    padding: 0;
    width: 2.5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-swatch.gw-slider [role=tab] {
    height: auto;
    width: auto;
  }
}
@media screen {
  .gw-swatch.gw-slider [role=tab][aria-selected=true] {
    background-color: transparent;
    border-color: rgb(var(--themeColorBrand));
  }
}
@media screen {
  .gw-swatch .gw-card {
    grid-template-columns: 2fr 1fr;
    -webkit-margin-before: 3.75rem;
            margin-top: 3.75rem;
  }
  [data-whatintent=mouse] .gw-swatch .gw-card:hover {
    box-shadow: none;
  }
}
@media screen {
  .gw-swatch [data-slide-tab] {
    display: block;
    line-height: 0;
  }
}
@media screen {
  .gw-swatch .gw-card__content {
    order: 2;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-swatch .gw-card__content {
    justify-content: center;
  }
}
@media screen {
  .gw-swatch .gw-card__media {
    order: 1;
  }
}
@media screen {
  .gw-swatch [role=tablist] {
    bottom: 0;
    justify-content: center;
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}
@media screen {
  [data-whatintent=touch] .gw-swatch:focus {
    background-color: rgb(var(--themeWhite));
  }

  .gw-swatch__title {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .gw-swatch__title:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
}
@media screen {
  .gw-tabs {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  .gw-tabs__button {
    background-color: rgba(var(--themeGrey2), 0.3);
    color: rgb(var(--themeColorText));
    bottom: -0.0625rem;
    padding: 0.75rem;
    position: relative;
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
    z-index: 1;
  }
  [data-whatintent=keyboard] .gw-tabs__button:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] .gw-tabs__button:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] .gw-tabs__button:focus {
    outline: 0;
  }

  .gw-tabs__button:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
  .gw-tabs__button[aria-selected=true] {
    background-color: rgb(var(--themeWhite));
  }
  .gw-tabs__tablist {
    display: flex;
  }
  [dir="ltr"] .gw-tabs__tablist > *{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-tabs__tablist > *{
            margin-left: 0.75rem;
  }
  .gw-tabs__tablist > * {
    -webkit-margin-after: 0;
            margin-bottom: 0;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
  .gw-tabs.is-js-enabled .gw-tabs__content {
    background-color: rgb(var(--themeWhite));
    padding: 1.875rem;
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
    word-break: break-word;
  }
  [data-whatintent=keyboard] .gw-tabs.is-js-enabled .gw-tabs__content:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] .gw-tabs.is-js-enabled .gw-tabs__content:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] .gw-tabs.is-js-enabled .gw-tabs__content:focus {
    outline: 0;
  }

  .gw-tabs.is-js-enabled .gw-tabs__content:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-tabs--vertical.is-js-enabled {
    display: grid;
    gap: 0;
    grid-template-areas: "tabs content";
    grid-template-columns: auto 1fr;
  }
}
@media screen {
  .gw-tabs--vertical.is-js-enabled .gw-tabs__content {
    grid-area: content;
  }
}
@media screen {
  .gw-tabs--vertical .gw-tabs__tablist {
    flex-direction: column;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-tabs--vertical .gw-tabs__tablist {
    grid-area: tabs;
  }
}
@media screen {
  [dir="ltr"] .gw-tabs--vertical .gw-tabs__tablist > *{
            margin-right: 0;
  }
  [dir="rtl"] .gw-tabs--vertical .gw-tabs__tablist > *{
            margin-left: 0;
  }
  .gw-tabs--vertical .gw-tabs__tablist > * {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
}
@media screen {
  .gw-tabs--vertical .gw-tabs__button {
    border-color: transparent;
    bottom: 0;
  }
}
@media screen {
  [dir="ltr"] .gw-tabs--vertical [aria-selected=true]{
    border-right-color: rgb(var(--themeColorBackground));
  }
  [dir="rtl"] .gw-tabs--vertical [aria-selected=true]{
    border-left-color: rgb(var(--themeColorBackground));
  }
  [dir="ltr"] .gw-tabs--vertical [aria-selected=true]{
    right: -0.0625rem;
  }
  [dir="rtl"] .gw-tabs--vertical [aria-selected=true]{
    left: -0.0625rem;
  }
  .gw-tabs--vertical [aria-selected=true] {
    border-color: rgb(var(--themeColorBrandAlt));
    border-inline-end-color: rgb(var(--themeColorBackground));
    inset-inline-end: -0.0625rem;
  }
}
@media screen {
  .gw-tabs--contact .gw-contact-card {
    border: none;
    box-shadow: none;
  }
  .gw-tabs--contact .gw-contact-card:hover:not(:disabled) {
    border: none;
    box-shadow: none;
  }
  .gw-tabs--contact .gw-cms__content-text {
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-tabs--contact .gw-cms__content-text h1 {
    font-size: 1.5rem;
  }
  .gw-tabs--contact .gw-cms__content-text .gw-button-group {
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
    /* stylelint-disable-next-line max-nesting-depth */
  }
  .gw-tabs--contact .gw-cms__content-text .gw-button-group .gw-button {
    text-transform: lowercase;
  }
  .gw-tabs--contact .gw-cms__content-text .gw-contact-card {
    border: none;
    box-shadow: none;
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-tabs--contact .gw-cms__content-text .gw-contact-card-grid.gw-grid {
    justify-content: flex-start;
  }
  .gw-tabs--contact .gw-cms__content-text .gw-contact-card:not(.gw-contact-card--person) address > span {
    display: none;
  }
  .gw-tabs--contact .gw-cms__content-text .gw-contact-card:not(.gw-contact-card--person) .gw-button-group {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  [role=tab] {
    border-radius: 0;
  }
}
@media screen {
  .gw-tabs.is-js-enabled .gw-tabs__content {
    border-radius: 0.5rem;
  }
  [data-whatintent=keyboard] .gw-tabs.is-js-enabled .gw-tabs__content:focus {
    box-shadow: none;
    outline: 0.125rem solid rgb(var(--themeBlack));
    outline-offset: 0.375rem;
    outline-style: solid;
  }

  .gw-tabs__button {
    background-color: transparent;
    -webkit-border-after: 0.125rem solid rgb(var(--themeColorTextSecondary));
            border-bottom: 0.125rem solid rgb(var(--themeColorTextSecondary));
    color: rgb(var(--themeColorTextSecondary));
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-tabs__button {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 120em) {
  .gw-tabs__button {
    font-size: 1.5rem;
  }
}
@media screen {
  [data-whatintent=keyboard] .gw-tabs__button:focus {
    box-shadow: none;
    outline: 0.125rem solid rgb(var(--themeBlack));
    outline-offset: 0.375rem;
    outline-style: solid;
  }
}
@media screen {
  .gw-tabs__button:focus {
    border-radius: 0.1875rem;
  }
}
@media screen {
  .gw-tabs__button:hover {
    color: rgb(var(--themeColorLinkHover));
  }
}
@media screen {
  .gw-tabs__button[aria-selected=true] {
    background-color: transparent;
    -webkit-border-after: 0.25rem solid rgb(var(--themeColorBrandAlt));
            border-bottom: 0.25rem solid rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorBrandAlt));
  }
}
@media screen {
  .gw-testimonials {
    padding: 0 4rem;
    position: relative;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-testimonials {
    display: flex;
    flex-flow: row nowrap;
    padding: 4rem;
  }
}
@media screen {
  .gw-testimonials__doublequote {
    margin: 0 auto;
    transform: translateY(25%);
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-testimonials__doublequote{
    left: 4rem;
  }
  [dir="rtl"] .gw-testimonials__doublequote{
    right: 4rem;
  }
  .gw-testimonials__doublequote {
    top: 0;
    inset-inline-start: 4rem;
    margin: 4rem;
    position: absolute;
    transform: translateY(0);
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-testimonials__testimonial {
    padding: 6rem 2rem 6rem 0;
  }
}
@media screen {
  .gw-testimonials__quote {
    font-weight: bold;
    margin: 3rem 0;
  }
}
@media screen {
  .gw-testimonials__info {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    margin: 2rem 0;
  }
}
@media screen {
  [dir="ltr"] .gw-testimonials__logo{
            margin-right: 1rem;
  }
  [dir="rtl"] .gw-testimonials__logo{
            margin-left: 1rem;
  }
  .gw-testimonials__logo {
    height: auto;
    -webkit-margin-end: 1rem;
            margin-inline-end: 1rem;
    max-width: 150px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: left center;
       object-position: left center;
  }
}
@media screen {
  .gw-testimonials__text {
    display: flex;
    flex-flow: column nowrap;
  }
}
@media screen {
  .gw-testimonials__name {
    font-weight: bold;
  }
}
@media screen {
  .gw-testimonials__position {
    color: rgb(var(--themeColorBrandAlt2));
  }
}
@media screen {
  .gw-testimonials__picture {
    max-width: 350px;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
@media screen and (min-width: 64em) {
  .gw-testimonials__picture {
    max-width: 450px;
  }
}
@media screen {
  .gw-tabbed-slider {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1.875rem;
    justify-content: center;
    margin-left: 1.875rem;
    margin-right: 1.875rem;
    margin-inline: 1.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-tabbed-slider {
    margin-left: 5rem;
    margin-right: 5rem;
    margin-inline: 5rem;
  }
}
@media screen {
  .gw-tabbed-slider__wrap {
    background-color: lightgrey;
    margin-left: -1.875rem;
    margin-right: -1.875rem;
    margin-inline: -1.875rem;
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    padding-block: 3.75rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-tabbed-slider__wrap {
    margin-left: -5rem;
    margin-right: -5rem;
    margin-inline: -5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-tabbed-slider__tablist {
    max-width: 40%;
  }
}
@media screen {
  .gw-tabbed-slider__title {
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    -webkit-margin-after: 2.5rem;
            margin-bottom: 2.5rem;
  }
  .gw-tabbed-slider__title h1,
.gw-tabbed-slider__title h2,
.gw-tabbed-slider__title h3,
.gw-tabbed-slider__title h4,
.gw-tabbed-slider__title h5,
.gw-tabbed-slider__title h6 {
    font-size: 2.5rem;
  }
}
@media screen {
  .gw-tabbed-slider__button {
    border: 0.0625rem solid rgb(var(--themeColorBrand));
    border-radius: 1rem;
    display: flex;
    flex-direction: flex-start;
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    padding: 1.875rem;
  }
  [dir="ltr"] .gw-tabbed-slider__button .gw-icon{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-tabbed-slider__button .gw-icon{
            margin-left: 0.75rem;
  }
  .gw-tabbed-slider__button .gw-icon {
    height: 2rem;
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
    vertical-align: middle;
    width: 2rem;
  }
  .gw-tabbed-slider__button span {
    display: none;
  }
  .gw-tabbed-slider__button:hover {
    cursor: pointer;
  }
  .gw-tabbed-slider__button.is-selected, .gw-tabbed-slider__button:hover {
    background-color: rgba(var(--themeWhite), 0.2);
  }
  .gw-tabbed-slider__button.is-selected span, .gw-tabbed-slider__button:hover span {
    display: block;
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    -webkit-margin-before: 0.375rem;
            margin-top: 0.375rem;
  }
  .gw-tabbed-slider__button .gw-tabbed-slider__input {
    display: none;
  }
}
@media screen {
  .gw-tabbed-slider__label {
    align-self: center;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-tabbed-slider__images {
    max-width: 55%;
  }
}
@media screen {
  .gw-tabbed-slider__image {
    display: none;
  }
  .gw-tabbed-slider__image.is-selected {
    display: block;
  }
}
@media screen {
  .gw-table {
    -webkit-margin-after: 1.875rem;
            margin-bottom: 1.875rem;
    width: 100%;
    /* stylelint-disable no-descending-specificity */
    /* stylelint-enable no-descending-specificity */
  }
  .gw-table th,
.gw-table td {
    padding: 0.75rem;
  }
  .gw-table tr th {
    background-color: transparent;
    border: none;
    color: rgb(var(--themeColorText));
    font-weight: normal;
  }
  .gw-table tr td {
    border: none;
    font-weight: bold;
  }
  .gw-table tr:nth-child(even) {
    background-color: rgb(var(--themeGrey1));
  }
  .gw-table caption {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  .gw-table--striped th {
    background-color: transparent;
  }
  .gw-table--striped tr:nth-child(odd) {
    background-color: rgb(var(--themeGrey1));
  }
  .gw-table--striped tr:nth-child(even) {
    background-color: rgb(var(--themeColorBackground));
  }
  .gw-table-responsive-wrap[role=region][aria-labelledby][tabindex] {
    overflow: auto;
  }
  [data-whatintent=keyboard] .gw-table-responsive-wrap[role=region][aria-labelledby][tabindex]:focus {
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
  }
  [data-whatintent=keyboard] [data-whatintent=keyboard] .gw-table-responsive-wrap[role=region][aria-labelledby][tabindex]:focus:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] [data-whatintent=keyboard] .gw-table-responsive-wrap[role=region][aria-labelledby][tabindex]:focus:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] [data-whatintent=keyboard] .gw-table-responsive-wrap[role=region][aria-labelledby][tabindex]:focus:focus {
    outline: 0;
  }

  [data-whatintent=keyboard] .gw-table-responsive-wrap[role=region][aria-labelledby][tabindex]:focus:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
}
@media screen {
  .gw-table th {
    background-color: rgb(var(--themeGrey2));
    color: rgb(var(--themeColorText));
  }
  .gw-table--striped th {
    background-color: transparent;
    color: inherit;
  }
  .gw-table--striped tr:nth-child(odd) {
    background-color: rgb(var(--themeGrey2));
    color: rgb(var(--themeColorText));
  }
  .gw-table--striped tr:nth-child(even) {
    background-color: rgb(var(--themeGrey1));
  }
}
@media screen {
  .gw-toolbar {
    align-items: center;
    display: flex;
    font-size: 1rem;
    gap: 0.75rem;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
  [dir="ltr"] .gw-toolbar .gw-button--text-icon,[dir="ltr"]  .gw-toolbar .gw-button--icon{
            padding-right: 1.25rem;
  }
  [dir="rtl"] .gw-toolbar .gw-button--text-icon,[dir="rtl"]  .gw-toolbar .gw-button--icon{
            padding-left: 1.25rem;
  }
  [dir="ltr"] .gw-toolbar .gw-button--text-icon,[dir="ltr"]  .gw-toolbar .gw-button--icon{
            padding-left: 1.25rem;
  }
  [dir="rtl"] .gw-toolbar .gw-button--text-icon,[dir="rtl"]  .gw-toolbar .gw-button--icon{
            padding-right: 1.25rem;
  }
  .gw-toolbar .gw-button--text-icon, .gw-toolbar .gw-button--icon {
    background-color: transparent;
    border: 0;
    font-size: 1em;
    -webkit-padding-end: 1.25rem;
            padding-inline-end: 1.25rem;
            padding-inline-start: 1.25rem;
    -webkit-padding-start: 1.25rem;
  }
  .gw-toolbar .gw-button-group {
    align-items: center;
    flex-wrap: unset;
    justify-content: space-between;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  [dir="ltr"] .gw-toolbar .gw-button-group > *{
            margin-right: 0;
  }
  [dir="rtl"] .gw-toolbar .gw-button-group > *{
            margin-left: 0;
  }
  .gw-toolbar .gw-button-group > * {
    -webkit-margin-after: 0;
            margin-bottom: 0;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }
  .gw-toolbar .gw-icon {
    height: 1.5em;
    width: 1.5em;
  }
  .gw-toolbar--stock {
    width: 100%;
  }
  .gw-toolbar--stock .gw-button-group {
    width: 100%;
  }
  .gw-toolbar--stock .gw-button-group > a {
    line-height: 1.2;
  }
  [dir="ltr"] .gw-toolbar--stock .gw-button-group > :first-child{
            margin-right: auto;
  }
  [dir="rtl"] .gw-toolbar--stock .gw-button-group > :first-child{
            margin-left: auto;
  }
  .gw-toolbar--stock .gw-button-group > :first-child {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
  }
  .gw-toolbar--stock__location {
    align-items: center;
    display: flex;
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 0.875rem;
    line-height: 1;
    padding: 0.75rem 1.25rem;
  }
  [dir="ltr"] .gw-toolbar--stock__location .gw-icon{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-toolbar--stock__location .gw-icon{
            margin-left: 0.75rem;
  }
  .gw-toolbar--stock__location .gw-icon {
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
  }
  .gw-toolbar--stock__location > span {
    font-size: 0.875rem;
  }
  .gw-toolbar--stock__compare .gw-icon {
    fill: transparent;
    stroke: rgb(var(--themeColorText));
    stroke-width: 0.25rem;
  }
  .gw-toolbar--stock__compare.is-selected {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-toolbar--stock__compare.is-selected .gw-icon {
    fill: inherit;
  }
  .gw-toolbar--stock .gw-share__fallback {
    right: 0;
  }
  .gw-toolbar--stock .gw-button--icon {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-inline: 0.75rem;
  }
  .gw-toolbar--stock .gw-button[class*="--text"] {
    font-family: var(--themeFontFamily), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 0.875rem;
    /* stylelint-disable-next-line max-nesting-depth */
  }
  .gw-toolbar--stock .gw-button[class*="--text"] span > span {
    font-size: 0.875rem;
  }
}
@media screen {
  .gw-toolbar {
    margin: 0.75rem 0;
  }
  .gw-toolbar .gw-icon {
    color: rgb(var(--themeColorBrandAlt));
    stroke: rgb(var(--themeColorBrandAlt));
  }
  .gw-toolbar--stock .gw-button.gw-button--text-icon {
    font-family: var(--themeFontFamilySemiBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
  }
  .gw-toolbar--stock__location {
    color: rgb(var(--themeColorBrandAlt));
    font-family: var(--themeFontFamilySemiBold), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  .gw-toolbar--stock__compare.is-selected {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .gw-toolbar--stock__compare.is-selected .gw-icon {
    fill: rgb(var(--themeColorBrandAlt));
    stroke-width: 0.15rem;
  }
  .gw-toolbar__item {
    color: rgb(var(--themeColorTextSecondary));
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
}
@media screen {
  .gw-vacancies {
    padding: 3rem 1rem;
  }
  .gw-vacancies__button {
    display: none;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-vacancies__button {
    background: rgba(var(--themeColorBrand), 0.05);
    color: rgb(var(--themeColorBrand));
    display: block;
    font-weight: bold;
    padding: 1rem 2rem;
  }
  .gw-vacancies__button:hover {
    background: rgb(var(--themeColorBrand));
    color: rgb(var(--themeWhite));
  }
}
@media screen {
  .gw-vacancies__vacancy {
    align-items: center;
    color: rgb(var(--themeColorText));
    display: flex;
    flex-flow: row nowrap;
    font-weight: normal;
    padding: 2rem 0;
    text-decoration: none;
  }
  .gw-vacancies__vacancy * {
    color: rgb(var(--themeColorText));
    font-weight: normal;
    text-decoration: none;
  }
  .gw-vacancies__vacancy:hover {
    text-decoration: none;
    transform: scale(1);
  }
  .gw-vacancies__vacancy:hover * {
    text-decoration: none;
    transform: scale(1);
  }
  .gw-vacancies__vacancy:hover .gw-vacancies__button {
    background: rgb(var(--themeColorBrand));
    color: rgb(var(--themeWhite));
  }
  .gw-vacancies__vacancy + .gw-vacancies__vacancy {
    border-top: 0.0625rem solid rgb(var(--themeGrey4));
  }
}
@media screen {
  [dir="ltr"] .gw-vacancies__state{
            margin-right: 1rem;
  }
  [dir="rtl"] .gw-vacancies__state{
            margin-left: 1rem;
  }
  .gw-vacancies__state {
    color: rgb(var(--themeWhite));
    font-weight: bold;
    -webkit-margin-end: 1rem;
            margin-inline-end: 1rem;
    padding: 0.5rem;
  }
  .gw-vacancies__state.open {
    background: rgb(var(--themeAccentPositive));
  }
  .gw-vacancies__state.filled {
    background: rgb(var(--themeGrey4));
  }
}
@media screen {
  .gw-vacancies__title {
    margin: 0 auto 0 0;
  }
}
@media screen {
  .gw-vacancies__info {
    display: none;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .gw-vacancies__info{
            margin-right: 3rem;
  }
  [dir="rtl"] .gw-vacancies__info{
            margin-left: 3rem;
  }
  .gw-vacancies__info {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    -webkit-margin-end: 3rem;
            margin-inline-end: 3rem;
  }
}
@media screen {
  .gw-vacancies__icon {
    height: 24px;
    margin-right: 0.5rem;
    -o-object-fit: contain;
       object-fit: contain;
    stroke: rgb(var(--themeColorBrand));
    width: 24px;
  }
}
@media screen {
  .gw-video-card {
    align-items: center;
    text-align: center;
  }
  .gw-video-card .gw-card__media {
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
  }
  [dir="ltr"] .gw-video-card.gw-card--media-inline{
    text-align: left;
  }
  [dir="rtl"] .gw-video-card.gw-card--media-inline{
    text-align: right;
  }
  .gw-video-card.gw-card--media-inline {
    text-align: start;
  }
  [dir="ltr"] .gw-video-card.gw-card--media-inline-alt{
    text-align: right;
  }
  [dir="rtl"] .gw-video-card.gw-card--media-inline-alt{
    text-align: left;
  }
  .gw-video-card.gw-card--media-inline-alt {
    text-align: end;
  }
  .gw-video-card.gw-card--media-inline .gw-card__media, .gw-video-card.gw-card--media-inline-alt .gw-card__media {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .gw-video-player {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .gw-video-player > a {
    display: block;
    position: relative;
  }
  [data-whatintent=keyboard] .gw-video-player > a:focus {
    box-shadow: 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
  }
  [dir="ltr"] .gw-video-player__icon{
    left: calc(50% - 2rem);
  }
  [dir="rtl"] .gw-video-player__icon{
    right: calc(50% - 2rem);
  }
  .gw-video-player__icon {
    background-color: rgba(var(--themeColorBrand), 0.4);
    border: 1rem solid transparent;
    border-radius: 50%;
    color: rgb(var(--themeColorTextAlt));
    height: 4rem;
    top: calc(50% - 2rem);
    inset-inline-start: calc(50% - 2rem);
    position: absolute;
    width: 4rem;
  }
  .gw-video-player__modal.gw-modal__overlay .gw-modal__inner {
    background-color: rgb(var(--themeColorBackgroundAlt));
    max-height: 98%;
    max-width: 95%;
    -webkit-padding-after: 0;
    -webkit-padding-before: 3.75rem;
            padding-bottom: 0;
            padding-top: 3.75rem;
  }
  [dir="ltr"] .gw-video-player__modal.gw-modal__overlay [data-modal-close]{
            margin-right: 0.75rem;
  }
  [dir="rtl"] .gw-video-player__modal.gw-modal__overlay [data-modal-close]{
            margin-left: 0.75rem;
  }
  .gw-video-player__modal.gw-modal__overlay [data-modal-close] {
    background-color: unset;
    color: rgb(var(--themeColorTextAlt));
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
    z-index: 1;
  }
  .gw-video-player__modal.gw-modal__overlay .gw-responsive-media br {
    display: none;
  }
  .gw-video-player--youtube-embed {
    height: 55vw;
    max-width: 60rem;
    position: relative;
    width: 100%;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-video-player--youtube-embed {
    height: 36rem;
  }
}
@media screen {
  .gw-video-player--youtube-embed:hover .gw-icon {
    opacity: 0.8;
  }
}
@media screen {
  [dir="ltr"] .gw-video-player--youtube-embed__cover-image,[dir="ltr"] 
.gw-video-player--youtube-embed iframe{
    left: 0;
  }
  [dir="rtl"] .gw-video-player--youtube-embed__cover-image,[dir="rtl"] 
.gw-video-player--youtube-embed iframe{
    right: 0;
  }
  .gw-video-player--youtube-embed__cover-image,
.gw-video-player--youtube-embed iframe {
    height: 100%;
    top: 0;
    inset-inline-start: 0;
    position: absolute;
    width: 100%;
  }
}
@media screen {
  .gw-video-player--youtube-embed__cover-image {
    opacity: 1;
  }
  [dir="ltr"] .gw-video-player--youtube-embed__cover-image .gw-icon{
    left: 50%;
  }
  [dir="rtl"] .gw-video-player--youtube-embed__cover-image .gw-icon{
    right: 50%;
  }
  .gw-video-player--youtube-embed__cover-image .gw-icon {
    fill: none;
    height: 5rem;
    top: 50%;
    inset-inline-start: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    width: 5rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .gw-video-player--youtube-embed__cover-image .gw-icon {
    height: initial;
    width: initial;
  }
}
@media screen {
  .gw-video-player--youtube-embed__cover-image .gw-image {
    height: 100%;
    width: 100%;
  }
}
@media screen {
  .gw-video-player--youtube-embed iframe {
    opacity: 0;
  }
}
@media screen {
  .page-1 .gw-cms,
.page-default-home .gw-cms {
    text-align: center;
  }
  [dir="ltr"] .page-1 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-home .gw-cms > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .page-1 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-home .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .page-1 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-home .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .page-1 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-home .gw-cms > :not([class]){
            margin-right: auto;
  }
  .page-1 .gw-cms > :not([class]),
.page-default-home .gw-cms > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
  .page-1 .gw-cms:first-of-type,
.page-default-home .gw-cms:first-of-type {
    -webkit-margin-before: 3.75rem;
            margin-top: 3.75rem;
  }
}
@media screen {
  .page-1 .gw-slider--banner,
.page-default-home .gw-slider--banner {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .page-10 .gw-cms,
.page-default-productlist .gw-cms {
    text-align: center;
  }
  [dir="ltr"] .page-10 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-productlist .gw-cms > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .page-10 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-productlist .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .page-10 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-productlist .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .page-10 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-productlist .gw-cms > :not([class]){
            margin-right: auto;
  }
  .page-10 .gw-cms > :not([class]),
.page-default-productlist .gw-cms > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
  .page-10 .gw-cms > h2,
.page-default-productlist .gw-cms > h2 {
    -webkit-margin-before: 2rem;
            margin-top: 2rem;
  }
  .page-10 .gw-layout__inner > .gw-cms:first-of-type,
.page-default-productlist .gw-layout__inner > .gw-cms:first-of-type {
    -webkit-margin-before: 3.75rem;
            margin-top: 3.75rem;
  }
  .page-10 .gw-product-card-grid,
.page-default-productlist .gw-product-card-grid {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  .page-10 .gw-product-card__block-link p:first-of-type,
.page-default-productlist .gw-product-card__block-link p:first-of-type {
    color: rgb(var(--themeColorText));
  }
}
@media screen {
  [dir="ltr"] .page-16 .gw-layout__fullbleed .gw-responsive-media,[dir="ltr"] 
.page-8 .gw-layout__fullbleed .gw-responsive-media,[dir="ltr"] 
.page-default-inventorydetail .gw-layout__fullbleed .gw-responsive-media{
            margin-right: auto;
  }
  [dir="rtl"] .page-16 .gw-layout__fullbleed .gw-responsive-media,[dir="rtl"] 
.page-8 .gw-layout__fullbleed .gw-responsive-media,[dir="rtl"] 
.page-default-inventorydetail .gw-layout__fullbleed .gw-responsive-media{
            margin-left: auto;
  }
  [dir="ltr"] .page-16 .gw-layout__fullbleed .gw-responsive-media,[dir="ltr"] 
.page-8 .gw-layout__fullbleed .gw-responsive-media,[dir="ltr"] 
.page-default-inventorydetail .gw-layout__fullbleed .gw-responsive-media{
            margin-left: auto;
  }
  [dir="rtl"] .page-16 .gw-layout__fullbleed .gw-responsive-media,[dir="rtl"] 
.page-8 .gw-layout__fullbleed .gw-responsive-media,[dir="rtl"] 
.page-default-inventorydetail .gw-layout__fullbleed .gw-responsive-media{
            margin-right: auto;
  }
  .page-16 .gw-layout__fullbleed .gw-responsive-media,
.page-8 .gw-layout__fullbleed .gw-responsive-media,
.page-default-inventorydetail .gw-layout__fullbleed .gw-responsive-media {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
    max-width: var(--themeMaxContentWidth);
  }
  .page-16 .gw-certifications,
.page-8 .gw-certifications,
.page-default-inventorydetail .gw-certifications {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    padding-block: 3.75rem;
  }
  .page-16 .gw-cms[aria-labelledby=about-us] + div,
.page-8 .gw-cms[aria-labelledby=about-us] + div,
.page-default-inventorydetail .gw-cms[aria-labelledby=about-us] + div {
    background-color: rgb(var(--themeWhite));
    padding: 1.875rem;
  }
  .page-16 .gw-cms,
.page-16 .gw-cms__content-text,
.page-8 .gw-cms,
.page-8 .gw-cms__content-text,
.page-default-inventorydetail .gw-cms,
.page-default-inventorydetail .gw-cms__content-text {
    text-align: center;
  }
  [dir="ltr"] .page-16 .gw-cms > :not([class]),[dir="ltr"] 
.page-16 .gw-cms__content-text > :not([class]),[dir="ltr"] 
.page-8 .gw-cms > :not([class]),[dir="ltr"] 
.page-8 .gw-cms__content-text > :not([class]),[dir="ltr"] 
.page-default-inventorydetail .gw-cms > :not([class]),[dir="ltr"] 
.page-default-inventorydetail .gw-cms__content-text > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .page-16 .gw-cms > :not([class]),[dir="rtl"] 
.page-16 .gw-cms__content-text > :not([class]),[dir="rtl"] 
.page-8 .gw-cms > :not([class]),[dir="rtl"] 
.page-8 .gw-cms__content-text > :not([class]),[dir="rtl"] 
.page-default-inventorydetail .gw-cms > :not([class]),[dir="rtl"] 
.page-default-inventorydetail .gw-cms__content-text > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .page-16 .gw-cms > :not([class]),[dir="ltr"] 
.page-16 .gw-cms__content-text > :not([class]),[dir="ltr"] 
.page-8 .gw-cms > :not([class]),[dir="ltr"] 
.page-8 .gw-cms__content-text > :not([class]),[dir="ltr"] 
.page-default-inventorydetail .gw-cms > :not([class]),[dir="ltr"] 
.page-default-inventorydetail .gw-cms__content-text > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .page-16 .gw-cms > :not([class]),[dir="rtl"] 
.page-16 .gw-cms__content-text > :not([class]),[dir="rtl"] 
.page-8 .gw-cms > :not([class]),[dir="rtl"] 
.page-8 .gw-cms__content-text > :not([class]),[dir="rtl"] 
.page-default-inventorydetail .gw-cms > :not([class]),[dir="rtl"] 
.page-default-inventorydetail .gw-cms__content-text > :not([class]){
            margin-right: auto;
  }
  .page-16 .gw-cms > :not([class]),
.page-16 .gw-cms__content-text > :not([class]),
.page-8 .gw-cms > :not([class]),
.page-8 .gw-cms__content-text > :not([class]),
.page-default-inventorydetail .gw-cms > :not([class]),
.page-default-inventorydetail .gw-cms__content-text > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
  .page-16 .gw-product-summary__name.is-hidden-on-large,
.page-8 .gw-product-summary__name.is-hidden-on-large,
.page-default-inventorydetail .gw-product-summary__name.is-hidden-on-large {
    -webkit-margin-after: -1.25rem;
            margin-bottom: -1.25rem;
  }
  .page-16 .gw-product-summary__price--extra,
.page-8 .gw-product-summary__price--extra,
.page-default-inventorydetail .gw-product-summary__price--extra {
    -webkit-margin-before: 0.75rem;
            margin-top: 0.75rem;
  }
  [dir="ltr"] .page-16 .gw-toolbar--stock,[dir="ltr"] 
.page-8 .gw-toolbar--stock,[dir="ltr"] 
.page-default-inventorydetail .gw-toolbar--stock{
            margin-right: -1.25rem;
  }
  [dir="rtl"] .page-16 .gw-toolbar--stock,[dir="rtl"] 
.page-8 .gw-toolbar--stock,[dir="rtl"] 
.page-default-inventorydetail .gw-toolbar--stock{
            margin-left: -1.25rem;
  }
  .page-16 .gw-toolbar--stock,
.page-8 .gw-toolbar--stock,
.page-default-inventorydetail .gw-toolbar--stock {
    -webkit-margin-end: -1.25rem;
            margin-inline-end: -1.25rem;
  }
  .page-16 .gw-product-specs,
.page-8 .gw-product-specs,
.page-default-inventorydetail .gw-product-specs {
    -webkit-margin-after: 2.5rem;
    -webkit-margin-before: 2.5rem;
            margin-bottom: 2.5rem;
            margin-top: 2.5rem;
  }
  [dir="ltr"] .page-16 .gw-product-specs__item,[dir="ltr"] 
.page-8 .gw-product-specs__item,[dir="ltr"] 
.page-default-inventorydetail .gw-product-specs__item{
            margin-right: 1.25rem;
  }
  [dir="rtl"] .page-16 .gw-product-specs__item,[dir="rtl"] 
.page-8 .gw-product-specs__item,[dir="rtl"] 
.page-default-inventorydetail .gw-product-specs__item{
            margin-left: 1.25rem;
  }
  .page-16 .gw-product-specs__item,
.page-8 .gw-product-specs__item,
.page-default-inventorydetail .gw-product-specs__item {
    background-color: transparent;
    color: inherit;
    flex-grow: 0;
    -webkit-margin-end: 1.25rem;
            margin-inline-end: 1.25rem;
    padding: 0;
  }
  .page-16 .gw-product-specs__item span:first-of-type,
.page-8 .gw-product-specs__item span:first-of-type,
.page-default-inventorydetail .gw-product-specs__item span:first-of-type {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .page-16 .gw-product-specs__item span:first-of-type:focus,
.page-8 .gw-product-specs__item span:first-of-type:focus,
.page-default-inventorydetail .gw-product-specs__item span:first-of-type:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
  }
  [dir="ltr"] .page-16 .gw-accordion,[dir="ltr"] 
.page-8 .gw-accordion,[dir="ltr"] 
.page-default-inventorydetail .gw-accordion{
            margin-right: auto;
  }
  [dir="rtl"] .page-16 .gw-accordion,[dir="rtl"] 
.page-8 .gw-accordion,[dir="rtl"] 
.page-default-inventorydetail .gw-accordion{
            margin-left: auto;
  }
  [dir="ltr"] .page-16 .gw-accordion,[dir="ltr"] 
.page-8 .gw-accordion,[dir="ltr"] 
.page-default-inventorydetail .gw-accordion{
            margin-left: auto;
  }
  [dir="rtl"] .page-16 .gw-accordion,[dir="rtl"] 
.page-8 .gw-accordion,[dir="rtl"] 
.page-default-inventorydetail .gw-accordion{
            margin-right: auto;
  }
  .page-16 .gw-accordion,
.page-8 .gw-accordion,
.page-default-inventorydetail .gw-accordion {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
    max-width: var(--themeMaxContentWidth);
  }
  .page-16 .gw-card-slider__wrap[aria-labelledby=similar-vehicles],
.page-8 .gw-card-slider__wrap[aria-labelledby=similar-vehicles],
.page-default-inventorydetail .gw-card-slider__wrap[aria-labelledby=similar-vehicles] {
    background-color: rgb(var(--themeGrey1));
  }
  [dir="ltr"] .page-16 .gw-gallery > .gw-icon--hover,[dir="ltr"] 
.page-8 .gw-gallery > .gw-icon--hover,[dir="ltr"] 
.page-default-inventorydetail .gw-gallery > .gw-icon--hover{
    left: 50%;
  }
  [dir="rtl"] .page-16 .gw-gallery > .gw-icon--hover,[dir="rtl"] 
.page-8 .gw-gallery > .gw-icon--hover,[dir="rtl"] 
.page-default-inventorydetail .gw-gallery > .gw-icon--hover{
    right: 50%;
  }
  .page-16 .gw-gallery > .gw-icon--hover,
.page-8 .gw-gallery > .gw-icon--hover,
.page-default-inventorydetail .gw-gallery > .gw-icon--hover {
    height: 30%;
    top: 50%;
    inset-inline-start: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    width: 30%;
    z-index: 1;
  }
  [dir="ltr"] .page-16 .gw-grid--asymmetric,[dir="ltr"] 
.page-8 .gw-grid--asymmetric,[dir="ltr"] 
.page-default-inventorydetail .gw-grid--asymmetric{
            margin-right: auto;
  }
  [dir="rtl"] .page-16 .gw-grid--asymmetric,[dir="rtl"] 
.page-8 .gw-grid--asymmetric,[dir="rtl"] 
.page-default-inventorydetail .gw-grid--asymmetric{
            margin-left: auto;
  }
  [dir="ltr"] .page-16 .gw-grid--asymmetric,[dir="ltr"] 
.page-8 .gw-grid--asymmetric,[dir="ltr"] 
.page-default-inventorydetail .gw-grid--asymmetric{
            margin-left: auto;
  }
  [dir="rtl"] .page-16 .gw-grid--asymmetric,[dir="rtl"] 
.page-8 .gw-grid--asymmetric,[dir="rtl"] 
.page-default-inventorydetail .gw-grid--asymmetric{
            margin-right: auto;
  }
  .page-16 .gw-grid--asymmetric,
.page-8 .gw-grid--asymmetric,
.page-default-inventorydetail .gw-grid--asymmetric {
    -webkit-margin-before: 1.875rem;
            margin-top: 1.875rem;
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
    max-width: 106rem;
  }
}
@media screen and (min-width: 64em) {
  .page-16 .gw-grid--asymmetric,
.page-8 .gw-grid--asymmetric,
.page-default-inventorydetail .gw-grid--asymmetric {
    display: grid;
    gap: 1.875rem;
    grid-template-areas: "col1 col2";
    grid-template-columns: calc(65% - 1.875rem / 2) calc(35% - 1.875rem / 2);
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  .page-16 .gw-grid--asymmetric > :first-child,
.page-8 .gw-grid--asymmetric > :first-child,
.page-default-inventorydetail .gw-grid--asymmetric > :first-child {
    grid-area: col1;
  }
  .page-16 .gw-grid--asymmetric > :last-child,
.page-8 .gw-grid--asymmetric > :last-child,
.page-default-inventorydetail .gw-grid--asymmetric > :last-child {
    grid-area: col2;
  }
}
@media screen {
  [dir="ltr"] .page-16 .gw-grid--asymmetric .gw-cms,[dir="ltr"] 
.page-16 .gw-grid--asymmetric .gw-cms__content-text,[dir="ltr"] 
.page-8 .gw-grid--asymmetric .gw-cms,[dir="ltr"] 
.page-8 .gw-grid--asymmetric .gw-cms__content-text,[dir="ltr"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-cms,[dir="ltr"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-cms__content-text{
    text-align: left;
  }
  [dir="rtl"] .page-16 .gw-grid--asymmetric .gw-cms,[dir="rtl"] 
.page-16 .gw-grid--asymmetric .gw-cms__content-text,[dir="rtl"] 
.page-8 .gw-grid--asymmetric .gw-cms,[dir="rtl"] 
.page-8 .gw-grid--asymmetric .gw-cms__content-text,[dir="rtl"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-cms,[dir="rtl"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-cms__content-text{
    text-align: right;
  }
  .page-16 .gw-grid--asymmetric .gw-cms,
.page-16 .gw-grid--asymmetric .gw-cms__content-text,
.page-8 .gw-grid--asymmetric .gw-cms,
.page-8 .gw-grid--asymmetric .gw-cms__content-text,
.page-default-inventorydetail .gw-grid--asymmetric .gw-cms,
.page-default-inventorydetail .gw-grid--asymmetric .gw-cms__content-text {
    text-align: start;
  }
}
@media screen {
  .page-16 .gw-grid--asymmetric .gw-grid--asymmetric__right-track,
.page-8 .gw-grid--asymmetric .gw-grid--asymmetric__right-track,
.page-default-inventorydetail .gw-grid--asymmetric .gw-grid--asymmetric__right-track {
    position: relative;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .page-16 .gw-grid--asymmetric .gw-grid--asymmetric__right-track .gw-product-summary,
.page-8 .gw-grid--asymmetric .gw-grid--asymmetric__right-track .gw-product-summary,
.page-default-inventorydetail .gw-grid--asymmetric .gw-grid--asymmetric__right-track .gw-product-summary {
    position: -webkit-sticky;
    position: sticky;
  }
}
@media screen {
  .page-16 .gw-grid--asymmetric .gw-grid--asymmetric__left-track,
.page-8 .gw-grid--asymmetric .gw-grid--asymmetric__left-track,
.page-default-inventorydetail .gw-grid--asymmetric .gw-grid--asymmetric__left-track {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .page-16 .gw-grid--asymmetric .gw-grid--asymmetric__left-track .gw-cms,
.page-8 .gw-grid--asymmetric .gw-grid--asymmetric__left-track .gw-cms,
.page-default-inventorydetail .gw-grid--asymmetric .gw-grid--asymmetric__left-track .gw-cms {
    -webkit-margin-before: 3.75rem;
            margin-top: 3.75rem;
  }
}
@media screen {
  [dir="ltr"] .page-16 .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles],[dir="ltr"] 
.page-8 .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles],[dir="ltr"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles]{
            margin-right: 0;
  }
  [dir="rtl"] .page-16 .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles],[dir="rtl"] 
.page-8 .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles],[dir="rtl"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles]{
            margin-left: 0;
  }
  [dir="ltr"] .page-16 .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles],[dir="ltr"] 
.page-8 .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles],[dir="ltr"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles]{
            margin-left: 0;
  }
  [dir="rtl"] .page-16 .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles],[dir="rtl"] 
.page-8 .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles],[dir="rtl"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles]{
            margin-right: 0;
  }
  .page-16 .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles],
.page-8 .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles],
.page-default-inventorydetail .gw-grid--asymmetric .gw-card-slider__wrap[aria-labelledby=similar-vehicles] {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
  }
}
@media screen {
  .page-16 .gw-grid--asymmetric h2,
.page-8 .gw-grid--asymmetric h2,
.page-default-inventorydetail .gw-grid--asymmetric h2 {
    text-transform: unset;
  }
}
@media screen {
  .page-16 .gw-grid--asymmetric .gw-product-specs__list,
.page-8 .gw-grid--asymmetric .gw-product-specs__list,
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__list {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    justify-content: flex-start;
  }
}
@media screen and (min-width: 48.0625em) {
  .page-16 .gw-grid--asymmetric .gw-product-specs__list,
.page-8 .gw-grid--asymmetric .gw-product-specs__list,
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__list {
    justify-content: space-around;
  }
}
@media screen {
  .page-16 .gw-grid--asymmetric .gw-product-specs__item,
.page-8 .gw-grid--asymmetric .gw-product-specs__item,
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__item {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
    font-size: 1rem;
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
    text-align: center;
    width: 6.25rem;
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .page-16 .gw-grid--asymmetric .gw-product-specs__item,
.page-8 .gw-grid--asymmetric .gw-product-specs__item,
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__item {
    font-size: 1.5rem;
    width: 10rem;
  }
}
@media screen and (min-width: 90em) {
  .page-16 .gw-grid--asymmetric .gw-product-specs__item,
.page-8 .gw-grid--asymmetric .gw-product-specs__item,
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__item {
    width: unset;
  }
}
@media screen {
  [dir="ltr"] .page-16 .gw-grid--asymmetric .gw-product-specs__item .gw-icon,[dir="ltr"] 
.page-8 .gw-grid--asymmetric .gw-product-specs__item .gw-icon,[dir="ltr"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__item .gw-icon{
            margin-left: 0;
  }
  [dir="rtl"] .page-16 .gw-grid--asymmetric .gw-product-specs__item .gw-icon,[dir="rtl"] 
.page-8 .gw-grid--asymmetric .gw-product-specs__item .gw-icon,[dir="rtl"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__item .gw-icon{
            margin-right: 0;
  }
  .page-16 .gw-grid--asymmetric .gw-product-specs__item .gw-icon,
.page-8 .gw-grid--asymmetric .gw-product-specs__item .gw-icon,
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__item .gw-icon {
    height: 1.5em;
            margin-inline-start: 0;
    -webkit-margin-start: 0;
    overflow: visible;
    width: 1.5em;
  }
}
@media screen and (min-width: 48.0625em) {
  .page-16 .gw-grid--asymmetric .gw-product-specs__item .gw-icon,
.page-8 .gw-grid--asymmetric .gw-product-specs__item .gw-icon,
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__item .gw-icon {
    height: 2.5em;
    width: 2.5em;
  }
}
@media screen {
  .page-16 .gw-grid--asymmetric .gw-cms.gw-payment-options,
.page-8 .gw-grid--asymmetric .gw-cms.gw-payment-options,
.page-default-inventorydetail .gw-grid--asymmetric .gw-cms.gw-payment-options {
    -webkit-margin-before: -3.75rem;
            margin-top: -3.75rem;
    -webkit-padding-before: 5rem;
            padding-top: 5rem;
  }
  .page-16 .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options,
.page-8 .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options,
.page-default-inventorydetail .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
  .page-16 .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options .gw-button--toggle:hover:not(:disabled),
.page-8 .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options .gw-button--toggle:hover:not(:disabled),
.page-default-inventorydetail .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options .gw-button--toggle:hover:not(:disabled) {
    transform: scale(1);
  }
  .page-16 .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options .gw-button--toggle.is-selected,
.page-8 .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options .gw-button--toggle.is-selected,
.page-default-inventorydetail .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options .gw-button--toggle.is-selected {
    background-color: rgb(var(--themeColorLinkAlt));
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeColorBrandAlt));
  }
}
@media screen {
  .page-16 .gw-grid--asymmetric .gw-payment-options__finance .gw-grid,
.page-16 .gw-grid--asymmetric .gw-payment-options__finance .gw-table,
.page-8 .gw-grid--asymmetric .gw-payment-options__finance .gw-grid,
.page-8 .gw-grid--asymmetric .gw-payment-options__finance .gw-table,
.page-default-inventorydetail .gw-grid--asymmetric .gw-payment-options__finance .gw-grid,
.page-default-inventorydetail .gw-grid--asymmetric .gw-payment-options__finance .gw-table {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .page-16 .gw-layout__main,
.page-8 .gw-layout__main,
.page-default-inventorydetail .gw-layout__main {
    overflow: initial;
  }
}
@media screen {
  [dir="ltr"] .page-16 .gw-grid--asymmetric .gw-tabs .gw-tabs__content,[dir="ltr"] 
.page-16 .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content,[dir="ltr"] 
.page-8 .gw-grid--asymmetric .gw-tabs .gw-tabs__content,[dir="ltr"] 
.page-8 .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content,[dir="ltr"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-tabs .gw-tabs__content,[dir="ltr"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content{
            padding-right: 0;
  }
  [dir="rtl"] .page-16 .gw-grid--asymmetric .gw-tabs .gw-tabs__content,[dir="rtl"] 
.page-16 .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content,[dir="rtl"] 
.page-8 .gw-grid--asymmetric .gw-tabs .gw-tabs__content,[dir="rtl"] 
.page-8 .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content,[dir="rtl"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-tabs .gw-tabs__content,[dir="rtl"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content{
            padding-left: 0;
  }
  [dir="ltr"] .page-16 .gw-grid--asymmetric .gw-tabs .gw-tabs__content,[dir="ltr"] 
.page-16 .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content,[dir="ltr"] 
.page-8 .gw-grid--asymmetric .gw-tabs .gw-tabs__content,[dir="ltr"] 
.page-8 .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content,[dir="ltr"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-tabs .gw-tabs__content,[dir="ltr"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content{
            padding-left: 0;
  }
  [dir="rtl"] .page-16 .gw-grid--asymmetric .gw-tabs .gw-tabs__content,[dir="rtl"] 
.page-16 .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content,[dir="rtl"] 
.page-8 .gw-grid--asymmetric .gw-tabs .gw-tabs__content,[dir="rtl"] 
.page-8 .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content,[dir="rtl"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-tabs .gw-tabs__content,[dir="rtl"] 
.page-default-inventorydetail .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content{
            padding-right: 0;
  }
  .page-16 .gw-grid--asymmetric .gw-tabs .gw-tabs__content,
.page-16 .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content,
.page-8 .gw-grid--asymmetric .gw-tabs .gw-tabs__content,
.page-8 .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content,
.page-default-inventorydetail .gw-grid--asymmetric .gw-tabs .gw-tabs__content,
.page-default-inventorydetail .gw-grid--asymmetric .gw-tabs.is-js-enabled .gw-tabs__content {
    -webkit-padding-end: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;
    -webkit-padding-start: 0;
  }
  .page-16 .gw-grid--asymmetric .gw-product-specs,
.page-8 .gw-grid--asymmetric .gw-product-specs,
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs {
    /* stylelint-disable max-nesting-depth */
    /* stylelint-enable max-nesting-depth */
  }
}
@media screen and (min-width: 48.0625em) {
  .page-16 .gw-grid--asymmetric .gw-product-specs__list,
.page-8 .gw-grid--asymmetric .gw-product-specs__list,
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__list {
    border: 0.0625rem solid rgb(var(--themeGrey3));
    border-radius: 0.5rem;
    padding: 2.5rem 1.875rem;
  }
}
@media screen {
  .page-16 .gw-grid--asymmetric .gw-product-specs__item,
.page-8 .gw-grid--asymmetric .gw-product-specs__item,
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__item {
    color: rgb(var(--themeColorText));
    flex: 0 0 100%;
    flex-direction: row;
    font-family: var(--themeFontFamilyTitle), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    gap: 1.25rem;
    text-align: initial;
  }
}
@media screen and (min-width: 48.0625em) {
  .page-16 .gw-grid--asymmetric .gw-product-specs__item,
.page-8 .gw-grid--asymmetric .gw-product-specs__item,
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__item {
    flex: 1;
    flex-direction: column;
    font-size: 1.25rem;
    gap: 0;
    text-align: center;
  }
}
@media screen {
  .page-16 .gw-grid--asymmetric .gw-product-specs__item .gw-icon,
.page-8 .gw-grid--asymmetric .gw-product-specs__item .gw-icon,
.page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__item .gw-icon {
    fill: rgb(var(--themeColorTextSecondary));
  }
}
@media screen {
  .page-16 .gw-grid--asymmetric .gw-contact-card,
.page-8 .gw-grid--asymmetric .gw-contact-card,
.page-default-inventorydetail .gw-grid--asymmetric .gw-contact-card {
    border: 0.0625rem solid rgb(var(--themeGrey3));
  }
  .page-16 .gw-grid--asymmetric .gw-contact-card:hover:not(:disabled),
.page-8 .gw-grid--asymmetric .gw-contact-card:hover:not(:disabled),
.page-default-inventorydetail .gw-grid--asymmetric .gw-contact-card:hover:not(:disabled) {
    border: 0.0625rem solid rgb(var(--themeGrey3));
  }
}
@media screen {
  .page-16 .gw-grid--asymmetric .gw-cms[aria-labelledby=about-us] + div,
.page-8 .gw-grid--asymmetric .gw-cms[aria-labelledby=about-us] + div,
.page-default-inventorydetail .gw-grid--asymmetric .gw-cms[aria-labelledby=about-us] + div {
    padding: 0;
  }
}
@media screen {
  .page-16 .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options,
.page-8 .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options,
.page-default-inventorydetail .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    margin-block: 2.5rem;
  }
  .page-16 .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options .gw-button--toggle.is-selected,
.page-8 .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options .gw-button--toggle.is-selected,
.page-default-inventorydetail .gw-grid--asymmetric .gw-cms.gw-payment-options .gw-button-group--toggle__payment-options .gw-button--toggle.is-selected {
    background-color: transparent;
    border-color: rgb(var(--themeColorBrandAlt));
    box-shadow: none;
    color: rgb(var(--themeColorText));
  }
}
@media screen {
  .page-14 .gw-cms > div,
.page-17 .gw-cms > div,
.page-default-inventorylist .gw-cms > div {
    text-align: center;
  }
  [dir="ltr"] .page-14 .gw-cms > div > :not([class]),[dir="ltr"] 
.page-17 .gw-cms > div > :not([class]),[dir="ltr"] 
.page-default-inventorylist .gw-cms > div > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .page-14 .gw-cms > div > :not([class]),[dir="rtl"] 
.page-17 .gw-cms > div > :not([class]),[dir="rtl"] 
.page-default-inventorylist .gw-cms > div > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .page-14 .gw-cms > div > :not([class]),[dir="ltr"] 
.page-17 .gw-cms > div > :not([class]),[dir="ltr"] 
.page-default-inventorylist .gw-cms > div > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .page-14 .gw-cms > div > :not([class]),[dir="rtl"] 
.page-17 .gw-cms > div > :not([class]),[dir="rtl"] 
.page-default-inventorylist .gw-cms > div > :not([class]){
            margin-right: auto;
  }
  .page-14 .gw-cms > div > :not([class]),
.page-17 .gw-cms > div > :not([class]),
.page-default-inventorylist .gw-cms > div > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
  [dir="ltr"] .page-14 .gw-layout__inner--stock,[dir="ltr"] 
.page-17 .gw-layout__inner--stock,[dir="ltr"] 
.page-default-inventorylist .gw-layout__inner--stock{
            margin-right: -1.875rem;
  }
  [dir="rtl"] .page-14 .gw-layout__inner--stock,[dir="rtl"] 
.page-17 .gw-layout__inner--stock,[dir="rtl"] 
.page-default-inventorylist .gw-layout__inner--stock{
            margin-left: -1.875rem;
  }
  [dir="ltr"] .page-14 .gw-layout__inner--stock,[dir="ltr"] 
.page-17 .gw-layout__inner--stock,[dir="ltr"] 
.page-default-inventorylist .gw-layout__inner--stock{
            margin-left: -1.875rem;
  }
  [dir="rtl"] .page-14 .gw-layout__inner--stock,[dir="rtl"] 
.page-17 .gw-layout__inner--stock,[dir="rtl"] 
.page-default-inventorylist .gw-layout__inner--stock{
            margin-right: -1.875rem;
  }
  .page-14 .gw-layout__inner--stock,
.page-17 .gw-layout__inner--stock,
.page-default-inventorylist .gw-layout__inner--stock {
    display: flex;
    flex-flow: column wrap;
    -webkit-margin-end: -1.875rem;
            margin-inline-end: -1.875rem;
            margin-inline-start: -1.875rem;
    -webkit-margin-start: -1.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .page-14 .gw-layout__inner--stock,[dir="ltr"] 
.page-17 .gw-layout__inner--stock,[dir="ltr"] 
.page-default-inventorylist .gw-layout__inner--stock{
            margin-right: -5rem;
  }
  [dir="rtl"] .page-14 .gw-layout__inner--stock,[dir="rtl"] 
.page-17 .gw-layout__inner--stock,[dir="rtl"] 
.page-default-inventorylist .gw-layout__inner--stock{
            margin-left: -5rem;
  }
  [dir="ltr"] .page-14 .gw-layout__inner--stock,[dir="ltr"] 
.page-17 .gw-layout__inner--stock,[dir="ltr"] 
.page-default-inventorylist .gw-layout__inner--stock{
            margin-left: -5rem;
  }
  [dir="rtl"] .page-14 .gw-layout__inner--stock,[dir="rtl"] 
.page-17 .gw-layout__inner--stock,[dir="rtl"] 
.page-default-inventorylist .gw-layout__inner--stock{
            margin-right: -5rem;
  }
  .page-14 .gw-layout__inner--stock,
.page-17 .gw-layout__inner--stock,
.page-default-inventorylist .gw-layout__inner--stock {
    display: flex;
    flex-flow: row wrap;
    -webkit-margin-end: -5rem;
            margin-inline-end: -5rem;
            margin-inline-start: -5rem;
    -webkit-margin-start: -5rem;
  }
}
@media screen {
  .page-14 .gw-layout__inner--stock > section,
.page-17 .gw-layout__inner--stock > section,
.page-default-inventorylist .gw-layout__inner--stock > section {
    flex: 1 1 auto;
  }
}
@media screen {
  .page-14 .gw-product-card:hover .gw-card__title a,
.page-17 .gw-product-card:hover .gw-card__title a,
.page-default-inventorylist .gw-product-card:hover .gw-card__title a {
    color: rgb(var(--themeColorBrand));
  }
  .page-14 .gw-product-card .gw-card__title span,
.page-17 .gw-product-card .gw-card__title span,
.page-default-inventorylist .gw-product-card .gw-card__title span {
    -webkit-margin-before: 1.25rem;
            margin-top: 1.25rem;
  }
}
@media screen {
  .page-19 .gw-cms,
.page-default-newslist .gw-cms {
    text-align: center;
  }
  [dir="ltr"] .page-19 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-newslist .gw-cms > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .page-19 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-newslist .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .page-19 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-newslist .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .page-19 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-newslist .gw-cms > :not([class]){
            margin-right: auto;
  }
  .page-19 .gw-cms > :not([class]),
.page-default-newslist .gw-cms > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
  .page-19 .gw-layout__inner > .gw-layout__has-max-width:first-of-type,
.page-19 .gw-layout__inner > .gw-cms:first-of-type,
.page-default-newslist .gw-layout__inner > .gw-layout__has-max-width:first-of-type,
.page-default-newslist .gw-layout__inner > .gw-cms:first-of-type {
    -webkit-margin-before: 3.75rem;
            margin-top: 3.75rem;
  }
}
@media screen {
  .page-20 .gw-layout__fullbleed,
.page-default-offerdetail .gw-layout__fullbleed {
    background-color: rgb(var(--themeWhite));
  }
  .page-20 .gw-layout__fullbleed + .gw-layout__has-max-width,
.page-default-offerdetail .gw-layout__fullbleed + .gw-layout__has-max-width {
    -webkit-margin-before: 3.75rem;
            margin-top: 3.75rem;
  }
  .page-20 .gw-toolbar--promotions,
.page-default-offerdetail .gw-toolbar--promotions {
    padding: 0 1.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  .page-20 .gw-toolbar--promotions,
.page-default-offerdetail .gw-toolbar--promotions {
    padding: 0 5rem;
  }
}
@media screen and (min-width: 64em) {
  .page-20 .gw-toolbar--promotions,
.page-default-offerdetail .gw-toolbar--promotions {
    margin: 0 auto;
    max-width: var(--themeMaxContentWidth);
    padding: 0;
  }
}
@media screen {
  .page-20 .gw-toolbar--promotions .gw-share__button,
.page-default-offerdetail .gw-toolbar--promotions .gw-share__button {
    margin-left: -1.25rem;
  }
}
@media screen {
  .page-20 .gw-banner .gw-cms__content-image,
.page-default-offerdetail .gw-banner .gw-cms__content-image {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  [dir="ltr"] .page-20 .gw-banner__title,[dir="ltr"] 
.page-default-offerdetail .gw-banner__title{
            padding-right: 1.25rem;
  }
  [dir="rtl"] .page-20 .gw-banner__title,[dir="rtl"] 
.page-default-offerdetail .gw-banner__title{
            padding-left: 1.25rem;
  }
  [dir="ltr"] .page-20 .gw-banner__title,[dir="ltr"] 
.page-default-offerdetail .gw-banner__title{
            padding-left: 1.25rem;
  }
  [dir="rtl"] .page-20 .gw-banner__title,[dir="rtl"] 
.page-default-offerdetail .gw-banner__title{
            padding-right: 1.25rem;
  }
  .page-20 .gw-banner__title,
.page-default-offerdetail .gw-banner__title {
    -webkit-padding-end: 1.25rem;
            padding-inline-end: 1.25rem;
            padding-inline-start: 1.25rem;
    -webkit-padding-start: 1.25rem;
  }
}
@media screen {
  .page-21 .gw-cms,
.page-default-offerlist .gw-cms {
    text-align: center;
  }
  [dir="ltr"] .page-21 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-offerlist .gw-cms > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .page-21 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-offerlist .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .page-21 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-offerlist .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .page-21 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-offerlist .gw-cms > :not([class]){
            margin-right: auto;
  }
  .page-21 .gw-cms > :not([class]),
.page-default-offerlist .gw-cms > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
  .page-21 .gw-layout__inner > .gw-cms:first-of-type,
.page-default-offerlist .gw-layout__inner > .gw-cms:first-of-type {
    -webkit-margin-before: 3.75rem;
            margin-top: 3.75rem;
  }
}
@media screen {
  .page-22 [class*=gw-cms],
.page-22 [aria-labelledby=department-contact-details],
.page-default-contactus [class*=gw-cms],
.page-default-contactus [aria-labelledby=department-contact-details] {
    margin: 0 auto;
    max-width: var(--themeMaxContentWidth);
    text-align: center;
  }
  [dir="ltr"] .page-22 [class*=gw-cms] > :not([class]),[dir="ltr"] 
.page-22 [aria-labelledby=department-contact-details] > :not([class]),[dir="ltr"] 
.page-default-contactus [class*=gw-cms] > :not([class]),[dir="ltr"] 
.page-default-contactus [aria-labelledby=department-contact-details] > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .page-22 [class*=gw-cms] > :not([class]),[dir="rtl"] 
.page-22 [aria-labelledby=department-contact-details] > :not([class]),[dir="rtl"] 
.page-default-contactus [class*=gw-cms] > :not([class]),[dir="rtl"] 
.page-default-contactus [aria-labelledby=department-contact-details] > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .page-22 [class*=gw-cms] > :not([class]),[dir="ltr"] 
.page-22 [aria-labelledby=department-contact-details] > :not([class]),[dir="ltr"] 
.page-default-contactus [class*=gw-cms] > :not([class]),[dir="ltr"] 
.page-default-contactus [aria-labelledby=department-contact-details] > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .page-22 [class*=gw-cms] > :not([class]),[dir="rtl"] 
.page-22 [aria-labelledby=department-contact-details] > :not([class]),[dir="rtl"] 
.page-default-contactus [class*=gw-cms] > :not([class]),[dir="rtl"] 
.page-default-contactus [aria-labelledby=department-contact-details] > :not([class]){
            margin-right: auto;
  }
  .page-22 [class*=gw-cms] > :not([class]),
.page-22 [aria-labelledby=department-contact-details] > :not([class]),
.page-default-contactus [class*=gw-cms] > :not([class]),
.page-default-contactus [aria-labelledby=department-contact-details] > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
  .page-22 [class*=gw-cms] .gw-accordion__content > div:not([class]),
.page-22 [aria-labelledby=department-contact-details] .gw-accordion__content > div:not([class]),
.page-default-contactus [class*=gw-cms] .gw-accordion__content > div:not([class]),
.page-default-contactus [aria-labelledby=department-contact-details] .gw-accordion__content > div:not([class]) {
    text-align: center;
  }
  [dir="ltr"] .page-22 [class*=gw-cms] .gw-accordion__content > div:not([class]) > :not([class]),[dir="ltr"] 
.page-22 [aria-labelledby=department-contact-details] .gw-accordion__content > div:not([class]) > :not([class]),[dir="ltr"] 
.page-default-contactus [class*=gw-cms] .gw-accordion__content > div:not([class]) > :not([class]),[dir="ltr"] 
.page-default-contactus [aria-labelledby=department-contact-details] .gw-accordion__content > div:not([class]) > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .page-22 [class*=gw-cms] .gw-accordion__content > div:not([class]) > :not([class]),[dir="rtl"] 
.page-22 [aria-labelledby=department-contact-details] .gw-accordion__content > div:not([class]) > :not([class]),[dir="rtl"] 
.page-default-contactus [class*=gw-cms] .gw-accordion__content > div:not([class]) > :not([class]),[dir="rtl"] 
.page-default-contactus [aria-labelledby=department-contact-details] .gw-accordion__content > div:not([class]) > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .page-22 [class*=gw-cms] .gw-accordion__content > div:not([class]) > :not([class]),[dir="ltr"] 
.page-22 [aria-labelledby=department-contact-details] .gw-accordion__content > div:not([class]) > :not([class]),[dir="ltr"] 
.page-default-contactus [class*=gw-cms] .gw-accordion__content > div:not([class]) > :not([class]),[dir="ltr"] 
.page-default-contactus [aria-labelledby=department-contact-details] .gw-accordion__content > div:not([class]) > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .page-22 [class*=gw-cms] .gw-accordion__content > div:not([class]) > :not([class]),[dir="rtl"] 
.page-22 [aria-labelledby=department-contact-details] .gw-accordion__content > div:not([class]) > :not([class]),[dir="rtl"] 
.page-default-contactus [class*=gw-cms] .gw-accordion__content > div:not([class]) > :not([class]),[dir="rtl"] 
.page-default-contactus [aria-labelledby=department-contact-details] .gw-accordion__content > div:not([class]) > :not([class]){
            margin-right: auto;
  }
  .page-22 [class*=gw-cms] .gw-accordion__content > div:not([class]) > :not([class]),
.page-22 [aria-labelledby=department-contact-details] .gw-accordion__content > div:not([class]) > :not([class]),
.page-default-contactus [class*=gw-cms] .gw-accordion__content > div:not([class]) > :not([class]),
.page-default-contactus [aria-labelledby=department-contact-details] .gw-accordion__content > div:not([class]) > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
}
@media screen {
  .page-24,
.page-default-branchlocator {
    /* stylelint-disable order/order */
    /* stylelint-enable order/order */
  }
  .page-24 [aria-labelledby=branch-contact-details],
.page-default-branchlocator [aria-labelledby=branch-contact-details] {
    margin: 0 auto;
    max-width: var(--themeMaxContentWidth);
    text-align: center;
  }
  [dir="ltr"] .page-24 [aria-labelledby=branch-contact-details] > :not([class]),[dir="ltr"] 
.page-default-branchlocator [aria-labelledby=branch-contact-details] > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .page-24 [aria-labelledby=branch-contact-details] > :not([class]),[dir="rtl"] 
.page-default-branchlocator [aria-labelledby=branch-contact-details] > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .page-24 [aria-labelledby=branch-contact-details] > :not([class]),[dir="ltr"] 
.page-default-branchlocator [aria-labelledby=branch-contact-details] > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .page-24 [aria-labelledby=branch-contact-details] > :not([class]),[dir="rtl"] 
.page-default-branchlocator [aria-labelledby=branch-contact-details] > :not([class]){
            margin-right: auto;
  }
  .page-24 [aria-labelledby=branch-contact-details] > :not([class]),
.page-default-branchlocator [aria-labelledby=branch-contact-details] > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
  [dir="ltr"] .page-24 [aria-labelledby=branch-contact-details] .gw-contact-card,[dir="ltr"] 
.page-default-branchlocator [aria-labelledby=branch-contact-details] .gw-contact-card{
    text-align: left;
  }
  [dir="rtl"] .page-24 [aria-labelledby=branch-contact-details] .gw-contact-card,[dir="rtl"] 
.page-default-branchlocator [aria-labelledby=branch-contact-details] .gw-contact-card{
    text-align: right;
  }
  .page-24 [aria-labelledby=branch-contact-details] .gw-contact-card,
.page-default-branchlocator [aria-labelledby=branch-contact-details] .gw-contact-card {
    text-align: start;
  }
  .page-24 .gw-cms,
.page-default-branchlocator .gw-cms {
    text-align: center;
  }
  [dir="ltr"] .page-24 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-branchlocator .gw-cms > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .page-24 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-branchlocator .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .page-24 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-branchlocator .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .page-24 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-branchlocator .gw-cms > :not([class]){
            margin-right: auto;
  }
  .page-24 .gw-cms > :not([class]),
.page-default-branchlocator .gw-cms > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
  .page-24 .gw-map,
.page-default-branchlocator .gw-map {
    height: 100%;
  }
  @supports (aspect-ratio: auto) {
    .page-24 .gw-map,
.page-default-branchlocator .gw-map {
      height: unset;
    }
  }
  .page-24 .gw-map__wrap,
.page-default-branchlocator .gw-map__wrap {
    background: none;
  }
}
@media screen {
  .page-900 .gw-product-specs,
.page-default-productdetail .gw-product-specs {
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  .page-900 .gw-product-specs__item,
.page-default-productdetail .gw-product-specs__item {
    align-items: center;
    background-color: transparent;
    color: rgb(var(--themeColorBrand));
    flex: initial;
    flex-basis: auto;
  }
}
@media screen and (min-width: 48.0625em) {
  .page-900 .gw-product-specs__item,
.page-default-productdetail .gw-product-specs__item {
    color: rgb(var(--themeColorTextAlt));
  }
}
@media screen {
  .page-900 .gw-product-specs__item .gw-icon,
.page-default-productdetail .gw-product-specs__item .gw-icon {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .page-900 .gw-product-specs__item span:first-of-type,
.page-default-productdetail .gw-product-specs__item span:first-of-type {
    -webkit-margin-before: 0.625rem;
            margin-top: 0.625rem;
  }
}
@media screen {
  .page-900 .gw-product-specs__list,
.page-default-productdetail .gw-product-specs__list {
    background-color: rgb(var(--themeWhite));
    justify-content: center;
  }
}
@media screen and (min-width: 48.0625em) {
  .page-900 .gw-product-specs__list,
.page-default-productdetail .gw-product-specs__list {
    background-color: rgb(var(--themeColorBrand));
  }
}
@media screen {
  .page-900 .gw-product-specs__list .gw-product-specs__item:not(.gw-product-specs__action),
.page-default-productdetail .gw-product-specs__list .gw-product-specs__item:not(.gw-product-specs__action) {
    min-width: 12.5rem;
  }
}
@media screen {
  .page-900 .gw-product-specs__actions,
.page-default-productdetail .gw-product-specs__actions {
    width: 100%;
  }
  .page-900 .gw-product-specs__actions__list,
.page-default-productdetail .gw-product-specs__actions__list {
    background-color: transparent;
    justify-content: center;
  }
}
@media screen {
  .page-900 .gw-product-specs__action,
.page-default-productdetail .gw-product-specs__action {
    margin: 0.75rem;
  }
  .page-900 .gw-product-specs__action .gw-button[class*="--text"].gw-button--primary,
.page-default-productdetail .gw-product-specs__action .gw-button[class*="--text"].gw-button--primary {
    /* stylelint-disable-next-line max-nesting-depth */
    background-color: rgb(var(--themeColorBrand));
    border-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorTextAlt));
  }
}
@media screen and (min-width: 48.0625em) {
  .page-900 .gw-product-specs__action .gw-button[class*="--text"].gw-button--primary,
.page-default-productdetail .gw-product-specs__action .gw-button[class*="--text"].gw-button--primary {
    background-color: rgb(var(--themeWhite));
    border-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorBrand));
  }
}
@media screen {
  .page-900 .gw-product-specs__action span:first-of-type,
.page-default-productdetail .gw-product-specs__action span:first-of-type {
    -webkit-margin-before: 0;
            margin-top: 0;
  }
}
@media screen {
  .page-900 .gw-product-specs__price,
.page-default-productdetail .gw-product-specs__price {
    align-items: flex-start;
  }
  .page-900 .gw-product-specs__price--mobile,
.page-default-productdetail .gw-product-specs__price--mobile {
    background-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorTextAlt));
  }
  .page-900 .gw-product-specs__price--large,
.page-default-productdetail .gw-product-specs__price--large {
    background-color: rgba(var(--themeColorBrand), 0.5);
  }
}
@media screen {
  [dir="ltr"] .page-900 [aria-labelledby=product-variants],[dir="ltr"] 
.page-default-productdetail [aria-labelledby=product-variants]{
            padding-right: 1.875rem;
  }
  [dir="rtl"] .page-900 [aria-labelledby=product-variants],[dir="rtl"] 
.page-default-productdetail [aria-labelledby=product-variants]{
            padding-left: 1.875rem;
  }
  [dir="ltr"] .page-900 [aria-labelledby=product-variants],[dir="ltr"] 
.page-default-productdetail [aria-labelledby=product-variants]{
            padding-left: 1.875rem;
  }
  [dir="rtl"] .page-900 [aria-labelledby=product-variants],[dir="rtl"] 
.page-default-productdetail [aria-labelledby=product-variants]{
            padding-right: 1.875rem;
  }
  .page-900 [aria-labelledby=product-variants],
.page-default-productdetail [aria-labelledby=product-variants] {
    -webkit-padding-end: 1.875rem;
            padding-inline-end: 1.875rem;
            padding-inline-start: 1.875rem;
    -webkit-padding-start: 1.875rem;
  }
}
@media screen and (min-width: 48.0625em) {
  [dir="ltr"] .page-900 [aria-labelledby=product-variants],[dir="ltr"] 
.page-default-productdetail [aria-labelledby=product-variants]{
            padding-right: 5rem;
  }
  [dir="rtl"] .page-900 [aria-labelledby=product-variants],[dir="rtl"] 
.page-default-productdetail [aria-labelledby=product-variants]{
            padding-left: 5rem;
  }
  [dir="ltr"] .page-900 [aria-labelledby=product-variants],[dir="ltr"] 
.page-default-productdetail [aria-labelledby=product-variants]{
            padding-left: 5rem;
  }
  [dir="rtl"] .page-900 [aria-labelledby=product-variants],[dir="rtl"] 
.page-default-productdetail [aria-labelledby=product-variants]{
            padding-right: 5rem;
  }
  .page-900 [aria-labelledby=product-variants],
.page-default-productdetail [aria-labelledby=product-variants] {
    -webkit-padding-end: 5rem;
            padding-inline-end: 5rem;
            padding-inline-start: 5rem;
    -webkit-padding-start: 5rem;
  }
}
@media screen {
  .page-900 [id=product-variants],
.page-900 [id=product-gallery-title],
.page-900 .gw-product-features > h2,
.page-default-productdetail [id=product-variants],
.page-default-productdetail [id=product-gallery-title],
.page-default-productdetail .gw-product-features > h2 {
    text-align: center;
  }
}
@media screen {
  .page-900 .gw-cms,
.page-default-productdetail .gw-cms {
    text-align: center;
  }
  [dir="ltr"] .page-900 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-productdetail .gw-cms > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .page-900 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-productdetail .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .page-900 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-productdetail .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .page-900 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-productdetail .gw-cms > :not([class]){
            margin-right: auto;
  }
  .page-900 .gw-cms > :not([class]),
.page-default-productdetail .gw-cms > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
}
@media screen {
  .page-900 [id=dealerHtml],
.page-default-productdetail [id=dealerHtml] {
    text-align: center;
  }
  [dir="ltr"] .page-900 [id=dealerHtml] > :not([class]),[dir="ltr"] 
.page-default-productdetail [id=dealerHtml] > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .page-900 [id=dealerHtml] > :not([class]),[dir="rtl"] 
.page-default-productdetail [id=dealerHtml] > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .page-900 [id=dealerHtml] > :not([class]),[dir="ltr"] 
.page-default-productdetail [id=dealerHtml] > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .page-900 [id=dealerHtml] > :not([class]),[dir="rtl"] 
.page-default-productdetail [id=dealerHtml] > :not([class]){
            margin-right: auto;
  }
  .page-900 [id=dealerHtml] > :not([class]),
.page-default-productdetail [id=dealerHtml] > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
}
@media screen and (max-width: 48em) {
  /* stylelint-disable-next-line max-nesting-depth */
  .gw-layout__main > .gw-layout__inner {
    overflow-x: hidden;
  }
}
@media screen {
  .page-900 .gw-banner__video,
.page-default-productdetail .gw-banner__video {
    -webkit-margin-after: -0.5rem;
            margin-bottom: -0.5rem;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media screen {
  .page-900 .gw-product-specs__list,
.page-default-productdetail .gw-product-specs__list {
    background-color: rgb(var(--themeGrey5));
  }
  .page-900 .gw-product-specs__item,
.page-default-productdetail .gw-product-specs__item {
    color: rgb(var(--themeWhite));
  }
  .page-900 .gw-product-specs__item .gw-icon,
.page-default-productdetail .gw-product-specs__item .gw-icon {
    height: 2rem;
    width: 2rem;
  }
  .page-900 .gw-product-specs__actions__list,
.page-default-productdetail .gw-product-specs__actions__list {
    -webkit-padding-after: 0.75rem;
    -webkit-padding-before: 0.75rem;
            padding-bottom: 0.75rem;
            padding-top: 0.75rem;
  }
  .page-900 .gw-product-specs__action .gw-button,
.page-default-productdetail .gw-product-specs__action .gw-button {
    background-color: transparent;
    border-color: rgb(var(--themeWhite));
    box-shadow: none;
    color: rgb(var(--themeWhite));
    padding: 0.75rem 1.875rem;
  }
  .page-900 .gw-product-specs__action .gw-button.gw-button--primary,
.page-900 .gw-product-specs__action .gw-button[class*="--text"].gw-button--primary,
.page-default-productdetail .gw-product-specs__action .gw-button.gw-button--primary,
.page-default-productdetail .gw-product-specs__action .gw-button[class*="--text"].gw-button--primary {
    background-color: rgb(var(--themeColorBrandAlt));
    border-color: rgb(var(--themeColorBrandAlt));
    color: rgb(var(--themeWhite));
  }
  .page-900 .gw-product-specs__action .gw-icon,
.page-default-productdetail .gw-product-specs__action .gw-icon {
    height: 1rem;
    width: 1rem;
  }
  .page-900 .gw-product-specs__price--mobile,
.page-default-productdetail .gw-product-specs__price--mobile {
    background-color: rgb(var(--themeGrey5));
  }
  .page-900 .gw-product-specs__price--large,
.page-default-productdetail .gw-product-specs__price--large {
    background-color: transparent;
  }
  [dir="ltr"] .page-900 .gw-product-specs .gw-banner__title,[dir="ltr"] 
.page-900 .gw-product-specs .gw-product-specs__item:not(.gw-product-specs__action):first-child,[dir="ltr"] 
.page-900 .gw-product-specs [aria-labelledby=product-variants],[dir="ltr"] 
.page-default-productdetail .gw-product-specs .gw-banner__title,[dir="ltr"] 
.page-default-productdetail .gw-product-specs .gw-product-specs__item:not(.gw-product-specs__action):first-child,[dir="ltr"] 
.page-default-productdetail .gw-product-specs [aria-labelledby=product-variants]{
            padding-right: 1.25rem;
  }
  [dir="rtl"] .page-900 .gw-product-specs .gw-banner__title,[dir="rtl"] 
.page-900 .gw-product-specs .gw-product-specs__item:not(.gw-product-specs__action):first-child,[dir="rtl"] 
.page-900 .gw-product-specs [aria-labelledby=product-variants],[dir="rtl"] 
.page-default-productdetail .gw-product-specs .gw-banner__title,[dir="rtl"] 
.page-default-productdetail .gw-product-specs .gw-product-specs__item:not(.gw-product-specs__action):first-child,[dir="rtl"] 
.page-default-productdetail .gw-product-specs [aria-labelledby=product-variants]{
            padding-left: 1.25rem;
  }
  [dir="ltr"] .page-900 .gw-product-specs .gw-banner__title,[dir="ltr"] 
.page-900 .gw-product-specs .gw-product-specs__item:not(.gw-product-specs__action):first-child,[dir="ltr"] 
.page-900 .gw-product-specs [aria-labelledby=product-variants],[dir="ltr"] 
.page-default-productdetail .gw-product-specs .gw-banner__title,[dir="ltr"] 
.page-default-productdetail .gw-product-specs .gw-product-specs__item:not(.gw-product-specs__action):first-child,[dir="ltr"] 
.page-default-productdetail .gw-product-specs [aria-labelledby=product-variants]{
            padding-left: 1.25rem;
  }
  [dir="rtl"] .page-900 .gw-product-specs .gw-banner__title,[dir="rtl"] 
.page-900 .gw-product-specs .gw-product-specs__item:not(.gw-product-specs__action):first-child,[dir="rtl"] 
.page-900 .gw-product-specs [aria-labelledby=product-variants],[dir="rtl"] 
.page-default-productdetail .gw-product-specs .gw-banner__title,[dir="rtl"] 
.page-default-productdetail .gw-product-specs .gw-product-specs__item:not(.gw-product-specs__action):first-child,[dir="rtl"] 
.page-default-productdetail .gw-product-specs [aria-labelledby=product-variants]{
            padding-right: 1.25rem;
  }
  .page-900 .gw-product-specs .gw-banner__title,
.page-900 .gw-product-specs .gw-product-specs__item:not(.gw-product-specs__action):first-child,
.page-900 .gw-product-specs [aria-labelledby=product-variants],
.page-default-productdetail .gw-product-specs .gw-banner__title,
.page-default-productdetail .gw-product-specs .gw-product-specs__item:not(.gw-product-specs__action):first-child,
.page-default-productdetail .gw-product-specs [aria-labelledby=product-variants] {
    -webkit-padding-end: 1.25rem;
            padding-inline-end: 1.25rem;
            padding-inline-start: 1.25rem;
    -webkit-padding-start: 1.25rem;
  }
  .page-900 .gw-news-card .gw-toolbar,
.page-900 .gw-promotion-card .gw-toolbar,
.page-default-productdetail .gw-news-card .gw-toolbar,
.page-default-productdetail .gw-promotion-card .gw-toolbar {
    flex-direction: row;
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .page-904 .gw-cms,
.page-default-newsdetail .gw-cms {
    text-align: center;
  }
  [dir="ltr"] .page-904 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-newsdetail .gw-cms > :not([class]){
            margin-right: auto;
  }
  [dir="rtl"] .page-904 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-newsdetail .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="ltr"] .page-904 .gw-cms > :not([class]),[dir="ltr"] 
.page-default-newsdetail .gw-cms > :not([class]){
            margin-left: auto;
  }
  [dir="rtl"] .page-904 .gw-cms > :not([class]),[dir="rtl"] 
.page-default-newsdetail .gw-cms > :not([class]){
            margin-right: auto;
  }
  .page-904 .gw-cms > :not([class]),
.page-default-newsdetail .gw-cms > :not([class]) {
    -webkit-margin-end: auto;
            margin-inline-end: auto;
            margin-inline-start: auto;
    -webkit-margin-start: auto;
  }
}
@media screen {
  #cg-manage li {
    -webkit-margin-after: 0 !important;
            margin-bottom: 0 !important;
  }
  [dir="ltr"] #cg-manage input[type=checkbox]{
            margin-right: 0.75rem;
  }
  [dir="rtl"] #cg-manage input[type=checkbox]{
            margin-left: 0.75rem;
  }
  #cg-manage input[type=checkbox] {
    -webkit-margin-end: 0.75rem;
            margin-inline-end: 0.75rem;
    position: relative;
    top: -0.25rem;
    transform: scale(1.5) !important;
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
  }
  [data-whatintent=keyboard] #cg-manage input[type=checkbox]:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] #cg-manage input[type=checkbox]:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] #cg-manage input[type=checkbox]:focus {
    outline: 0;
  }

  #cg-manage input[type=checkbox]:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
  #cg-manage h3 {
    padding: 0 !important;
  }
  #cg-manage button {
    background-color: rgb(var(--themeColorBrand));
    border: 0.0625rem solid;
    border-color: rgb(var(--themeColorBrand));
    color: rgb(var(--themeColorTextAlt));
    display: inline-block;
    font-weight: bold;
    -webkit-margin-after: 1.25rem;
            margin-bottom: 1.25rem;
    padding: 0.75rem;
    text-decoration: none;
    transition: box-shadow 150ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out, outline 150ms ease-in-out, transform 150ms ease-in-out;
  }
  [data-whatintent=keyboard] #cg-manage button:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(var(--themeAccentNeutral));
    outline: 0;
  }

  [data-whatintent=touch] #cg-manage button:focus {
    background-color: rgba(var(--themeColorBrand), 0.2);
    outline: 0;
  }

  [data-whatintent=mouse] #cg-manage button:focus {
    outline: 0;
  }

  #cg-manage button:focus {
    outline: 0.25rem solid rgb(var(--themeAccentNeutral));
  }
  [data-whatintent=mouse] #cg-manage button:hover {
    opacity: 0.8;
  }

  #cg-manage button:active {
    transform: scale(0.975);
  }
  #cg-manage button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  #cg-manage button:disabled:hover {
    opacity: 0.8;
  }
  #cg-manage button:disabled:active {
    transform: none;
  }
}
@media screen {
  .seamless {
    height: 80vh;
    width: 100%;
  }
}
@media screen {
  .display-nav {
    z-index: 1000 !important;
  }
}
@media print {
  /*
  ----------------------------------------------------------------------------
  Print stylesheet
  ----------------------------------------------------------------------------
  */
  /*
  ----------------------------------------------------------------------------
  Basic reset.
  ----------------------------------------------------------------------------
  */
  /* stylelint-disable declaration-no-important */
  @page {
    margin: 10pt;
  }
  body {
    font-size: 10pt;
  }

  body:not(.page-printstock) {
    color: #000;
    font-family: sans-serif;
  }

  figure {
    margin: 0;
  }

  h1 {
    font-size: 20pt;
  }

  h2 {
    font-size: 16pt;
  }

  h3 {
    font-size: 12pt;
  }

  h4,
h5,
h6 {
    font-size: 10pt;
    margin: 0;
  }

  h1,
h2,
h3,
p {
    -webkit-margin-after: 5pt;
            margin-bottom: 5pt;
  }

  th,
td {
    border: 1pt solid grey;
    font-size: 8pt;
    padding: 3pt;
    text-align: initial;
  }

  a {
    text-decoration: underline;
  }
  a + a {
    margin: 0 10pt;
  }

  [href]:after {
    content: "(" attr(href) ")";
  }

  [href^=mailto]:after,
[href^=tel]:after,
[href^=fax]:after,
[href="/"]:after,
[href="#"]:after {
    content: "";
  }

  table,
figure,
pre {
    -webkit-margin-after: 10pt;
            margin-bottom: 10pt;
    page-break-inside: avoid;
  }

  h1,
h2,
h3,
h4,
h5,
h6 {
    page-break-after: avoid;
  }

  p {
    orphans: 3;
    widows: 3;
  }

  thead {
    display: table-header-group;
  }

  /*
  ----------------------------------------------------------------------------
  Specific component styles.
  ----------------------------------------------------------------------------
  */
  [class*=gw-grid] {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
  }
  [class*=gw-grid] > * {
    margin: 5pt;
  }

  .gw-form__field, .gw-form__fieldset {
    -webkit-margin-after: 10pt;
            margin-bottom: 10pt;
  }
  .gw-form__label span {
    display: block;
  }

  .gw-button-group {
    -webkit-margin-after: 10pt;
            margin-bottom: 10pt;
  }
  .gw-button-group .gw-button {
    display: block;
    margin: 0;
  }

  .gw-header__logo {
    font-size: 16pt;
  }
  .gw-header__logo .gw-image {
    display: block;
    max-height: 30pt;
  }

  .gw-banner__inner {
    -webkit-margin-after: 10pt;
            margin-bottom: 10pt;
  }
  .gw-banner__inner.gw-responsive-media {
    padding: 0 !important;
  }

  /*
  ----------------------------------------------------------------------------
  Hide stuff.

  Notes:
  - We might want to still show specific images?
  ----------------------------------------------------------------------------
  */
  img,
button,
.gw-icon,
.gw-skiplinks,
.gw-navigation,
.gw-header__search,
.gw-header__offers,
.gw-branch-rotator,
.gw-product-search,
.gw-footer__group-title,
.gw-footer__group-content,
.gw-footer__item:not(.gw-footer__item--copyright),
.gw-layout__sticky--footer {
    display: none;
  }

  /* stylelint-enable declaration-no-important */
}
/*
----------------------------------------------------------------------------
Finally, pages that need specific treatment for print, but still need to inherit current theme styling in the browser (e.g. stock details).
----------------------------------------------------------------------------
*/
/*
----------------------------------------------------------------------------
Simplified stylesheet for pages that need to be printed (e.g. stock details).

TODO:
- If this gets too big, we could split the code into smaller partials.
- Some of these style rules could be moved into "_print.scss".
----------------------------------------------------------------------------
*/
@media screen {
  .page-printstock .gw-layout__has-max-width {
    -webkit-margin-before: 3.75rem;
            margin-top: 3.75rem;
  }
}
@media screen and (min-width: 64em) {
  .page-printstock .gw-grid--asymmetric {
    display: grid;
    gap: 1.875rem;
    grid-template-areas: "col1 col2";
    grid-template-columns: calc(55% - 1.875rem / 2) calc(45% - 1.875rem / 2);
    -webkit-margin-after: 3.75rem;
            margin-bottom: 3.75rem;
  }
  .page-printstock .gw-grid--asymmetric > :first-child {
    grid-area: col1;
  }
  .page-printstock .gw-grid--asymmetric > :last-child {
    grid-area: col2;
  }
}
@media screen {
  .page-printstock.page-16 .gw-grid--asymmetric .gw-product-specs,
.page-printstock .page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs {
    /* stylelint-disable-next-line max-nesting-depth */
  }
  .page-printstock.page-16 .gw-grid--asymmetric .gw-product-specs__item,
.page-printstock .page-default-inventorydetail .gw-grid--asymmetric .gw-product-specs__item {
    font-size: 1rem;
  }
}
@media screen {
  .page-printstock .gw-header__logo {
    display: block;
    margin: 0 0 0.75rem;
  }
}
@media screen {
  .page-printstock .gw-product-summary {
    background-color: transparent;
  }
  .page-printstock .gw-product-summary__name > span {
    -webkit-margin-before: 0;
            margin-top: 0;
  }
  .page-printstock .gw-product-summary__price, .page-printstock .gw-product-summary__finance {
    padding: 0;
  }
}
@media screen {
  .page-printstock h2:not(:first-of-type) {
    -webkit-margin-before: 1.875rem;
            margin-top: 1.875rem;
  }
}
@media screen {
  .page-printstock .gw-grid:not(.gw-contact-card-grid),
.page-printstock .gw-table {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
}
@media screen {
  .page-printstock [aria-labelledby=branch-contact-details] {
    -webkit-margin-before: 1.875rem;
            margin-top: 1.875rem;
  }
  .page-printstock [aria-labelledby=branch-contact-details] .gw-button-group {
    -webkit-margin-after: 0.75rem;
            margin-bottom: 0.75rem;
  }
}
@media screen {
  .page-printstock [class*=card-grid].gw-grid {
    justify-content: start;
  }
}
@media print {
  .page-printstock .gw-product-summary__price,
.page-printstock .gw-product-summary__finance {
    font-size: 15pt;
    font-weight: bold;
    line-height: 1.3;
  }
  .page-printstock .gw-product-summary__price {
    -webkit-margin-after: 0;
            margin-bottom: 0;
  }
  .page-printstock .gw-product-summary__price--extra,
.page-printstock .gw-product-summary__smallprint {
    font-size: 8pt;
  }
  [dir="ltr"] .page-printstock .gw-grid--asymmetric > :first-child{
            margin-left: -5pt;
  }
  [dir="rtl"] .page-printstock .gw-grid--asymmetric > :first-child{
            margin-right: -5pt;
  }
  .page-printstock .gw-grid--asymmetric > :first-child {
            margin-inline-start: -5pt;
    -webkit-margin-start: -5pt;
    max-width: 200pt;
  }
  .page-printstock .gw-grid--asymmetric > :last-child {
    max-width: 350pt;
  }
  .page-printstock .gw-grid--asymmetric img {
    display: block;
  }
  .page-printstock .gw-grid--asymmetric .gw-icon {
    display: block;
    height: 1.5em;
    width: 1.5em;
  }
  .page-printstock .gw-product-specs__list {
    display: flex;
    flex-wrap: wrap;
    font-size: 8pt;
    -webkit-margin-before: 5pt;
            margin-top: 5pt;
  }
  [dir="ltr"] .page-printstock .gw-product-specs__list > *{
            margin-right: 5pt;
  }
  [dir="rtl"] .page-printstock .gw-product-specs__list > *{
            margin-left: 5pt;
  }
  .page-printstock .gw-product-specs__list > * {
    align-items: center;
    display: flex;
    flex-direction: column;
    -webkit-margin-after: 5pt;
            margin-bottom: 5pt;
    -webkit-margin-end: 5pt;
            margin-inline-end: 5pt;
  }
  .page-printstock .gw-product-specs__list > * span:first-of-type {
    display: none;
  }
  .page-printstock .gw-grid .gw-table {
    -webkit-margin-after: 10pt;
            margin-bottom: 10pt;
    width: calc(50% - 10pt);
  }
}
/*# sourceMappingURL=index.be96de82.css.map */
