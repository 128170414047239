/*
----------------------------------------------------------------------------
shame.css

- Temporary hacks & one-off fixes to help us get stuff done!
- See https://csswizardry.com/2013/04/shame-css/.
----------------------------------------------------------------------------
*/

/* stylelint-disable declaration-no-important, selector-max-id */

/*
----------------------------------------------------------------------------
Cookie consent form.
- The rendered HTML is not as per our standard <form> markup as detailed in Storybook, and the HTML is added directly via the CMS, so is not easy to update across 100s of websites.
- There is also an inline '<style scoped>' block, so the CSS below overrides these styles to improve the form's UI consistency & accessibility.
----------------------------------------------------------------------------
*/

#cg-manage {
    li {
        margin-block-end: 0 !important;
    }

    input[type='checkbox'] {
        @include focus-default($opacity: false, $transform: false);
        margin-inline-end: $gutter-xs;
        position: relative;
        top: -0.25rem;
        transform: scale(1.5) !important;
    }

    h3 {
        padding: 0 !important;
    }

    button {
        @include button-default;
        background-color: $color-brand;
        border: $border-width $border-style;
        border-color: $color-brand;
        color: $color-text-alt;
        font-weight: bold;
        margin-block-end: $gutter-s;
        padding: $gutter-xs;
    }
}

/*
----------------------------------------------------------------------------
iframes.
- The 'seamless' class for iframe is a legacy class that is added via the CMS currently. This will be fixed properly when the time allows. This is to allow the iframe to display full width and have a manually set height with no border.
----------------------------------------------------------------------------
*/

.seamless {
    height: 80vh;
    width: 100%;
}

/*
----------------------------------------------------------------------------
Navigation.
- We have added a class to override the z-index issue on the navigation dropdowns when a modal is on the page. This class is added via JS when a dropdown is hovered over or when the mobile menu is interacted with by the user.
----------------------------------------------------------------------------
*/

.display-nav {
    z-index: z('modal') !important;
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-shame) {
    @include gw-shame;
}
