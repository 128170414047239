.gw-modal {
    @at-root {
        // Modal content (in-page) is hidden until Ajax-fetched by opening the modal.
        [data-modal-ajax-content] {
            display: none;

            .gw-modal__ajax & {
                display: block;
            }
        }
    }
    align-items: center;
    display: flex;
    flex-direction: column;
    inset-block-end: 0;
    inset-block-start: 0;
    inset-inline-end: 0;
    inset-inline-start: 0;
    justify-content: center;
    pointer-events: none;
    position: fixed;

    &__overlay {
        background-color: #{rgba(var(--themeColorBackgroundAlt), 0.8)};
        inset-block-end: 0;
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-inline-start: 0;
        position: fixed;
        z-index: z('modal');

        &.gw-modal__overlay--full-width {
            background-color: transparent;
        }
    }

    &__inner {
        background-color: $color-background;
        max-height: 80%;
        overflow-y: auto;
        padding: $gutter-xl $gutter $gutter;
        pointer-events: all;
        position: relative;
        width: 80%;

        > [data-modal-close] {
            inset-block-start: math.div($gutter-xs, 2);
            inset-inline-end: math.div($gutter-xs, 2);
            position: absolute;
        }
    }

    &--full-width {
        inset-block-start: auto;
        z-index: z('modal');

        .gw-modal__inner {
            background-color: $color-brand;
            color: $color-text-alt;
            padding: $gutter-xl $gutter $gutter;
            width: 100%;

            a {
                color: $color-text-alt;
            }

            p {
                max-width: 100%;
            }
        }

        .gw-modal__title {
            color: $color-text-alt;

            &:focus-visible {
                outline: none;
            }
        }
    }

    &--full-width__video {
        .gw-button-group {
            margin-block-end: 0;
        }

        .gw-grid--asymmetric {
            gap: 0;
            margin-block-end: 0;
        }

        .gw-modal__inner {
            @include bp-large {
                max-height: unset;
            }
            max-height: 60vh;
        }
    }

    &--floating-button {
        + .gw-button--floating--modal--container {
            .gw-button--floating--modal.gw-button[class*='--text'] {
                background-color: $color-brand;

                /* stylelint-disable max-nesting-depth */
                & .gw-icon {
                    margin-inline-start: $gutter-xs;
                }

                & span {
                    height: unset;
                    opacity: 1;
                    width: unset;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-modal) {
    @include gw-modal;
}
