@mixin gw-sticky-footer {
    .gw-sticky-footer {
        background-color: $color-grey-5;

        &__button {
            background-color: $color-grey-5;
            border: 0;
            border-inline-end: $border-width $border-style $color-grey-4;
            border-radius: 0;
            color: $color-white;
            font-family: $font-family-semibold;
            padding: $gutter-s;

            // Bully CSS specificity.
            &[class*='--text'] {
                color: $color-white;
                padding-inline-end: 0;
                padding-inline-start: 0;
                font-family: $font-family-semibold;
            }

            .gw-button__inner {
                justify-content: center;
            }

            &:hover {
                background-color: $color-link-hover;

                // Need some extra specificity to override 'button--text' styles.
                &[class*='--text']:not(:disabled) {
                    color: $color-white;
                    text-decoration: none;
                }
            }

            [data-whatintent='mouse'] &:hover {
                opacity: 1;
            }

            .gw-icon {
                @include bp-medium {
                    height: $icon-size-s;
                    width: $icon-size-s;
                }
            }
        }

        &__item:last-child {
            .gw-sticky-footer__button {
                border-inline-end: 0;
            }
        }
    }
}
