.gw-layout__sticky--footer--multi-franchise {
    /* stylelint-disable max-nesting-depth */
    background-color: $color-sticky-footer-background;
    padding: $gutter-s;

    .gw-sticky-footer__item {
        @include bp-medium {
            /* stylelint-disable selector-max-id */
            .gw-button--text-icon:not(#back-to-top) {
                .gw-button__inner .gw-icon {
                    display: none;
                    margin-inline-end: $gutter-xs;

                    & + span {
                        height: auto;
                        overflow: initial;
                        position: initial;
                        width: max-content;
                    }
                }
            }
            /* stylelint-enable selector-max-id */
        }

        flex-grow: 0;
        margin-inline-end: $gutter-s;
        min-height: 0;

        .gw-button__inner .gw-icon {
            margin: 0;

            & + span {
                @include visually-hide;
            }
        }

        &:last-child {
            margin-inline-start: auto;
        }

        /* stylelint-disable selector-max-id */
        #back-to-top {
            align-self: end;
            border-radius: $border-radius-round;
        }
        /* stylelint-enable selector-max-id */
    }
    /* stylelint-enable max-nesting-depth */
}

@if mixin-exists(gw-sticky-footer--multi-franchise) {
    @include gw-sticky-footer--multi-franchise;
}
