$video-button-size: 4rem;

.gw-video-player {
    width: fit-content;

    > a {
        display: block;
        position: relative;

        [data-whatintent='keyboard'] &:focus {
            box-shadow: 0 0 0 0.25rem $color-neutral;
        }
    }

    &__icon {
        background-color: rgba(var(--themeColorBrand), 0.4);
        border: 1rem solid transparent;
        border-radius: 50%;
        color: rgb(var(--themeColorTextAlt));
        height: 4rem;
        inset-block-start: calc(50% - 2rem);
        inset-inline-start: calc(50% - 2rem);
        position: absolute;
        width: 4rem;
    }

    // Style video modal just like gallery modal
    &__modal.gw-modal__overlay {
        .gw-modal__inner {
            background-color: $color-background-alt;
            max-height: 98%;
            max-width: 95%;
            padding-block-end: 0;
            padding-block-start: $gutter-xl;
        }

        [data-modal-close] {
            background-color: unset;
            color: $color-text-alt;
            margin-inline-end: $gutter-xs;
            z-index: z('default');
        }

        // <br> selector required because CMS editor can add random line breaks into HTML!
        .gw-responsive-media {
            br {
                display: none;
            }
        }
    }

    &--youtube-embed {
        @include bp-medium {
            height: 36rem;
        }

        height: 55vw;
        max-width: 60rem;
        position: relative;
        width: 100%;

        &:hover {
            .gw-icon {
                opacity: 0.8;
            }
        }

        &__cover-image,
        iframe {
            height: 100%;
            inset-block-start: 0;
            inset-inline-start: 0;
            position: absolute;
            width: 100%;
        }

        &__cover-image {
            opacity: 1;

            // stylelint-disable-next-line no-descending-specificity
            .gw-icon {
                // stylelint-disable-next-line max-nesting-depth
                @include bp-medium {
                    height: initial;
                    width: initial;
                }

                fill: none;
                height: 5rem;
                inset-block-start: 50%;
                inset-inline-start: 50%;
                position: absolute;
                transform: translateX(-50%) translateY(-50%);
                width: 5rem;
            }

            .gw-image {
                height: 100%;
                width: 100%;
            }
        }

        iframe {
            opacity: 0;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-video-player) {
    @include gw-video-player;
}
