@mixin gw-news-card {
    .gw-news-card {
        background-color: $color-white;
        border: $border-width $border-style $color-grey-2;
        border-radius: $border-radius-medium-small;
        box-shadow: none;

        &:hover {
            border: $border-width $border-style $color-grey-3;
        }

        .gw-card__media {
            .gw-image {
                border-end-end-radius: 0;
                border-end-start-radius: 0;
                border-start-end-radius: $border-radius-medium-small;
                border-start-start-radius: $border-radius-medium-small;
            }
        }

        &--block-link {
            background-color: $color-grey-1;
            color: $color-link;
            font-family: $font-family-title;
            text-decoration: none;

            .gw-card__title {
                color: $color-link;
                text-align: center;
            }
        }
    }
}
