@mixin gw-navigation {
    .gw-navigation {
        @at-root {
            .is-js-sticky & {
                padding-inline-start: 0;
            }
        }

        padding-inline-start: $gutter-xs;

        .gw-layout__sticky--header & {
            @include bp-medium {
                align-items: center;
                display: flex;
            }
        }

        &__link {
            @include bp-medium {
                font-size: $font-size;
            }

            font-size: $font-size-s;

            // Bully CSS specificity.
            &[class*='--text'] {
                @include bp-medium {
                    font-size: $font-size;
                }

                border-radius: 0;
                font-family: inherit;
                font-size: $font-size-s;
                letter-spacing: inherit;
                padding-inline-end: 0;
                padding-inline-start: 0;

                .show-mobile-nav & {
                    padding-inline-end: $gutter-xs;
                    padding-inline-start: $gutter-xs;
                }
            }
        }

        &__list {
            .gw-button[class*='--text'],
            .gw-navigation__link {
                @include bp-medium {
                    @include focus-default($keyboard-outline: true);
                }

                border-radius: $border-radius-medium-small;
                color: $color-brand;
                font-family: $font-family-semibold;

                &:hover {
                    color: $color-brand-alt;
                    opacity: unset;
                    text-decoration: none;
                }
            }

            .gw-button-group {
                background-color: transparent;

                .gw-button--primary {
                    @include button-primary;

                    border-radius: $border-radius;
                }
            }

            &--nested {
                /* stylelint-disable no-descending-specificity */
                @include bp-medium {
                    @include responsive-grid-columns(
                        $min-width: 17rem,
                        $max-width: 17rem
                    );
                }

                .gw-navigation__link {
                    color: $color-text;
                }
                /* stylelint-enable no-descending-specificity */
            }

            .gw-icon {
                @include bp-medium {
                    height: $font-size;
                    width: $font-size;
                }

                height: $font-size-s;
                width: $font-size-s;
            }

            .gw-accordion__title {
                font-size: $font-size-s;
            }
        }

        &__title {
            border-color: #{rgba(var(--themeBlack), 0.2)};
            color: $color-brand;
            font-family: $font-family-semibold;
            font-size: $font-size-m;
        }

        &__dropdown {
            background-color: $color-white;
        }

        // 4th level navigation overlay.
        &__dropdown--overlay {
            /* stylelint-disable-next-line no-descending-specificity */
            .gw-button[class*='--text'] {
                color: $color-text-alt;
            }
        }

        &__button--back {
            background-color: $color-white;
            border-radius: 0;

            // Bully CSS specificity.
            &[class*='--text'] {
                font-family: inherit;
                font-size: $font-size-s;
                letter-spacing: inherit;
                padding-inline-end: $gutter-xs;
                padding-inline-start: $gutter-xs;
            }
        }

        .show-mobile-nav {
            > .gw-navigation__list {
                background-color: $color-white;
            }
        }
    }
}
