.gw-news-card {
    @if $allow-dark-mode {
        @media (prefers-color-scheme: dark) {
            background-color: $color-grey-3;
        }
    }
    @include card-media-top;
    align-items: center;
    background-color: $color-grey-1;
    border: 0;
    padding: 0;

    // TODO: Width is needed by Safari so that aspect-ratio padding-top hack works inside <figure> which is a flex item. Ideally, we should fix the HTML so that the <a> is attached to the card title, not the image, as per product/stock cards.
    .gw-card__media {
        width: 100%;
    }

    .gw-card__content {
        margin-block-start: 0;
        order: 2;
        padding: 0 $gutter-s;
        width: 100%;
    }

    .gw-toolbar {
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-inline-end: -$gutter-s;
        margin-inline-start: -$gutter-s;

        &__item {
            padding-inline-start: $gutter-s;
        }
    }

    .gw-share__fallback {
        right: 0;
    }

    // In a grid.
    &-grid.gw-grid {
        @include responsive-grid-columns($max-width: 18.75rem);
    }

    // Block link (as used in slider).
    &--block-link {
        justify-content: center;
        padding: 0 $gutter-s;
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-news-card) {
    @include gw-news-card;
}
