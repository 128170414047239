.gw-header-card {
    padding: 1rem;

    &__upper {
        @include bp-medium {
            flex-flow: row nowrap;
        }

        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
    }

    &__lower {
        @include bp-medium {
            flex-flow: row wrap;
            margin: -12rem 0 4rem 0;
            padding: 6rem 5rem 0.5rem 5rem;
        }

        background: rgb(var(--themeColorBackgroundAlt));
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        > div {
            @include bp-medium {
                flex: 0 0 calc(50% - 2rem);
            }
            flex: 0 1 50%;
        }
    }

    &__image {
        @include bp-medium {
            max-width: 50%;
        }

        max-width: 100%;
    }

    &__sub-title {
        @include bp-medium {
            margin-block-end: 5rem;
            margin-block-start: 0;
            margin-inline-end: 50%;
        }

        flex: 0 1 100%;
        margin-block-start: 3rem;
    }
}
