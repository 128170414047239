.gw-promotion-filter {
    @if $allow-dark-mode {
        @media (prefers-color-scheme: dark) {
            background-color: $color-grey-3;
        }
    }

    @include bp-medium {
        margin-inline-end: -$layout-main-bleed;
        margin-inline-start: -$layout-main-bleed;
    }
    background-color: $color-grey-1;
    justify-content: center;
    margin-block-start: $gutter-xl;
    margin-inline-end: -$layout-main-bleed-mobile;
    margin-inline-start: -$layout-main-bleed-mobile;
    padding: $gutter-xs * 2;
    padding-block-end: $gutter-xs;
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-promotion-filter) {
    @include gw-promotion-filter;
}
