// Branch details.
.page-22,
.page-default-contactus {
    [class*='gw-cms'],
    [aria-labelledby='department-contact-details'] {
        @include layout-constraint;
        @include layout-centered-content;

        .gw-accordion__content {
            > div:not([class]) {
                @include layout-centered-content;
            }
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(page-22-branch-details) {
    @include page-22-branch-details;
}
