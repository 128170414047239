.gw-responsive-media {
    @at-root {
        .gw-banner &,
        .gw-card &,
        [class*='gw-grid'] & {
            margin-block-end: 0;
        }
    }
    @include responsive-media;
    margin-block-end: $gutter-xl;

    &--productvideo {
        @include bp-small {
            background-color: $color-white;
            margin-inline-end: -$layout-main-bleed;
            margin-inline-start: -$layout-main-bleed;
            padding: $gutter-xl;
        }

        padding: $gutter-xl;
        /* TODO: Remove this once we find a fix for padding-top: unset */
        /* stylelint-disable-next-line declaration-no-important */
        padding-block-start: $gutter-xl !important;
        text-align: center;

        iframe {
            max-width: $layout-max-width;
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-responsive-media) {
    @include gw-image;
}
