.gw-cta-card {
    /* stylelint-disable max-nesting-depth */
    @include bp-medium {
        padding: $gutter-xl;

        &__content {
            width: 50%;
        }
    }

    background-color: $color-brand;
    overflow: hidden;
    padding: $gutter-xl $gutter;
    position: relative;
    width: 100%;

    &__background-img {
        @include bp-medium {
            inset-block-start: 0;
            transform: none;
            width: auto;
        }

        inset-block-end: 0;
        inset-inline-end: 0;
        position: absolute;
        transform: translateX(33%);
        width: 33%;
    }

    &__button.gw-button[class*='--text'].gw-button--primary {
        @include bp-medium {
            margin-block-start: 0;
        }

        background-color: $color-grey-1;
        border-color: $color-grey-1;
        color: $color-brand;
        margin-block-start: $gutter-xs;
        margin-inline-end: $gutter-xs;

        &:hover {
            background-color: $color-brand-alt;
            border-color: $color-brand-alt;
            color: $color-text-alt;
        }
    }

    &.gw-card:hover {
        box-shadow: none;
        transform: none;
    }

    &__content {
        display: block;
    }

    &__title,
    &__text {
        color: $color-white;
        margin-block-end: $gutter-l;
    }
    /* stylelint-enable max-nesting-depth */
}

@if mixin-exists(gw-cta-card) {
    @include gw-cta-card;
}
