.gw-product-search-results {
    @include bp-medium {
        margin-inline-end: -$layout-main-bleed;
        margin-inline-start: -$layout-main-bleed;
        padding-inline-end: $layout-main-bleed;
        padding-inline-start: $layout-main-bleed;
    }
    background-color: $color-white; // Force it to be white so we don't have to keep overriding in themes.
    margin-block-end: $gutter-s;
    margin-inline-end: -$layout-main-bleed-mobile;
    margin-inline-start: -$layout-main-bleed-mobile;
    padding: $gutter-s $layout-main-bleed-mobile 0;

    h1,
    [id='products-search-filters'] {
        @include visually-hide;
    }

    &__form {
        // Make flex items stack vertically on small screens.
        @include bp-xsmall {
            align-items: flex-start;
            flex-flow: row wrap;
        }

        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin-block-end: 0;
        max-width: 100%;
        position: relative;

        > * {
            margin-block-end: math.div($gutter-l, 2.5);
            margin-inline-end: math.div($gutter-l, 2.5);

            /* stylelint-disable max-nesting-depth */
            &:last-child {
                margin-inline-end: 0;
            }
            /* stylelint-enable max-nesting-depth */
        }

        > .gw-button {
            display: inline-flex;
        }

        // Hide both icons on "All stock" button.
        .has-no-condition {
            .gw-icon {
                display: none;
            }
        }

        > .gw-form__field {
            @include bp-medium {
                margin-inline-start: auto;
                width: auto;

                /* stylelint-disable max-nesting-depth */
                &:not(.is-hidden-on-medium) + .gw-form__field {
                    margin-inline-start: 0;
                }
                /* stylelint-enable max-nesting-depth */
            }
            width: 100%;

            .gw-form__label {
                /* stylelint-disable max-nesting-depth */
                > span:not([class]):first-child {
                    @include visually-hide;
                }
                /* stylelint-enable max-nesting-depth */
            }

            .gw-form__select {
                border-radius: $border-radius-small;
                font-size: $font-size;
                height: auto;
                padding: $gutter-xs $gutter-l $gutter-xs $gutter-xs;
            }
        }

        [role='region'][aria-live] {
            @include bp-medium {
                margin-inline-start: 0;
            }
            margin-inline-start: auto;

            .gw-button--small {
                padding: math.div($gutter-s, 2) $gutter-s math.div($gutter-s, 2)
                    math.div($gutter-s, 2);
            }
        }
    }

    [data-id='products-filter-button'] {
        border: 0;
        color: $color-brand;
        padding-inline-end: 0;
        padding-inline-start: 0;

        .gw-icon {
            height: $icon-size-l;
            width: $icon-size-l;
        }
    }

    [data-id='stock-compare'] {
        border: 0;
        color: $color-brand;

        .gw-icon {
            fill: transparent;
            height: $icon-size-l;
            stroke: $color-brand;
            stroke-width: $icon-stroke-width;
            width: $icon-size-l;
        }

        &.is-selected {
            .gw-icon {
                fill: $color-brand;
            }
        }
    }

    &--stock {
        @include bp-medium {
            background-color: $color-background;
            inset-block-start: 0;
            padding-block-start: 0;
            padding-inline-start: 0;
            position: sticky;
            z-index: 1;
        }

        + * > section[aria-labelledby='products-search-results'] {
            @include bp-medium {
                margin-top: $gutter-l;
            }
        }

        .gw-product-search-results {
            &__filters {
                /* stylelint-disable max-nesting-depth */
                @include bp-medium {
                    display: grid;
                    grid-template-columns: 20.625rem 1fr;
                }

                .gw-filter-sidebar__toggle {
                    @include bp-medium {
                        background-color: transparent;
                        border-radius: 0;
                        height: 100%;
                        margin: 0;
                        padding-block: $gutter-s;
                        padding-inline-start: $gutter-s;
                    }

                    .gw-icon {
                        &:nth-child(2) {
                            display: none;
                        }
                    }

                    &:active {
                        transform: none;
                    }

                    &.open {
                        @include bp-medium {
                            background-color: white;
                            margin: 0;
                        }

                        .gw-icon {
                            &:first-child {
                                display: none;
                            }

                            &:nth-child(2) {
                                display: block;
                            }
                        }
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }

            &__form {
                /* stylelint-disable max-nesting-depth */
                @include bp-medium {
                    align-items: center;
                    justify-content: center;
                    margin-inline-start: $gutter;
                    padding-block-start: $gutter-s;
                    width: 100%;
                }

                align-items: start;
                justify-content: space-between;
                margin-block: 0;

                [role='region'][aria-live] {
                    @include bp-medium {
                        width: auto;
                    }

                    margin-inline-start: 0;
                    width: 100%;

                    .gw-button--small {
                        @include bp-medium {
                            padding-inline-start: math.div($gutter-s, 2);
                        }

                        background-color: transparent;
                        padding-inline-start: 0;

                        &:hover {
                            color: $color-brand;
                            text-decoration: underline;
                        }
                    }
                }

                > .gw-form__field {
                    // This small breakpoint needs to be applied, as narrow screens need to have full width form inputs
                    @include bp-xsmall {
                        width: 100%;
                    }

                    @include bp-medium {
                        width: auto;
                    }

                    width: 45%;
                }
                /* stylelint-enable max-nesting-depth */
            }
        }
    }

    .gw-form__buttons {
        flex-basis: 100%;
    }

    .gw-grid {
        // i.e. 2 normal Grid column widths.
        @include responsive-grid-columns(
            $auto-layout: auto-fill,
            $max-width: $grid-item-min-width * 2
        );

        .gw-form__field {
            justify-content: flex-start;
        }
    }

    // When grid is inside tabs (or accordion on small screens).
    .gw-tabs,
    .gw-accordion {
        .gw-grid {
            @include responsive-grid-columns($max-width: 12.5rem);
        }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    [role='region'][aria-live] {
        margin-inline-end: 0;
    }

    [id='products-search-filters'] {
        flex-basis: 100%;
        margin-inline-end: 0;
    }

    & + [aria-labelledby='products-search-results'] {
        [id='products-search-results'] {
            font-size: $font-size-m;
        }
    }

    // JS enabled.
    &.is-js-enabled {
        [data-id='products-filter-content'] {
            display: none;
        }
    }

    // Modal (for filters).
    &__modal {
        .gw-modal__inner {
            max-height: 98%;
            padding-inline-end: $gutter - $gutter-s; // Subtract product filter padding.
            width: 98%;
        }

        .gw-button[class*='--text'] {
            @include bp-small {
                font-size: $font-size-s;
                padding: $gutter-xs;
            }
        }
    }

    .gw-button--toggle {
        &.is-selected:not(.has-no-condition) {
            /* stylelint-disable-next-line max-nesting-depth */
            &:nth-child(1) {
                display: block;
            }
        }

        &[class*='--text'].is-selected {
            background-color: rgba(var(--themeColorBrandAlt), 0.05);
            border-color: $color-brand-alt;
            color: $color-brand-alt;

            /* stylelint-disable-next-line max-nesting-depth */
            &:hover:not(:disabled) {
                background-color: rgba(var(--themeColorBrandAlt), 0.05);
                border-color: $color-brand-alt;
                color: $color-brand-alt;
            }
        }
    }
}

// This mixin function calls in any THEME specific overrides from the './scss/_THEMES' folder, but only if it exists.
@if mixin-exists(gw-product-search-results) {
    @include gw-product-search-results;
}
