@mixin gw-button {
    .gw-button {
        @at-root {
            [data-whatintent='keyboard'] &:focus {
                box-shadow: none;
                outline: $border-width-m $border-style $color-black;
                outline-offset: math.div($gutter-xs, 2);
                outline-style: $border-style;
            }
        }

        letter-spacing: 0.1rem;

        &:disabled {
            color: $color-grey-2;

            &.gw-button--primary,
            &.gw-button--secondary,
            &.gw-button--positive,
            &.gw-button--negative {
                &[class*='--text'] {
                    background-color: $color-grey-2;
                    border-color: $color-grey-2;
                    box-shadow: none;
                    color: $color-text-alt;
                }
            }
        }

        &[class*='--text'] {
            @at-root {
                [data-whatintent='keyboard'] &:focus {
                    box-shadow: none;
                    outline: $border-width-m $border-style $color-black;
                    outline-offset: math.div($gutter-xs, 2);
                    outline-style: $border-style;
                }
            }
            background-color: transparent;
            border-color: transparent;
            color: $color-link;
            font-family: $font-family;
            font-weight: normal;
            padding-inline-end: $gutter;
            padding-inline-start: $gutter;
            transition: none;

            &.gw-button--primary,
            &.gw-button--positive {
                @include button-primary;
            }

            &.gw-button--secondary,
            &.gw-button--negative {
                @include button-secondary;
            }

            &.gw-button--toggle {
                @at-root {
                    /* stylelint-disable max-nesting-depth */
                    [data-whatintent='keyboard'] &:focus {
                        box-shadow: none;
                        outline: $border-style $color-black;
                        outline-offset: math.div($gutter-xs, 2);
                        outline-style: $border-style;
                    }
                    /* stylelint-enable max-nesting-depth */
                }

                background-color: transparent;
                border-color: $color-grey-3;
                border-radius: $border-radius-medium-small;
                color: $color-text-secondary;

                &:hover:not(:disabled) {
                    background-color: transparent;
                    border-color: $color-grey-4;
                    color: $color-text-secondary;
                    opacity: 1;
                    transform: scale(1);
                }

                &.is-selected {
                    background-color: transparent;
                    border-color: $color-brand-alt;
                    border-width: $border-width-m;
                    box-shadow: none;
                    color: $color-text;
                }

                &.is-selected:hover {
                    border-color: $color-brand-alt;
                    color: $color-text;
                }
            }

            &:hover:not(:disabled) {
                color: $color-link-hover;
                transition: none;

                &.gw-button--positive,
                &.gw-button--negative {
                    opacity: 1;
                    text-decoration: none;
                    transform: scale(1);
                }
            }

            &.gw-button--floating {
                background-color: transparent;
                border-color: $color-link;
                color: $color-link;

                &:hover:not(:disabled) {
                    background-color: $color-link-hover;
                    border-color: $color-link-hover;
                    color: $color-text-alt;
                }

                .gw-button__inner > span {
                    text-transform: unset;
                }

                &-small.gw-button[class*='--text'] {
                    padding-inline-end: $gutter-xs;
                    padding-inline-start: $gutter-xs;
                }

                &-medium.gw-button[class*='--text'] {
                    padding-inline-end: $gutter-s;
                    padding-inline-start: $gutter-s;
                }
            }
        }

        &--multi-toggle {
            border-color: $color-grey-3;

            .gw-form__field {
                color: $color-text;

                &.is-selected {
                    background-color: $color-brand-alt;
                }

                &.is-selected .gw-form__label {
                    color: $color-white;
                }
            }
        }
    }
}
