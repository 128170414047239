@mixin gw-branch-rotator {
    .gw-branch-rotator {
        font-family: $font-family-title;
        font-size: $font-size;

        &__inner {
            > .gw-icon {
                display: none;
            }
        }

        &.gw-slider {
            padding-block-end: 0;

            [data-button-type] {
                border-radius: 0;
            }

            [data-button-type='next'] {
                @at-root {
                    /* stylelint-disable max-nesting-depth */
                    [dir='rtl'] & {
                        transform: none;
                    }

                    [data-whatintent='touch'] &:focus {
                        background-color: $color-grey-4;
                    }
                    /* stylelint-enable max-nesting-depth */
                }
                background-color: $color-grey-4;
                color: $color-text-alt;

                &:hover {
                    background-color: $color-brand;
                }
            }
        }

        .gw-slider__slide {
            @at-root {
                [data-whatintent='touch'] &:focus {
                    background-color: $color-grey-4;
                }
            }

            background-color: $color-grey-4;
            color: $color-text-alt;
        }

        &[data-hotline-theme='intense'] {
            .gw-branch-rotator__hotline-slide {
                @at-root {
                    /* stylelint-disable max-nesting-depth */
                    [data-whatintent='touch'] &:focus {
                        background-color: $color-negative;
                    }
                    /* stylelint-enable max-nesting-depth */
                }

                background-color: $color-negative;
                color: $color-text-alt;
            }

            .gw-branch-rotator__hotline-button {
                @at-root {
                    /* stylelint-disable max-nesting-depth */
                    [data-whatintent='touch'] &:focus {
                        background-color: $color-negative;
                    }
                    /* stylelint-enable max-nesting-depth */
                }
                background-color: $color-negative;
                color: $color-text-alt;
            }
        }

        .gw-button--icon {
            .gw-icon {
                fill: $color-text-alt;
            }
        }

        &__dropdown {
            padding-block-start: 0;
            padding-inline-end: 0;
            padding-inline-start: 0;
        }
    }
}
